import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../services/course/course.service';
import * as moment from 'moment';
import { UserService } from '../services/user/user.service';
import { isDate } from 'util';
import { NotificationService } from '../services/notification/notification.service';
import { AuthService } from '../services/auth/auth.service';
import { StateCityService } from '../services/state-city.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  course = null;
  cases = [];

  fullCourse = null;

  students = [];
  isAdmin = false;

  transaction = null;

  user = {
    createdAt: new Date(),
    name: null,
    email: null,
    gender: null,
    birthDate: null,
    city: null,
    state: null,
    address: null,
    district: null,
    complement: null,
    cellPhone: null,
    document: null,
    civilState: null,
    spouseName: null,
    spouseEmail: null,
    spouseCellPhone: null,
    children: null,
    optinNewsletter: null,
    profileId: null,
    hasChild: false,
    password: null,
    confirmPassword: null,
    number: null
  };

  isCompleted = false;

  civilState = [
    { id: 1, name: 'Solteiro(a)' },
    { id: 2, name: 'Casado(a)' },
  ];

  genders = [
    { id: 1, name: 'Masculino' },
    { id: 2, name: 'Feminino' },
  ];

  states = null;
  cities = null;

  constructor(
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private stateCityService: StateCityService
  ) { }

  async ngOnInit() {
    let loggedUser = AuthService.getUserByToken();

    this.user = await this.userService.getUser(loggedUser.id);

    this.fillUser();

    this.states = this.stateCityService.listState();

    this.isCompleted = true;
    // this.user.address && this.user.address.length > 0 &&
    // this.user.birthDate && this.user.birthDate.length > 0 &&
    // this.user.cellPhone && this.user.cellPhone.length > 0 &&
    // this.user.city && this.user.city.length > 0 &&
    // this.user.state && this.user.state.length > 0 &&
    // this.user.address && this.user.address.length > 0 &&
    // this.user.number && this.user.number.length > 0 &&
    // this.user.gender && this.user.gender.length > 0 &&
    // this.user.civilState && this.user.civilState.length > 0 &&
    // (
    //   this.user.civilState == 1 ||
    //   (this.user.civilState != 1 &&
    //     this.user.spouseName && this.user.spouseName.length > 0 &&
    //     this.user.spouseEmail && this.user.spouseEmail.length > 0 &&
    //     this.user.spouseCellPhone && this.user.spouseCellPhone.length > 0)
    // );


    if (this.user.state) {
      let previousCity = this.user.city;
      this.changeState(this.user.state);

      if (previousCity) {
        this.user.city = previousCity;
      }
    }

    await this.loadData();
  }

  fillUser() {
    if (!this.user.gender) {
      this.user.gender = '';
    }
    if (!this.user.birthDate) {
      this.user.birthDate = '';
    }
    if (!this.user.city) {
      this.user.city = '';
    }
    if (!this.user.state) {
      this.user.state = '';
    }
    if (!this.user.address) {
      this.user.address = '';
    }
    if (!this.user.district) {
      this.user.district = '';
    }
    if (!this.user.complement) {
      this.user.complement = '';
    }
    if (!this.user.cellPhone) {
      this.user.cellPhone = '';
    }
    if (!this.user.civilState) {
      this.user.civilState = '';
    }
    if (!this.user.spouseName) {
      this.user.spouseName = '';
    }
    if (!this.user.spouseEmail) {
      this.user.spouseEmail = '';
    }
    if (!this.user.spouseCellPhone) {
      this.user.spouseCellPhone = '';
    }
    if (!this.user.children) {
      this.user.children = '';
    }
    if (!this.user.hasChild) {
      this.user.hasChild = false;
    }
    if (!this.user.number) {
      this.user.number = '';
    }
  }

  async loadData() {
    this.cases = [];

    const user = this.userService.getLoggedUser();
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      return this.router.navigate(['/dashboard']);
    }
    this.fullCourse = await this.courseService.getFullCourse(id);

    // TODO: Verificar se assim está correto ou se é do jeito antigo
    // if ('course' in this.fullCourse) {
    //   this.course = this.fullCourse['course'];
    // }

    if (this.fullCourse && 'course' in this.fullCourse) {
      this.course = this.fullCourse['course'];
    }

    if ('course' in this.fullCourse && 'cases' in this.fullCourse.course) {
      let causes = this.fullCourse.course.cases;
      causes.map(c => c.teamPlenaryDate = new Date(c.teamPlenaryDate))
      causes.map(c => c.toggle = false)
      causes.sort((a, b) => a.teamPlenaryDate.getTime() - b.teamPlenaryDate.getTime());




      for (let courseCase of causes) {
        let answer = this.fullCourse.answers.find(a => a.caseId == courseCase.id && a.userId == user.id);
        if (answer) {
          courseCase.answer = answer;
        } else {
          courseCase.answer = {
            id: 0,
            actionPlan: '',
            allowShare: false,
            caseId: courseCase.id,
            courseId: id,
            createdAt: new Date(),
            facts: '',
            problems: '',
            solutions: '',
            userId: user.id
          }
        }
      }
      this.cases = causes;
      console.log("this.cases")
      console.log(this.cases)
    }


    this.isAdmin = user && (user.profile == 'Administrator' || user.profile == 'CEFF');
    if (this.isAdmin || this.fullCourse.isTeamLeader) {
      this.students = await this.courseService.listCourseUsers(id);
      console.log('list this.students');
      console.log(this.students);
      for (let student of this.students) {
        if ('isSpouse' in student) {

        } else {
          student.isSpouse = false;
        }
      }
      this.students = this.students.filter(s => !s.isSpouse);
    }
  }

  changeState(stateId) {
    this.user.city = null;
    this.cities = this.stateCityService.getCitiesByState(stateId);
  }

  editCourse() {
    const id = this.route.snapshot.paramMap.get('id');
    this.router.navigate(['/curso/atualizar/' + id])
  }

  async seeCaseDetails(courseCase: any) {
    let user = AuthService.getUserByToken();
    courseCase.toggle = !courseCase.toggle;
    if (user.profile == "CEFF" || user.profile == "Administrator" || this.isTeamLeader()) {
      let answers = await this.courseService.listUsersAnswers(courseCase.id);
      courseCase.answers = answers;
    }
  }

  isTeamLeader() {
    let user = AuthService.getUserByToken();
    return user.email == this.course.teamLeaderOne ||
      user.email == this.course.teamLeaderTwo ||
      user.email == this.course.teamLeaderThree ||
      user.email == this.course.teamLeaderFour ||
      user.email == this.course.teamLeaderFive ||
      user.email == this.course.teamLeaderSix;
  }

  getPaymentLink() {
    if (this.fullCourse.url) {
      return this.fullCourse.url;
    }

    return this.fullCourse.course.paymentExternalLink;
  }

  async createPaymentTransaction() {
    this.transaction = await this.courseService.createPagseguroTransaction(this.course.id);
    if (!this.transaction) {
      this.notificationService.notification$.next({
        type: 'error',
        message: 'Falha ao criar cobrança. Entre em contato com o Administrador'
      });
      return false;
    }

    if (this.transaction.urlCheckOut) {
      // this.showSuccessMessage = true;
      window.open(this.transaction.urlCheckOut, '_blank');
      setTimeout(() => window.location.reload(), 5000);
    }
    // this.openPagseguroPaymentModal(this.transaction.id, this.transaction.checkoutCode);
  }

  getCaseStatus(courseCase) {
    const teamMeetingDate = new Date(courseCase.teamMeetingDate);
    const teamPlenaryDate = new Date(courseCase.teamPlenaryDate);
    const now = new Date;
    if (moment().isBefore(teamMeetingDate)) {
      return 'waiting';
    }
    if (moment().isAfter(teamPlenaryDate)) {
      return 'available';
    }
    return 'processing'
  }

  getNextCaseAppointment() {
    let today = new Date();
    let dates = [];
    if (this.cases) {
      this.cases.map((courseCase, idx) => {
        if (courseCase.teamMeetingDate) {
          let teamMeetingDate = new Date(courseCase.teamMeetingDate);
          if (teamMeetingDate > today) {
            dates.push({
              date: moment(teamMeetingDate).toDate(),
              meeting: 'Reunião de equipe',
              case: idx + 1
            });
          }
        }
        if (courseCase.teamPlenaryDate) {
          let teamPlenaryDate = new Date(courseCase.teamPlenaryDate);
          if (teamPlenaryDate > today) {
            dates.push({
              date: moment(teamPlenaryDate).toDate(),
              meeting: 'Reunião plenária',
              case: idx + 1
            });
          }
        }
      });
      dates.sort((a, b) => a.date.getTime() - b.date.getTime());
      // return dates && dates.length > 0 ? 'Caso ' + dates[0].case + ', ' +dates[0].meeting + ': ' + moment(dates[0].date).format('DD/MM/YYYY HH:mm') + 'hrs' : '';
      return dates && dates.length > 0 ? 'Caso ' + dates[0].case + ', ' + dates[0].meeting : '';
    }
    return '-;'
  }

  goToCaseDetails(id) {
    const url = this.router.createUrlTree(['/curso/caso/' + id])
    window.open(url.toString(), '_blank')
  }

  goToUserDetail(userId) {
    const url = this.router.createUrlTree(['/usuario/' + userId])
    window.open(url.toString(), '_blank')
  }

  getPaymentStatus(status: any) {
    if (!status) return 'Não pago';

    switch (status.toString()) {
      case '1': return 'Aguardando pagamento';
      case '2': return 'Em análise';
      case '3': return 'Pago';
      case 'paid': return 'Pago;'
      case '4': return 'Disponível';
      case '5': return 'Em disputa';
      case '6': return 'Devolvido';
      case '7': return 'Cancelado';
      case '8': return 'Debitado';
      case '9': return 'Retenção temporária';
      default: return 'Aguardando pagamento';
    }
  }

  async saveAnswer(answer: any) {
    if (answer.id == 0) {
      const result = await this.courseService.createAnswer(answer);
      if (result) {
        this.notificationService.notification$.next({
          type: 'success',
          message: 'Informações salvas com sucesso'
        });
      } else {
        this.notificationService.notification$.next({
          type: 'success',
          message: 'Informações salvas com sucesso'
        });
      }
    } else {
      const result = await this.courseService.updateAnswer(answer);
      if (result) {
        this.notificationService.notification$.next({
          type: 'success',
          message: 'Informações salvas com sucesso'
        });
      } else {
        this.notificationService.notification$.next({
          type: 'success',
          message: 'Informações salvas com sucesso'
        });
      }
    }

    await this.loadData();
  }

  copyClipboard() {
    const id = this.route.snapshot.paramMap.get('id');
    let link = 'https://portalibf.com.br/inscricao/' + id;
    // let link = 'http://localhost:4200/inscricao/' + id;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async updateUserInfo() {
    await this.userService.updateUser(this.user);
    let loggedUser = AuthService.getUserByToken();
    this.user = await this.userService.getUser(loggedUser.id);
    this.fillUser();
    this.isCompleted =
      this.user.address && this.user.address.length > 0 &&
      this.user.birthDate && this.user.birthDate.length > 0 &&
      this.user.cellPhone && this.user.cellPhone.length > 0 &&
      this.user.city && this.user.city.length > 0 &&
      this.user.state && this.user.state.length > 0 &&
      this.user.gender && this.user.gender.length > 0 &&
      this.user.civilState && this.user.civilState.length > 0 &&
      (
        this.user.civilState == 1 ||
        (this.user.civilState != 1 &&
          this.user.spouseName && this.user.spouseName.length > 0 &&
          this.user.spouseEmail && this.user.spouseEmail.length > 0 &&
          this.user.spouseCellPhone && this.user.spouseCellPhone.length > 0)
      );
  }

  seeUser(studentId: any) {
    this.router.navigate(['/usuario/' + studentId]);
  }

  sevenMoreDaysToCourse() {
    let days = moment(this.fullCourse.course.startDate).diff(moment(new Date()), 'days');
    return days > 7;
  }

  goToLink(link: any) {
    window.open(link, "_blank");
  }

  isLink(link: string): boolean {
    return link.includes('.com');
  }

  adicionarHttps(url: string) {
    if (!url.startsWith('https://')) {
      return 'https://' + url;
    } else {
      return url;
    }
  }


  extrairTituloELink(link: string): [string[], string[]] {
    const parts = link.split(' ');

    let titulos = [];
    let linksFormatados = [];
    let titulo = "";
    for (const part of parts) {
      if (part.includes('.com')) {
        titulos.push(titulo);
        const linkFormatado = this.adicionarHttps(part);
        linksFormatados.push(linkFormatado);
        titulo = ""; // Reset the titulo variable
      } else {
        titulo += part + ' '; // Add space between parts
      }
    }
    return [titulos, linksFormatados];
  }

  formatarLinks(teamMeetingAddress: string): string {
    // Extrair o título e o link do teamMeetingAddress
    const [titulos, linksFormatados] = this.extrairTituloELink(teamMeetingAddress);

    // Verificar se o link é válido
    if (linksFormatados.length === 0) {
        return titulos.join(' '); // Retornar apenas o título se o link não for válido
    }

    // Criar os links formatados como HTML clicável
    const linksHTML = linksFormatados.map((link, index) => {
        const titulo = titulos[index];
        
        return `<a target="_blank">${titulo} </a> <a href="${link}" target="_blank">${link}</a> <br>`;
    });

    return linksHTML.join(' '); // Retornar os links formatados
}

}
