import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { CourseService } from '../services/course/course.service';
import { NotificationService } from '../services/notification/notification.service';
import { UserService } from '../services/user/user.service';
import * as moment from 'moment';
import { cpfValidate, emailValidate } from '../utils/formValidator';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { StateCityService } from '../services/state-city.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

const ufToState = {
  'AC': 'Acre',
  'AL': 'Alagoas',
  'AP': 'Amapá',
  'AM': 'Amazonas',
  'BA': 'Bahia',
  'CE': 'Ceará',
  'DF': 'Distrito Federal',
  'ES': 'Espírito Santo',
  'GO': 'Goiás',
  'MA': 'Maranhão',
  'MT': 'Mato Grosso',
  'MS': 'Mato Grosso do Sul',
  'MG': 'Minas Gerais',
  'PA': 'Pará',
  'PB': 'Paraíba',
  'PR': 'Paraná',
  'PE': 'Pernambuco',
  'PI': 'Piauí',
  'RJ': 'Rio de Janeiro',
  'RN': 'Rio Grande do Norte',
  'RS': 'Rio Grande do Sul',
  'RO': 'Rondônia',
  'RR': 'Roraima',
  'SC': 'Santa Catarina',
  'SP': 'São Paulo',
  'SE': 'Sergipe',
  'TO': 'Tocantins'
};

@Component({
  selector: 'app-course-registration-logged-out',
  templateUrl: './course-registration-logged-out.component.html',
  styleUrls: ['./course-registration-logged-out.component.scss']
})

export class CourseRegistrationLoggedOutComponent implements OnInit {
  states$: Observable<Array<any>>;
  cities$: Observable<Array<any>>;
  stateIdS = null;
  cityIdS = null;

  courseId = null;
  course = null;
  courseUsers = [];
  shouldEnableSpouseEmail = false;
  
  courseUser = {
    createdAt: new Date(),
    courseId: null,
    userId: null,
    profile: 1,
    spouseEmail: ''
  };

  transaction = null;

  isModalOpened = false;

  spouseInfo = {
    id: null,
    email: null,
    name: null,
    document: null,
    complement: null
  };

  isPaymentProccessed = false;

  validatedSpouseDocument = false;

  showMoreDetails = false;

  showSuccessMessage = false;

  isClosed = false;

  cases = [];

  subscribeEnabled = false;

  isLogged = false;

  user = null;

  courseAddress = {
    cep: '',
    city: '',
    state: '',
    number: '',
    address: '',
    complement: '', 
    district: '',
  };

  acceptTerms = false;
  acceptCoupleTerms = false;

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private router: Router,
    private notificationService: NotificationService,
    private userService: UserService,
    private stateCityService: StateCityService
  ) { }

  async ngOnInit() {
    if (window.screen.width > 960) {
      this.showMoreDetails = true;
    }

    this.isLogged = AuthService.getToken() != null;

    if (this.isLogged) {
      let loggedUser = AuthService.getUserByToken();
      this.user = await this.userService.getUser(loggedUser.id);
    }

    this.courseId = this.route.snapshot.paramMap.get('id');
    this.course = await this.courseService.getFullCourseDescription(this.courseId);

    if (this.course.isCoupleCourse == 'false' || !this.course.isCoupleCourse) {
      this.shouldEnableSpouseEmail = false;
    } else if (this.course.isCoupleCourse == 'true' || this.course.isCoupleCourse) {
      this.shouldEnableSpouseEmail = true;
    }

    if ('cases' in this.course) {
      const causes = this.course.cases;
      causes.map(c => c.teamPlenaryDate = new Date(c.teamPlenaryDate))
      causes.map(c => c.toggle = false)
      this.cases = causes;
      this.cases.sort((a, b) => a.teamPlenaryDate.getTime() - b.teamPlenaryDate.getTime());
    }

    this.isClosed = moment().isAfter(this.course.subscribeLimitDate);
    this.courseUsers = await this.courseService.listUserCourses();

    if (this.isUserAlreadyRegistered()) {
      return this.router.navigate(['/curso/' + this.courseId]);
    }

    this.courseUser.courseId = this.courseId;

    if (this.router.url.indexOf('notpaid') != -1) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Finalize o pagamento para concluir a inscrição'
      });
      this.isPaymentProccessed = true;
    }

    this.states$ = of(this.stateCityService.listState());
    this.cities$ = of(this.stateCityService.listCity());
  }

  isUserAlreadyRegistered() {
    return this.courseUsers.filter(c => c.courseId === this.courseId).length > 0;
  }

  getPaymentMsg() {
    let courseUser = this.courseUsers.find(c => c.courseId === this.courseId);
    if (courseUser.status == 'Waiting') {
      return 'Você já está inscrito nesse curso. Estamos aguardando a confirmação do seu pagamento. Acesse a aba de cursos para acompanhar!';
    }

    return 'Tivemos problema ao processar seu pagamento. Tente novamente!';
  }

  shouldReproccessPayment() {
    let courseUser = this.courseUsers.find(c => c.courseId === this.courseId);
    return courseUser.status != 'Waiting';
  }

  changeSpouseDocument(document) {
    this.validatedSpouseDocument = false;
    this.spouseInfo = {
      id: null,
      email: null,
      name: null,
      document: this.spouseInfo.document,
      complement: ''
    };
  }

  async User() {
    if (this.course.isCoupleCourse) {
      this.isModalOpened = true;
    } else {
      await this.registerUserOnCourse();
    }
  }

  async createPaymentTransaction() {
    this.transaction = await this.courseService.createPagseguroTransaction(this.course.id);
    if (!this.transaction) {
      this.notificationService.notification$.next({
        type: 'error',
        message: 'Falha ao criar cobrança. Entre em contato com o Administrador'
      });
      return false;
    }

    if (this.transaction.urlCheckOut) {
      this.showSuccessMessage = true;
      if (!this.course.isOnline) {
        window.open(this.transaction.urlCheckOut, '_blank');
      }
    }
    await this.courseService.saveSpouseCourseUser({
      createdAt: new Date(),
      courseId: this.courseUser.courseId,
      userId: this.spouseInfo.id,
      profile: 1
    }, this.transaction.id);
  }

  closeModal() {
    this.isModalOpened = false;
  }

  async goToPayment() {
    if (!this.spouseInfo.document || !cpfValidate(this.spouseInfo.document)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um cpf válido para o cônjuge'
      });
      return false;
    }
    if (!this.spouseInfo.email || !emailValidate(this.spouseInfo.email)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um e-mail válido para o cônjuge'
      });
      return false;
    }
    let lastName = this.spouseInfo.name.split(' ')
    if (!lastName) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome completo para prosseguir'
      });
      return false;
    }
    lastName = lastName.filter(l => l.length > 0);
    if (!this.spouseInfo.name || lastName.length <= 1) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome completo para prosseguir'
      });
      return false;
    }
    this.closeModal();
    await this.registerUserOnCourse();
  }

  userDidNotPaid() {
    window.location.href = environment.appUrl + 'curso/inscricao/notpaid/' + this.courseId
  }

  async registerUserOnCourse() {

    this.courseUser.spouseEmail = this.courseUser.spouseEmail.trim().toLowerCase();

    if (this.course.isCoupleCourse && !this.course.spouseEmail) {
      if (!this.spouseInfo.email || !emailValidate(this.spouseInfo.email)) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha um e-mail válido para o cônjuge'
        });
        return false;
      }
      return false;
    } else {
      console.log('teste')
    }
    // let spouseUser = null;
    // if (this.course.isCoupleCourse && !this.spouseInfo.id) {
    //   this.spouseInfo = await this.userService.createSpouseUser(this.spouseInfo);
    // }
    // let userCourse = await this.courseService.registerUserOnCourse(this.courseUser)
    // if (userCourse) {
    //   await this.createPaymentTransaction();
    // }
  }

  async updateCourseTransaction(id, externalTransactionId) {

    let courseTransaction = {
      id,
      externalTransactionId
    };

    await this.courseService.updateCourseTransaction(courseTransaction);

    if (this.course.isCoupleCourse && this.spouseInfo.id) {
      await this.courseService.saveSpouseCourseUser({
        createdAt: new Date(),
        courseId: this.courseUser.courseId,
        userId: this.spouseInfo.id,
        profile: 1
      }, this.transaction.id);
    }

    window.location.href = environment.appUrl + 'curso/' + this.course.id;
  }

  async deleteCourseTransaction(id) {
    await this.courseService.deleteCourseTransaction(id);
  }

  getFullDescription() {
    if (!this.course) {
      return '';
    }
    if (this.showMoreDetails || this.course.fullDescription.length <= 128) {
      return this.course.fullDescription;
    } else {
      return this.course.fullDescription.substr(0, 128) + '...';
    }
  }

  async searchUserByCpf() {
    let user = await this.userService.getUserByCpf(this.spouseInfo.document);
    this.validatedSpouseDocument = true;
    if (user) {
      this.spouseInfo.email = user.email;
      this.spouseInfo.name = user.name;
      this.spouseInfo.id = user.id;
    }
  }

  addInterestList() {

  }

  enableSubscription() {
    if(!this.course.isCoupleCourse) {
      return this.acceptTerms;
    }

    return this.acceptTerms && this.acceptCoupleTerms;
  }

  subscribe() {
    this.subscribeEnabled = true;
  }

  login() {
    localStorage.setItem('redirectCourseSubscription', this.courseId.toString());
    this.router.navigate(['/login']);
  }

  registerUser() {
    localStorage.setItem('redirectCourseSubscription', this.courseId.toString());
    this.router.navigate(['/registrar']);
  }

  async getCep() {
    let cep = this.courseAddress.cep.replace(/\D/g, '');
    let cepAddress = await this.userService.getAddressByCep(cep);
    this.courseAddress.city = cepAddress && cepAddress.localidade ? cepAddress.localidade : '';
    this.courseAddress.state = cepAddress && cepAddress.uf ? cepAddress.uf : '';
    this.courseAddress.address = cepAddress && cepAddress.logradouro ? cepAddress.logradouro : '';
    this.courseAddress.district = cepAddress && cepAddress.bairro ? cepAddress.bairro : '';
  }

  async inSubscriptionUpdateUser() {
    let cep = this.courseAddress.cep.replace(/\D/g, '');
    let cepAddress = await this.userService.getAddressByCep(cep);

    if (cepAddress && cepAddress.uf) {
      const stateName = ufToState[cepAddress.uf];
  
      // Subscribe to states$ observable to get the array of states
      this.states$.subscribe(states => {
        // Find the state object by name
        const selectedState = states.find(state => state.name === stateName);
        console.log(selectedState);
        if (selectedState) {
          this.courseAddress.cep = cep;
          this.stateIdS = selectedState.id;

          const selectedCityId$ = this.cities$.pipe(
            map(cities => cities.find(city => city.name === cepAddress.localidade && city.stateId === selectedState.id)),
            map(selectedCity => selectedCity ? selectedCity.id : null) // Optional: handle case where city is not found
          );
          
          selectedCityId$.subscribe(
            cityId => {
              this.cityIdS = cityId;
                console.log('ID da cidade:', cityId);
            },
            error => {
                console.error('Erro:', error);
            }
          );
        }
      });
    }
    // Cria um objeto contendo todos os dados necessários
    const updatedUser: any = {
      id: this.userService.getLoggedUser().id,
      cep: this.courseAddress.cep,
      city: this.cityIdS,
      state: this.stateIdS,
      number: this.courseAddress.number,
      address: this.courseAddress.address,
      complement: this.courseAddress.complement,
      district: this.courseAddress.district,
    };
  
    // Adiciona spouseEmail condicionalmente
    if (this.shouldEnableSpouseEmail) {
      updatedUser.civilState = '2';
      updatedUser.spouseEmail = this.user.spouseEmail.trim().toLowerCase();;
      updatedUser.spouseCellPhone = this.user.spouseCellPhone;
      updatedUser.spouseName = this.user.spouseName;
    }

    // Chama o serviço de atualização do usuário com o objeto criado
    await this.userService.updateUser(updatedUser);
  
    // Atualiza a informação do usuário localmente
    this.user = await this.userService.getUser(this.userService.getLoggedUser().id);
  
  }

  cleanPhoneNumber(phoneNumber: string) {
    // Remove todos os caracteres não numéricos e espaços em branco
    return phoneNumber.replace(/[^\d]/g, '');
  }

  async completeSubscription() {
    // Validar que está tudo preenchido do endereço
    if (!this.courseAddress) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: "Para finalizar a inscrição, precisamos do seu endereço completo. Insira o CEP, estado, cidade, rua, número e complemento (se houver)."
      });
      return false;
    }
    if (!this.courseAddress.cep) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: "Preencha um CEP válido"
      });
      return false;
    }
    if (!this.courseAddress.state) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: "Preencha um estado válido"
      });
      return false;
    }
    if (!this.courseAddress.city) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: "Preencha uma cidade válida"
      });
      return false;
    }
    if (!this.courseAddress.address) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: "Preencha um endereço válido"
      });
      return false;
    }
    if (!this.courseAddress.number) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: "Preencha um número válido"
      });
      return false;
    }


    if (this.course.isCoupleCourse){
      if(!emailValidate(this.user.spouseEmail)){
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha um e-mail válido para o cônjuge'
        });
        return false;
      }
      if(this.user.spouseName.length === 0){
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o nome completo cônjuge'
        });
        return false;
      }
      if(this.user.spouseCellPhone.length === 0){
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha um celular válido para o cônjuge'
        });
        return false;
      }
      this.user.spouseEmail = this.user.spouseEmail.trim().toLowerCase();
    }
    let userCourse = await this.courseService.registerUserOnCourse(this.courseUser);
    if (userCourse) {
      this.transaction = await this.courseService.createIuguTransaction({
        courseId: this.course.id,
        address: this.courseAddress,
        userId: this.user.id
      });
      if (!this.transaction) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'Falha ao criar cobrança. Entre em contato com o Administrador'
        });
        return false;
      }

      if (this.transaction.url) {
        this.showSuccessMessage = true;
        window.open(this.transaction.url, '_blank');
      }

      this.inSubscriptionUpdateUser();

      this.router.navigate(['/curso/' + this.courseId]);
    }
  }
}
