import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  user = null;

  isAdmin = false;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getLoggedUser();
    this.isAdmin = this.user && this.user.profile == 'Administrator';
  }

  isUserAuthorized(profile: Array<string>) {
    return AuthService.isUserAuthorized(profile);
  }

}
