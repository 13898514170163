import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  static getToken(): string {
    return localStorage.getItem('token');
  }

  // static getUserByToken() {
  //   const token = localStorage.getItem('token');
  //   console.log("token="+token);
  //   if (token) {
  //     try {
  //       console.log("jsontoken= " + JSON.parse(atob(token.split('.')[1])));
  //       return JSON.parse(atob(token.split('.')[1]));
  //     } catch {
  //       return null;
  //     }
  //   }
  //   return null;
  // }
  static getUserByToken() {
    const token = localStorage.getItem('token');
    console.log("token=" + token);
    
    if (token) {
      try {
        const base64Url = token.split('.')[1];
        if (!base64Url) {
          throw new Error("Invalid token format.");
        }
        // Decode Base64Url (handle possible padding issues)
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const decodedPayload = atob(base64);
        
        console.log("jsontoken= " + decodedPayload);
        return JSON.parse(decodedPayload);
        
      } catch (error) {
        console.error("Failed to decode token:", error);
        return null;
      }
    }
    return null;
  }

  static isUserAuthorized(allowedProfiles: Array<string>): boolean {
    const user = this.getUserByToken();
    console.log("allowedProfiles = "+allowedProfiles);
    console.log("user = "+user);
    if (user && user.profile && allowedProfiles.filter(ap => ap === user.profile).length > 0) {
      return true;
    }
    return false;
  }
}
