import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../services/course/course.service';
import { NotificationService } from '../services/notification/notification.service';
import { StateCityService } from '../services/state-city.service';
import { UserService } from '../services/user/user.service';
import * as moment from 'moment';
import { templateSourceUrl } from '@angular/compiler';

@Component({
  selector: 'app-upsert-course',
  templateUrl: './upsert-course.component.html',
  styleUrls: ['./upsert-course.component.scss']
})
export class UpsertCourseComponent implements OnInit {

  isEditionMode = false;

  today = new Date();

  course = {
    id: null,
    isOnline: false,
    isPilot: false,
    isCoupleCourse: false,
    title: null,
    city: null,
    state: null,
    startDate: null,
    endDate: null,
    subscribeLimitDate: null,
    closedForSubscription: false,
    bookUrl: null,
    maxStudents: null,
    fullDescription: null,
    cases: [],
    amount: 0,
    paymentExternalLink: '',
    ceffId: null,
    introduction: null,
    introductionUrl: null,
    courseImgUri: null,
    teamLeaderOne: null,
    teamLeaderTwo: null,
    teamLeaderThree: null,
    teamLeaderFour: null,
    teamLeaderFive: null,
    teamLeaderSix: null,
    moreDetails: null,
    promotionalDate: null,
    promotionMeetingUrl: null,
    ceffContact: null,
    promotionId: '',
    templateId: null
  };

  cases: any[] = [];
  ceffs: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  templates: any[] = [];

  selectedTemplateId = null;

  constructor(
    private stateCityService: StateCityService,
    private courseService: CourseService,
    private notificationService: NotificationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.isEditionMode = true;
      this.course = await this.courseService.getCourseById(id);
      this.course.promotionId = this.course.templateId;
      this.selectedTemplateId = this.course.templateId;

      if (this.course && this.course.cases && this.course.cases.length > 0) {
        this.course.cases.sort((a, b) => new Date(a.teamMeetingDate).getTime() - new Date(b.teamMeetingDate).getTime())
        this.course.cases.map(c => {
          this.cases.push({
            id: c.id,
            courseId: c.courseId,
            title: c.title,
            description: c.description,
            teamMeetingAddress: c.teamMeetingAddress,
            teamMeetingDate: c.teamMeetingDate,
            teamPlenaryAddress: c.teamPlenaryAddress,
            teamPlenaryDate: c.teamPlenaryDate,
            urlPdf: c.urlPdf,
            plenaryVideoUrl: c.plenaryVideoUrl
          });
        })
      }
    }
    this.states = this.stateCityService.listState();
    this.cities = this.stateCityService.listCity();
    this.ceffs = await this.userService.listCeffs();
    this.templates = await this.courseService.listCourseTemplates();
  }

  changeTemplate() {

    this.cases = [];

    if (this.selectedTemplateId) {
      let template = this.templates.find(t => t.id == this.selectedTemplateId);
      this.course.introduction = template.introduction;
      this.course.introductionUrl = template.introductionUrl;
      this.course.fullDescription = template.fullDescription;
      this.course.courseImgUri = template.courseImgUri;
      this.course.isCoupleCourse = template.isCoupleCourse;
      for (const courseCase of template.cases) {
        this.cases.push({
          id: 0,
          courseId: null,
          title: courseCase.title,
          description: courseCase.title,
          teamMeetingAddress: null,
          teamMeetingDate: null,
          teamPlenaryAddress: null,
          teamPlenaryDate: null,
          urlPdf: courseCase.urlPdf,
          plenaryVideoUrl: null
        });
      }
    }
  }

  changeState(stateId) {
    this.course.city = null;
    this.cities = this.stateCityService.getCitiesByState(stateId)
  }

  newCase() {
    this.cases.push({
      id: 0,
      courseId: null,
      title: null,
      description: null,
      teamMeetingAddress: null,
      teamMeetingDate: null,
      teamPlenaryAddress: null,
      teamPlenaryDate: null,
      urlPdf: null,
      plenaryVideoUrl: null
    });
  }

  validate() {
    if (!this.course.title) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome do curso'
      });
      return false;
    }
    if (!this.course.amount || this.course.amount === 0) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o preço do curso'
      });
      return false;
    }
    if (!this.course.maxStudents) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o número máximo de alunos'
      });
      return false;
    }
    if (!this.course.fullDescription) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a descrição do caso'
      });
      return false;
    }
    if (!this.course.isOnline && !this.course.state) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o estado'
      });
      return false;
    }
    if (!this.course.isOnline && !this.course.city) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a cidade'
      });
      return false;
    }
    if (!this.course.startDate) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a data de início'
      });
      return false;
    }
    if (!this.course.endDate) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a data de término'
      });
      return false;
    }
    if (!this.course.subscribeLimitDate) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a data de limite de inscrição'
      });
      return false;
    }
    if (moment(this.course.startDate).isAfter(this.course.endDate)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'A data de início não pode ser posterior a data de término'
      });
      return false;
    }

    if (moment(this.course.subscribeLimitDate).isAfter(this.course.endDate)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'A data limite de inscrição não pode ser posterior a data de término'
      });
      return false;
    }
    return this.validateCases();
  }

  validateCases() {
    if (!this.cases || this.cases.length === 0) {
      return false;
    }

    let isValid = true;
    for (let i = 0; i < this.cases.length; i++) {
      if (!this.cases[i].title) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o título do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      if (!this.cases[i].description) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o descrição do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      if (!this.cases[i].title) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o descrição do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      if (!this.cases[i].teamMeetingAddress) {
        // this.notificationService.notification$.next({
        //   type: 'warning',
        //   message: 'Preencha o endereço ou link da reunião de equipe do caso ' + (i + 1)
        // });
        // isValid = false;
        // break;
        // Verifica o endereço de reunião de equipe para todos os cursos
        // a partir da primeira reunião
        if (i > 0) {
          this.notificationService.notification$.next({
            type: 'warning',
            message: 'Preencha o endereço ou link da reunião de equipe do caso ' + (i + 1)
          });
          isValid = false;
          break;
        }
        else {
          // Verifica se o endereço de reunião de equipe do primeiro caso do AM1 e AM2 está preenchido
          // Outros cursos não precisam (não tem reunião de equipe antes da plenária)
          if ((this.selectedTemplateId === '386659ad-d76d-4bc3-8674-0123b4c6f56e' || this.selectedTemplateId === '2594ed0e-af93-40ad-ad44-3409290173ca')) {
            this.notificationService.notification$.next({
              type: 'warning',
              message: 'Preencha o endereço ou link da reunião de equipe do caso ' + (i + 1)
            });
            isValid = false;
            break;
          }
        }
      }
      if (!this.cases[i].teamMeetingDate) {
        // this.notificationService.notification$.next({
        //   type: 'warning',
        //   message: 'Preencha a data da reunião de equipe do caso ' + (i + 1)
        // });
        // isValid = false;
        // break;
        // Verifica as datas de reunião de equipe para todos os cursos
        // a partir da primeira reunião
        if (i > 0) {
          this.notificationService.notification$.next({
            type: 'warning',
            message: 'Preencha a data da reunião de equipe do caso ' + (i + 1)
          });
          isValid = false;
          break;
        }
        else {
          // Verifica se a data de reunião de equipe do primeiro caso do AM1 e AM2 está preenchido
          // Outros cursos não precisam (não tem reunião de equipe antes da plenária)
          if ((this.selectedTemplateId === '386659ad-d76d-4bc3-8674-0123b4c6f56e' || this.selectedTemplateId === '2594ed0e-af93-40ad-ad44-3409290173ca')) {
            this.notificationService.notification$.next({
              type: 'warning',
              message: 'Preencha a data da reunião de equipe do caso ' + (i + 1)
            });
            isValid = false;
            break;
          }
          else {
            // Se é a primeira reunião de equipe, está vazio e não é AM1 e AM2
            // deve ser null
            const date = new Date(1900, 0, 1, 11, 11);
            const formattedDate = date.toISOString();
            const year = formattedDate.slice(0, 4);
            const month = formattedDate.slice(5, 7);
            const day = formattedDate.slice(8, 10);
            const hour = formattedDate.slice(11, 13);
            const minute = formattedDate.slice(14, 16);
            const finalDate = `${year}-${month}-${day}T${hour}:${minute}`;
            this.cases[i].teamMeetingDate = finalDate;
          }
        }
      }
      if (!this.cases[i].teamPlenaryDate) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha a data da plenária do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  async save() {
    if (this.validate()) {
      this.course.promotionId = this.selectedTemplateId;
      if (this.isEditionMode) {
        const course = await this.courseService.updateCourse(this.course);
        await this.courseService.updateAllCourseCases(this.course.id, this.cases);
        this.router.navigate(['/curso/' + course.id]);
      } else {
        const course = await this.courseService.createCourse(this.course);
        for (let courseCase of this.cases) {
          courseCase.courseId = course.id;
          const caseResult = await this.courseService.createCourseCase(courseCase);
        }
        this.router.navigate(['/curso/' + course.id]);
      }
    }
  }

  removeCase(index) {
    this.cases.splice(index, 1);
  }

  minPromotionalDate(): string {
    const today = new Date();
    today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
    return today.toISOString().slice(0, 16);
  }

  // Convert the UTC date to UTC-3
  convertToLocalTime(utcDate: string): string {
    const date = new Date(utcDate);
    // Adjusting the time to UTC-3 (subtracting 3 hours)
    date.setHours(date.getHours() + 3);
    console.log("utcDate=" + utcDate);
    console.log("date=" + date);
    // Return the adjusted date in the correct format
    return date.toISOString().slice(0, 16);
  }

  // Convert the input back to UTC before saving
  convertToUTC(localDate: string): string {
    const date = new Date(localDate);
    // Adjusting the time to UTC (adding 3 hours to get UTC from UTC-3)
    date.setHours(date.getHours() + 3);
    // Return the adjusted date as an ISO string
    return date.toISOString();
  }

}
