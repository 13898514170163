import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/services/course/course.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-user-transactions',
  templateUrl: './user-transactions.component.html',
  styleUrls: ['./user-transactions.component.scss']
})
export class UserTransactionsComponent implements OnInit {
  user: any;
  transactions: Array<any>;

  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private courseService: CourseService) { }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.user = await this.userService.getUser(id);
    this.transactions = await this.courseService.getTransactionsByUserId(id);
  }

}
