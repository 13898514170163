import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '../services/course/course.service';
import { StateCityService } from '../services/state-city.service';
import { UserService } from '../services/user/user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-opened-subscriptions',
  templateUrl: './opened-subscriptions.component.html',
  styleUrls: ['./opened-subscriptions.component.scss']
})
export class OpenedSubscriptionsComponent implements OnInit {

  openedSubscriptions: any[] = [];
  teamLeaderCourses: any[] = [];

  states = [];
  cities = [];

  isAdmin = false;

  constructor(
    private userService: UserService,
    private stateCityService: StateCityService,
    private courseService: CourseService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.states = this.stateCityService.listState();
    const user = this.userService.getLoggedUser();
    this.isAdmin = user && (user.profile == 'Administrator' || user.profile == 'CEFF');
    this.teamLeaderCourses = await this.courseService.listTeamLeaderCourse();
    let maxDate = new Date(2099, 1, 1, 1, 1, 1, 1);
    // this.openedSubscriptions = await this.courseService.listNextCourses(moment('01/01/2023').format('YYYY-MM-DD'), moment(maxDate).format('YYYY-MM-DD'));
    // let currentYear = new Date().getFullYear();
    // let minDate = `01/01/${currentYear}`;
    let minDate = new Date();
    this.openedSubscriptions = await this.courseService.listNextCourses(moment(minDate).format('YYYY-MM-DD'), moment(maxDate).format('YYYY-MM-DD'));
    for (let i = 0; i < this.openedSubscriptions.length; i++) {
      let state = this.states.find(s => s.id == this.openedSubscriptions[i].state);
      let city = this.stateCityService.getCity(this.openedSubscriptions[i].city);
      this.openedSubscriptions[i].cityName = city.name;
      this.openedSubscriptions[i].stateName = state.name;
    }
    console.log(this.openedSubscriptions)
  }

  seeCourse(id: any) {
    this.router.navigate(['/curso/inscricao/' + id]);
  }



}
