import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user/user.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { AuthenticateChooseCompanyComponent } from './authenticate-choose-company/authenticate-choose-company.component';
import { BotComponent } from './bot/bot.component';
import { RegisterComponent } from './register/register.component';
import { CourseComponent } from './course/course.component';
import { CaseExecutionComponent } from './case-execution/case-execution.component';
import { CoursesComponent } from './courses/courses.component';
import { UsersComponent } from './users/users.component';
import { NewCourseComponent } from './new-course/new-course.component';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { CourseRegistrationComponent } from './course-registration/course-registration.component';
import { HelpComponent } from './help/help.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { OpenedSubscriptionsComponent } from './opened-subscriptions/opened-subscriptions.component';
import { UpsertCourseComponent } from './upsert-course/upsert-course.component';
import { MyCoursesComponent } from './my-courses/my-courses.component';
import { PlataformInitComponent } from './plataform-init/plataform-init.component';
import { OnlinecourseslistComponent } from './onlinecourseslist/onlinecourseslist.component';
import { CourseRegistrationLoggedOutComponent } from './course-registration-logged-out/course-registration-logged-out.component';
import { UserTransactionsComponent } from './user/user-transactions/user-transactions.component';
import { MyProfileComponent } from './my-profile/my-profile.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'ajuda', component: HelpComponent },
  { path: 'login/:courseId', component: LoginComponent },
  { path: 'registrar', component: RegisterComponent },
  { path: 'registrar/:courseId', component: RegisterComponent },
  { path: 'recover-password', component: RecoveryPasswordComponent },
  { path: 'dashboard', component: UserDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'cursos', component: CoursesComponent, canActivate: [AuthGuardService] },
  { path: 'cursos/inscritos', component: MyCoursesComponent },
  { path: 'cursos/inscricoes', component: OpenedSubscriptionsComponent },
  { path: 'lista/cursos', component: CourseListComponent },
  { path: 'lista/cursos/nacional', component: OnlinecourseslistComponent },
  { path: 'lista/cursos/curso/:id', component: CourseDetailComponent },
  { path: 'curso/inscricao/:id', component: CourseRegistrationComponent },
  { path: 'inscricao/:id', component: CourseRegistrationLoggedOutComponent },
  { path: 'curso/inscricao/notpaid/:id', component: CourseRegistrationComponent, canActivate: [AuthGuardService] },
  { path: 'curso/criar', component: UpsertCourseComponent, canActivate: [AuthGuardService] },
  { path: 'curso/atualizar/:id', component: UpsertCourseComponent, canActivate: [AuthGuardService] },
  { path: 'curso/novo', component: NewCourseComponent, canActivate: [AuthGuardService] },
  { path: 'curso/editar/:id', component: NewCourseComponent, canActivate: [AuthGuardService] },
  { path: 'usuarios', component: UsersComponent, canActivate: [AuthGuardService] },
  { path: 'usuario/:id', component: UserComponent, canActivate: [AuthGuardService] },
  { path: 'usuario/:id/transacoes', component: UserTransactionsComponent, canActivate: [AuthGuardService] },
  { path: 'curso/:id', component: CourseComponent, canActivate: [AuthGuardService] },
  { path: 'curso/caso/:id', component: CaseExecutionComponent, canActivate: [AuthGuardService] },
  { path: 'profiles', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'autenticar', component: AuthenticateChooseCompanyComponent, canActivate: [AuthGuardService] },
  { path: 'dashboard', component: UserDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'meu-perfil', component: MyProfileComponent, canActivate: [AuthGuardService] },
  { path: '**', component: PlataformInitComponent }
  // { path: '**', component: DashboardComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
