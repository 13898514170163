import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { ProfileService } from '../services/profile/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StateCityService } from '../services/state-city.service';
import { CourseService } from '../services/course/course.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

// Para ambientes de navegador
async function getStateByCodeFromAPI(code: number): Promise<string> {
  try {
    const response = await fetch(`https://servicodados.ibge.gov.br/api/v2/malhas?cod_area=${code}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.nome || 'Estado não encontrado';
  } catch (error) {
    return 'Erro ao buscar estado';
  }
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  states$: Observable<Array<any>>;
  cities$: Observable<Array<any>>;
  user = null;
  courses = [];

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private courseService: CourseService,
    private stateCityService: StateCityService

  ) { }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if(!id) {
      return this.router.navigate(['/dashboard']);
    }
    this.user = await this.userService.getUser(id);
    this.courses = await this.courseService.listUserCourses(id);
    console.log(this.courses);
    this.states$ = of(this.stateCityService.listState());
    this.cities$ = of(this.stateCityService.listCity());
  }

  getState(stateId) {
    const state = this.stateCityService.getState(stateId);
    return state && state.name ? state.name : '';
  }

  getCity(cityId) {
    const city = this.stateCityService.getCity(cityId);
    return city && city.name ? city.name : '';
  }

  getCivilState(civilState) {
    switch(Number(civilState)) {
      case 1: return 'Solteiro(a)';
      case 2: return 'Casado(a)';
      case 3: return 'Divorciado(a)';
      case 2: return 'Viúvo(a)';
      default: 'Não informado'
    }
  }

  seeCourse(courseId: any) {
    this.router.navigate(['/curso/' + courseId]);
  }
}
