import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { CourseService } from '../services/course/course.service';
import * as moment from 'moment';
import { UserService } from '../services/user/user.service';
import { StateCityService } from '../services/state-city.service';
import { NotificationService } from '../services/notification/notification.service';
import { cellPhoneValidate } from '../utils/formValidator';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  activeMenu = 'myCourses';

  myCourses = [];
  nextCourses = [];
  users = [];

  isRegisterModalOpened = false;
  user = null;

  civilState = [
    { id: 1, name: 'Solteiro(a)' },
    { id: 2, name: 'Casado(a)' },
  ];

  genders = [
    { id: 1, name: 'Masculino' },
    { id: 2, name: 'Feminino' },
  ];

  states = [];
  cities = [];

  teamLeaderCourses: any[] = [];

  constructor(
    private courseService: CourseService,
    private stateCityService: StateCityService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.states = this.stateCityService.listState();
    this.teamLeaderCourses = await this.courseService.listTeamLeaderCourse();

    let userLogged = this.userService.getLoggedUser();

    if (AuthService.isUserAuthorized(['Administrator']) || AuthService.isUserAuthorized(['CEFF'])) {
      this.activeMenu = 'courses';
    } else {

      this.myCourses = await this.courseService.listUserCourses();

      if (this.teamLeaderCourses && this.teamLeaderCourses.length > 0) {
        for (let course of this.teamLeaderCourses) {
          if (this.myCourses.filter(m => m.courseId == course.id).length === 0) {
            course.isPaid = true;
            this.myCourses.push({
              course: course,
              courseId: course.id,
              createdAt: new Date(),
              id: course.id,
              isPaid: true,
              profile: 3,
              status: "Paid",
              userId: userLogged.id
            });
          }
        }
      }

    }
    const loggedUser = this.userService.getLoggedUser();
    this.user = await this.userService.getUser(loggedUser.id);

    if (!this.user.birthDate || !this.user.city || !this.user.state || !this.user.cellPhone ||
      !this.user.civilState || !this.user.gender) {
      this.isRegisterModalOpened = true;
    }
  }

  isUserAuthorized(profile) {
    return AuthService.isUserAuthorized([profile]);
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  async changeActiveMenu(menu) {
    switch (menu) {
      case 'nextCourses':
        let maxDate = new Date(2099, 1, 1, 1, 1, 1, 1);
        this.nextCourses = await this.courseService.listNextCourses(moment().format('YYYY-MM-DD'), moment(maxDate).format('YYYY-MM-DD'));

        break;
    }
    this.activeMenu = menu;
  }

  goToCoursePage(id) {
    const url = this.router.createUrlTree(['/curso/' + id])
    window.open(url.toString(), '_blank')
  }

  getCity(cityId) {
    const city = this.stateCityService.getCity(Number(cityId));
    return city && city.name ? city.name : '';
  }

  getState(stateId) {
    const state = this.stateCityService.getState(stateId);
    return state && state.name ? state.name : '';
  }

  changeState(stateId) {
    this.user.city = null;
    this.cities = this.stateCityService.getCitiesByState(stateId);
  }

  validateRegister() {
    let lastName = this.user.name.split(' ')
    if (!lastName) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome completo para prosseguir'
      });
      return false;
    }
    lastName = lastName.filter(l => l.length > 0);
    if (!this.user.name || lastName.length <= 1) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome completo para prosseguir'
      });
      return false;
    }
    if (!this.user.cellPhone || !cellPhoneValidate(this.user.cellPhone)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um telefone válido para prosseguir'
      });
      return false;
    }
    if (!this.user.gender) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o gênero para prosseguir'
      });
      return false;
    }
    if (!this.user.birthDate) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha uma data de nascimento válida para prosseguir'
      });
      return false;
    }
    if (!this.user.state) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Selecione um estado para prosseguir'
      });
      return false;
    }
    if (!this.user.city) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Selecione uma cidade para prosseguir'
      });
      return false;
    }
    if (!this.user.address) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um endereço para prosseguir'
      });
      return false;
    }
    if (!this.user.district) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um bairro para prosseguir'
      });
      return false;
    }
    if (!this.user.civilState) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Selecione um estado civil para prosseguir'
      });
      return false;
    }
    if (this.user.civilState != 1) {
      if (this.user.hasChild == null || this.user.children == null) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha a quantidade de filhos para prosseguir'
        });
        return false;
      }
      if (this.user.civilState == 2 && !this.user.spouseName) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o nome de seu cônjuge para prosseguir'
        });
        return false;
      }
      if (this.user.civilState == 2 && !this.user.spouseEmail) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o e-mail de seu cônjuge para prosseguir'
        });
        return false;
      }
      if (this.user.civilState == 2 && !this.user.spouseCellPhone) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o telefone de seu cônjuge para prosseguir'
        });
        return false;
      }
    }
    return true;
  }

  async saveUser() {
    if (this.validateRegister()) {
      if (await this.userService.updateUser(this.user)) {
        this.user = this.userService.getUser(this.user.id);
        this.isRegisterModalOpened = false;
      }
    }
  }

  closeModal() {
    this.isRegisterModalOpened = false;
  }
}
