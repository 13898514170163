import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() pageTitle: any;

  @Input() hideOptions: boolean = false;

  @Input() showNotLoggedOptions: boolean = false;

  isSideMenuOpened = false;

  user = null;

  isAdmin = false;

  menuOpened = false;

  width = 0;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.width = window.innerWidth;
    this.user = this.userService.getLoggedUser();
    this.isAdmin = this.user && this.user.profile == 'Administrator';
  }

  openMenu() {
    this.menuOpened = !this.menuOpened;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  goHome() {
    this.router.navigate(['/']);
  }

  newCourse() {
    this.router.navigate(['/curso/novo']);
  }

  goToCourses() {
    this.router.navigate(['/lista/cursos']);
  }

  help() {
    this.router.navigate(['/ajuda']);
  }

  isUserAuthorized(profile: Array<string>) {
    return AuthService.isUserAuthorized(profile);
  }

}
