import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../services/course/course.service';
import { NotificationService } from '../services/notification/notification.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-case-execution',
  templateUrl: './case-execution.component.html',
  styleUrls: ['./case-execution.component.scss']
})
export class CaseExecutionComponent implements OnInit {

  userCaseAnswers = {
    id: null,
    caseId: null,
    courseId: null,
    createdAt: null,
    facts: null,
    problems: null,
    solutions: null,
    userId: null,
    actionPlan: null,
    allowShare: false
  };

  course = null;

  isAdmin = false;

  isTeamLeader = false;

  usersAnswers = [];

  facts = [];
  problems = [];
  solutions = [];

  constructor(
    private courseService: CourseService,
    private notificationService: NotificationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    const user = this.userService.getLoggedUser();
    this.isAdmin = user && user.profile == 'Administrator';

    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      return this.router.navigate(['/dashboard']);
    }

    if (this.isAdmin) {
      await this.listAnswers();
    }

    let caseAnswers = await this.courseService.getUserCaseExecution(id);
    this.course = await this.courseService.getCourseCase(id);

    if (this.course && this.course.courseUser && this.course.courseUser.profile == 2) {
      this.isTeamLeader = true;
      await this.listAnswers();
    }

    if (this.course && this.course.isTeamLeader) {
      this.isTeamLeader = true;
      await this.listAnswers();
    }
    if (caseAnswers) {
      this.userCaseAnswers = caseAnswers;
    }
    this.userCaseAnswers.caseId = id;
    this.userCaseAnswers.courseId = this.course.course.id;
  }

  async listAnswers() {
    const id = this.route.snapshot.paramMap.get('id');
    this.usersAnswers = await this.courseService.listUsersAnswers(id);
    this.usersAnswers.forEach(answer => {
      if (answer.user && answer.facts && answer.facts.length > 0) {
        this.facts.push({
          value: answer.facts,
          user: answer.user ? answer.user.name : null
        });
      }
      if (answer.user && answer.problems && answer.problems.length > 0) {
        this.problems.push({
          value: answer.problems,
          user: answer.user ? answer.user.name : null
        });
      }
      if (answer.user && answer.solutions && answer.solutions.length > 0) {
        this.solutions.push({
          value: answer.solutions,
          user: answer.user ? answer.user.name : null
        });
      }
    })
  }

  async saveUserAnswers() {
    const result = await this.courseService.saveAnswers(this.userCaseAnswers);
    if (result) {
      this.notificationService.notification$.next({
        type: 'success',
        message: 'Informações salvas com sucesso'
      });
    } else {
      this.notificationService.notification$.next({
        type: 'success',
        message: 'Informações salvas com sucesso'
      });
    }
  }

  changeAllowShare(allowShare) {

    this.userCaseAnswers.allowShare = allowShare == 'true';
  }
}
