import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '../services/course/course.service';
import { StateCityService } from '../services/state-city.service';
import { UserService } from '../services/user/user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  @Input() coursesArr: any;

  courses = [];
  states = [];
  cities = [];

  isAdmin = false;

  filterCourses = 'all';
  filteredCourses = [];

  teamLeaderCourses: any[] = [];

  templateCourses: any[] = [];

  selectedCourse = null;
  user: any;

  constructor(
    private userService: UserService,
    private stateCityService: StateCityService,
    private courseService: CourseService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.user = this.userService.getLoggedUser();
    this.isAdmin = this.user && (this.user.profile == 'Administrator' || this.user.profile == 'CEFF');
    if (!this.isAdmin) {
      return this.router.navigate(['/dashboard']);
    }
    this.templateCourses = await this.courseService.listCourseTemplates();
    this.teamLeaderCourses = await this.courseService.listTeamLeaderCourse();
    this.states = this.stateCityService.listState();
  }

  async selectCourse(template: any) {
    this.selectedCourse = template;
    this.courses = await this.courseService.listCoursesByTemplateId(template.id);
    if (this.user.profile == 'CEFF') {
      this.courses = this.courses.filter(course => course.ceffId == this.user.id)
    }
    this.filteredCourses = [...this.courses];
  }

  createNewCourse() {
    this.router.navigate(['/curso/criar']);
  }

  seeCourse(courseId) {
    this.router.navigate(['/curso/' + courseId]);
  }

  getCity(cityId) {
    const city = this.stateCityService.getCity(cityId);
    return city && city.name ? city.name : '';
  }

  getState(stateId) {
    const state = this.stateCityService.getState(stateId);
    return state && state.name ? state.name : '';
  }

  changeState(state) {
    if (state == 'all') {
      this.cities = [];
      this.filteredCourses = [...this.courses];
      return;
    }
    this.cities = this.stateCityService.getCitiesByState(state);
  }

  changeCity(city) {
    if (city == 'all') {
      this.filteredCourses = [...this.courses];
      return;
    }
    else {
      this.filteredCourses = this.courses.filter(course => course.city == city);
    }
  }

}
