import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { WorkerEvaluationComponent } from './worker-evaluation/worker-evaluation.component';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user/user.component';
import { AddUserComponent } from './user/add-user/add-user.component';
import { UserCardComponent } from './user/user-card/user-card.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { NotificationComponent } from './notification/notification.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { AuthenticateChooseCompanyComponent } from './authenticate-choose-company/authenticate-choose-company.component';
import { BotComponent } from './bot/bot.component';
import { RegisterComponent } from './register/register.component';
import { MyCoursesComponent } from './my-courses/my-courses.component';
import { NextCoursesComponent } from './next-courses/next-courses.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { CourseComponent } from './course/course.component';
import { CaseExecutionComponent } from './case-execution/case-execution.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CoursesComponent } from './courses/courses.component';
import { UsersComponent } from './users/users.component';
import { NewCourseComponent } from './new-course/new-course.component';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { CourseRegistrationComponent } from './course-registration/course-registration.component';
import { ModalComponent } from './modal/modal.component';
import { HelpComponent } from './help/help.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { OpenedSubscriptionsComponent } from './opened-subscriptions/opened-subscriptions.component';
import { UpsertCourseComponent } from './upsert-course/upsert-course.component';
import { PlataformInitComponent } from './plataform-init/plataform-init.component';
import { OnlinecourseslistComponent } from './onlinecourseslist/onlinecourseslist.component';
import { CourseRegistrationLoggedOutComponent } from './course-registration-logged-out/course-registration-logged-out.component';
import { UserTransactionsComponent } from './user/user-transactions/user-transactions.component';
import { HeaderWithoutLoginComponent } from './header-without-login/header-without-login.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localeBr, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    WorkerEvaluationComponent,
    ProfileComponent,
    UserComponent,
    AddUserComponent,
    UserCardComponent,
    LoaderSpinnerComponent,
    NotificationComponent,
    RecoveryPasswordComponent,
    AuthenticateChooseCompanyComponent,
    BotComponent,
    RegisterComponent,
    MyCoursesComponent,
    NextCoursesComponent,
    MyProfileComponent,
    CourseComponent,
    CaseExecutionComponent,
    CoursesComponent,
    UsersComponent,
    NewCourseComponent,
    CourseListComponent,
    CourseDetailComponent,
    CourseRegistrationComponent,
    ModalComponent,
    HelpComponent,
    UserDashboardComponent,
    OpenedSubscriptionsComponent,
    UpsertCourseComponent,
    PlataformInitComponent,
    OnlinecourseslistComponent,
    CourseRegistrationLoggedOutComponent,
    UserTransactionsComponent,
    HeaderWithoutLoginComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
  entryComponents: [
    NotificationComponent,
    ModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
