import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, from, Observable, of } from 'rxjs';
import { startWith, debounceTime, map } from 'rxjs/operators';
import { StateCityService } from '../services/state-city.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  cities$: Observable<Array<any>>;
  states: Array<any>;
  users$: Observable<any>;
  filteredUsers$: Observable<Array<any>>;
  filteredCities$: Observable<Array<any>>;
  states$: Observable<Array<any>>;

  searchByName: FormControl;
  searchByCpf: FormControl;
  searchByEmail: FormControl;
  searchByAddrState: FormControl;
  searchByAddrCity: FormControl;

  searchByNameQuery$: Observable<string>;
  searchByCpfQuery$: Observable<string>;
  searchByAddrStateQuery$: Observable<number>;
  searchByAddrCityQuery$: Observable<number>;
  searchByEmailQuery$: Observable<string>;

  constructor(
    private userService: UserService,
    private stateCityService: StateCityService,
    private router: Router
  ) { }

  ngOnInit() {
    this.cities$ = of(this.stateCityService.listCity());
    this.states$ = of(this.stateCityService.listState());
    this.users$ = from(this.userService.listUsers());

    this.searchByName = new FormControl('');
    this.searchByEmail = new FormControl('');
    this.searchByCpf = new FormControl('');
    this.searchByAddrState = new FormControl();
    this.searchByAddrCity = new FormControl();

    this.searchByNameQuery$ = this.searchByName.valueChanges.pipe(startWith(''), debounceTime(100));
    this.searchByCpfQuery$ = this.searchByCpf.valueChanges.pipe(startWith(''), debounceTime(100));
    this.searchByAddrStateQuery$ = this.searchByAddrState.valueChanges.pipe(startWith(0), debounceTime(100));
    this.searchByAddrCityQuery$ = this.searchByAddrCity.valueChanges.pipe(startWith(0), debounceTime(100));
    this.searchByEmailQuery$ = this.searchByEmail.valueChanges.pipe(startWith(''), debounceTime(100));


    this.filteredUsers$ = combineLatest([
      this.users$,
      this.searchByNameQuery$,
      this.searchByCpfQuery$,
      this.searchByAddrStateQuery$,
      this.searchByAddrCityQuery$,
      this.searchByEmailQuery$]).pipe(
        map(([users, name, cpf, addrState, addrCity, email]) => {
          let usersFilteredByName = name ? users.filter(this.filterUserByName(name)) : users;
          let usersFilteredByCpf = cpf ? usersFilteredByName.filter(this.filterUserByCpf(cpf)) : usersFilteredByName;
          let usersFilteredByAddrState = addrState ? usersFilteredByCpf.filter(this.filterUserByAddrState(addrState)) : usersFilteredByCpf;
          let usersFilteredByAddrCity = addrCity ? usersFilteredByAddrState.filter(this.filterUserByAddrCity(addrCity)) : usersFilteredByAddrState;
          let usersFilteredByEmail = email ? usersFilteredByAddrCity.filter(this.filterUserByEmail(email)) : usersFilteredByAddrCity;
          return usersFilteredByEmail;
        })
      );

    this.onStateChange();
  }

  getCity(cityId) {
    const city = this.stateCityService.getCity(cityId);
    return city && city.name ? city.name : '';
  }

  getState(stateId) {
    const state = this.stateCityService.getState(stateId);
    return state && state.name ? state.name : '';
  }

  getUserDetails(userId) {
    return this.router.navigate(['/usuario/' + userId]);
  }

  filterUserByCpf(filterValue: string) {
    return user => user.document.replace('.', '').toLowerCase().includes(filterValue.replace('.', ''));
  }

  filterUserByName(filterValue: string) {
    return user => user.name.toLowerCase().includes(filterValue);
  }

  filterUserByAddrState(filterValue: number) {
    return user => user.state == filterValue;
  }

  filterUserByAddrCity(filterValue: number) {
    return user => user.city == filterValue;
  }

  filterUserByEmail(filterValue: string) {
    return user => user.email.toLowerCase().includes(filterValue);
  }

  navigateToUserDetails(user: any) {
    if (this.isUserMarried(user)) this.router.navigate(['usuarios'])
  }

  isUserMarried(user: any): boolean {
    return user.civilState == 2;
  }

  onStateChange() {
    this.searchByAddrStateQuery$ = this.searchByAddrState.valueChanges.pipe(startWith(''));
    this.filteredCities$ = combineLatest([this.cities$, this.searchByAddrStateQuery$]).pipe(
      map(([cities, state]) => {
        let citiesFilteredByState = state ? cities.filter(this.filterCityByState(state)) : cities;
        return citiesFilteredByState;
      })
    )
  }

  filterCityByState(filterValue) {
    return city => city.stateId == filterValue;
  }

}
