export const cpfValidate = (cpf: string) => {
  cpf = cpf.replace(/\D/g, '');
  if(cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }
  let result = true;
  [9, 10].forEach(j => {
      let soma = 0;
      let r;
      cpf.split(/(?=)/).splice(0,j).forEach((e, i) => {
          soma += Number(e) * ((j + 2) - (i + 1));
      });
      r = soma % 11;
      r = (r < 2) ? 0 : 11 - r;
      if(r !== Number(cpf.substring(j, j + 1))) {
        result = false;
      }
  });
  return result;
};

export const cnpjValidate = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g,'');
  if (cnpj === '' || cnpj.length !== 14) {
    return false;
  }
  if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999') {
    return false;
  }
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== Number(digitos.charAt(0))) {
    return false;
  }
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != Number(digitos.charAt(1))) {
    return false;
  }
  return true;
};

export const emailValidate = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const cellPhoneValidate = (cellphone: string) => {
  const re = /^\([1-9]{2}\)\s?[2-9][0-9]{3,4}\-?[0-9]{4}/;
  return re.test(cellphone);
}
