import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { Observable, combineLatest, of } from 'rxjs';
import { tap, startWith, map } from 'rxjs/operators';
import { NotificationService } from '../services/notification/notification.service';
import { StateCityService } from '../services/state-city.service';
import { UserService } from '../services/user/user.service';

const ufToState = {
  'AC': 'Acre',
  'AL': 'Alagoas',
  'AP': 'Amapá',
  'AM': 'Amazonas',
  'BA': 'Bahia',
  'CE': 'Ceará',
  'DF': 'Distrito Federal',
  'ES': 'Espírito Santo',
  'GO': 'Goiás',
  'MA': 'Maranhão',
  'MT': 'Mato Grosso',
  'MS': 'Mato Grosso do Sul',
  'MG': 'Minas Gerais',
  'PA': 'Pará',
  'PB': 'Paraíba',
  'PR': 'Paraná',
  'PE': 'Pernambuco',
  'PI': 'Piauí',
  'RJ': 'Rio de Janeiro',
  'RN': 'Rio Grande do Norte',
  'RS': 'Rio Grande do Sul',
  'RO': 'Rondônia',
  'RR': 'Roraima',
  'SC': 'Santa Catarina',
  'SP': 'São Paulo',
  'SE': 'Sergipe',
  'TO': 'Tocantins'
};

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  states$: Observable<Array<any>>;
  cities$: Observable<Array<any>>;
  filteredCities$: Observable<Array<any>>;
  searchByAddrStateQuery$: Observable<number>;
  profileForm: FormGroup;
  hasChildrenHasChanged$: Observable<boolean>;
  isMarriedHasChanged$: Observable<string>;
  user: any;

  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private stateCityService: StateCityService) { }

  async ngOnInit() {
    this.buildForm();
    this.states$ = of(this.stateCityService.listState());
    this.cities$ = of(this.stateCityService.listCity());
    this.user = await this.userService.getUser(this.userService.getLoggedUser().id);
    this.onHasChildrenChanged();
    this.onIsMarriedChanged();
    this.onStateChange();
    this.initializeForm();
  }

  buildForm() {
    this.profileForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      document: ['', [Validators.required]],
      birthDate: [null],
      cellPhone: [''],
      gender: [''],
      civilState: [''],
      hasChildren: [null, Validators.required],
      cep: [''],
      city: [''],
      state: [''],
      address: [''],
      number: [''],
      complement: [''],
      district: [''],
      zip: [''],
      spouseName: [''],
      spouseEmail: [''],
      spouseCellPhone: [''],
      children: [[]]
    });
  }

initializeForm() {
    this.profileForm.patchValue(this.user);
    console.log(this.user.birthDate);
    const formattedDate = moment.utc(this.user.birthDate).format('YYYY-MM-DD');
    this.profileForm.get('birthDate').setValue(formattedDate);
}

  onIsMarriedChanged() {
    this.isMarriedHasChanged$ = this.profileForm.get('civilState').valueChanges.pipe(
      tap((isMarried) => {
        if (isMarried == 'true') {
          this.profileForm.setControl('spouseName', new FormControl(''))
          this.profileForm.setControl('spouseCellPhone', new FormControl(''))
          this.profileForm.setControl('spouseEmail', new FormControl(''))
        }
        if (isMarried == 'false') {
          this.profileForm.removeControl('spouseName')
          this.profileForm.removeControl('spouseCellPhone')
          this.profileForm.removeControl('spouseEmail')
        }
      })
    )
    this.isMarriedHasChanged$.subscribe();
  }

  onHasChildrenChanged() {
    this.hasChildrenHasChanged$ = this.profileForm.get('hasChildren').valueChanges.pipe(
      tap((hasChildren) => {
        if (hasChildren === 'true') {
          let childrenForm: Array<FormGroup> = [];
          childrenForm.push(this.createChildFormItem());
          this.profileForm.setControl('children', this.formBuilder.array(
            childrenForm,
          ))
        }
        if (hasChildren === 'false') {
          this.profileForm.removeControl('children');
        }
      })
    );
    this.hasChildrenHasChanged$.subscribe();
  }

  onStateChange() {
    this.searchByAddrStateQuery$ = (this.profileForm.get(['state']) as FormControl).valueChanges.pipe(startWith(''));
    this.filteredCities$ = combineLatest([this.cities$, this.searchByAddrStateQuery$]).pipe(
      map(([cities, state]) => {
        let citiesFilteredByState = state ? cities.filter(this.filterCityByState(state)) : cities;
        return citiesFilteredByState;
      })
    )
  }

  createChildFormItem(): FormGroup {
    return this.formBuilder.group({
      birthDate: [null, Validators.required]
    })
  }

  get isMarried(): boolean {
    if (this.profileForm.get('civilState').value == 1) return false;
    if (this.profileForm.get('civilState').value == 2) return true;
  }

  get childrenForm(): FormArray {
    return <FormArray>this.profileForm.get('children');
  }

  get hasChildren(): boolean {
    if (this.profileForm.get('hasChildren').value == 'true') return true;
    if (this.profileForm.get('hasChildren').value == 'false') return false;
  }

  isFormValid(): boolean {
    return this.profileForm.valid;
  }

  addChildToForm() {
    this.childrenForm.push(this.createChildFormItem());
  }

  removeChild(index: number): void {
    this.childrenForm.removeAt(index);
  }

  filterCityByState(filterValue) {
    return city => city.stateId == filterValue;
  }

  async getCep() {
    let cep = this.profileForm.get('cep').value.replace(/\D/g, ''); // Remove non-numeric characters from CEP input  
    let cepAddress = await this.userService.getAddressByCep(cep);
    console.log("cepAddress:", cepAddress);
  
    if (cepAddress && cepAddress.uf) {
      const stateName = ufToState[cepAddress.uf];
  
      // Subscribe to states$ observable to get the array of states
      this.states$.subscribe(states => {
        // Find the state object by name
        const selectedState = states.find(state => state.name === stateName);
        console.log(selectedState);
        if (selectedState) {
          this.profileForm.patchValue({
            cep: cep,
            state: selectedState.id,
            city: null, // Reset city initially
            address: cepAddress.logradouro ? cepAddress.logradouro : '',
            district: cepAddress.bairro ? cepAddress.bairro : '',
          });
  
          // Subscribe to filteredCities$ to get cities filtered by state
          this.filteredCities$.subscribe(cities => {
            const selectedCity = cities.find(city => city.name === cepAddress.localidade);
            console.log(selectedCity);
            if (selectedCity) {
              this.profileForm.patchValue({
                city: selectedCity.id
              });
            }
          });
        }
      });
    }
  }

  async saveProfile() {
    if (this.isMarried){
      const trimmedLowerCaseEmail = this.profileForm.get('spouseEmail').value.trim().toLowerCase();
      this.profileForm.get('spouseEmail').setValue(trimmedLowerCaseEmail, { emitEvent: false });
    }
    console.log(this.profileForm.value);
    await this.userService.updateUser(this.profileForm.value);
    this.user = await this.userService.getUser(this.userService.getLoggedUser().id);
    this.notificationService.notification$.next({
      type: 'success',
      message: 'Usuário atualizado com sucesso'
    });
  }
}
