import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-plataform-init',
  templateUrl: './plataform-init.component.html',
  styleUrls: ['./plataform-init.component.scss']
})
export class PlataformInitComponent implements OnInit {

  isAdmin = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    let token = AuthService.getToken();

    this.isAdmin = AuthService.isUserAuthorized(['Administrator']);

    if(token) {
      this.router.navigate(['/dashboard']);
    }
  }

}
