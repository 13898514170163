import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../services/course/course.service';
import * as moment from 'moment';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit {

  course = null;

  transaction = null;

  isClosed = false;

  constructor(
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.course = await this.courseService.getCourseById(id);
    this.isClosed = moment().isAfter(this.course.subscribeLimitDate);
  }

  register() {
    this.router.navigate(['/registrar/' + this.course.id]);
  }

  async callPagseguroPaymentModal() {
    this.transaction = await this.courseService.createPagseguroTransaction(this.course.id);
  }

  getDates() {
    if(this.course && this.course.cases) {
      let dates = '';
      let datesArr = [];
      this.course.cases.sort((a,b) => new Date(a.teamPlenaryDate).getTime() - new Date(b.teamPlenaryDate).getTime());
      this.course.cases.forEach(c => {
        datesArr.push(moment(c.teamMeetingDate).format('DD/MM/YYYY'));
        datesArr.push(moment(c.teamPlenaryDate).format('DD/MM/YYYY'));
      });
      // datesArr.sort((a,b) => new Date(a).getTime() - new Date(b).getTime());
      dates = datesArr.join(', ');
      return dates;
    }
    return '-';
  }

}
