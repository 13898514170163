import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification/notification.service';

let dataState = [
  {id:11,name: "Rondônia"},
  {id:12,name: "Acre"},
  {id:13,name: "Amazonas"},
  {id:14,name: "Roraima"},
  {id:15,name: "Pará"},
  {id:16,name: "Amapá"},
  {id:17,name: "Tocantins"},
  {id:21,name: "Maranhão"},
  {id:22,name: "Piauí"},
  {id:23,name: "Ceará"},
  {id:24,name: "Rio Grande do Norte"},
  {id:25,name: "Paraíba"},
  {id:26,name: "Pernambuco"},
  {id:27,name: "Alagoas"},
  {id:28,name: "Sergipe"},
  {id:29,name: "Bahia"},
  {id:31,name: "Minas Gerais"},
  {id:32,name: "Espírito Santo"},
  {id:33,name: "Rio de Janeiro"},
  {id:35,name: "São Paulo"},
  {id:41,name: "Paraná"},
  {id:42,name: "Santa Catarina"},
  {id:43,name: "Rio Grande do Sul"},
  {id:50,name: "Mato Grosso do Sul"},
  {id:51,name: "Mato Grosso"},
  {id:52,name: "Goiás"},
  {id:53,name: "Distrito Federal"}
];

const dataCity = [
  {stateId: 53,  id: 9999999,  name: "Distrito Federal"},
  {stateId: 11,  id: 1100015,  name: "Alta Floresta D'Oeste"},
  {stateId: 11,  id: 1100379,  name: "Alto Alegre dos Parecis"},
  {stateId: 11,  id: 1100403,  name: "Alto Paraíso"},
  {stateId: 11,  id: 1100346,  name: "Alvorada D'Oeste"},
  {stateId: 11,  id: 1100023,  name: "Ariquemes"},
  {stateId: 11,  id: 1100452,  name: "Buritis"},
  {stateId: 11,  id: 1100031,  name: "Cabixi"},
  {stateId: 11,  id: 1100601,  name: "Cacaulândia"},
  {stateId: 11,  id: 1100049,  name: "Cacoal"},
  {stateId: 11,  id: 1100700,  name: "Campo Novo de Rondônia"},
  {stateId: 11,  id: 1100809,  name: "Candeias do Jamari"},
  {stateId: 11,  id: 1100908,  name: "Castanheiras"},
  {stateId: 11,  id: 1100056,  name: "Cerejeiras"},
  {stateId: 11,  id: 1100924,  name: "Chupinguaia"},
  {stateId: 11,  id: 1100064,  name: "Colorado do Oeste"},
  {stateId: 11,  id: 1100072,  name: "Corumbiara"},
  {stateId: 11,  id: 1100080,  name: "Costa Marques"},
  {stateId: 11,  id: 1100940,  name: "Cujubim"},
  {stateId: 11,  id: 1100098,  name: "Espigão D'Oeste"},
  {stateId: 11,  id: 1101005,  name: "Governador Jorge Teixeira"},
  {stateId: 11,  id: 1100106,  name: "Guajará-Mirim"},
  {stateId: 11,  id: 1101104,  name: "Itapuã do Oeste"},
  {stateId: 11,  id: 1100114,  name: "Jaru"},
  {stateId: 11,  id: 1100122,  name: "Ji-Paraná"},
  {stateId: 11,  id: 1100130,  name: "Machadinho D'Oeste"},
  {stateId: 11,  id: 1101203,  name: "Ministro Andreazza"},
  {stateId: 11,  id: 1101302,  name: "Mirante da Serra"},
  {stateId: 11,  id: 1101401,  name: "Monte Negro"},
  {stateId: 11,  id: 1100148,  name: "Nova Brasilândia D'Oeste"},
  {stateId: 11,  id: 1100338,  name: "Nova Mamoré"},
  {stateId: 11,  id: 1101435,  name: "Nova União"},
  {stateId: 11,  id: 1100502,  name: "Novo Horizonte do Oeste"},
  {stateId: 11,  id: 1100155,  name: "Ouro Preto do Oeste"},
  {stateId: 11,  id: 1101450,  name: "Parecis"},
  {stateId: 11,  id: 1100189,  name: "Pimenta Bueno"},
  {stateId: 11,  id: 1101468,  name: "Pimenteiras do Oeste"},
  {stateId: 11,  id: 1100205,  name: "Porto Velho"},
  {stateId: 11,  id: 1100254,  name: "Presidente Médici"},
  {stateId: 11,  id: 1101476,  name: "Primavera de Rondônia"},
  {stateId: 11,  id: 1100262,  name: "Rio Crespo"},
  {stateId: 11,  id: 1100288,  name: "Rolim de Moura"},
  {stateId: 11,  id: 1100296,  name: "Santa Luzia D'Oeste"},
  {stateId: 11,  id: 1101484,  name: "São Felipe D'Oeste"},
  {stateId: 11,  id: 1101492,  name: "São Francisco do Guaporé"},
  {stateId: 11,  id: 1100320,  name: "São Miguel do Guaporé"},
  {stateId: 11,  id: 1101500,  name: "Seringueiras"},
  {stateId: 11,  id: 1101559,  name: "Teixeirópolis"},
  {stateId: 11,  id: 1101609,  name: "Theobroma"},
  {stateId: 11,  id: 1101708,  name: "Urupá"},
  {stateId: 11,  id: 1101757,  name: "Vale do Anari"},
  {stateId: 11,  id: 1101807,  name: "Vale do Paraíso"},
  {stateId: 11,  id: 1100304,  name: "Vilhena"},
  {stateId: 12,  id: 1200013,  name: "Acrelândia"},
  {stateId: 12,  id: 1200054,  name: "Assis Brasil"},
  {stateId: 12,  id: 1200104,  name: "Brasiléia"},
  {stateId: 12,  id: 1200138,  name: "Bujari"},
  {stateId: 12,  id: 1200179,  name: "Capixaba"},
  {stateId: 12,  id: 1200203,  name: "Cruzeiro do Sul"},
  {stateId: 12,  id: 1200252,  name: "Epitaciolândia"},
  {stateId: 12,  id: 1200302,  name: "Feijó"},
  {stateId: 12,  id: 1200328,  name: "Jordão"},
  {stateId: 12,  id: 1200336,  name: "Mâncio Lima"},
  {stateId: 12,  id: 1200344,  name: "Manoel Urbano"},
  {stateId: 12,  id: 1200351,  name: "Marechal Thaumaturgo"},
  {stateId: 12,  id: 1200385,  name: "Plácido de Castro"},
  {stateId: 12,  id: 1200807,  name: "Porto Acre"},
  {stateId: 12,  id: 1200393,  name: "Porto Walter"},
  {stateId: 12,  id: 1200401,  name: "Rio Branco"},
  {stateId: 12,  id: 1200427,  name: "Rodrigues Alves"},
  {stateId: 12,  id: 1200435,  name: "Santa Rosa do Purus"},
  {stateId: 12,  id: 1200500,  name: "Sena Madureira"},
  {stateId: 12,  id: 1200450,  name: "Senador Guiomard"},
  {stateId: 12,  id: 1200609,  name: "Tarauacá"},
  {stateId: 12,  id: 1200708,  name: "Xapuri"},
  {stateId: 13,  id: 1300029,  name: "Alvarães"},
  {stateId: 13,  id: 1300060,  name: "Amaturá"},
  {stateId: 13,  id: 1300086,  name: "Anamã"},
  {stateId: 13,  id: 1300102,  name: "Anori"},
  {stateId: 13,  id: 1300144,  name: "Apuí"},
  {stateId: 13,  id: 1300201,  name: "Atalaia do Norte"},
  {stateId: 13,  id: 1300300,  name: "Autazes"},
  {stateId: 13,  id: 1300409,  name: "Barcelos"},
  {stateId: 13,  id: 1300508,  name: "Barreirinha"},
  {stateId: 13,  id: 1300607,  name: "Benjamin Constant"},
  {stateId: 13,  id: 1300631,  name: "Beruri"},
  {stateId: 13,  id: 1300680,  name: "Boa Vista do Ramos"},
  {stateId: 13,  id: 1300706,  name: "Boca do Acre"},
  {stateId: 13,  id: 1300805,  name: "Borba"},
  {stateId: 13,  id: 1300839,  name: "Caapiranga"},
  {stateId: 13,  id: 1300904,  name: "Canutama"},
  {stateId: 13,  id: 1301001,  name: "Carauari"},
  {stateId: 13,  id: 1301100,  name: "Careiro"},
  {stateId: 13,  id: 1301159,  name: "Careiro da Várzea"},
  {stateId: 13,  id: 1301209,  name: "Coari"},
  {stateId: 13,  id: 1301308,  name: "Codajás"},
  {stateId: 13,  id: 1301407,  name: "Eirunepé"},
  {stateId: 13,  id: 1301506,  name: "Envira"},
  {stateId: 13,  id: 1301605,  name: "Fonte Boa"},
  {stateId: 13,  id: 1301654,  name: "Guajará"},
  {stateId: 13,  id: 1301704,  name: "Humaitá"},
  {stateId: 13,  id: 1301803,  name: "Ipixuna"},
  {stateId: 13,  id: 1301852,  name: "Iranduba"},
  {stateId: 13,  id: 1301902,  name: "Itacoatiara"},
  {stateId: 13,  id: 1301951,  name: "Itamarati"},
  {stateId: 13,  id: 1302009,  name: "Itapiranga"},
  {stateId: 13,  id: 1302108,  name: "Japurá"},
  {stateId: 13,  id: 1302207,  name: "Juruá"},
  {stateId: 13,  id: 1302306,  name: "Jutaí"},
  {stateId: 13,  id: 1302405,  name: "Lábrea"},
  {stateId: 13,  id: 1302504,  name: "Manacapuru"},
  {stateId: 13,  id: 1302553,  name: "Manaquiri"},
  {stateId: 13,  id: 1302603,  name: "Manaus"},
  {stateId: 13,  id: 1302702,  name: "Manicoré"},
  {stateId: 13,  id: 1302801,  name: "Maraã"},
  {stateId: 13,  id: 1302900,  name: "Maués"},
  {stateId: 13,  id: 1303007,  name: "Nhamundá"},
  {stateId: 13,  id: 1303106,  name: "Nova Olinda do Norte"},
  {stateId: 13,  id: 1303205,  name: "Novo Airão"},
  {stateId: 13,  id: 1303304,  name: "Novo Aripuanã"},
  {stateId: 13,  id: 1303403,  name: "Parintins"},
  {stateId: 13,  id: 1303502,  name: "Pauini"},
  {stateId: 13,  id: 1303536,  name: "Presidente Figueiredo"},
  {stateId: 13,  id: 1303569,  name: "Rio Preto da Eva"},
  {stateId: 13,  id: 1303601,  name: "Santa Isabel do Rio Negro"},
  {stateId: 13,  id: 1303700,  name: "Santo Antônio do Içá"},
  {stateId: 13,  id: 1303809,  name: "São Gabriel da Cachoeira"},
  {stateId: 13,  id: 1303908,  name: "São Paulo de Olivença"},
  {stateId: 13,  id: 1303957,  name: "São Sebastião do Uatumã"},
  {stateId: 13,  id: 1304005,  name: "Silves"},
  {stateId: 13,  id: 1304062,  name: "Tabatinga"},
  {stateId: 13,  id: 1304104,  name: "Tapauá"},
  {stateId: 13,  id: 1304203,  name: "Tefé"},
  {stateId: 13,  id: 1304237,  name: "Tonantins"},
  {stateId: 13,  id: 1304260,  name: "Uarini"},
  {stateId: 13,  id: 1304302,  name: "Urucará"},
  {stateId: 13,  id: 1304401,  name: "Urucurituba"},
  {stateId: 14,  id: 1400050,  name: "Alto Alegre"},
  {stateId: 14,  id: 1400027,  name: "Amajari"},
  {stateId: 14,  id: 1400100,  name: "Boa Vista"},
  {stateId: 14,  id: 1400159,  name: "Bonfim"},
  {stateId: 14,  id: 1400175,  name: "Cantá"},
  {stateId: 14,  id: 1400209,  name: "Caracaraí"},
  {stateId: 14,  id: 1400233,  name: "Caroebe"},
  {stateId: 14,  id: 1400282,  name: "Iracema"},
  {stateId: 14,  id: 1400308,  name: "Mucajaí"},
  {stateId: 14,  id: 1400407,  name: "Normandia"},
  {stateId: 14,  id: 1400456,  name: "Pacaraima"},
  {stateId: 14,  id: 1400472,  name: "Rorainópolis"},
  {stateId: 14,  id: 1400506,  name: "São João da Baliza"},
  {stateId: 14,  id: 1400605,  name: "São Luiz"},
  {stateId: 14,  id: 1400704,  name: "Uiramutã"},
  {stateId: 15,  id: 1500107,  name: "Abaetetuba"},
  {stateId: 15,  id: 1500131,  name: "Abel Figueiredo"},
  {stateId: 15,  id: 1500206,  name: "Acará"},
  {stateId: 15,  id: 1500305,  name: "Afuá"},
  {stateId: 15,  id: 1500347,  name: "Água Azul do Norte"},
  {stateId: 15,  id: 1500404,  name: "Alenquer"},
  {stateId: 15,  id: 1500503,  name: "Almeirim"},
  {stateId: 15,  id: 1500602,  name: "Altamira"},
  {stateId: 15,  id: 1500701,  name: "Anajás"},
  {stateId: 15,  id: 1500800,  name: "Ananindeua"},
  {stateId: 15,  id: 1500859,  name: "Anapu"},
  {stateId: 15,  id: 1500909,  name: "Augusto Corrêa"},
  {stateId: 15,  id: 1500958,  name: "Aurora do Pará"},
  {stateId: 15,  id: 1501006,  name: "Aveiro"},
  {stateId: 15,  id: 1501105,  name: "Bagre"},
  {stateId: 15,  id: 1501204,  name: "Baião"},
  {stateId: 15,  id: 1501253,  name: "Bannach"},
  {stateId: 15,  id: 1501303,  name: "Barcarena"},
  {stateId: 15,  id: 1501402,  name: "Belém"},
  {stateId: 15,  id: 1501451,  name: "Belterra"},
  {stateId: 15,  id: 1501501,  name: "Benevides"},
  {stateId: 15,  id: 1501576,  name: "Bom Jesus do Tocantins"},
  {stateId: 15,  id: 1501600,  name: "Bonito"},
  {stateId: 15,  id: 1501709,  name: "Bragança"},
  {stateId: 15,  id: 1501725,  name: "Brasil Novo"},
  {stateId: 15,  id: 1501758,  name: "Brejo Grande do Araguaia"},
  {stateId: 15,  id: 1501782,  name: "Breu Branco"},
  {stateId: 15,  id: 1501808,  name: "Breves"},
  {stateId: 15,  id: 1501907,  name: "Bujaru"},
  {stateId: 15,  id: 1502004,  name: "Cachoeira do Arari"},
  {stateId: 15,  id: 1501956,  name: "Cachoeira do Piriá"},
  {stateId: 15,  id: 1502103,  name: "Cametá"},
  {stateId: 15,  id: 1502152,  name: "Canaã dos Carajás"},
  {stateId: 15,  id: 1502202,  name: "Capanema"},
  {stateId: 15,  id: 1502301,  name: "Capitão Poço"},
  {stateId: 15,  id: 1502400,  name: "Castanhal"},
  {stateId: 15,  id: 1502509,  name: "Chaves"},
  {stateId: 15,  id: 1502608,  name: "Colares"},
  {stateId: 15,  id: 1502707,  name: "Conceição do Araguaia"},
  {stateId: 15,  id: 1502756,  name: "Concórdia do Pará"},
  {stateId: 15,  id: 1502764,  name: "Cumaru do Norte"},
  {stateId: 15,  id: 1502772,  name: "Curionópolis"},
  {stateId: 15,  id: 1502806,  name: "Curralinho"},
  {stateId: 15,  id: 1502855,  name: "Curuá"},
  {stateId: 15,  id: 1502905,  name: "Curuçá"},
  {stateId: 15,  id: 1502939,  name: "Dom Eliseu"},
  {stateId: 15,  id: 1502954,  name: "Eldorado do Carajás"},
  {stateId: 15,  id: 1503002,  name: "Faro"},
  {stateId: 15,  id: 1503044,  name: "Floresta do Araguaia"},
  {stateId: 15,  id: 1503077,  name: "Garrafão do Norte"},
  {stateId: 15,  id: 1503093,  name: "Goianésia do Pará"},
  {stateId: 15,  id: 1503101,  name: "Gurupá"},
  {stateId: 15,  id: 1503200,  name: "Igarapé-Açu"},
  {stateId: 15,  id: 1503309,  name: "Igarapé-Miri"},
  {stateId: 15,  id: 1503408,  name: "Inhangapi"},
  {stateId: 15,  id: 1503457,  name: "Ipixuna do Pará"},
  {stateId: 15,  id: 1503507,  name: "Irituia"},
  {stateId: 15,  id: 1503606,  name: "Itaituba"},
  {stateId: 15,  id: 1503705,  name: "Itupiranga"},
  {stateId: 15,  id: 1503754,  name: "Jacareacanga"},
  {stateId: 15,  id: 1503804,  name: "Jacundá"},
  {stateId: 15,  id: 1503903,  name: "Juruti"},
  {stateId: 15,  id: 1504000,  name: "Limoeiro do Ajuru"},
  {stateId: 15,  id: 1504059,  name: "Mãe do Rio"},
  {stateId: 15,  id: 1504109,  name: "Magalhães Barata"},
  {stateId: 15,  id: 1504208,  name: "Marabá"},
  {stateId: 15,  id: 1504307,  name: "Maracanã"},
  {stateId: 15,  id: 1504406,  name: "Marapanim"},
  {stateId: 15,  id: 1504422,  name: "Marituba"},
  {stateId: 15,  id: 1504455,  name: "Medicilândia"},
  {stateId: 15,  id: 1504505,  name: "Melgaço"},
  {stateId: 15,  id: 1504604,  name: "Mocajuba"},
  {stateId: 15,  id: 1504703,  name: "Moju"},
  {stateId: 15,  id: 1504752,  name: "Mojuí dos Campos"},
  {stateId: 15,  id: 1504802,  name: "Monte Alegre"},
  {stateId: 15,  id: 1504901,  name: "Muaná"},
  {stateId: 15,  id: 1504950,  name: "Nova Esperança do Piriá"},
  {stateId: 15,  id: 1504976,  name: "Nova Ipixuna"},
  {stateId: 15,  id: 1505007,  name: "Nova Timboteua"},
  {stateId: 15,  id: 1505031,  name: "Novo Progresso"},
  {stateId: 15,  id: 1505064,  name: "Novo Repartimento"},
  {stateId: 15,  id: 1505106,  name: "Óbidos"},
  {stateId: 15,  id: 1505205,  name: "Oeiras do Pará"},
  {stateId: 15,  id: 1505304,  name: "Oriximiná"},
  {stateId: 15,  id: 1505403,  name: "Ourém"},
  {stateId: 15,  id: 1505437,  name: "Ourilândia do Norte"},
  {stateId: 15,  id: 1505486,  name: "Pacajá"},
  {stateId: 15,  id: 1505494,  name: "Palestina do Pará"},
  {stateId: 15,  id: 1505502,  name: "Paragominas"},
  {stateId: 15,  id: 1505536,  name: "Parauapebas"},
  {stateId: 15,  id: 1505551,  name: "Pau D'Arco"},
  {stateId: 15,  id: 1505601,  name: "Peixe-Boi"},
  {stateId: 15,  id: 1505635,  name: "Piçarra"},
  {stateId: 15,  id: 1505650,  name: "Placas"},
  {stateId: 15,  id: 1505700,  name: "Ponta de Pedras"},
  {stateId: 15,  id: 1505809,  name: "Portel"},
  {stateId: 15,  id: 1505908,  name: "Porto de Moz"},
  {stateId: 15,  id: 1506005,  name: "Prainha"},
  {stateId: 15,  id: 1506104,  name: "Primavera"},
  {stateId: 15,  id: 1506112,  name: "Quatipuru"},
  {stateId: 15,  id: 1506138,  name: "Redenção"},
  {stateId: 15,  id: 1506161,  name: "Rio Maria"},
  {stateId: 15,  id: 1506187,  name: "Rondon do Pará"},
  {stateId: 15,  id: 1506195,  name: "Rurópolis"},
  {stateId: 15,  id: 1506203,  name: "Salinópolis"},
  {stateId: 15,  id: 1506302,  name: "Salvaterra"},
  {stateId: 15,  id: 1506351,  name: "Santa Bárbara do Pará"},
  {stateId: 15,  id: 1506401,  name: "Santa Cruz do Arari"},
  {stateId: 15,  id: 1506500,  name: "Santa Izabel do Pará"},
  {stateId: 15,  id: 1506559,  name: "Santa Luzia do Pará"},
  {stateId: 15,  id: 1506583,  name: "Santa Maria das Barreiras"},
  {stateId: 15,  id: 1506609,  name: "Santa Maria do Pará"},
  {stateId: 15,  id: 1506708,  name: "Santana do Araguaia"},
  {stateId: 15,  id: 1506807,  name: "Santarém"},
  {stateId: 15,  id: 1506906,  name: "Santarém Novo"},
  {stateId: 15,  id: 1507003,  name: "Santo Antônio do Tauá"},
  {stateId: 15,  id: 1507102,  name: "São Caetano de Odivelas"},
  {stateId: 15,  id: 1507151,  name: "São Domingos do Araguaia"},
  {stateId: 15,  id: 1507201,  name: "São Domingos do Capim"},
  {stateId: 15,  id: 1507300,  name: "São Félix do Xingu"},
  {stateId: 15,  id: 1507409,  name: "São Francisco do Pará"},
  {stateId: 15,  id: 1507458,  name: "São Geraldo do Araguaia"},
  {stateId: 15,  id: 1507466,  name: "São João da Ponta"},
  {stateId: 15,  id: 1507474,  name: "São João de Pirabas"},
  {stateId: 15,  id: 1507508,  name: "São João do Araguaia"},
  {stateId: 15,  id: 1507607,  name: "São Miguel do Guamá"},
  {stateId: 15,  id: 1507706,  name: "São Sebastião da Boa Vista"},
  {stateId: 15,  id: 1507755,  name: "Sapucaia"},
  {stateId: 15,  id: 1507805,  name: "Senador José Porfírio"},
  {stateId: 15,  id: 1507904,  name: "Soure"},
  {stateId: 15,  id: 1507953,  name: "Tailândia"},
  {stateId: 15,  id: 1507961,  name: "Terra Alta"},
  {stateId: 15,  id: 1507979,  name: "Terra Santa"},
  {stateId: 15,  id: 1508001,  name: "Tomé-Açu"},
  {stateId: 15,  id: 1508035,  name: "Tracuateua"},
  {stateId: 15,  id: 1508050,  name: "Trairão"},
  {stateId: 15,  id: 1508084,  name: "Tucumã"},
  {stateId: 15,  id: 1508100,  name: "Tucuruí"},
  {stateId: 15,  id: 1508126,  name: "Ulianópolis"},
  {stateId: 15,  id: 1508159,  name: "Uruará"},
  {stateId: 15,  id: 1508209,  name: "Vigia"},
  {stateId: 15,  id: 1508308,  name: "Viseu"},
  {stateId: 15,  id: 1508357,  name: "Vitória do Xingu"},
  {stateId: 15,  id: 1508407,  name: "Xinguara"},
  {stateId: 16,  id: 1600105,  name: "Amapá"},
  {stateId: 16,  id: 1600204,  name: "Calçoene"},
  {stateId: 16,  id: 1600212,  name: "Cutias"},
  {stateId: 16,  id: 1600238,  name: "Ferreira Gomes"},
  {stateId: 16,  id: 1600253,  name: "Itaubal"},
  {stateId: 16,  id: 1600279,  name: "Laranjal do Jari"},
  {stateId: 16,  id: 1600303,  name: "Macapá"},
  {stateId: 16,  id: 1600402,  name: "Mazagão"},
  {stateId: 16,  id: 1600501,  name: "Oiapoque"},
  {stateId: 16,  id: 1600154,  name: "Pedra Branca do Amapari"},
  {stateId: 16,  id: 1600535,  name: "Porto Grande"},
  {stateId: 16,  id: 1600550,  name: "Pracuúba"},
  {stateId: 16,  id: 1600600,  name: "Santana"},
  {stateId: 16,  id: 1600055,  name: "Serra do Navio"},
  {stateId: 16,  id: 1600709,  name: "Tartarugalzinho"},
  {stateId: 16,  id: 1600808,  name: "Vitória do Jari"},
  {stateId: 17,  id: 1700251,  name: "Abreulândia"},
  {stateId: 17,  id: 1700301,  name: "Aguiarnópolis"},
  {stateId: 17,  id: 1700350,  name: "Aliança do Tocantins"},
  {stateId: 17,  id: 1700400,  name: "Almas"},
  {stateId: 17,  id: 1700707,  name: "Alvorada"},
  {stateId: 17,  id: 1701002,  name: "Ananás"},
  {stateId: 17,  id: 1701051,  name: "Angico"},
  {stateId: 17,  id: 1701101,  name: "Aparecida do Rio Negro"},
  {stateId: 17,  id: 1701309,  name: "Aragominas"},
  {stateId: 17,  id: 1701903,  name: "Araguacema"},
  {stateId: 17,  id: 1702000,  name: "Araguaçu"},
  {stateId: 17,  id: 1702109,  name: "Araguaína"},
  {stateId: 17,  id: 1702158,  name: "Araguanã"},
  {stateId: 17,  id: 1702208,  name: "Araguatins"},
  {stateId: 17,  id: 1702307,  name: "Arapoema"},
  {stateId: 17,  id: 1702406,  name: "Arraias"},
  {stateId: 17,  id: 1702554,  name: "Augustinópolis"},
  {stateId: 17,  id: 1702703,  name: "Aurora do Tocantins"},
  {stateId: 17,  id: 1702901,  name: "Axixá do Tocantins"},
  {stateId: 17,  id: 1703008,  name: "Babaçulândia"},
  {stateId: 17,  id: 1703057,  name: "Bandeirantes do Tocantins"},
  {stateId: 17,  id: 1703073,  name: "Barra do Ouro"},
  {stateId: 17,  id: 1703107,  name: "Barrolândia"},
  {stateId: 17,  id: 1703206,  name: "Bernardo Sayão"},
  {stateId: 17,  id: 1703305,  name: "Bom Jesus do Tocantins"},
  {stateId: 17,  id: 1703602,  name: "Brasilândia do Tocantins"},
  {stateId: 17,  id: 1703701,  name: "Brejinho de Nazaré"},
  {stateId: 17,  id: 1703800,  name: "Buriti do Tocantins"},
  {stateId: 17,  id: 1703826,  name: "Cachoeirinha"},
  {stateId: 17,  id: 1703842,  name: "Campos Lindos"},
  {stateId: 17,  id: 1703867,  name: "Cariri do Tocantins"},
  {stateId: 17,  id: 1703883,  name: "Carmolândia"},
  {stateId: 17,  id: 1703891,  name: "Carrasco Bonito"},
  {stateId: 17,  id: 1703909,  name: "Caseara"},
  {stateId: 17,  id: 1704105,  name: "Centenário"},
  {stateId: 17,  id: 1705102,  name: "Chapada da Natividade"},
  {stateId: 17,  id: 1704600,  name: "Chapada de Areia"},
  {stateId: 17,  id: 1705508,  name: "Colinas do Tocantins"},
  {stateId: 17,  id: 1716703,  name: "Colméia"},
  {stateId: 17,  id: 1705557,  name: "Combinado"},
  {stateId: 17,  id: 1705607,  name: "Conceição do Tocantins"},
  {stateId: 17,  id: 1706001,  name: "Couto Magalhães"},
  {stateId: 17,  id: 1706100,  name: "Cristalândia"},
  {stateId: 17,  id: 1706258,  name: "Crixás do Tocantins"},
  {stateId: 17,  id: 1706506,  name: "Darcinópolis"},
  {stateId: 17,  id: 1707009,  name: "Dianópolis"},
  {stateId: 17,  id: 1707108,  name: "Divinópolis do Tocantins"},
  {stateId: 17,  id: 1707207,  name: "Dois Irmãos do Tocantins"},
  {stateId: 17,  id: 1707306,  name: "Dueré"},
  {stateId: 17,  id: 1707405,  name: "Esperantina"},
  {stateId: 17,  id: 1707553,  name: "Fátima"},
  {stateId: 17,  id: 1707652,  name: "Figueirópolis"},
  {stateId: 17,  id: 1707702,  name: "Filadélfia"},
  {stateId: 17,  id: 1708205,  name: "Formoso do Araguaia"},
  {stateId: 17,  id: 1708254,  name: "Fortaleza do Tabocão"},
  {stateId: 17,  id: 1708304,  name: "Goianorte"},
  {stateId: 17,  id: 1709005,  name: "Goiatins"},
  {stateId: 17,  id: 1709302,  name: "Guaraí"},
  {stateId: 17,  id: 1709500,  name: "Gurupi"},
  {stateId: 17,  id: 1709807,  name: "Ipueiras"},
  {stateId: 17,  id: 1710508,  name: "Itacajá"},
  {stateId: 17,  id: 1710706,  name: "Itaguatins"},
  {stateId: 17,  id: 1710904,  name: "Itapiratins"},
  {stateId: 17,  id: 1711100,  name: "Itaporã do Tocantins"},
  {stateId: 17,  id: 1711506,  name: "Jaú do Tocantins"},
  {stateId: 17,  id: 1711803,  name: "Juarina"},
  {stateId: 17,  id: 1711902,  name: "Lagoa da Confusão"},
  {stateId: 17,  id: 1711951,  name: "Lagoa do Tocantins"},
  {stateId: 17,  id: 1712009,  name: "Lajeado"},
  {stateId: 17,  id: 1712157,  name: "Lavandeira"},
  {stateId: 17,  id: 1712405,  name: "Lizarda"},
  {stateId: 17,  id: 1712454,  name: "Luzinópolis"},
  {stateId: 17,  id: 1712504,  name: "Marianópolis do Tocantins"},
  {stateId: 17,  id: 1712702,  name: "Mateiros"},
  {stateId: 17,  id: 1712801,  name: "Maurilândia do Tocantins"},
  {stateId: 17,  id: 1713205,  name: "Miracema do Tocantins"},
  {stateId: 17,  id: 1713304,  name: "Miranorte"},
  {stateId: 17,  id: 1713601,  name: "Monte do Carmo"},
  {stateId: 17,  id: 1713700,  name: "Monte Santo do Tocantins"},
  {stateId: 17,  id: 1713957,  name: "Muricilândia"},
  {stateId: 17,  id: 1714203,  name: "Natividade"},
  {stateId: 17,  id: 1714302,  name: "Nazaré"},
  {stateId: 17,  id: 1714880,  name: "Nova Olinda"},
  {stateId: 17,  id: 1715002,  name: "Nova Rosalândia"},
  {stateId: 17,  id: 1715101,  name: "Novo Acordo"},
  {stateId: 17,  id: 1715150,  name: "Novo Alegre"},
  {stateId: 17,  id: 1715259,  name: "Novo Jardim"},
  {stateId: 17,  id: 1715507,  name: "Oliveira de Fátima"},
  {stateId: 17,  id: 1721000,  name: "Palmas"},
  {stateId: 17,  id: 1715705,  name: "Palmeirante"},
  {stateId: 17,  id: 1713809,  name: "Palmeiras do Tocantins"},
  {stateId: 17,  id: 1715754,  name: "Palmeirópolis"},
  {stateId: 17,  id: 1716109,  name: "Paraíso do Tocantins"},
  {stateId: 17,  id: 1716208,  name: "Paranã"},
  {stateId: 17,  id: 1716307,  name: "Pau D'Arco"},
  {stateId: 17,  id: 1716505,  name: "Pedro Afonso"},
  {stateId: 17,  id: 1716604,  name: "Peixe"},
  {stateId: 17,  id: 1716653,  name: "Pequizeiro"},
  {stateId: 17,  id: 1717008,  name: "Pindorama do Tocantins"},
  {stateId: 17,  id: 1717206,  name: "Piraquê"},
  {stateId: 17,  id: 1717503,  name: "Pium"},
  {stateId: 17,  id: 1717800,  name: "Ponte Alta do Bom Jesus"},
  {stateId: 17,  id: 1717909,  name: "Ponte Alta do Tocantins"},
  {stateId: 17,  id: 1718006,  name: "Porto Alegre do Tocantins"},
  {stateId: 17,  id: 1718204,  name: "Porto Nacional"},
  {stateId: 17,  id: 1718303,  name: "Praia Norte"},
  {stateId: 17,  id: 1718402,  name: "Presidente Kennedy"},
  {stateId: 17,  id: 1718451,  name: "Pugmil"},
  {stateId: 17,  id: 1718501,  name: "Recursolândia"},
  {stateId: 17,  id: 1718550,  name: "Riachinho"},
  {stateId: 17,  id: 1718659,  name: "Rio da Conceição"},
  {stateId: 17,  id: 1718709,  name: "Rio dos Bois"},
  {stateId: 17,  id: 1718758,  name: "Rio Sono"},
  {stateId: 17,  id: 1718808,  name: "Sampaio"},
  {stateId: 17,  id: 1718840,  name: "Sandolândia"},
  {stateId: 17,  id: 1718865,  name: "Santa Fé do Araguaia"},
  {stateId: 17,  id: 1718881,  name: "Santa Maria do Tocantins"},
  {stateId: 17,  id: 1718899,  name: "Santa Rita do Tocantins"},
  {stateId: 17,  id: 1718907,  name: "Santa Rosa do Tocantins"},
  {stateId: 17,  id: 1719004,  name: "Santa Tereza do Tocantins"},
  {stateId: 17,  id: 1720002,  name: "Santa Terezinha do Tocantins"},
  {stateId: 17,  id: 1720101,  name: "São Bento do Tocantins"},
  {stateId: 17,  id: 1720150,  name: "São Félix do Tocantins"},
  {stateId: 17,  id: 1720200,  name: "São Miguel do Tocantins"},
  {stateId: 17,  id: 1720259,  name: "São Salvador do Tocantins"},
  {stateId: 17,  id: 1720309,  name: "São Sebastião do Tocantins"},
  {stateId: 17,  id: 1720499,  name: "São Valério"},
  {stateId: 17,  id: 1720655,  name: "Silvanópolis"},
  {stateId: 17,  id: 1720804,  name: "Sítio Novo do Tocantins"},
  {stateId: 17,  id: 1720853,  name: "Sucupira"},
  {stateId: 17,  id: 1720903,  name: "Taguatinga"},
  {stateId: 17,  id: 1720937,  name: "Taipas do Tocantins"},
  {stateId: 17,  id: 1720978,  name: "Talismã"},
  {stateId: 17,  id: 1721109,  name: "Tocantínia"},
  {stateId: 17,  id: 1721208,  name: "Tocantinópolis"},
  {stateId: 17,  id: 1721257,  name: "Tupirama"},
  {stateId: 17,  id: 1721307,  name: "Tupiratins"},
  {stateId: 17,  id: 1722081,  name: "Wanderlândia"},
  {stateId: 17,  id: 1722107,  name: "Xambioá"},
  {stateId: 21,  id: 2100055,  name: "Açailândia"},
  {stateId: 21,  id: 2100105,  name: "Afonso Cunha"},
  {stateId: 21,  id: 2100154,  name: "Água Doce do Maranhão"},
  {stateId: 21,  id: 2100204,  name: "Alcântara"},
  {stateId: 21,  id: 2100303,  name: "Aldeias Altas"},
  {stateId: 21,  id: 2100402,  name: "Altamira do Maranhão"},
  {stateId: 21,  id: 2100436,  name: "Alto Alegre do Maranhão"},
  {stateId: 21,  id: 2100477,  name: "Alto Alegre do Pindaré"},
  {stateId: 21,  id: 2100501,  name: "Alto Parnaíba"},
  {stateId: 21,  id: 2100550,  name: "Amapá do Maranhão"},
  {stateId: 21,  id: 2100600,  name: "Amarante do Maranhão"},
  {stateId: 21,  id: 2100709,  name: "Anajatuba"},
  {stateId: 21,  id: 2100808,  name: "Anapurus"},
  {stateId: 21,  id: 2100832,  name: "Apicum-Açu"},
  {stateId: 21,  id: 2100873,  name: "Araguanã"},
  {stateId: 21,  id: 2100907,  name: "Araioses"},
  {stateId: 21,  id: 2100956,  name: "Arame"},
  {stateId: 21,  id: 2101004,  name: "Arari"},
  {stateId: 21,  id: 2101103,  name: "Axixá"},
  {stateId: 21,  id: 2101202,  name: "Bacabal"},
  {stateId: 21,  id: 2101251,  name: "Bacabeira"},
  {stateId: 21,  id: 2101301,  name: "Bacuri"},
  {stateId: 21,  id: 2101350,  name: "Bacurituba"},
  {stateId: 21,  id: 2101400,  name: "Balsas"},
  {stateId: 21,  id: 2101509,  name: "Barão de Grajaú"},
  {stateId: 21,  id: 2101608,  name: "Barra do Corda"},
  {stateId: 21,  id: 2101707,  name: "Barreirinhas"},
  {stateId: 21,  id: 2101772,  name: "Bela Vista do Maranhão"},
  {stateId: 21,  id: 2101731,  name: "Belágua"},
  {stateId: 21,  id: 2101806,  name: "Benedito Leite"},
  {stateId: 21,  id: 2101905,  name: "Bequimão"},
  {stateId: 21,  id: 2101939,  name: "Bernardo do Mearim"},
  {stateId: 21,  id: 2101970,  name: "Boa Vista do Gurupi"},
  {stateId: 21,  id: 2102002,  name: "Bom Jardim"},
  {stateId: 21,  id: 2102036,  name: "Bom Jesus das Selvas"},
  {stateId: 21,  id: 2102077,  name: "Bom Lugar"},
  {stateId: 21,  id: 2102101,  name: "Brejo"},
  {stateId: 21,  id: 2102150,  name: "Brejo de Areia"},
  {stateId: 21,  id: 2102200,  name: "Buriti"},
  {stateId: 21,  id: 2102309,  name: "Buriti Bravo"},
  {stateId: 21,  id: 2102325,  name: "Buriticupu"},
  {stateId: 21,  id: 2102358,  name: "Buritirana"},
  {stateId: 21,  id: 2102374,  name: "Cachoeira Grande"},
  {stateId: 21,  id: 2102408,  name: "Cajapió"},
  {stateId: 21,  id: 2102507,  name: "Cajari"},
  {stateId: 21,  id: 2102556,  name: "Campestre do Maranhão"},
  {stateId: 21,  id: 2102606,  name: "Cândido Mendes"},
  {stateId: 21,  id: 2102705,  name: "Cantanhede"},
  {stateId: 21,  id: 2102754,  name: "Capinzal do Norte"},
  {stateId: 21,  id: 2102804,  name: "Carolina"},
  {stateId: 21,  id: 2102903,  name: "Carutapera"},
  {stateId: 21,  id: 2103000,  name: "Caxias"},
  {stateId: 21,  id: 2103109,  name: "Cedral"},
  {stateId: 21,  id: 2103125,  name: "Central do Maranhão"},
  {stateId: 21,  id: 2103158,  name: "Centro do Guilherme"},
  {stateId: 21,  id: 2103174,  name: "Centro Novo do Maranhão"},
  {stateId: 21,  id: 2103208,  name: "Chapadinha"},
  {stateId: 21,  id: 2103257,  name: "Cidelândia"},
  {stateId: 21,  id: 2103307,  name: "Codó"},
  {stateId: 21,  id: 2103406,  name: "Coelho Neto"},
  {stateId: 21,  id: 2103505,  name: "Colinas"},
  {stateId: 21,  id: 2103554,  name: "Conceição do Lago-Açu"},
  {stateId: 21,  id: 2103604,  name: "Coroatá"},
  {stateId: 21,  id: 2103703,  name: "Cururupu"},
  {stateId: 21,  id: 2103752,  name: "Davinópolis"},
  {stateId: 21,  id: 2103802,  name: "Dom Pedro"},
  {stateId: 21,  id: 2103901,  name: "Duque Bacelar"},
  {stateId: 21,  id: 2104008,  name: "Esperantinópolis"},
  {stateId: 21,  id: 2104057,  name: "Estreito"},
  {stateId: 21,  id: 2104073,  name: "Feira Nova do Maranhão"},
  {stateId: 21,  id: 2104081,  name: "Fernando Falcão"},
  {stateId: 21,  id: 2104099,  name: "Formosa da Serra Negra"},
  {stateId: 21,  id: 2104107,  name: "Fortaleza dos Nogueiras"},
  {stateId: 21,  id: 2104206,  name: "Fortuna"},
  {stateId: 21,  id: 2104305,  name: "Godofredo Viana"},
  {stateId: 21,  id: 2104404,  name: "Gonçalves Dias"},
  {stateId: 21,  id: 2104503,  name: "Governador Archer"},
  {stateId: 21,  id: 2104552,  name: "Governador Edison Lobão"},
  {stateId: 21,  id: 2104602,  name: "Governador Eugênio Barros"},
  {stateId: 21,  id: 2104628,  name: "Governador Luiz Rocha"},
  {stateId: 21,  id: 2104651,  name: "Governador Newton Bello"},
  {stateId: 21,  id: 2104677,  name: "Governador Nunes Freire"},
  {stateId: 21,  id: 2104701,  name: "Graça Aranha"},
  {stateId: 21,  id: 2104800,  name: "Grajaú"},
  {stateId: 21,  id: 2104909,  name: "Guimarães"},
  {stateId: 21,  id: 2105005,  name: "Humberto de Campos"},
  {stateId: 21,  id: 2105104,  name: "Icatu"},
  {stateId: 21,  id: 2105153,  name: "Igarapé do Meio"},
  {stateId: 21,  id: 2105203,  name: "Igarapé Grande"},
  {stateId: 21,  id: 2105302,  name: "Imperatriz"},
  {stateId: 21,  id: 2105351,  name: "Itaipava do Grajaú"},
  {stateId: 21,  id: 2105401,  name: "Itapecuru Mirim"},
  {stateId: 21,  id: 2105427,  name: "Itinga do Maranhão"},
  {stateId: 21,  id: 2105450,  name: "Jatobá"},
  {stateId: 21,  id: 2105476,  name: "Jenipapo dos Vieiras"},
  {stateId: 21,  id: 2105500,  name: "João Lisboa"},
  {stateId: 21,  id: 2105609,  name: "Joselândia"},
  {stateId: 21,  id: 2105658,  name: "Junco do Maranhão"},
  {stateId: 21,  id: 2105708,  name: "Lago da Pedra"},
  {stateId: 21,  id: 2105807,  name: "Lago do Junco"},
  {stateId: 21,  id: 2105948,  name: "Lago dos Rodrigues"},
  {stateId: 21,  id: 2105906,  name: "Lago Verde"},
  {stateId: 21,  id: 2105922,  name: "Lagoa do Mato"},
  {stateId: 21,  id: 2105963,  name: "Lagoa Grande do Maranhão"},
  {stateId: 21,  id: 2105989,  name: "Lajeado Novo"},
  {stateId: 21,  id: 2106003,  name: "Lima Campos"},
  {stateId: 21,  id: 2106102,  name: "Loreto"},
  {stateId: 21,  id: 2106201,  name: "Luís Domingues"},
  {stateId: 21,  id: 2106300,  name: "Magalhães de Almeida"},
  {stateId: 21,  id: 2106326,  name: "Maracaçumé"},
  {stateId: 21,  id: 2106359,  name: "Marajá do Sena"},
  {stateId: 21,  id: 2106375,  name: "Maranhãozinho"},
  {stateId: 21,  id: 2106409,  name: "Mata Roma"},
  {stateId: 21,  id: 2106508,  name: "Matinha"},
  {stateId: 21,  id: 2106607,  name: "Matões"},
  {stateId: 21,  id: 2106631,  name: "Matões do Norte"},
  {stateId: 21,  id: 2106672,  name: "Milagres do Maranhão"},
  {stateId: 21,  id: 2106706,  name: "Mirador"},
  {stateId: 21,  id: 2106755,  name: "Miranda do Norte"},
  {stateId: 21,  id: 2106805,  name: "Mirinzal"},
  {stateId: 21,  id: 2106904,  name: "Monção"},
  {stateId: 21,  id: 2107001,  name: "Montes Altos"},
  {stateId: 21,  id: 2107100,  name: "Morros"},
  {stateId: 21,  id: 2107209,  name: "Nina Rodrigues"},
  {stateId: 21,  id: 2107258,  name: "Nova Colinas"},
  {stateId: 21,  id: 2107308,  name: "Nova Iorque"},
  {stateId: 21,  id: 2107357,  name: "Nova Olinda do Maranhão"},
  {stateId: 21,  id: 2107407,  name: "Olho d'Água das Cunhãs"},
  {stateId: 21,  id: 2107456,  name: "Olinda Nova do Maranhão"},
  {stateId: 21,  id: 2107506,  name: "Paço do Lumiar"},
  {stateId: 21,  id: 2107605,  name: "Palmeirândia"},
  {stateId: 21,  id: 2107704,  name: "Paraibano"},
  {stateId: 21,  id: 2107803,  name: "Parnarama"},
  {stateId: 21,  id: 2107902,  name: "Passagem Franca"},
  {stateId: 21,  id: 2108009,  name: "Pastos Bons"},
  {stateId: 21,  id: 2108058,  name: "Paulino Neves"},
  {stateId: 21,  id: 2108108,  name: "Paulo Ramos"},
  {stateId: 21,  id: 2108207,  name: "Pedreiras"},
  {stateId: 21,  id: 2108256,  name: "Pedro do Rosário"},
  {stateId: 21,  id: 2108306,  name: "Penalva"},
  {stateId: 21,  id: 2108405,  name: "Peri Mirim"},
  {stateId: 21,  id: 2108454,  name: "Peritoró"},
  {stateId: 21,  id: 2108504,  name: "Pindaré-Mirim"},
  {stateId: 21,  id: 2108603,  name: "Pinheiro"},
  {stateId: 21,  id: 2108702,  name: "Pio XII"},
  {stateId: 21,  id: 2108801,  name: "Pirapemas"},
  {stateId: 21,  id: 2108900,  name: "Poção de Pedras"},
  {stateId: 21,  id: 2109007,  name: "Porto Franco"},
  {stateId: 21,  id: 2109056,  name: "Porto Rico do Maranhão"},
  {stateId: 21,  id: 2109106,  name: "Presidente Dutra"},
  {stateId: 21,  id: 2109205,  name: "Presidente Juscelino"},
  {stateId: 21,  id: 2109239,  name: "Presidente Médici"},
  {stateId: 21,  id: 2109270,  name: "Presidente Sarney"},
  {stateId: 21,  id: 2109304,  name: "Presidente Vargas"},
  {stateId: 21,  id: 2109403,  name: "Primeira Cruz"},
  {stateId: 21,  id: 2109452,  name: "Raposa"},
  {stateId: 21,  id: 2109502,  name: "Riachão"},
  {stateId: 21,  id: 2109551,  name: "Ribamar Fiquene"},
  {stateId: 21,  id: 2109601,  name: "Rosário"},
  {stateId: 21,  id: 2109700,  name: "Sambaíba"},
  {stateId: 21,  id: 2109759,  name: "Santa Filomena do Maranhão"},
  {stateId: 21,  id: 2109809,  name: "Santa Helena"},
  {stateId: 21,  id: 2109908,  name: "Santa Inês"},
  {stateId: 21,  id: 2110005,  name: "Santa Luzia"},
  {stateId: 21,  id: 2110039,  name: "Santa Luzia do Paruá"},
  {stateId: 21,  id: 2110104,  name: "Santa Quitéria do Maranhão"},
  {stateId: 21,  id: 2110203,  name: "Santa Rita"},
  {stateId: 21,  id: 2110237,  name: "Santana do Maranhão"},
  {stateId: 21,  id: 2110278,  name: "Santo Amaro do Maranhão"},
  {stateId: 21,  id: 2110302,  name: "Santo Antônio dos Lopes"},
  {stateId: 21,  id: 2110401,  name: "São Benedito do Rio Preto"},
  {stateId: 21,  id: 2110500,  name: "São Bento"},
  {stateId: 21,  id: 2110609,  name: "São Bernardo"},
  {stateId: 21,  id: 2110658,  name: "São Domingos do Azeitão"},
  {stateId: 21,  id: 2110708,  name: "São Domingos do Maranhão"},
  {stateId: 21,  id: 2110807,  name: "São Félix de Balsas"},
  {stateId: 21,  id: 2110856,  name: "São Francisco do Brejão"},
  {stateId: 21,  id: 2110906,  name: "São Francisco do Maranhão"},
  {stateId: 21,  id: 2111003,  name: "São João Batista"},
  {stateId: 21,  id: 2111029,  name: "São João do Carú"},
  {stateId: 21,  id: 2111052,  name: "São João do Paraíso"},
  {stateId: 21,  id: 2111078,  name: "São João do Soter"},
  {stateId: 21,  id: 2111102,  name: "São João dos Patos"},
  {stateId: 21,  id: 2111201,  name: "São José de Ribamar"},
  {stateId: 21,  id: 2111250,  name: "São José dos Basílios"},
  {stateId: 21,  id: 2111300,  name: "São Luís"},
  {stateId: 21,  id: 2111409,  name: "São Luís Gonzaga do Maranhão"},
  {stateId: 21,  id: 2111508,  name: "São Mateus do Maranhão"},
  {stateId: 21,  id: 2111532,  name: "São Pedro da Água Branca"},
  {stateId: 21,  id: 2111573,  name: "São Pedro dos Crentes"},
  {stateId: 21,  id: 2111607,  name: "São Raimundo das Mangabeiras"},
  {stateId: 21,  id: 2111631,  name: "São Raimundo do Doca Bezerra"},
  {stateId: 21,  id: 2111672,  name: "São Roberto"},
  {stateId: 21,  id: 2111706,  name: "São Vicente Ferrer"},
  {stateId: 21,  id: 2111722,  name: "Satubinha"},
  {stateId: 21,  id: 2111748,  name: "Senador Alexandre Costa"},
  {stateId: 21,  id: 2111763,  name: "Senador La Rocque"},
  {stateId: 21,  id: 2111789,  name: "Serrano do Maranhão"},
  {stateId: 21,  id: 2111805,  name: "Sítio Novo"},
  {stateId: 21,  id: 2111904,  name: "Sucupira do Norte"},
  {stateId: 21,  id: 2111953,  name: "Sucupira do Riachão"},
  {stateId: 21,  id: 2112001,  name: "Tasso Fragoso"},
  {stateId: 21,  id: 2112100,  name: "Timbiras"},
  {stateId: 21,  id: 2112209,  name: "Timon"},
  {stateId: 21,  id: 2112233,  name: "Trizidela do Vale"},
  {stateId: 21,  id: 2112274,  name: "Tufilândia"},
  {stateId: 21,  id: 2112308,  name: "Tuntum"},
  {stateId: 21,  id: 2112407,  name: "Turiaçu"},
  {stateId: 21,  id: 2112456,  name: "Turilândia"},
  {stateId: 21,  id: 2112506,  name: "Tutóia"},
  {stateId: 21,  id: 2112605,  name: "Urbano Santos"},
  {stateId: 21,  id: 2112704,  name: "Vargem Grande"},
  {stateId: 21,  id: 2112803,  name: "Viana"},
  {stateId: 21,  id: 2112852,  name: "Vila Nova dos Martírios"},
  {stateId: 21,  id: 2112902,  name: "Vitória do Mearim"},
  {stateId: 21,  id: 2113009,  name: "Vitorino Freire"},
  {stateId: 21,  id: 2114007,  name: "Zé Doca"},
  {stateId: 22,  id: 2200053,  name: "Acauã"},
  {stateId: 22,  id: 2200103,  name: "Agricolândia"},
  {stateId: 22,  id: 2200202,  name: "Água Branca"},
  {stateId: 22,  id: 2200251,  name: "Alagoinha do Piauí"},
  {stateId: 22,  id: 2200277,  name: "Alegrete do Piauí"},
  {stateId: 22,  id: 2200301,  name: "Alto Longá"},
  {stateId: 22,  id: 2200400,  name: "Altos"},
  {stateId: 22,  id: 2200459,  name: "Alvorada do Gurguéia"},
  {stateId: 22,  id: 2200509,  name: "Amarante"},
  {stateId: 22,  id: 2200608,  name: "Angical do Piauí"},
  {stateId: 22,  id: 2200707,  name: "Anísio de Abreu"},
  {stateId: 22,  id: 2200806,  name: "Antônio Almeida"},
  {stateId: 22,  id: 2200905,  name: "Aroazes"},
  {stateId: 22,  id: 2200954,  name: "Aroeiras do Itaim"},
  {stateId: 22,  id: 2201002,  name: "Arraial"},
  {stateId: 22,  id: 2201051,  name: "Assunção do Piauí"},
  {stateId: 22,  id: 2201101,  name: "Avelino Lopes"},
  {stateId: 22,  id: 2201150,  name: "Baixa Grande do Ribeiro"},
  {stateId: 22,  id: 2201176,  name: "Barra D'Alcântara"},
  {stateId: 22,  id: 2201200,  name: "Barras"},
  {stateId: 22,  id: 2201309,  name: "Barreiras do Piauí"},
  {stateId: 22,  id: 2201408,  name: "Barro Duro"},
  {stateId: 22,  id: 2201507,  name: "Batalha"},
  {stateId: 22,  id: 2201556,  name: "Bela Vista do Piauí"},
  {stateId: 22,  id: 2201572,  name: "Belém do Piauí"},
  {stateId: 22,  id: 2201606,  name: "Beneditinos"},
  {stateId: 22,  id: 2201705,  name: "Bertolínia"},
  {stateId: 22,  id: 2201739,  name: "Betânia do Piauí"},
  {stateId: 22,  id: 2201770,  name: "Boa Hora"},
  {stateId: 22,  id: 2201804,  name: "Bocaina"},
  {stateId: 22,  id: 2201903,  name: "Bom Jesus"},
  {stateId: 22,  id: 2201919,  name: "Bom Princípio do Piauí"},
  {stateId: 22,  id: 2201929,  name: "Bonfim do Piauí"},
  {stateId: 22,  id: 2201945,  name: "Boqueirão do Piauí"},
  {stateId: 22,  id: 2201960,  name: "Brasileira"},
  {stateId: 22,  id: 2201988,  name: "Brejo do Piauí"},
  {stateId: 22,  id: 2202000,  name: "Buriti dos Lopes"},
  {stateId: 22,  id: 2202026,  name: "Buriti dos Montes"},
  {stateId: 22,  id: 2202059,  name: "Cabeceiras do Piauí"},
  {stateId: 22,  id: 2202075,  name: "Cajazeiras do Piauí"},
  {stateId: 22,  id: 2202083,  name: "Cajueiro da Praia"},
  {stateId: 22,  id: 2202091,  name: "Caldeirão Grande do Piauí"},
  {stateId: 22,  id: 2202109,  name: "Campinas do Piauí"},
  {stateId: 22,  id: 2202117,  name: "Campo Alegre do Fidalgo"},
  {stateId: 22,  id: 2202133,  name: "Campo Grande do Piauí"},
  {stateId: 22,  id: 2202174,  name: "Campo Largo do Piauí"},
  {stateId: 22,  id: 2202208,  name: "Campo Maior"},
  {stateId: 22,  id: 2202251,  name: "Canavieira"},
  {stateId: 22,  id: 2202307,  name: "Canto do Buriti"},
  {stateId: 22,  id: 2202406,  name: "Capitão de Campos"},
  {stateId: 22,  id: 2202455,  name: "Capitão Gervásio Oliveira"},
  {stateId: 22,  id: 2202505,  name: "Caracol"},
  {stateId: 22,  id: 2202539,  name: "Caraúbas do Piauí"},
  {stateId: 22,  id: 2202554,  name: "Caridade do Piauí"},
  {stateId: 22,  id: 2202604,  name: "Castelo do Piauí"},
  {stateId: 22,  id: 2202653,  name: "Caxingó"},
  {stateId: 22,  id: 2202703,  name: "Cocal"},
  {stateId: 22,  id: 2202711,  name: "Cocal de Telha"},
  {stateId: 22,  id: 2202729,  name: "Cocal dos Alves"},
  {stateId: 22,  id: 2202737,  name: "Coivaras"},
  {stateId: 22,  id: 2202752,  name: "Colônia do Gurguéia"},
  {stateId: 22,  id: 2202778,  name: "Colônia do Piauí"},
  {stateId: 22,  id: 2202802,  name: "Conceição do Canindé"},
  {stateId: 22,  id: 2202851,  name: "Coronel José Dias"},
  {stateId: 22,  id: 2202901,  name: "Corrente"},
  {stateId: 22,  id: 2203008,  name: "Cristalândia do Piauí"},
  {stateId: 22,  id: 2203107,  name: "Cristino Castro"},
  {stateId: 22,  id: 2203206,  name: "Curimatá"},
  {stateId: 22,  id: 2203230,  name: "Currais"},
  {stateId: 22,  id: 2203271,  name: "Curral Novo do Piauí"},
  {stateId: 22,  id: 2203255,  name: "Curralinhos"},
  {stateId: 22,  id: 2203305,  name: "Demerval Lobão"},
  {stateId: 22,  id: 2203354,  name: "Dirceu Arcoverde"},
  {stateId: 22,  id: 2203404,  name: "Dom Expedito Lopes"},
  {stateId: 22,  id: 2203453,  name: "Dom Inocêncio"},
  {stateId: 22,  id: 2203420,  name: "Domingos Mourão"},
  {stateId: 22,  id: 2203503,  name: "Elesbão Veloso"},
  {stateId: 22,  id: 2203602,  name: "Eliseu Martins"},
  {stateId: 22,  id: 2203701,  name: "Esperantina"},
  {stateId: 22,  id: 2203750,  name: "Fartura do Piauí"},
  {stateId: 22,  id: 2203800,  name: "Flores do Piauí"},
  {stateId: 22,  id: 2203859,  name: "Floresta do Piauí"},
  {stateId: 22,  id: 2203909,  name: "Floriano"},
  {stateId: 22,  id: 2204006,  name: "Francinópolis"},
  {stateId: 22,  id: 2204105,  name: "Francisco Ayres"},
  {stateId: 22,  id: 2204154,  name: "Francisco Macedo"},
  {stateId: 22,  id: 2204204,  name: "Francisco Santos"},
  {stateId: 22,  id: 2204303,  name: "Fronteiras"},
  {stateId: 22,  id: 2204352,  name: "Geminiano"},
  {stateId: 22,  id: 2204402,  name: "Gilbués"},
  {stateId: 22,  id: 2204501,  name: "Guadalupe"},
  {stateId: 22,  id: 2204550,  name: "Guaribas"},
  {stateId: 22,  id: 2204600,  name: "Hugo Napoleão"},
  {stateId: 22,  id: 2204659,  name: "Ilha Grande"},
  {stateId: 22,  id: 2204709,  name: "Inhuma"},
  {stateId: 22,  id: 2204808,  name: "Ipiranga do Piauí"},
  {stateId: 22,  id: 2204907,  name: "Isaías Coelho"},
  {stateId: 22,  id: 2205003,  name: "Itainópolis"},
  {stateId: 22,  id: 2205102,  name: "Itaueira"},
  {stateId: 22,  id: 2205151,  name: "Jacobina do Piauí"},
  {stateId: 22,  id: 2205201,  name: "Jaicós"},
  {stateId: 22,  id: 2205250,  name: "Jardim do Mulato"},
  {stateId: 22,  id: 2205276,  name: "Jatobá do Piauí"},
  {stateId: 22,  id: 2205300,  name: "Jerumenha"},
  {stateId: 22,  id: 2205359,  name: "João Costa"},
  {stateId: 22,  id: 2205409,  name: "Joaquim Pires"},
  {stateId: 22,  id: 2205458,  name: "Joca Marques"},
  {stateId: 22,  id: 2205508,  name: "José de Freitas"},
  {stateId: 22,  id: 2205516,  name: "Juazeiro do Piauí"},
  {stateId: 22,  id: 2205524,  name: "Júlio Borges"},
  {stateId: 22,  id: 2205532,  name: "Jurema"},
  {stateId: 22,  id: 2205557,  name: "Lagoa Alegre"},
  {stateId: 22,  id: 2205573,  name: "Lagoa de São Francisco"},
  {stateId: 22,  id: 2205565,  name: "Lagoa do Barro do Piauí"},
  {stateId: 22,  id: 2205581,  name: "Lagoa do Piauí"},
  {stateId: 22,  id: 2205599,  name: "Lagoa do Sítio"},
  {stateId: 22,  id: 2205540,  name: "Lagoinha do Piauí"},
  {stateId: 22,  id: 2205607,  name: "Landri Sales"},
  {stateId: 22,  id: 2205706,  name: "Luís Correia"},
  {stateId: 22,  id: 2205805,  name: "Luzilândia"},
  {stateId: 22,  id: 2205854,  name: "Madeiro"},
  {stateId: 22,  id: 2205904,  name: "Manoel Emídio"},
  {stateId: 22,  id: 2205953,  name: "Marcolândia"},
  {stateId: 22,  id: 2206001,  name: "Marcos Parente"},
  {stateId: 22,  id: 2206050,  name: "Massapê do Piauí"},
  {stateId: 22,  id: 2206100,  name: "Matias Olímpio"},
  {stateId: 22,  id: 2206209,  name: "Miguel Alves"},
  {stateId: 22,  id: 2206308,  name: "Miguel Leão"},
  {stateId: 22,  id: 2206357,  name: "Milton Brandão"},
  {stateId: 22,  id: 2206407,  name: "Monsenhor Gil"},
  {stateId: 22,  id: 2206506,  name: "Monsenhor Hipólito"},
  {stateId: 22,  id: 2206605,  name: "Monte Alegre do Piauí"},
  {stateId: 22,  id: 2206654,  name: "Morro Cabeça no Tempo"},
  {stateId: 22,  id: 2206670,  name: "Morro do Chapéu do Piauí"},
  {stateId: 22,  id: 2206696,  name: "Murici dos Portelas"},
  {stateId: 22,  id: 2206704,  name: "Nazaré do Piauí"},
  {stateId: 22,  id: 2206720,  name: "Nazária"},
  {stateId: 22,  id: 2206753,  name: "Nossa Senhora de Nazaré"},
  {stateId: 22,  id: 2206803,  name: "Nossa Senhora dos Remédios"},
  {stateId: 22,  id: 2207959,  name: "Nova Santa Rita"},
  {stateId: 22,  id: 2206902,  name: "Novo Oriente do Piauí"},
  {stateId: 22,  id: 2206951,  name: "Novo Santo Antônio"},
  {stateId: 22,  id: 2207009,  name: "Oeiras"},
  {stateId: 22,  id: 2207108,  name: "Olho D'Água do Piauí"},
  {stateId: 22,  id: 2207207,  name: "Padre Marcos"},
  {stateId: 22,  id: 2207306,  name: "Paes Landim"},
  {stateId: 22,  id: 2207355,  name: "Pajeú do Piauí"},
  {stateId: 22,  id: 2207405,  name: "Palmeira do Piauí"},
  {stateId: 22,  id: 2207504,  name: "Palmeirais"},
  {stateId: 22,  id: 2207553,  name: "Paquetá"},
  {stateId: 22,  id: 2207603,  name: "Parnaguá"},
  {stateId: 22,  id: 2207702,  name: "Parnaíba"},
  {stateId: 22,  id: 2207751,  name: "Passagem Franca do Piauí"},
  {stateId: 22,  id: 2207777,  name: "Patos do Piauí"},
  {stateId: 22,  id: 2207793,  name: "Pau D'Arco do Piauí"},
  {stateId: 22,  id: 2207801,  name: "Paulistana"},
  {stateId: 22,  id: 2207850,  name: "Pavussu"},
  {stateId: 22,  id: 2207900,  name: "Pedro II"},
  {stateId: 22,  id: 2207934,  name: "Pedro Laurentino"},
  {stateId: 22,  id: 2208007,  name: "Picos"},
  {stateId: 22,  id: 2208106,  name: "Pimenteiras"},
  {stateId: 22,  id: 2208205,  name: "Pio IX"},
  {stateId: 22,  id: 2208304,  name: "Piracuruca"},
  {stateId: 22,  id: 2208403,  name: "Piripiri"},
  {stateId: 22,  id: 2208502,  name: "Porto"},
  {stateId: 22,  id: 2208551,  name: "Porto Alegre do Piauí"},
  {stateId: 22,  id: 2208601,  name: "Prata do Piauí"},
  {stateId: 22,  id: 2208650,  name: "Queimada Nova"},
  {stateId: 22,  id: 2208700,  name: "Redenção do Gurguéia"},
  {stateId: 22,  id: 2208809,  name: "Regeneração"},
  {stateId: 22,  id: 2208858,  name: "Riacho Frio"},
  {stateId: 22,  id: 2208874,  name: "Ribeira do Piauí"},
  {stateId: 22,  id: 2208908,  name: "Ribeiro Gonçalves"},
  {stateId: 22,  id: 2209005,  name: "Rio Grande do Piauí"},
  {stateId: 22,  id: 2209104,  name: "Santa Cruz do Piauí"},
  {stateId: 22,  id: 2209153,  name: "Santa Cruz dos Milagres"},
  {stateId: 22,  id: 2209203,  name: "Santa Filomena"},
  {stateId: 22,  id: 2209302,  name: "Santa Luz"},
  {stateId: 22,  id: 2209377,  name: "Santa Rosa do Piauí"},
  {stateId: 22,  id: 2209351,  name: "Santana do Piauí"},
  {stateId: 22,  id: 2209401,  name: "Santo Antônio de Lisboa"},
  {stateId: 22,  id: 2209450,  name: "Santo Antônio dos Milagres"},
  {stateId: 22,  id: 2209500,  name: "Santo Inácio do Piauí"},
  {stateId: 22,  id: 2209559,  name: "São Braz do Piauí"},
  {stateId: 22,  id: 2209609,  name: "São Félix do Piauí"},
  {stateId: 22,  id: 2209658,  name: "São Francisco de Assis do Piauí"},
  {stateId: 22,  id: 2209708,  name: "São Francisco do Piauí"},
  {stateId: 22,  id: 2209757,  name: "São Gonçalo do Gurguéia"},
  {stateId: 22,  id: 2209807,  name: "São Gonçalo do Piauí"},
  {stateId: 22,  id: 2209856,  name: "São João da Canabrava"},
  {stateId: 22,  id: 2209872,  name: "São João da Fronteira"},
  {stateId: 22,  id: 2209906,  name: "São João da Serra"},
  {stateId: 22,  id: 2209955,  name: "São João da Varjota"},
  {stateId: 22,  id: 2209971,  name: "São João do Arraial"},
  {stateId: 22,  id: 2210003,  name: "São João do Piauí"},
  {stateId: 22,  id: 2210052,  name: "São José do Divino"},
  {stateId: 22,  id: 2210102,  name: "São José do Peixe"},
  {stateId: 22,  id: 2210201,  name: "São José do Piauí"},
  {stateId: 22,  id: 2210300,  name: "São Julião"},
  {stateId: 22,  id: 2210359,  name: "São Lourenço do Piauí"},
  {stateId: 22,  id: 2210375,  name: "São Luis do Piauí"},
  {stateId: 22,  id: 2210383,  name: "São Miguel da Baixa Grande"},
  {stateId: 22,  id: 2210391,  name: "São Miguel do Fidalgo"},
  {stateId: 22,  id: 2210409,  name: "São Miguel do Tapuio"},
  {stateId: 22,  id: 2210508,  name: "São Pedro do Piauí"},
  {stateId: 22,  id: 2210607,  name: "São Raimundo Nonato"},
  {stateId: 22,  id: 2210623,  name: "Sebastião Barros"},
  {stateId: 22,  id: 2210631,  name: "Sebastião Leal"},
  {stateId: 22,  id: 2210656,  name: "Sigefredo Pacheco"},
  {stateId: 22,  id: 2210706,  name: "Simões"},
  {stateId: 22,  id: 2210805,  name: "Simplício Mendes"},
  {stateId: 22,  id: 2210904,  name: "Socorro do Piauí"},
  {stateId: 22,  id: 2210938,  name: "Sussuapara"},
  {stateId: 22,  id: 2210953,  name: "Tamboril do Piauí"},
  {stateId: 22,  id: 2210979,  name: "Tanque do Piauí"},
  {stateId: 22,  id: 2211001,  name: "Teresina"},
  {stateId: 22,  id: 2211100,  name: "União"},
  {stateId: 22,  id: 2211209,  name: "Uruçuí"},
  {stateId: 22,  id: 2211308,  name: "Valença do Piauí"},
  {stateId: 22,  id: 2211357,  name: "Várzea Branca"},
  {stateId: 22,  id: 2211407,  name: "Várzea Grande"},
  {stateId: 22,  id: 2211506,  name: "Vera Mendes"},
  {stateId: 22,  id: 2211605,  name: "Vila Nova do Piauí"},
  {stateId: 22,  id: 2211704,  name: "Wall Ferraz"},
  {stateId: 23,  id: 2300101,  name: "Abaiara"},
  {stateId: 23,  id: 2300150,  name: "Acarape"},
  {stateId: 23,  id: 2300200,  name: "Acaraú"},
  {stateId: 23,  id: 2300309,  name: "Acopiara"},
  {stateId: 23,  id: 2300408,  name: "Aiuaba"},
  {stateId: 23,  id: 2300507,  name: "Alcântaras"},
  {stateId: 23,  id: 2300606,  name: "Altaneira"},
  {stateId: 23,  id: 2300705,  name: "Alto Santo"},
  {stateId: 23,  id: 2300754,  name: "Amontada"},
  {stateId: 23,  id: 2300804,  name: "Antonina do Norte"},
  {stateId: 23,  id: 2300903,  name: "Apuiarés"},
  {stateId: 23,  id: 2301000,  name: "Aquiraz"},
  {stateId: 23,  id: 2301109,  name: "Aracati"},
  {stateId: 23,  id: 2301208,  name: "Aracoiaba"},
  {stateId: 23,  id: 2301257,  name: "Ararendá"},
  {stateId: 23,  id: 2301307,  name: "Araripe"},
  {stateId: 23,  id: 2301406,  name: "Aratuba"},
  {stateId: 23,  id: 2301505,  name: "Arneiroz"},
  {stateId: 23,  id: 2301604,  name: "Assaré"},
  {stateId: 23,  id: 2301703,  name: "Aurora"},
  {stateId: 23,  id: 2301802,  name: "Baixio"},
  {stateId: 23,  id: 2301851,  name: "Banabuiú"},
  {stateId: 23,  id: 2301901,  name: "Barbalha"},
  {stateId: 23,  id: 2301950,  name: "Barreira"},
  {stateId: 23,  id: 2302008,  name: "Barro"},
  {stateId: 23,  id: 2302057,  name: "Barroquinha"},
  {stateId: 23,  id: 2302107,  name: "Baturité"},
  {stateId: 23,  id: 2302206,  name: "Beberibe"},
  {stateId: 23,  id: 2302305,  name: "Bela Cruz"},
  {stateId: 23,  id: 2302404,  name: "Boa Viagem"},
  {stateId: 23,  id: 2302503,  name: "Brejo Santo"},
  {stateId: 23,  id: 2302602,  name: "Camocim"},
  {stateId: 23,  id: 2302701,  name: "Campos Sales"},
  {stateId: 23,  id: 2302800,  name: "Canindé"},
  {stateId: 23,  id: 2302909,  name: "Capistrano"},
  {stateId: 23,  id: 2303006,  name: "Caridade"},
  {stateId: 23,  id: 2303105,  name: "Cariré"},
  {stateId: 23,  id: 2303204,  name: "Caririaçu"},
  {stateId: 23,  id: 2303303,  name: "Cariús"},
  {stateId: 23,  id: 2303402,  name: "Carnaubal"},
  {stateId: 23,  id: 2303501,  name: "Cascavel"},
  {stateId: 23,  id: 2303600,  name: "Catarina"},
  {stateId: 23,  id: 2303659,  name: "Catunda"},
  {stateId: 23,  id: 2303709,  name: "Caucaia"},
  {stateId: 23,  id: 2303808,  name: "Cedro"},
  {stateId: 23,  id: 2303907,  name: "Chaval"},
  {stateId: 23,  id: 2303931,  name: "Choró"},
  {stateId: 23,  id: 2303956,  name: "Chorozinho"},
  {stateId: 23,  id: 2304004,  name: "Coreaú"},
  {stateId: 23,  id: 2304103,  name: "Crateús"},
  {stateId: 23,  id: 2304202,  name: "Crato"},
  {stateId: 23,  id: 2304236,  name: "Croatá"},
  {stateId: 23,  id: 2304251,  name: "Cruz"},
  {stateId: 23,  id: 2304269,  name: "Deputado Irapuan Pinheiro"},
  {stateId: 23,  id: 2304277,  name: "Ererê"},
  {stateId: 23,  id: 2304285,  name: "Eusébio"},
  {stateId: 23,  id: 2304301,  name: "Farias Brito"},
  {stateId: 23,  id: 2304350,  name: "Forquilha"},
  {stateId: 23,  id: 2304400,  name: "Fortaleza"},
  {stateId: 23,  id: 2304459,  name: "Fortim"},
  {stateId: 23,  id: 2304509,  name: "Frecheirinha"},
  {stateId: 23,  id: 2304608,  name: "General Sampaio"},
  {stateId: 23,  id: 2304657,  name: "Graça"},
  {stateId: 23,  id: 2304707,  name: "Granja"},
  {stateId: 23,  id: 2304806,  name: "Granjeiro"},
  {stateId: 23,  id: 2304905,  name: "Groaíras"},
  {stateId: 23,  id: 2304954,  name: "Guaiúba"},
  {stateId: 23,  id: 2305001,  name: "Guaraciaba do Norte"},
  {stateId: 23,  id: 2305100,  name: "Guaramiranga"},
  {stateId: 23,  id: 2305209,  name: "Hidrolândia"},
  {stateId: 23,  id: 2305233,  name: "Horizonte"},
  {stateId: 23,  id: 2305266,  name: "Ibaretama"},
  {stateId: 23,  id: 2305308,  name: "Ibiapina"},
  {stateId: 23,  id: 2305332,  name: "Ibicuitinga"},
  {stateId: 23,  id: 2305357,  name: "Icapuí"},
  {stateId: 23,  id: 2305407,  name: "Icó"},
  {stateId: 23,  id: 2305506,  name: "Iguatu"},
  {stateId: 23,  id: 2305605,  name: "Independência"},
  {stateId: 23,  id: 2305654,  name: "Ipaporanga"},
  {stateId: 23,  id: 2305704,  name: "Ipaumirim"},
  {stateId: 23,  id: 2305803,  name: "Ipu"},
  {stateId: 23,  id: 2305902,  name: "Ipueiras"},
  {stateId: 23,  id: 2306009,  name: "Iracema"},
  {stateId: 23,  id: 2306108,  name: "Irauçuba"},
  {stateId: 23,  id: 2306207,  name: "Itaiçaba"},
  {stateId: 23,  id: 2306256,  name: "Itaitinga"},
  {stateId: 23,  id: 2306306,  name: "Itapajé"},
  {stateId: 23,  id: 2306405,  name: "Itapipoca"},
  {stateId: 23,  id: 2306504,  name: "Itapiúna"},
  {stateId: 23,  id: 2306553,  name: "Itarema"},
  {stateId: 23,  id: 2306603,  name: "Itatira"},
  {stateId: 23,  id: 2306702,  name: "Jaguaretama"},
  {stateId: 23,  id: 2306801,  name: "Jaguaribara"},
  {stateId: 23,  id: 2306900,  name: "Jaguaribe"},
  {stateId: 23,  id: 2307007,  name: "Jaguaruana"},
  {stateId: 23,  id: 2307106,  name: "Jardim"},
  {stateId: 23,  id: 2307205,  name: "Jati"},
  {stateId: 23,  id: 2307254,  name: "Jijoca de Jericoacoara"},
  {stateId: 23,  id: 2307304,  name: "Juazeiro do Norte"},
  {stateId: 23,  id: 2307403,  name: "Jucás"},
  {stateId: 23,  id: 2307502,  name: "Lavras da Mangabeira"},
  {stateId: 23,  id: 2307601,  name: "Limoeiro do Norte"},
  {stateId: 23,  id: 2307635,  name: "Madalena"},
  {stateId: 23,  id: 2307650,  name: "Maracanaú"},
  {stateId: 23,  id: 2307700,  name: "Maranguape"},
  {stateId: 23,  id: 2307809,  name: "Marco"},
  {stateId: 23,  id: 2307908,  name: "Martinópole"},
  {stateId: 23,  id: 2308005,  name: "Massapê"},
  {stateId: 23,  id: 2308104,  name: "Mauriti"},
  {stateId: 23,  id: 2308203,  name: "Meruoca"},
  {stateId: 23,  id: 2308302,  name: "Milagres"},
  {stateId: 23,  id: 2308351,  name: "Milhã"},
  {stateId: 23,  id: 2308377,  name: "Miraíma"},
  {stateId: 23,  id: 2308401,  name: "Missão Velha"},
  {stateId: 23,  id: 2308500,  name: "Mombaça"},
  {stateId: 23,  id: 2308609,  name: "Monsenhor Tabosa"},
  {stateId: 23,  id: 2308708,  name: "Morada Nova"},
  {stateId: 23,  id: 2308807,  name: "Moraújo"},
  {stateId: 23,  id: 2308906,  name: "Morrinhos"},
  {stateId: 23,  id: 2309003,  name: "Mucambo"},
  {stateId: 23,  id: 2309102,  name: "Mulungu"},
  {stateId: 23,  id: 2309201,  name: "Nova Olinda"},
  {stateId: 23,  id: 2309300,  name: "Nova Russas"},
  {stateId: 23,  id: 2309409,  name: "Novo Oriente"},
  {stateId: 23,  id: 2309458,  name: "Ocara"},
  {stateId: 23,  id: 2309508,  name: "Orós"},
  {stateId: 23,  id: 2309607,  name: "Pacajus"},
  {stateId: 23,  id: 2309706,  name: "Pacatuba"},
  {stateId: 23,  id: 2309805,  name: "Pacoti"},
  {stateId: 23,  id: 2309904,  name: "Pacujá"},
  {stateId: 23,  id: 2310001,  name: "Palhano"},
  {stateId: 23,  id: 2310100,  name: "Palmácia"},
  {stateId: 23,  id: 2310209,  name: "Paracuru"},
  {stateId: 23,  id: 2310258,  name: "Paraipaba"},
  {stateId: 23,  id: 2310308,  name: "Parambu"},
  {stateId: 23,  id: 2310407,  name: "Paramoti"},
  {stateId: 23,  id: 2310506,  name: "Pedra Branca"},
  {stateId: 23,  id: 2310605,  name: "Penaforte"},
  {stateId: 23,  id: 2310704,  name: "Pentecoste"},
  {stateId: 23,  id: 2310803,  name: "Pereiro"},
  {stateId: 23,  id: 2310852,  name: "Pindoretama"},
  {stateId: 23,  id: 2310902,  name: "Piquet Carneiro"},
  {stateId: 23,  id: 2310951,  name: "Pires Ferreira"},
  {stateId: 23,  id: 2311009,  name: "Poranga"},
  {stateId: 23,  id: 2311108,  name: "Porteiras"},
  {stateId: 23,  id: 2311207,  name: "Potengi"},
  {stateId: 23,  id: 2311231,  name: "Potiretama"},
  {stateId: 23,  id: 2311264,  name: "Quiterianópolis"},
  {stateId: 23,  id: 2311306,  name: "Quixadá"},
  {stateId: 23,  id: 2311355,  name: "Quixelô"},
  {stateId: 23,  id: 2311405,  name: "Quixeramobim"},
  {stateId: 23,  id: 2311504,  name: "Quixeré"},
  {stateId: 23,  id: 2311603,  name: "Redenção"},
  {stateId: 23,  id: 2311702,  name: "Reriutaba"},
  {stateId: 23,  id: 2311801,  name: "Russas"},
  {stateId: 23,  id: 2311900,  name: "Saboeiro"},
  {stateId: 23,  id: 2311959,  name: "Salitre"},
  {stateId: 23,  id: 2312205,  name: "Santa Quitéria"},
  {stateId: 23,  id: 2312007,  name: "Santana do Acaraú"},
  {stateId: 23,  id: 2312106,  name: "Santana do Cariri"},
  {stateId: 23,  id: 2312304,  name: "São Benedito"},
  {stateId: 23,  id: 2312403,  name: "São Gonçalo do Amarante"},
  {stateId: 23,  id: 2312502,  name: "São João do Jaguaribe"},
  {stateId: 23,  id: 2312601,  name: "São Luís do Curu"},
  {stateId: 23,  id: 2312700,  name: "Senador Pompeu"},
  {stateId: 23,  id: 2312809,  name: "Senador Sá"},
  {stateId: 23,  id: 2312908,  name: "Sobral"},
  {stateId: 23,  id: 2313005,  name: "Solonópole"},
  {stateId: 23,  id: 2313104,  name: "Tabuleiro do Norte"},
  {stateId: 23,  id: 2313203,  name: "Tamboril"},
  {stateId: 23,  id: 2313252,  name: "Tarrafas"},
  {stateId: 23,  id: 2313302,  name: "Tauá"},
  {stateId: 23,  id: 2313351,  name: "Tejuçuoca"},
  {stateId: 23,  id: 2313401,  name: "Tianguá"},
  {stateId: 23,  id: 2313500,  name: "Trairi"},
  {stateId: 23,  id: 2313559,  name: "Tururu"},
  {stateId: 23,  id: 2313609,  name: "Ubajara"},
  {stateId: 23,  id: 2313708,  name: "Umari"},
  {stateId: 23,  id: 2313757,  name: "Umirim"},
  {stateId: 23,  id: 2313807,  name: "Uruburetama"},
  {stateId: 23,  id: 2313906,  name: "Uruoca"},
  {stateId: 23,  id: 2313955,  name: "Varjota"},
  {stateId: 23,  id: 2314003,  name: "Várzea Alegre"},
  {stateId: 23,  id: 2314102,  name: "Viçosa do Ceará"},
  {stateId: 24,  id: 2400109,  name: "Acari"},
  {stateId: 24,  id: 2400208,  name: "Açu"},
  {stateId: 24,  id: 2400307,  name: "Afonso Bezerra"},
  {stateId: 24,  id: 2400406,  name: "Água Nova"},
  {stateId: 24,  id: 2400505,  name: "Alexandria"},
  {stateId: 24,  id: 2400604,  name: "Almino Afonso"},
  {stateId: 24,  id: 2400703,  name: "Alto do Rodrigues"},
  {stateId: 24,  id: 2400802,  name: "Angicos"},
  {stateId: 24,  id: 2400901,  name: "Antônio Martins"},
  {stateId: 24,  id: 2401008,  name: "Apodi"},
  {stateId: 24,  id: 2401107,  name: "Areia Branca"},
  {stateId: 24,  id: 2401206,  name: "Arês"},
  {stateId: 24,  id: 2401305,  name: "Augusto Severo"},
  {stateId: 24,  id: 2401404,  name: "Baía Formosa"},
  {stateId: 24,  id: 2401453,  name: "Baraúna"},
  {stateId: 24,  id: 2401503,  name: "Barcelona"},
  {stateId: 24,  id: 2401602,  name: "Bento Fernandes"},
  {stateId: 24,  id: 2401651,  name: "Bodó"},
  {stateId: 24,  id: 2401701,  name: "Bom Jesus"},
  {stateId: 24,  id: 2401800,  name: "Brejinho"},
  {stateId: 24,  id: 2401859,  name: "Caiçara do Norte"},
  {stateId: 24,  id: 2401909,  name: "Caiçara do Rio do Vento"},
  {stateId: 24,  id: 2402006,  name: "Caicó"},
  {stateId: 24,  id: 2402105,  name: "Campo Redondo"},
  {stateId: 24,  id: 2402204,  name: "Canguaretama"},
  {stateId: 24,  id: 2402303,  name: "Caraúbas"},
  {stateId: 24,  id: 2402402,  name: "Carnaúba dos Dantas"},
  {stateId: 24,  id: 2402501,  name: "Carnaubais"},
  {stateId: 24,  id: 2402600,  name: "Ceará-Mirim"},
  {stateId: 24,  id: 2402709,  name: "Cerro Corá"},
  {stateId: 24,  id: 2402808,  name: "Coronel Ezequiel"},
  {stateId: 24,  id: 2402907,  name: "Coronel João Pessoa"},
  {stateId: 24,  id: 2403004,  name: "Cruzeta"},
  {stateId: 24,  id: 2403103,  name: "Currais Novos"},
  {stateId: 24,  id: 2403202,  name: "Doutor Severiano"},
  {stateId: 24,  id: 2403301,  name: "Encanto"},
  {stateId: 24,  id: 2403400,  name: "Equador"},
  {stateId: 24,  id: 2403509,  name: "Espírito Santo"},
  {stateId: 24,  id: 2403608,  name: "Extremoz"},
  {stateId: 24,  id: 2403707,  name: "Felipe Guerra"},
  {stateId: 24,  id: 2403756,  name: "Fernando Pedroza"},
  {stateId: 24,  id: 2403806,  name: "Florânia"},
  {stateId: 24,  id: 2403905,  name: "Francisco Dantas"},
  {stateId: 24,  id: 2404002,  name: "Frutuoso Gomes"},
  {stateId: 24,  id: 2404101,  name: "Galinhos"},
  {stateId: 24,  id: 2404200,  name: "Goianinha"},
  {stateId: 24,  id: 2404309,  name: "Governador Dix-Sept Rosado"},
  {stateId: 24,  id: 2404408,  name: "Grossos"},
  {stateId: 24,  id: 2404507,  name: "Guamaré"},
  {stateId: 24,  id: 2404606,  name: "Ielmo Marinho"},
  {stateId: 24,  id: 2404705,  name: "Ipanguaçu"},
  {stateId: 24,  id: 2404804,  name: "Ipueira"},
  {stateId: 24,  id: 2404853,  name: "Itajá"},
  {stateId: 24,  id: 2404903,  name: "Itaú"},
  {stateId: 24,  id: 2405009,  name: "Jaçanã"},
  {stateId: 24,  id: 2405108,  name: "Jandaíra"},
  {stateId: 24,  id: 2405207,  name: "Janduís"},
  {stateId: 24,  id: 2405306,  name: "Januário Cicco"},
  {stateId: 24,  id: 2405405,  name: "Japi"},
  {stateId: 24,  id: 2405504,  name: "Jardim de Angicos"},
  {stateId: 24,  id: 2405603,  name: "Jardim de Piranhas"},
  {stateId: 24,  id: 2405702,  name: "Jardim do Seridó"},
  {stateId: 24,  id: 2405801,  name: "João Câmara"},
  {stateId: 24,  id: 2405900,  name: "João Dias"},
  {stateId: 24,  id: 2406007,  name: "José da Penha"},
  {stateId: 24,  id: 2406106,  name: "Jucurutu"},
  {stateId: 24,  id: 2406155,  name: "Jundiá"},
  {stateId: 24,  id: 2406205,  name: "Lagoa d'Anta"},
  {stateId: 24,  id: 2406304,  name: "Lagoa de Pedras"},
  {stateId: 24,  id: 2406403,  name: "Lagoa de Velhos"},
  {stateId: 24,  id: 2406502,  name: "Lagoa Nova"},
  {stateId: 24,  id: 2406601,  name: "Lagoa Salgada"},
  {stateId: 24,  id: 2406700,  name: "Lajes"},
  {stateId: 24,  id: 2406809,  name: "Lajes Pintadas"},
  {stateId: 24,  id: 2406908,  name: "Lucrécia"},
  {stateId: 24,  id: 2407005,  name: "Luís Gomes"},
  {stateId: 24,  id: 2407104,  name: "Macaíba"},
  {stateId: 24,  id: 2407203,  name: "Macau"},
  {stateId: 24,  id: 2407252,  name: "Major Sales"},
  {stateId: 24,  id: 2407302,  name: "Marcelino Vieira"},
  {stateId: 24,  id: 2407401,  name: "Martins"},
  {stateId: 24,  id: 2407500,  name: "Maxaranguape"},
  {stateId: 24,  id: 2407609,  name: "Messias Targino"},
  {stateId: 24,  id: 2407708,  name: "Montanhas"},
  {stateId: 24,  id: 2407807,  name: "Monte Alegre"},
  {stateId: 24,  id: 2407906,  name: "Monte das Gameleiras"},
  {stateId: 24,  id: 2408003,  name: "Mossoró"},
  {stateId: 24,  id: 2408102,  name: "Natal"},
  {stateId: 24,  id: 2408201,  name: "Nísia Floresta"},
  {stateId: 24,  id: 2408300,  name: "Nova Cruz"},
  {stateId: 24,  id: 2408409,  name: "Olho d'Água do Borges"},
  {stateId: 24,  id: 2408508,  name: "Ouro Branco"},
  {stateId: 24,  id: 2408607,  name: "Paraná"},
  {stateId: 24,  id: 2408706,  name: "Paraú"},
  {stateId: 24,  id: 2408805,  name: "Parazinho"},
  {stateId: 24,  id: 2408904,  name: "Parelhas"},
  {stateId: 24,  id: 2403251,  name: "Parnamirim"},
  {stateId: 24,  id: 2409100,  name: "Passa e Fica"},
  {stateId: 24,  id: 2409209,  name: "Passagem"},
  {stateId: 24,  id: 2409308,  name: "Patu"},
  {stateId: 24,  id: 2409407,  name: "Pau dos Ferros"},
  {stateId: 24,  id: 2409506,  name: "Pedra Grande"},
  {stateId: 24,  id: 2409605,  name: "Pedra Preta"},
  {stateId: 24,  id: 2409704,  name: "Pedro Avelino"},
  {stateId: 24,  id: 2409803,  name: "Pedro Velho"},
  {stateId: 24,  id: 2409902,  name: "Pendências"},
  {stateId: 24,  id: 2410009,  name: "Pilões"},
  {stateId: 24,  id: 2410108,  name: "Poço Branco"},
  {stateId: 24,  id: 2410207,  name: "Portalegre"},
  {stateId: 24,  id: 2410256,  name: "Porto do Mangue"},
  {stateId: 24,  id: 2410405,  name: "Pureza"},
  {stateId: 24,  id: 2410504,  name: "Rafael Fernandes"},
  {stateId: 24,  id: 2410603,  name: "Rafael Godeiro"},
  {stateId: 24,  id: 2410702,  name: "Riacho da Cruz"},
  {stateId: 24,  id: 2410801,  name: "Riacho de Santana"},
  {stateId: 24,  id: 2410900,  name: "Riachuelo"},
  {stateId: 24,  id: 2408953,  name: "Rio do Fogo"},
  {stateId: 24,  id: 2411007,  name: "Rodolfo Fernandes"},
  {stateId: 24,  id: 2411106,  name: "Ruy Barbosa"},
  {stateId: 24,  id: 2411205,  name: "Santa Cruz"},
  {stateId: 24,  id: 2409332,  name: "Santa Maria"},
  {stateId: 24,  id: 2411403,  name: "Santana do Matos"},
  {stateId: 24,  id: 2411429,  name: "Santana do Seridó"},
  {stateId: 24,  id: 2411502,  name: "Santo Antônio"},
  {stateId: 24,  id: 2411601,  name: "São Bento do Norte"},
  {stateId: 24,  id: 2411700,  name: "São Bento do Trairí"},
  {stateId: 24,  id: 2411809,  name: "São Fernando"},
  {stateId: 24,  id: 2411908,  name: "São Francisco do Oeste"},
  {stateId: 24,  id: 2412005,  name: "São Gonçalo do Amarante"},
  {stateId: 24,  id: 2412104,  name: "São João do Sabugi"},
  {stateId: 24,  id: 2412203,  name: "São José de Mipibu"},
  {stateId: 24,  id: 2412302,  name: "São José do Campestre"},
  {stateId: 24,  id: 2412401,  name: "São José do Seridó"},
  {stateId: 24,  id: 2412500,  name: "São Miguel"},
  {stateId: 24,  id: 2412559,  name: "São Miguel do Gostoso"},
  {stateId: 24,  id: 2412609,  name: "São Paulo do Potengi"},
  {stateId: 24,  id: 2412708,  name: "São Pedro"},
  {stateId: 24,  id: 2412807,  name: "São Rafael"},
  {stateId: 24,  id: 2412906,  name: "São Tomé"},
  {stateId: 24,  id: 2413003,  name: "São Vicente"},
  {stateId: 24,  id: 2413102,  name: "Senador Elói de Souza"},
  {stateId: 24,  id: 2413201,  name: "Senador Georgino Avelino"},
  {stateId: 24,  id: 2410306,  name: "Serra Caiada"},
  {stateId: 24,  id: 2413300,  name: "Serra de São Bento"},
  {stateId: 24,  id: 2413359,  name: "Serra do Mel"},
  {stateId: 24,  id: 2413409,  name: "Serra Negra do Norte"},
  {stateId: 24,  id: 2413508,  name: "Serrinha"},
  {stateId: 24,  id: 2413557,  name: "Serrinha dos Pintos"},
  {stateId: 24,  id: 2413607,  name: "Severiano Melo"},
  {stateId: 24,  id: 2413706,  name: "Sítio Novo"},
  {stateId: 24,  id: 2413805,  name: "Taboleiro Grande"},
  {stateId: 24,  id: 2413904,  name: "Taipu"},
  {stateId: 24,  id: 2414001,  name: "Tangará"},
  {stateId: 24,  id: 2414100,  name: "Tenente Ananias"},
  {stateId: 24,  id: 2414159,  name: "Tenente Laurentino Cruz"},
  {stateId: 24,  id: 2411056,  name: "Tibau"},
  {stateId: 24,  id: 2414209,  name: "Tibau do Sul"},
  {stateId: 24,  id: 2414308,  name: "Timbaúba dos Batistas"},
  {stateId: 24,  id: 2414407,  name: "Touros"},
  {stateId: 24,  id: 2414456,  name: "Triunfo Potiguar"},
  {stateId: 24,  id: 2414506,  name: "Umarizal"},
  {stateId: 24,  id: 2414605,  name: "Upanema"},
  {stateId: 24,  id: 2414704,  name: "Várzea"},
  {stateId: 24,  id: 2414753,  name: "Venha-Ver"},
  {stateId: 24,  id: 2414803,  name: "Vera Cruz"},
  {stateId: 24,  id: 2414902,  name: "Viçosa"},
  {stateId: 24,  id: 2415008,  name: "Vila Flor"},
  {stateId: 25,  id: 2500106,  name: "Água Branca"},
  {stateId: 25,  id: 2500205,  name: "Aguiar"},
  {stateId: 25,  id: 2500304,  name: "Alagoa Grande"},
  {stateId: 25,  id: 2500403,  name: "Alagoa Nova"},
  {stateId: 25,  id: 2500502,  name: "Alagoinha"},
  {stateId: 25,  id: 2500536,  name: "Alcantil"},
  {stateId: 25,  id: 2500577,  name: "Algodão de Jandaíra"},
  {stateId: 25,  id: 2500601,  name: "Alhandra"},
  {stateId: 25,  id: 2500734,  name: "Amparo"},
  {stateId: 25,  id: 2500775,  name: "Aparecida"},
  {stateId: 25,  id: 2500809,  name: "Araçagi"},
  {stateId: 25,  id: 2500908,  name: "Arara"},
  {stateId: 25,  id: 2501005,  name: "Araruna"},
  {stateId: 25,  id: 2501104,  name: "Areia"},
  {stateId: 25,  id: 2501153,  name: "Areia de Baraúnas"},
  {stateId: 25,  id: 2501203,  name: "Areial"},
  {stateId: 25,  id: 2501302,  name: "Aroeiras"},
  {stateId: 25,  id: 2501351,  name: "Assunção"},
  {stateId: 25,  id: 2501401,  name: "Baía da Traição"},
  {stateId: 25,  id: 2501500,  name: "Bananeiras"},
  {stateId: 25,  id: 2501534,  name: "Baraúna"},
  {stateId: 25,  id: 2501609,  name: "Barra de Santa Rosa"},
  {stateId: 25,  id: 2501575,  name: "Barra de Santana"},
  {stateId: 25,  id: 2501708,  name: "Barra de São Miguel"},
  {stateId: 25,  id: 2501807,  name: "Bayeux"},
  {stateId: 25,  id: 2501906,  name: "Belém"},
  {stateId: 25,  id: 2502003,  name: "Belém do Brejo do Cruz"},
  {stateId: 25,  id: 2502052,  name: "Bernardino Batista"},
  {stateId: 25,  id: 2502102,  name: "Boa Ventura"},
  {stateId: 25,  id: 2502151,  name: "Boa Vista"},
  {stateId: 25,  id: 2502201,  name: "Bom Jesus"},
  {stateId: 25,  id: 2502300,  name: "Bom Sucesso"},
  {stateId: 25,  id: 2502409,  name: "Bonito de Santa Fé"},
  {stateId: 25,  id: 2502508,  name: "Boqueirão"},
  {stateId: 25,  id: 2502706,  name: "Borborema"},
  {stateId: 25,  id: 2502805,  name: "Brejo do Cruz"},
  {stateId: 25,  id: 2502904,  name: "Brejo dos Santos"},
  {stateId: 25,  id: 2503001,  name: "Caaporã"},
  {stateId: 25,  id: 2503100,  name: "Cabaceiras"},
  {stateId: 25,  id: 2503209,  name: "Cabedelo"},
  {stateId: 25,  id: 2503308,  name: "Cachoeira dos Índios"},
  {stateId: 25,  id: 2503407,  name: "Cacimba de Areia"},
  {stateId: 25,  id: 2503506,  name: "Cacimba de Dentro"},
  {stateId: 25,  id: 2503555,  name: "Cacimbas"},
  {stateId: 25,  id: 2503605,  name: "Caiçara"},
  {stateId: 25,  id: 2503704,  name: "Cajazeiras"},
  {stateId: 25,  id: 2503753,  name: "Cajazeirinhas"},
  {stateId: 25,  id: 2503803,  name: "Caldas Brandão"},
  {stateId: 25,  id: 2503902,  name: "Camalaú"},
  {stateId: 25,  id: 2504009,  name: "Campina Grande"},
  {stateId: 25,  id: 2504033,  name: "Capim"},
  {stateId: 25,  id: 2504074,  name: "Caraúbas"},
  {stateId: 25,  id: 2504108,  name: "Carrapateira"},
  {stateId: 25,  id: 2504157,  name: "Casserengue"},
  {stateId: 25,  id: 2504207,  name: "Catingueira"},
  {stateId: 25,  id: 2504306,  name: "Catolé do Rocha"},
  {stateId: 25,  id: 2504355,  name: "Caturité"},
  {stateId: 25,  id: 2504405,  name: "Conceição"},
  {stateId: 25,  id: 2504504,  name: "Condado"},
  {stateId: 25,  id: 2504603,  name: "Conde"},
  {stateId: 25,  id: 2504702,  name: "Congo"},
  {stateId: 25,  id: 2504801,  name: "Coremas"},
  {stateId: 25,  id: 2504850,  name: "Coxixola"},
  {stateId: 25,  id: 2504900,  name: "Cruz do Espírito Santo"},
  {stateId: 25,  id: 2505006,  name: "Cubati"},
  {stateId: 25,  id: 2505105,  name: "Cuité"},
  {stateId: 25,  id: 2505238,  name: "Cuité de Mamanguape"},
  {stateId: 25,  id: 2505204,  name: "Cuitegi"},
  {stateId: 25,  id: 2505279,  name: "Curral de Cima"},
  {stateId: 25,  id: 2505303,  name: "Curral Velho"},
  {stateId: 25,  id: 2505352,  name: "Damião"},
  {stateId: 25,  id: 2505402,  name: "Desterro"},
  {stateId: 25,  id: 2505600,  name: "Diamante"},
  {stateId: 25,  id: 2505709,  name: "Dona Inês"},
  {stateId: 25,  id: 2505808,  name: "Duas Estradas"},
  {stateId: 25,  id: 2505907,  name: "Emas"},
  {stateId: 25,  id: 2506004,  name: "Esperança"},
  {stateId: 25,  id: 2506103,  name: "Fagundes"},
  {stateId: 25,  id: 2506202,  name: "Frei Martinho"},
  {stateId: 25,  id: 2506251,  name: "Gado Bravo"},
  {stateId: 25,  id: 2506301,  name: "Guarabira"},
  {stateId: 25,  id: 2506400,  name: "Gurinhém"},
  {stateId: 25,  id: 2506509,  name: "Gurjão"},
  {stateId: 25,  id: 2506608,  name: "Ibiara"},
  {stateId: 25,  id: 2502607,  name: "Igaracy"},
  {stateId: 25,  id: 2506707,  name: "Imaculada"},
  {stateId: 25,  id: 2506806,  name: "Ingá"},
  {stateId: 25,  id: 2506905,  name: "Itabaiana"},
  {stateId: 25,  id: 2507002,  name: "Itaporanga"},
  {stateId: 25,  id: 2507101,  name: "Itapororoca"},
  {stateId: 25,  id: 2507200,  name: "Itatuba"},
  {stateId: 25,  id: 2507309,  name: "Jacaraú"},
  {stateId: 25,  id: 2507408,  name: "Jericó"},
  {stateId: 25,  id: 2507507,  name: "João Pessoa"},
  {stateId: 25,  id: 2513653,  name: "Joca Claudino"},
  {stateId: 25,  id: 2507606,  name: "Juarez Távora"},
  {stateId: 25,  id: 2507705,  name: "Juazeirinho"},
  {stateId: 25,  id: 2507804,  name: "Junco do Seridó"},
  {stateId: 25,  id: 2507903,  name: "Juripiranga"},
  {stateId: 25,  id: 2508000,  name: "Juru"},
  {stateId: 25,  id: 2508109,  name: "Lagoa"},
  {stateId: 25,  id: 2508208,  name: "Lagoa de Dentro"},
  {stateId: 25,  id: 2508307,  name: "Lagoa Seca"},
  {stateId: 25,  id: 2508406,  name: "Lastro"},
  {stateId: 25,  id: 2508505,  name: "Livramento"},
  {stateId: 25,  id: 2508554,  name: "Logradouro"},
  {stateId: 25,  id: 2508604,  name: "Lucena"},
  {stateId: 25,  id: 2508703,  name: "Mãe d'Água"},
  {stateId: 25,  id: 2508802,  name: "Malta"},
  {stateId: 25,  id: 2508901,  name: "Mamanguape"},
  {stateId: 25,  id: 2509008,  name: "Manaíra"},
  {stateId: 25,  id: 2509057,  name: "Marcação"},
  {stateId: 25,  id: 2509107,  name: "Mari"},
  {stateId: 25,  id: 2509156,  name: "Marizópolis"},
  {stateId: 25,  id: 2509206,  name: "Massaranduba"},
  {stateId: 25,  id: 2509305,  name: "Mataraca"},
  {stateId: 25,  id: 2509339,  name: "Matinhas"},
  {stateId: 25,  id: 2509370,  name: "Mato Grosso"},
  {stateId: 25,  id: 2509396,  name: "Maturéia"},
  {stateId: 25,  id: 2509404,  name: "Mogeiro"},
  {stateId: 25,  id: 2509503,  name: "Montadas"},
  {stateId: 25,  id: 2509602,  name: "Monte Horebe"},
  {stateId: 25,  id: 2509701,  name: "Monteiro"},
  {stateId: 25,  id: 2509800,  name: "Mulungu"},
  {stateId: 25,  id: 2509909,  name: "Natuba"},
  {stateId: 25,  id: 2510006,  name: "Nazarezinho"},
  {stateId: 25,  id: 2510105,  name: "Nova Floresta"},
  {stateId: 25,  id: 2510204,  name: "Nova Olinda"},
  {stateId: 25,  id: 2510303,  name: "Nova Palmeira"},
  {stateId: 25,  id: 2510402,  name: "Olho d'Água"},
  {stateId: 25,  id: 2510501,  name: "Olivedos"},
  {stateId: 25,  id: 2510600,  name: "Ouro Velho"},
  {stateId: 25,  id: 2510659,  name: "Parari"},
  {stateId: 25,  id: 2510709,  name: "Passagem"},
  {stateId: 25,  id: 2510808,  name: "Patos"},
  {stateId: 25,  id: 2510907,  name: "Paulista"},
  {stateId: 25,  id: 2511004,  name: "Pedra Branca"},
  {stateId: 25,  id: 2511103,  name: "Pedra Lavrada"},
  {stateId: 25,  id: 2511202,  name: "Pedras de Fogo"},
  {stateId: 25,  id: 2512721,  name: "Pedro Régis"},
  {stateId: 25,  id: 2511301,  name: "Piancó"},
  {stateId: 25,  id: 2511400,  name: "Picuí"},
  {stateId: 25,  id: 2511509,  name: "Pilar"},
  {stateId: 25,  id: 2511608,  name: "Pilões"},
  {stateId: 25,  id: 2511707,  name: "Pilõezinhos"},
  {stateId: 25,  id: 2511806,  name: "Pirpirituba"},
  {stateId: 25,  id: 2511905,  name: "Pitimbu"},
  {stateId: 25,  id: 2512002,  name: "Pocinhos"},
  {stateId: 25,  id: 2512036,  name: "Poço Dantas"},
  {stateId: 25,  id: 2512077,  name: "Poço de José de Moura"},
  {stateId: 25,  id: 2512101,  name: "Pombal"},
  {stateId: 25,  id: 2512200,  name: "Prata"},
  {stateId: 25,  id: 2512309,  name: "Princesa Isabel"},
  {stateId: 25,  id: 2512408,  name: "Puxinanã"},
  {stateId: 25,  id: 2512507,  name: "Queimadas"},
  {stateId: 25,  id: 2512606,  name: "Quixaba"},
  {stateId: 25,  id: 2512705,  name: "Remígio"},
  {stateId: 25,  id: 2512747,  name: "Riachão"},
  {stateId: 25,  id: 2512754,  name: "Riachão do Bacamarte"},
  {stateId: 25,  id: 2512762,  name: "Riachão do Poço"},
  {stateId: 25,  id: 2512788,  name: "Riacho de Santo Antônio"},
  {stateId: 25,  id: 2512804,  name: "Riacho dos Cavalos"},
  {stateId: 25,  id: 2512903,  name: "Rio Tinto"},
  {stateId: 25,  id: 2513000,  name: "Salgadinho"},
  {stateId: 25,  id: 2513109,  name: "Salgado de São Félix"},
  {stateId: 25,  id: 2513158,  name: "Santa Cecília"},
  {stateId: 25,  id: 2513208,  name: "Santa Cruz"},
  {stateId: 25,  id: 2513307,  name: "Santa Helena"},
  {stateId: 25,  id: 2513356,  name: "Santa Inês"},
  {stateId: 25,  id: 2513406,  name: "Santa Luzia"},
  {stateId: 25,  id: 2513703,  name: "Santa Rita"},
  {stateId: 25,  id: 2513802,  name: "Santa Teresinha"},
  {stateId: 25,  id: 2513505,  name: "Santana de Mangueira"},
  {stateId: 25,  id: 2513604,  name: "Santana dos Garrotes"},
  {stateId: 25,  id: 2513851,  name: "Santo André"},
  {stateId: 25,  id: 2513927,  name: "São Bentinho"},
  {stateId: 25,  id: 2513901,  name: "São Bento"},
  {stateId: 25,  id: 2513968,  name: "São Domingos"},
  {stateId: 25,  id: 2513943,  name: "São Domingos do Cariri"},
  {stateId: 25,  id: 2513984,  name: "São Francisco"},
  {stateId: 25,  id: 2514008,  name: "São João do Cariri"},
  {stateId: 25,  id: 2500700,  name: "São João do Rio do Peixe"},
  {stateId: 25,  id: 2514107,  name: "São João do Tigre"},
  {stateId: 25,  id: 2514206,  name: "São José da Lagoa Tapada"},
  {stateId: 25,  id: 2514305,  name: "São José de Caiana"},
  {stateId: 25,  id: 2514404,  name: "São José de Espinharas"},
  {stateId: 25,  id: 2514503,  name: "São José de Piranhas"},
  {stateId: 25,  id: 2514552,  name: "São José de Princesa"},
  {stateId: 25,  id: 2514602,  name: "São José do Bonfim"},
  {stateId: 25,  id: 2514651,  name: "São José do Brejo do Cruz"},
  {stateId: 25,  id: 2514701,  name: "São José do Sabugi"},
  {stateId: 25,  id: 2514800,  name: "São José dos Cordeiros"},
  {stateId: 25,  id: 2514453,  name: "São José dos Ramos"},
  {stateId: 25,  id: 2514909,  name: "São Mamede"},
  {stateId: 25,  id: 2515005,  name: "São Miguel de Taipu"},
  {stateId: 25,  id: 2515104,  name: "São Sebastião de Lagoa de Roça"},
  {stateId: 25,  id: 2515203,  name: "São Sebastião do Umbuzeiro"},
  {stateId: 25,  id: 2515401,  name: "São Vicente do Seridó"},
  {stateId: 25,  id: 2515302,  name: "Sapé"},
  {stateId: 25,  id: 2515500,  name: "Serra Branca"},
  {stateId: 25,  id: 2515609,  name: "Serra da Raiz"},
  {stateId: 25,  id: 2515708,  name: "Serra Grande"},
  {stateId: 25,  id: 2515807,  name: "Serra Redonda"},
  {stateId: 25,  id: 2515906,  name: "Serraria"},
  {stateId: 25,  id: 2515930,  name: "Sertãozinho"},
  {stateId: 25,  id: 2515971,  name: "Sobrado"},
  {stateId: 25,  id: 2516003,  name: "Solânea"},
  {stateId: 25,  id: 2516102,  name: "Soledade"},
  {stateId: 25,  id: 2516151,  name: "Sossêgo"},
  {stateId: 25,  id: 2516201,  name: "Sousa"},
  {stateId: 25,  id: 2516300,  name: "Sumé"},
  {stateId: 25,  id: 2516409,  name: "Tacima"},
  {stateId: 25,  id: 2516508,  name: "Taperoá"},
  {stateId: 25,  id: 2516607,  name: "Tavares"},
  {stateId: 25,  id: 2516706,  name: "Teixeira"},
  {stateId: 25,  id: 2516755,  name: "Tenório"},
  {stateId: 25,  id: 2516805,  name: "Triunfo"},
  {stateId: 25,  id: 2516904,  name: "Uiraúna"},
  {stateId: 25,  id: 2517001,  name: "Umbuzeiro"},
  {stateId: 25,  id: 2517100,  name: "Várzea"},
  {stateId: 25,  id: 2517209,  name: "Vieirópolis"},
  {stateId: 25,  id: 2505501,  name: "Vista Serrana"},
  {stateId: 25,  id: 2517407,  name: "Zabelê"},
  {stateId: 26,  id: 2600054,  name: "Abreu e Lima"},
  {stateId: 26,  id: 2600104,  name: "Afogados da Ingazeira"},
  {stateId: 26,  id: 2600203,  name: "Afrânio"},
  {stateId: 26,  id: 2600302,  name: "Agrestina"},
  {stateId: 26,  id: 2600401,  name: "Água Preta"},
  {stateId: 26,  id: 2600500,  name: "Águas Belas"},
  {stateId: 26,  id: 2600609,  name: "Alagoinha"},
  {stateId: 26,  id: 2600708,  name: "Aliança"},
  {stateId: 26,  id: 2600807,  name: "Altinho"},
  {stateId: 26,  id: 2600906,  name: "Amaraji"},
  {stateId: 26,  id: 2601003,  name: "Angelim"},
  {stateId: 26,  id: 2601052,  name: "Araçoiaba"},
  {stateId: 26,  id: 2601102,  name: "Araripina"},
  {stateId: 26,  id: 2601201,  name: "Arcoverde"},
  {stateId: 26,  id: 2601300,  name: "Barra de Guabiraba"},
  {stateId: 26,  id: 2601409,  name: "Barreiros"},
  {stateId: 26,  id: 2601508,  name: "Belém de Maria"},
  {stateId: 26,  id: 2601607,  name: "Belém do São Francisco"},
  {stateId: 26,  id: 2601706,  name: "Belo Jardim"},
  {stateId: 26,  id: 2601805,  name: "Betânia"},
  {stateId: 26,  id: 2601904,  name: "Bezerros"},
  {stateId: 26,  id: 2602001,  name: "Bodocó"},
  {stateId: 26,  id: 2602100,  name: "Bom Conselho"},
  {stateId: 26,  id: 2602209,  name: "Bom Jardim"},
  {stateId: 26,  id: 2602308,  name: "Bonito"},
  {stateId: 26,  id: 2602407,  name: "Brejão"},
  {stateId: 26,  id: 2602506,  name: "Brejinho"},
  {stateId: 26,  id: 2602605,  name: "Brejo da Madre de Deus"},
  {stateId: 26,  id: 2602704,  name: "Buenos Aires"},
  {stateId: 26,  id: 2602803,  name: "Buíque"},
  {stateId: 26,  id: 2602902,  name: "Cabo de Santo Agostinho"},
  {stateId: 26,  id: 2603009,  name: "Cabrobó"},
  {stateId: 26,  id: 2603108,  name: "Cachoeirinha"},
  {stateId: 26,  id: 2603207,  name: "Caetés"},
  {stateId: 26,  id: 2603306,  name: "Calçado"},
  {stateId: 26,  id: 2603405,  name: "Calumbi"},
  {stateId: 26,  id: 2603454,  name: "Camaragibe"},
  {stateId: 26,  id: 2603504,  name: "Camocim de São Félix"},
  {stateId: 26,  id: 2603603,  name: "Camutanga"},
  {stateId: 26,  id: 2603702,  name: "Canhotinho"},
  {stateId: 26,  id: 2603801,  name: "Capoeiras"},
  {stateId: 26,  id: 2603900,  name: "Carnaíba"},
  {stateId: 26,  id: 2603926,  name: "Carnaubeira da Penha"},
  {stateId: 26,  id: 2604007,  name: "Carpina"},
  {stateId: 26,  id: 2604106,  name: "Caruaru"},
  {stateId: 26,  id: 2604155,  name: "Casinhas"},
  {stateId: 26,  id: 2604205,  name: "Catende"},
  {stateId: 26,  id: 2604304,  name: "Cedro"},
  {stateId: 26,  id: 2604403,  name: "Chã de Alegria"},
  {stateId: 26,  id: 2604502,  name: "Chã Grande"},
  {stateId: 26,  id: 2604601,  name: "Condado"},
  {stateId: 26,  id: 2604700,  name: "Correntes"},
  {stateId: 26,  id: 2604809,  name: "Cortês"},
  {stateId: 26,  id: 2604908,  name: "Cumaru"},
  {stateId: 26,  id: 2605004,  name: "Cupira"},
  {stateId: 26,  id: 2605103,  name: "Custódia"},
  {stateId: 26,  id: 2605152,  name: "Dormentes"},
  {stateId: 26,  id: 2605202,  name: "Escada"},
  {stateId: 26,  id: 2605301,  name: "Exu"},
  {stateId: 26,  id: 2605400,  name: "Feira Nova"},
  {stateId: 26,  id: 2605459,  name: "Fernando de Noronha"},
  {stateId: 26,  id: 2605509,  name: "Ferreiros"},
  {stateId: 26,  id: 2605608,  name: "Flores"},
  {stateId: 26,  id: 2605707,  name: "Floresta"},
  {stateId: 26,  id: 2605806,  name: "Frei Miguelinho"},
  {stateId: 26,  id: 2605905,  name: "Gameleira"},
  {stateId: 26,  id: 2606002,  name: "Garanhuns"},
  {stateId: 26,  id: 2606101,  name: "Glória do Goitá"},
  {stateId: 26,  id: 2606200,  name: "Goiana"},
  {stateId: 26,  id: 2606309,  name: "Granito"},
  {stateId: 26,  id: 2606408,  name: "Gravatá"},
  {stateId: 26,  id: 2606507,  name: "Iati"},
  {stateId: 26,  id: 2606606,  name: "Ibimirim"},
  {stateId: 26,  id: 2606705,  name: "Ibirajuba"},
  {stateId: 26,  id: 2606804,  name: "Igarassu"},
  {stateId: 26,  id: 2606903,  name: "Iguaracy"},
  {stateId: 26,  id: 2607604,  name: "Ilha de Itamaracá"},
  {stateId: 26,  id: 2607000,  name: "Inajá"},
  {stateId: 26,  id: 2607109,  name: "Ingazeira"},
  {stateId: 26,  id: 2607208,  name: "Ipojuca"},
  {stateId: 26,  id: 2607307,  name: "Ipubi"},
  {stateId: 26,  id: 2607406,  name: "Itacuruba"},
  {stateId: 26,  id: 2607505,  name: "Itaíba"},
  {stateId: 26,  id: 2607653,  name: "Itambé"},
  {stateId: 26,  id: 2607703,  name: "Itapetim"},
  {stateId: 26,  id: 2607752,  name: "Itapissuma"},
  {stateId: 26,  id: 2607802,  name: "Itaquitinga"},
  {stateId: 26,  id: 2607901,  name: "Jaboatão dos Guararapes"},
  {stateId: 26,  id: 2607950,  name: "Jaqueira"},
  {stateId: 26,  id: 2608008,  name: "Jataúba"},
  {stateId: 26,  id: 2608057,  name: "Jatobá"},
  {stateId: 26,  id: 2608107,  name: "João Alfredo"},
  {stateId: 26,  id: 2608206,  name: "Joaquim Nabuco"},
  {stateId: 26,  id: 2608255,  name: "Jucati"},
  {stateId: 26,  id: 2608305,  name: "Jupi"},
  {stateId: 26,  id: 2608404,  name: "Jurema"},
  {stateId: 26,  id: 2608503,  name: "Lagoa de Itaenga"},
  {stateId: 26,  id: 2608453,  name: "Lagoa do Carro"},
  {stateId: 26,  id: 2608602,  name: "Lagoa do Ouro"},
  {stateId: 26,  id: 2608701,  name: "Lagoa dos Gatos"},
  {stateId: 26,  id: 2608750,  name: "Lagoa Grande"},
  {stateId: 26,  id: 2608800,  name: "Lajedo"},
  {stateId: 26,  id: 2608909,  name: "Limoeiro"},
  {stateId: 26,  id: 2609006,  name: "Macaparana"},
  {stateId: 26,  id: 2609105,  name: "Machados"},
  {stateId: 26,  id: 2609154,  name: "Manari"},
  {stateId: 26,  id: 2609204,  name: "Maraial"},
  {stateId: 26,  id: 2609303,  name: "Mirandiba"},
  {stateId: 26,  id: 2614303,  name: "Moreilândia"},
  {stateId: 26,  id: 2609402,  name: "Moreno"},
  {stateId: 26,  id: 2609501,  name: "Nazaré da Mata"},
  {stateId: 26,  id: 2609600,  name: "Olinda"},
  {stateId: 26,  id: 2609709,  name: "Orobó"},
  {stateId: 26,  id: 2609808,  name: "Orocó"},
  {stateId: 26,  id: 2609907,  name: "Ouricuri"},
  {stateId: 26,  id: 2610004,  name: "Palmares"},
  {stateId: 26,  id: 2610103,  name: "Palmeirina"},
  {stateId: 26,  id: 2610202,  name: "Panelas"},
  {stateId: 26,  id: 2610301,  name: "Paranatama"},
  {stateId: 26,  id: 2610400,  name: "Parnamirim"},
  {stateId: 26,  id: 2610509,  name: "Passira"},
  {stateId: 26,  id: 2610608,  name: "Paudalho"},
  {stateId: 26,  id: 2610707,  name: "Paulista"},
  {stateId: 26,  id: 2610806,  name: "Pedra"},
  {stateId: 26,  id: 2610905,  name: "Pesqueira"},
  {stateId: 26,  id: 2611002,  name: "Petrolândia"},
  {stateId: 26,  id: 2611101,  name: "Petrolina"},
  {stateId: 26,  id: 2611200,  name: "Poção"},
  {stateId: 26,  id: 2611309,  name: "Pombos"},
  {stateId: 26,  id: 2611408,  name: "Primavera"},
  {stateId: 26,  id: 2611507,  name: "Quipapá"},
  {stateId: 26,  id: 2611533,  name: "Quixaba"},
  {stateId: 26,  id: 2611606,  name: "Recife"},
  {stateId: 26,  id: 2611705,  name: "Riacho das Almas"},
  {stateId: 26,  id: 2611804,  name: "Ribeirão"},
  {stateId: 26,  id: 2611903,  name: "Rio Formoso"},
  {stateId: 26,  id: 2612000,  name: "Sairé"},
  {stateId: 26,  id: 2612109,  name: "Salgadinho"},
  {stateId: 26,  id: 2612208,  name: "Salgueiro"},
  {stateId: 26,  id: 2612307,  name: "Saloá"},
  {stateId: 26,  id: 2612406,  name: "Sanharó"},
  {stateId: 26,  id: 2612455,  name: "Santa Cruz"},
  {stateId: 26,  id: 2612471,  name: "Santa Cruz da Baixa Verde"},
  {stateId: 26,  id: 2612505,  name: "Santa Cruz do Capibaribe"},
  {stateId: 26,  id: 2612554,  name: "Santa Filomena"},
  {stateId: 26,  id: 2612604,  name: "Santa Maria da Boa Vista"},
  {stateId: 26,  id: 2612703,  name: "Santa Maria do Cambucá"},
  {stateId: 26,  id: 2612802,  name: "Santa Terezinha"},
  {stateId: 26,  id: 2612901,  name: "São Benedito do Sul"},
  {stateId: 26,  id: 2613008,  name: "São Bento do Una"},
  {stateId: 26,  id: 2613107,  name: "São Caitano"},
  {stateId: 26,  id: 2613206,  name: "São João"},
  {stateId: 26,  id: 2613305,  name: "São Joaquim do Monte"},
  {stateId: 26,  id: 2613404,  name: "São José da Coroa Grande"},
  {stateId: 26,  id: 2613503,  name: "São José do Belmonte"},
  {stateId: 26,  id: 2613602,  name: "São José do Egito"},
  {stateId: 26,  id: 2613701,  name: "São Lourenço da Mata"},
  {stateId: 26,  id: 2613800,  name: "São Vicente Férrer"},
  {stateId: 26,  id: 2613909,  name: "Serra Talhada"},
  {stateId: 26,  id: 2614006,  name: "Serrita"},
  {stateId: 26,  id: 2614105,  name: "Sertânia"},
  {stateId: 26,  id: 2614204,  name: "Sirinhaém"},
  {stateId: 26,  id: 2614402,  name: "Solidão"},
  {stateId: 26,  id: 2614501,  name: "Surubim"},
  {stateId: 26,  id: 2614600,  name: "Tabira"},
  {stateId: 26,  id: 2614709,  name: "Tacaimbó"},
  {stateId: 26,  id: 2614808,  name: "Tacaratu"},
  {stateId: 26,  id: 2614857,  name: "Tamandaré"},
  {stateId: 26,  id: 2615003,  name: "Taquaritinga do Norte"},
  {stateId: 26,  id: 2615102,  name: "Terezinha"},
  {stateId: 26,  id: 2615201,  name: "Terra Nova"},
  {stateId: 26,  id: 2615300,  name: "Timbaúba"},
  {stateId: 26,  id: 2615409,  name: "Toritama"},
  {stateId: 26,  id: 2615508,  name: "Tracunhaém"},
  {stateId: 26,  id: 2615607,  name: "Trindade"},
  {stateId: 26,  id: 2615706,  name: "Triunfo"},
  {stateId: 26,  id: 2615805,  name: "Tupanatinga"},
  {stateId: 26,  id: 2615904,  name: "Tuparetama"},
  {stateId: 26,  id: 2616001,  name: "Venturosa"},
  {stateId: 26,  id: 2616100,  name: "Verdejante"},
  {stateId: 26,  id: 2616183,  name: "Vertente do Lério"},
  {stateId: 26,  id: 2616209,  name: "Vertentes"},
  {stateId: 26,  id: 2616308,  name: "Vicência"},
  {stateId: 26,  id: 2616407,  name: "Vitória de Santo Antão"},
  {stateId: 26,  id: 2616506,  name: "Xexéu"},
  {stateId: 27,  id: 2700102,  name: "Água Branca"},
  {stateId: 27,  id: 2700201,  name: "Anadia"},
  {stateId: 27,  id: 2700300,  name: "Arapiraca"},
  {stateId: 27,  id: 2700409,  name: "Atalaia"},
  {stateId: 27,  id: 2700508,  name: "Barra de Santo Antônio"},
  {stateId: 27,  id: 2700607,  name: "Barra de São Miguel"},
  {stateId: 27,  id: 2700706,  name: "Batalha"},
  {stateId: 27,  id: 2700805,  name: "Belém"},
  {stateId: 27,  id: 2700904,  name: "Belo Monte"},
  {stateId: 27,  id: 2701001,  name: "Boca da Mata"},
  {stateId: 27,  id: 2701100,  name: "Branquinha"},
  {stateId: 27,  id: 2701209,  name: "Cacimbinhas"},
  {stateId: 27,  id: 2701308,  name: "Cajueiro"},
  {stateId: 27,  id: 2701357,  name: "Campestre"},
  {stateId: 27,  id: 2701407,  name: "Campo Alegre"},
  {stateId: 27,  id: 2701506,  name: "Campo Grande"},
  {stateId: 27,  id: 2701605,  name: "Canapi"},
  {stateId: 27,  id: 2701704,  name: "Capela"},
  {stateId: 27,  id: 2701803,  name: "Carneiros"},
  {stateId: 27,  id: 2701902,  name: "Chã Preta"},
  {stateId: 27,  id: 2702009,  name: "Coité do Nóia"},
  {stateId: 27,  id: 2702108,  name: "Colônia Leopoldina"},
  {stateId: 27,  id: 2702207,  name: "Coqueiro Seco"},
  {stateId: 27,  id: 2702306,  name: "Coruripe"},
  {stateId: 27,  id: 2702355,  name: "Craíbas"},
  {stateId: 27,  id: 2702405,  name: "Delmiro Gouveia"},
  {stateId: 27,  id: 2702504,  name: "Dois Riachos"},
  {stateId: 27,  id: 2702553,  name: "Estrela de Alagoas"},
  {stateId: 27,  id: 2702603,  name: "Feira Grande"},
  {stateId: 27,  id: 2702702,  name: "Feliz Deserto"},
  {stateId: 27,  id: 2702801,  name: "Flexeiras"},
  {stateId: 27,  id: 2702900,  name: "Girau do Ponciano"},
  {stateId: 27,  id: 2703007,  name: "Ibateguara"},
  {stateId: 27,  id: 2703106,  name: "Igaci"},
  {stateId: 27,  id: 2703205,  name: "Igreja Nova"},
  {stateId: 27,  id: 2703304,  name: "Inhapi"},
  {stateId: 27,  id: 2703403,  name: "Jacaré dos Homens"},
  {stateId: 27,  id: 2703502,  name: "Jacuípe"},
  {stateId: 27,  id: 2703601,  name: "Japaratinga"},
  {stateId: 27,  id: 2703700,  name: "Jaramataia"},
  {stateId: 27,  id: 2703759,  name: "Jequiá da Praia"},
  {stateId: 27,  id: 2703809,  name: "Joaquim Gomes"},
  {stateId: 27,  id: 2703908,  name: "Jundiá"},
  {stateId: 27,  id: 2704005,  name: "Junqueiro"},
  {stateId: 27,  id: 2704104,  name: "Lagoa da Canoa"},
  {stateId: 27,  id: 2704203,  name: "Limoeiro de Anadia"},
  {stateId: 27,  id: 2704302,  name: "Maceió"},
  {stateId: 27,  id: 2704401,  name: "Major Isidoro"},
  {stateId: 27,  id: 2704906,  name: "Mar Vermelho"},
  {stateId: 27,  id: 2704500,  name: "Maragogi"},
  {stateId: 27,  id: 2704609,  name: "Maravilha"},
  {stateId: 27,  id: 2704708,  name: "Marechal Deodoro"},
  {stateId: 27,  id: 2704807,  name: "Maribondo"},
  {stateId: 27,  id: 2705002,  name: "Mata Grande"},
  {stateId: 27,  id: 2705101,  name: "Matriz de Camaragibe"},
  {stateId: 27,  id: 2705200,  name: "Messias"},
  {stateId: 27,  id: 2705309,  name: "Minador do Negrão"},
  {stateId: 27,  id: 2705408,  name: "Monteirópolis"},
  {stateId: 27,  id: 2705507,  name: "Murici"},
  {stateId: 27,  id: 2705606,  name: "Novo Lino"},
  {stateId: 27,  id: 2705705,  name: "Olho d'Água das Flores"},
  {stateId: 27,  id: 2705804,  name: "Olho d'Água do Casado"},
  {stateId: 27,  id: 2705903,  name: "Olho d'Água Grande"},
  {stateId: 27,  id: 2706000,  name: "Olivença"},
  {stateId: 27,  id: 2706109,  name: "Ouro Branco"},
  {stateId: 27,  id: 2706208,  name: "Palestina"},
  {stateId: 27,  id: 2706307,  name: "Palmeira dos Índios"},
  {stateId: 27,  id: 2706406,  name: "Pão de Açúcar"},
  {stateId: 27,  id: 2706422,  name: "Pariconha"},
  {stateId: 27,  id: 2706448,  name: "Paripueira"},
  {stateId: 27,  id: 2706505,  name: "Passo de Camaragibe"},
  {stateId: 27,  id: 2706604,  name: "Paulo Jacinto"},
  {stateId: 27,  id: 2706703,  name: "Penedo"},
  {stateId: 27,  id: 2706802,  name: "Piaçabuçu"},
  {stateId: 27,  id: 2706901,  name: "Pilar"},
  {stateId: 27,  id: 2707008,  name: "Pindoba"},
  {stateId: 27,  id: 2707107,  name: "Piranhas"},
  {stateId: 27,  id: 2707206,  name: "Poço das Trincheiras"},
  {stateId: 27,  id: 2707305,  name: "Porto Calvo"},
  {stateId: 27,  id: 2707404,  name: "Porto de Pedras"},
  {stateId: 27,  id: 2707503,  name: "Porto Real do Colégio"},
  {stateId: 27,  id: 2707602,  name: "Quebrangulo"},
  {stateId: 27,  id: 2707701,  name: "Rio Largo"},
  {stateId: 27,  id: 2707800,  name: "Roteiro"},
  {stateId: 27,  id: 2707909,  name: "Santa Luzia do Norte"},
  {stateId: 27,  id: 2708006,  name: "Santana do Ipanema"},
  {stateId: 27,  id: 2708105,  name: "Santana do Mundaú"},
  {stateId: 27,  id: 2708204,  name: "São Brás"},
  {stateId: 27,  id: 2708303,  name: "São José da Laje"},
  {stateId: 27,  id: 2708402,  name: "São José da Tapera"},
  {stateId: 27,  id: 2708501,  name: "São Luís do Quitunde"},
  {stateId: 27,  id: 2708600,  name: "São Miguel dos Campos"},
  {stateId: 27,  id: 2708709,  name: "São Miguel dos Milagres"},
  {stateId: 27,  id: 2708808,  name: "São Sebastião"},
  {stateId: 27,  id: 2708907,  name: "Satuba"},
  {stateId: 27,  id: 2708956,  name: "Senador Rui Palmeira"},
  {stateId: 27,  id: 2709004,  name: "Tanque d'Arca"},
  {stateId: 27,  id: 2709103,  name: "Taquarana"},
  {stateId: 27,  id: 2709152,  name: "Teotônio Vilela"},
  {stateId: 27,  id: 2709202,  name: "Traipu"},
  {stateId: 27,  id: 2709301,  name: "União dos Palmares"},
  {stateId: 27,  id: 2709400,  name: "Viçosa"},
  {stateId: 28,  id: 2800100,  name: "Amparo de São Francisco"},
  {stateId: 28,  id: 2800209,  name: "Aquidabã"},
  {stateId: 28,  id: 2800308,  name: "Aracaju"},
  {stateId: 28,  id: 2800407,  name: "Arauá"},
  {stateId: 28,  id: 2800506,  name: "Areia Branca"},
  {stateId: 28,  id: 2800605,  name: "Barra dos Coqueiros"},
  {stateId: 28,  id: 2800670,  name: "Boquim"},
  {stateId: 28,  id: 2800704,  name: "Brejo Grande"},
  {stateId: 28,  id: 2801009,  name: "Campo do Brito"},
  {stateId: 28,  id: 2801108,  name: "Canhoba"},
  {stateId: 28,  id: 2801207,  name: "Canindé de São Francisco"},
  {stateId: 28,  id: 2801306,  name: "Capela"},
  {stateId: 28,  id: 2801405,  name: "Carira"},
  {stateId: 28,  id: 2801504,  name: "Carmópolis"},
  {stateId: 28,  id: 2801603,  name: "Cedro de São João"},
  {stateId: 28,  id: 2801702,  name: "Cristinápolis"},
  {stateId: 28,  id: 2801900,  name: "Cumbe"},
  {stateId: 28,  id: 2802007,  name: "Divina Pastora"},
  {stateId: 28,  id: 2802106,  name: "Estância"},
  {stateId: 28,  id: 2802205,  name: "Feira Nova"},
  {stateId: 28,  id: 2802304,  name: "Frei Paulo"},
  {stateId: 28,  id: 2802403,  name: "Gararu"},
  {stateId: 28,  id: 2802502,  name: "General Maynard"},
  {stateId: 28,  id: 2802601,  name: "Gracho Cardoso"},
  {stateId: 28,  id: 2802700,  name: "Ilha das Flores"},
  {stateId: 28,  id: 2802809,  name: "Indiaroba"},
  {stateId: 28,  id: 2802908,  name: "Itabaiana"},
  {stateId: 28,  id: 2803005,  name: "Itabaianinha"},
  {stateId: 28,  id: 2803104,  name: "Itabi"},
  {stateId: 28,  id: 2803203,  name: "Itaporanga d'Ajuda"},
  {stateId: 28,  id: 2803302,  name: "Japaratuba"},
  {stateId: 28,  id: 2803401,  name: "Japoatã"},
  {stateId: 28,  id: 2803500,  name: "Lagarto"},
  {stateId: 28,  id: 2803609,  name: "Laranjeiras"},
  {stateId: 28,  id: 2803708,  name: "Macambira"},
  {stateId: 28,  id: 2803807,  name: "Malhada dos Bois"},
  {stateId: 28,  id: 2803906,  name: "Malhador"},
  {stateId: 28,  id: 2804003,  name: "Maruim"},
  {stateId: 28,  id: 2804102,  name: "Moita Bonita"},
  {stateId: 28,  id: 2804201,  name: "Monte Alegre de Sergipe"},
  {stateId: 28,  id: 2804300,  name: "Muribeca"},
  {stateId: 28,  id: 2804409,  name: "Neópolis"},
  {stateId: 28,  id: 2804458,  name: "Nossa Senhora Aparecida"},
  {stateId: 28,  id: 2804508,  name: "Nossa Senhora da Glória"},
  {stateId: 28,  id: 2804607,  name: "Nossa Senhora das Dores"},
  {stateId: 28,  id: 2804706,  name: "Nossa Senhora de Lourdes"},
  {stateId: 28,  id: 2804805,  name: "Nossa Senhora do Socorro"},
  {stateId: 28,  id: 2804904,  name: "Pacatuba"},
  {stateId: 28,  id: 2805000,  name: "Pedra Mole"},
  {stateId: 28,  id: 2805109,  name: "Pedrinhas"},
  {stateId: 28,  id: 2805208,  name: "Pinhão"},
  {stateId: 28,  id: 2805307,  name: "Pirambu"},
  {stateId: 28,  id: 2805406,  name: "Poço Redondo"},
  {stateId: 28,  id: 2805505,  name: "Poço Verde"},
  {stateId: 28,  id: 2805604,  name: "Porto da Folha"},
  {stateId: 28,  id: 2805703,  name: "Propriá"},
  {stateId: 28,  id: 2805802,  name: "Riachão do Dantas"},
  {stateId: 28,  id: 2805901,  name: "Riachuelo"},
  {stateId: 28,  id: 2806008,  name: "Ribeirópolis"},
  {stateId: 28,  id: 2806107,  name: "Rosário do Catete"},
  {stateId: 28,  id: 2806206,  name: "Salgado"},
  {stateId: 28,  id: 2806305,  name: "Santa Luzia do Itanhy"},
  {stateId: 28,  id: 2806503,  name: "Santa Rosa de Lima"},
  {stateId: 28,  id: 2806404,  name: "Santana do São Francisco"},
  {stateId: 28,  id: 2806602,  name: "Santo Amaro das Brotas"},
  {stateId: 28,  id: 2806701,  name: "São Cristóvão"},
  {stateId: 28,  id: 2806800,  name: "São Domingos"},
  {stateId: 28,  id: 2806909,  name: "São Francisco"},
  {stateId: 28,  id: 2807006,  name: "São Miguel do Aleixo"},
  {stateId: 28,  id: 2807105,  name: "Simão Dias"},
  {stateId: 28,  id: 2807204,  name: "Siriri"},
  {stateId: 28,  id: 2807303,  name: "Telha"},
  {stateId: 28,  id: 2807402,  name: "Tobias Barreto"},
  {stateId: 28,  id: 2807501,  name: "Tomar do Geru"},
  {stateId: 28,  id: 2807600,  name: "Umbaúba"},
  {stateId: 29,  id: 2900108,  name: "Abaíra"},
  {stateId: 29,  id: 2900207,  name: "Abaré"},
  {stateId: 29,  id: 2900306,  name: "Acajutiba"},
  {stateId: 29,  id: 2900355,  name: "Adustina"},
  {stateId: 29,  id: 2900405,  name: "Água Fria"},
  {stateId: 29,  id: 2900603,  name: "Aiquara"},
  {stateId: 29,  id: 2900702,  name: "Alagoinhas"},
  {stateId: 29,  id: 2900801,  name: "Alcobaça"},
  {stateId: 29,  id: 2900900,  name: "Almadina"},
  {stateId: 29,  id: 2901007,  name: "Amargosa"},
  {stateId: 29,  id: 2901106,  name: "Amélia Rodrigues"},
  {stateId: 29,  id: 2901155,  name: "América Dourada"},
  {stateId: 29,  id: 2901205,  name: "Anagé"},
  {stateId: 29,  id: 2901304,  name: "Andaraí"},
  {stateId: 29,  id: 2901353,  name: "Andorinha"},
  {stateId: 29,  id: 2901403,  name: "Angical"},
  {stateId: 29,  id: 2901502,  name: "Anguera"},
  {stateId: 29,  id: 2901601,  name: "Antas"},
  {stateId: 29,  id: 2901700,  name: "Antônio Cardoso"},
  {stateId: 29,  id: 2901809,  name: "Antônio Gonçalves"},
  {stateId: 29,  id: 2901908,  name: "Aporá"},
  {stateId: 29,  id: 2901957,  name: "Apuarema"},
  {stateId: 29,  id: 2902054,  name: "Araçás"},
  {stateId: 29,  id: 2902005,  name: "Aracatu"},
  {stateId: 29,  id: 2902104,  name: "Araci"},
  {stateId: 29,  id: 2902203,  name: "Aramari"},
  {stateId: 29,  id: 2902252,  name: "Arataca"},
  {stateId: 29,  id: 2902302,  name: "Aratuípe"},
  {stateId: 29,  id: 2902401,  name: "Aurelino Leal"},
  {stateId: 29,  id: 2902500,  name: "Baianópolis"},
  {stateId: 29,  id: 2902609,  name: "Baixa Grande"},
  {stateId: 29,  id: 2902658,  name: "Banzaê"},
  {stateId: 29,  id: 2902708,  name: "Barra"},
  {stateId: 29,  id: 2902807,  name: "Barra da Estiva"},
  {stateId: 29,  id: 2902906,  name: "Barra do Choça"},
  {stateId: 29,  id: 2903003,  name: "Barra do Mendes"},
  {stateId: 29,  id: 2903102,  name: "Barra do Rocha"},
  {stateId: 29,  id: 2903201,  name: "Barreiras"},
  {stateId: 29,  id: 2903235,  name: "Barro Alto"},
  {stateId: 29,  id: 2903300,  name: "Barro Preto"},
  {stateId: 29,  id: 2903276,  name: "Barrocas"},
  {stateId: 29,  id: 2903409,  name: "Belmonte"},
  {stateId: 29,  id: 2903508,  name: "Belo Campo"},
  {stateId: 29,  id: 2903607,  name: "Biritinga"},
  {stateId: 29,  id: 2903706,  name: "Boa Nova"},
  {stateId: 29,  id: 2903805,  name: "Boa Vista do Tupim"},
  {stateId: 29,  id: 2903904,  name: "Bom Jesus da Lapa"},
  {stateId: 29,  id: 2903953,  name: "Bom Jesus da Serra"},
  {stateId: 29,  id: 2904001,  name: "Boninal"},
  {stateId: 29,  id: 2904050,  name: "Bonito"},
  {stateId: 29,  id: 2904100,  name: "Boquira"},
  {stateId: 29,  id: 2904209,  name: "Botuporã"},
  {stateId: 29,  id: 2904308,  name: "Brejões"},
  {stateId: 29,  id: 2904407,  name: "Brejolândia"},
  {stateId: 29,  id: 2904506,  name: "Brotas de Macaúbas"},
  {stateId: 29,  id: 2904605,  name: "Brumado"},
  {stateId: 29,  id: 2904704,  name: "Buerarema"},
  {stateId: 29,  id: 2904753,  name: "Buritirama"},
  {stateId: 29,  id: 2904803,  name: "Caatiba"},
  {stateId: 29,  id: 2904852,  name: "Cabaceiras do Paraguaçu"},
  {stateId: 29,  id: 2904902,  name: "Cachoeira"},
  {stateId: 29,  id: 2905008,  name: "Caculé"},
  {stateId: 29,  id: 2905107,  name: "Caém"},
  {stateId: 29,  id: 2905156,  name: "Caetanos"},
  {stateId: 29,  id: 2905206,  name: "Caetité"},
  {stateId: 29,  id: 2905305,  name: "Cafarnaum"},
  {stateId: 29,  id: 2905404,  name: "Cairu"},
  {stateId: 29,  id: 2905503,  name: "Caldeirão Grande"},
  {stateId: 29,  id: 2905602,  name: "Camacan"},
  {stateId: 29,  id: 2905701,  name: "Camaçari"},
  {stateId: 29,  id: 2905800,  name: "Camamu"},
  {stateId: 29,  id: 2905909,  name: "Campo Alegre de Lourdes"},
  {stateId: 29,  id: 2906006,  name: "Campo Formoso"},
  {stateId: 29,  id: 2906105,  name: "Canápolis"},
  {stateId: 29,  id: 2906204,  name: "Canarana"},
  {stateId: 29,  id: 2906303,  name: "Canavieiras"},
  {stateId: 29,  id: 2906402,  name: "Candeal"},
  {stateId: 29,  id: 2906501,  name: "Candeias"},
  {stateId: 29,  id: 2906600,  name: "Candiba"},
  {stateId: 29,  id: 2906709,  name: "Cândido Sales"},
  {stateId: 29,  id: 2906808,  name: "Cansanção"},
  {stateId: 29,  id: 2906824,  name: "Canudos"},
  {stateId: 29,  id: 2906857,  name: "Capela do Alto Alegre"},
  {stateId: 29,  id: 2906873,  name: "Capim Grosso"},
  {stateId: 29,  id: 2906899,  name: "Caraíbas"},
  {stateId: 29,  id: 2906907,  name: "Caravelas"},
  {stateId: 29,  id: 2907004,  name: "Cardeal da Silva"},
  {stateId: 29,  id: 2907103,  name: "Carinhanha"},
  {stateId: 29,  id: 2907202,  name: "Casa Nova"},
  {stateId: 29,  id: 2907301,  name: "Castro Alves"},
  {stateId: 29,  id: 2907400,  name: "Catolândia"},
  {stateId: 29,  id: 2907509,  name: "Catu"},
  {stateId: 29,  id: 2907558,  name: "Caturama"},
  {stateId: 29,  id: 2907608,  name: "Central"},
  {stateId: 29,  id: 2907707,  name: "Chorrochó"},
  {stateId: 29,  id: 2907806,  name: "Cícero Dantas"},
  {stateId: 29,  id: 2907905,  name: "Cipó"},
  {stateId: 29,  id: 2908002,  name: "Coaraci"},
  {stateId: 29,  id: 2908101,  name: "Cocos"},
  {stateId: 29,  id: 2908200,  name: "Conceição da Feira"},
  {stateId: 29,  id: 2908309,  name: "Conceição do Almeida"},
  {stateId: 29,  id: 2908408,  name: "Conceição do Coité"},
  {stateId: 29,  id: 2908507,  name: "Conceição do Jacuípe"},
  {stateId: 29,  id: 2908606,  name: "Conde"},
  {stateId: 29,  id: 2908705,  name: "Condeúba"},
  {stateId: 29,  id: 2908804,  name: "Contendas do Sincorá"},
  {stateId: 29,  id: 2908903,  name: "Coração de Maria"},
  {stateId: 29,  id: 2909000,  name: "Cordeiros"},
  {stateId: 29,  id: 2909109,  name: "Coribe"},
  {stateId: 29,  id: 2909208,  name: "Coronel João Sá"},
  {stateId: 29,  id: 2909307,  name: "Correntina"},
  {stateId: 29,  id: 2909406,  name: "Cotegipe"},
  {stateId: 29,  id: 2909505,  name: "Cravolândia"},
  {stateId: 29,  id: 2909604,  name: "Crisópolis"},
  {stateId: 29,  id: 2909703,  name: "Cristópolis"},
  {stateId: 29,  id: 2909802,  name: "Cruz das Almas"},
  {stateId: 29,  id: 2909901,  name: "Curaçá"},
  {stateId: 29,  id: 2910008,  name: "Dário Meira"},
  {stateId: 29,  id: 2910057,  name: "Dias d'Ávila"},
  {stateId: 29,  id: 2910107,  name: "Dom Basílio"},
  {stateId: 29,  id: 2910206,  name: "Dom Macedo Costa"},
  {stateId: 29,  id: 2910305,  name: "Elísio Medrado"},
  {stateId: 29,  id: 2910404,  name: "Encruzilhada"},
  {stateId: 29,  id: 2910503,  name: "Entre Rios"},
  {stateId: 29,  id: 2900504,  name: "Érico Cardoso"},
  {stateId: 29,  id: 2910602,  name: "Esplanada"},
  {stateId: 29,  id: 2910701,  name: "Euclides da Cunha"},
  {stateId: 29,  id: 2910727,  name: "Eunápolis"},
  {stateId: 29,  id: 2910750,  name: "Fátima"},
  {stateId: 29,  id: 2910776,  name: "Feira da Mata"},
  {stateId: 29,  id: 2910800,  name: "Feira de Santana"},
  {stateId: 29,  id: 2910859,  name: "Filadélfia"},
  {stateId: 29,  id: 2910909,  name: "Firmino Alves"},
  {stateId: 29,  id: 2911006,  name: "Floresta Azul"},
  {stateId: 29,  id: 2911105,  name: "Formosa do Rio Preto"},
  {stateId: 29,  id: 2911204,  name: "Gandu"},
  {stateId: 29,  id: 2911253,  name: "Gavião"},
  {stateId: 29,  id: 2911303,  name: "Gentio do Ouro"},
  {stateId: 29,  id: 2911402,  name: "Glória"},
  {stateId: 29,  id: 2911501,  name: "Gongogi"},
  {stateId: 29,  id: 2911600,  name: "Governador Mangabeira"},
  {stateId: 29,  id: 2911659,  name: "Guajeru"},
  {stateId: 29,  id: 2911709,  name: "Guanambi"},
  {stateId: 29,  id: 2911808,  name: "Guaratinga"},
  {stateId: 29,  id: 2911857,  name: "Heliópolis"},
  {stateId: 29,  id: 2911907,  name: "Iaçu"},
  {stateId: 29,  id: 2912004,  name: "Ibiassucê"},
  {stateId: 29,  id: 2912103,  name: "Ibicaraí"},
  {stateId: 29,  id: 2912202,  name: "Ibicoara"},
  {stateId: 29,  id: 2912301,  name: "Ibicuí"},
  {stateId: 29,  id: 2912400,  name: "Ibipeba"},
  {stateId: 29,  id: 2912509,  name: "Ibipitanga"},
  {stateId: 29,  id: 2912608,  name: "Ibiquera"},
  {stateId: 29,  id: 2912707,  name: "Ibirapitanga"},
  {stateId: 29,  id: 2912806,  name: "Ibirapuã"},
  {stateId: 29,  id: 2912905,  name: "Ibirataia"},
  {stateId: 29,  id: 2913002,  name: "Ibitiara"},
  {stateId: 29,  id: 2913101,  name: "Ibititá"},
  {stateId: 29,  id: 2913200,  name: "Ibotirama"},
  {stateId: 29,  id: 2913309,  name: "Ichu"},
  {stateId: 29,  id: 2913408,  name: "Igaporã"},
  {stateId: 29,  id: 2913457,  name: "Igrapiúna"},
  {stateId: 29,  id: 2913507,  name: "Iguaí"},
  {stateId: 29,  id: 2913606,  name: "Ilhéus"},
  {stateId: 29,  id: 2913705,  name: "Inhambupe"},
  {stateId: 29,  id: 2913804,  name: "Ipecaetá"},
  {stateId: 29,  id: 2913903,  name: "Ipiaú"},
  {stateId: 29,  id: 2914000,  name: "Ipirá"},
  {stateId: 29,  id: 2914109,  name: "Ipupiara"},
  {stateId: 29,  id: 2914208,  name: "Irajuba"},
  {stateId: 29,  id: 2914307,  name: "Iramaia"},
  {stateId: 29,  id: 2914406,  name: "Iraquara"},
  {stateId: 29,  id: 2914505,  name: "Irará"},
  {stateId: 29,  id: 2914604,  name: "Irecê"},
  {stateId: 29,  id: 2914653,  name: "Itabela"},
  {stateId: 29,  id: 2914703,  name: "Itaberaba"},
  {stateId: 29,  id: 2914802,  name: "Itabuna"},
  {stateId: 29,  id: 2914901,  name: "Itacaré"},
  {stateId: 29,  id: 2915007,  name: "Itaeté"},
  {stateId: 29,  id: 2915106,  name: "Itagi"},
  {stateId: 29,  id: 2915205,  name: "Itagibá"},
  {stateId: 29,  id: 2915304,  name: "Itagimirim"},
  {stateId: 29,  id: 2915353,  name: "Itaguaçu da Bahia"},
  {stateId: 29,  id: 2915403,  name: "Itaju do Colônia"},
  {stateId: 29,  id: 2915502,  name: "Itajuípe"},
  {stateId: 29,  id: 2915601,  name: "Itamaraju"},
  {stateId: 29,  id: 2915700,  name: "Itamari"},
  {stateId: 29,  id: 2915809,  name: "Itambé"},
  {stateId: 29,  id: 2915908,  name: "Itanagra"},
  {stateId: 29,  id: 2916005,  name: "Itanhém"},
  {stateId: 29,  id: 2916104,  name: "Itaparica"},
  {stateId: 29,  id: 2916203,  name: "Itapé"},
  {stateId: 29,  id: 2916302,  name: "Itapebi"},
  {stateId: 29,  id: 2916401,  name: "Itapetinga"},
  {stateId: 29,  id: 2916500,  name: "Itapicuru"},
  {stateId: 29,  id: 2916609,  name: "Itapitanga"},
  {stateId: 29,  id: 2916708,  name: "Itaquara"},
  {stateId: 29,  id: 2916807,  name: "Itarantim"},
  {stateId: 29,  id: 2916856,  name: "Itatim"},
  {stateId: 29,  id: 2916906,  name: "Itiruçu"},
  {stateId: 29,  id: 2917003,  name: "Itiúba"},
  {stateId: 29,  id: 2917102,  name: "Itororó"},
  {stateId: 29,  id: 2917201,  name: "Ituaçu"},
  {stateId: 29,  id: 2917300,  name: "Ituberá"},
  {stateId: 29,  id: 2917334,  name: "Iuiu"},
  {stateId: 29,  id: 2917359,  name: "Jaborandi"},
  {stateId: 29,  id: 2917409,  name: "Jacaraci"},
  {stateId: 29,  id: 2917508,  name: "Jacobina"},
  {stateId: 29,  id: 2917607,  name: "Jaguaquara"},
  {stateId: 29,  id: 2917706,  name: "Jaguarari"},
  {stateId: 29,  id: 2917805,  name: "Jaguaripe"},
  {stateId: 29,  id: 2917904,  name: "Jandaíra"},
  {stateId: 29,  id: 2918001,  name: "Jequié"},
  {stateId: 29,  id: 2918100,  name: "Jeremoabo"},
  {stateId: 29,  id: 2918209,  name: "Jiquiriçá"},
  {stateId: 29,  id: 2918308,  name: "Jitaúna"},
  {stateId: 29,  id: 2918357,  name: "João Dourado"},
  {stateId: 29,  id: 2918407,  name: "Juazeiro"},
  {stateId: 29,  id: 2918456,  name: "Jucuruçu"},
  {stateId: 29,  id: 2918506,  name: "Jussara"},
  {stateId: 29,  id: 2918555,  name: "Jussari"},
  {stateId: 29,  id: 2918605,  name: "Jussiape"},
  {stateId: 29,  id: 2918704,  name: "Lafaiete Coutinho"},
  {stateId: 29,  id: 2918753,  name: "Lagoa Real"},
  {stateId: 29,  id: 2918803,  name: "Laje"},
  {stateId: 29,  id: 2918902,  name: "Lajedão"},
  {stateId: 29,  id: 2919009,  name: "Lajedinho"},
  {stateId: 29,  id: 2919058,  name: "Lajedo do Tabocal"},
  {stateId: 29,  id: 2919108,  name: "Lamarão"},
  {stateId: 29,  id: 2919157,  name: "Lapão"},
  {stateId: 29,  id: 2919207,  name: "Lauro de Freitas"},
  {stateId: 29,  id: 2919306,  name: "Lençóis"},
  {stateId: 29,  id: 2919405,  name: "Licínio de Almeida"},
  {stateId: 29,  id: 2919504,  name: "Livramento de Nossa Senhora"},
  {stateId: 29,  id: 2919553,  name: "Luís Eduardo Magalhães"},
  {stateId: 29,  id: 2919603,  name: "Macajuba"},
  {stateId: 29,  id: 2919702,  name: "Macarani"},
  {stateId: 29,  id: 2919801,  name: "Macaúbas"},
  {stateId: 29,  id: 2919900,  name: "Macururé"},
  {stateId: 29,  id: 2919926,  name: "Madre de Deus"},
  {stateId: 29,  id: 2919959,  name: "Maetinga"},
  {stateId: 29,  id: 2920007,  name: "Maiquinique"},
  {stateId: 29,  id: 2920106,  name: "Mairi"},
  {stateId: 29,  id: 2920205,  name: "Malhada"},
  {stateId: 29,  id: 2920304,  name: "Malhada de Pedras"},
  {stateId: 29,  id: 2920403,  name: "Manoel Vitorino"},
  {stateId: 29,  id: 2920452,  name: "Mansidão"},
  {stateId: 29,  id: 2920502,  name: "Maracás"},
  {stateId: 29,  id: 2920601,  name: "Maragogipe"},
  {stateId: 29,  id: 2920700,  name: "Maraú"},
  {stateId: 29,  id: 2920809,  name: "Marcionílio Souza"},
  {stateId: 29,  id: 2920908,  name: "Mascote"},
  {stateId: 29,  id: 2921005,  name: "Mata de São João"},
  {stateId: 29,  id: 2921054,  name: "Matina"},
  {stateId: 29,  id: 2921104,  name: "Medeiros Neto"},
  {stateId: 29,  id: 2921203,  name: "Miguel Calmon"},
  {stateId: 29,  id: 2921302,  name: "Milagres"},
  {stateId: 29,  id: 2921401,  name: "Mirangaba"},
  {stateId: 29,  id: 2921450,  name: "Mirante"},
  {stateId: 29,  id: 2921500,  name: "Monte Santo"},
  {stateId: 29,  id: 2921609,  name: "Morpará"},
  {stateId: 29,  id: 2921708,  name: "Morro do Chapéu"},
  {stateId: 29,  id: 2921807,  name: "Mortugaba"},
  {stateId: 29,  id: 2921906,  name: "Mucugê"},
  {stateId: 29,  id: 2922003,  name: "Mucuri"},
  {stateId: 29,  id: 2922052,  name: "Mulungu do Morro"},
  {stateId: 29,  id: 2922102,  name: "Mundo Novo"},
  {stateId: 29,  id: 2922201,  name: "Muniz Ferreira"},
  {stateId: 29,  id: 2922250,  name: "Muquém do São Francisco"},
  {stateId: 29,  id: 2922300,  name: "Muritiba"},
  {stateId: 29,  id: 2922409,  name: "Mutuípe"},
  {stateId: 29,  id: 2922508,  name: "Nazaré"},
  {stateId: 29,  id: 2922607,  name: "Nilo Peçanha"},
  {stateId: 29,  id: 2922656,  name: "Nordestina"},
  {stateId: 29,  id: 2922706,  name: "Nova Canaã"},
  {stateId: 29,  id: 2922730,  name: "Nova Fátima"},
  {stateId: 29,  id: 2922755,  name: "Nova Ibiá"},
  {stateId: 29,  id: 2922805,  name: "Nova Itarana"},
  {stateId: 29,  id: 2922854,  name: "Nova Redenção"},
  {stateId: 29,  id: 2922904,  name: "Nova Soure"},
  {stateId: 29,  id: 2923001,  name: "Nova Viçosa"},
  {stateId: 29,  id: 2923035,  name: "Novo Horizonte"},
  {stateId: 29,  id: 2923050,  name: "Novo Triunfo"},
  {stateId: 29,  id: 2923100,  name: "Olindina"},
  {stateId: 29,  id: 2923209,  name: "Oliveira dos Brejinhos"},
  {stateId: 29,  id: 2923308,  name: "Ouriçangas"},
  {stateId: 29,  id: 2923357,  name: "Ourolândia"},
  {stateId: 29,  id: 2923407,  name: "Palmas de Monte Alto"},
  {stateId: 29,  id: 2923506,  name: "Palmeiras"},
  {stateId: 29,  id: 2923605,  name: "Paramirim"},
  {stateId: 29,  id: 2923704,  name: "Paratinga"},
  {stateId: 29,  id: 2923803,  name: "Paripiranga"},
  {stateId: 29,  id: 2923902,  name: "Pau Brasil"},
  {stateId: 29,  id: 2924009,  name: "Paulo Afonso"},
  {stateId: 29,  id: 2924058,  name: "Pé de Serra"},
  {stateId: 29,  id: 2924108,  name: "Pedrão"},
  {stateId: 29,  id: 2924207,  name: "Pedro Alexandre"},
  {stateId: 29,  id: 2924306,  name: "Piatã"},
  {stateId: 29,  id: 2924405,  name: "Pilão Arcado"},
  {stateId: 29,  id: 2924504,  name: "Pindaí"},
  {stateId: 29,  id: 2924603,  name: "Pindobaçu"},
  {stateId: 29,  id: 2924652,  name: "Pintadas"},
  {stateId: 29,  id: 2924678,  name: "Piraí do Norte"},
  {stateId: 29,  id: 2924702,  name: "Piripá"},
  {stateId: 29,  id: 2924801,  name: "Piritiba"},
  {stateId: 29,  id: 2924900,  name: "Planaltino"},
  {stateId: 29,  id: 2925006,  name: "Planalto"},
  {stateId: 29,  id: 2925105,  name: "Poções"},
  {stateId: 29,  id: 2925204,  name: "Pojuca"},
  {stateId: 29,  id: 2925253,  name: "Ponto Novo"},
  {stateId: 29,  id: 2925303,  name: "Porto Seguro"},
  {stateId: 29,  id: 2925402,  name: "Potiraguá"},
  {stateId: 29,  id: 2925501,  name: "Prado"},
  {stateId: 29,  id: 2925600,  name: "Presidente Dutra"},
  {stateId: 29,  id: 2925709,  name: "Presidente Jânio Quadros"},
  {stateId: 29,  id: 2925758,  name: "Presidente Tancredo Neves"},
  {stateId: 29,  id: 2925808,  name: "Queimadas"},
  {stateId: 29,  id: 2925907,  name: "Quijingue"},
  {stateId: 29,  id: 2925931,  name: "Quixabeira"},
  {stateId: 29,  id: 2925956,  name: "Rafael Jambeiro"},
  {stateId: 29,  id: 2926004,  name: "Remanso"},
  {stateId: 29,  id: 2926103,  name: "Retirolândia"},
  {stateId: 29,  id: 2926202,  name: "Riachão das Neves"},
  {stateId: 29,  id: 2926301,  name: "Riachão do Jacuípe"},
  {stateId: 29,  id: 2926400,  name: "Riacho de Santana"},
  {stateId: 29,  id: 2926509,  name: "Ribeira do Amparo"},
  {stateId: 29,  id: 2926608,  name: "Ribeira do Pombal"},
  {stateId: 29,  id: 2926657,  name: "Ribeirão do Largo"},
  {stateId: 29,  id: 2926707,  name: "Rio de Contas"},
  {stateId: 29,  id: 2926806,  name: "Rio do Antônio"},
  {stateId: 29,  id: 2926905,  name: "Rio do Pires"},
  {stateId: 29,  id: 2927002,  name: "Rio Real"},
  {stateId: 29,  id: 2927101,  name: "Rodelas"},
  {stateId: 29,  id: 2927200,  name: "Ruy Barbosa"},
  {stateId: 29,  id: 2927309,  name: "Salinas da Margarida"},
  {stateId: 29,  id: 2927408,  name: "Salvador"},
  {stateId: 29,  id: 2927507,  name: "Santa Bárbara"},
  {stateId: 29,  id: 2927606,  name: "Santa Brígida"},
  {stateId: 29,  id: 2927705,  name: "Santa Cruz Cabrália"},
  {stateId: 29,  id: 2927804,  name: "Santa Cruz da Vitória"},
  {stateId: 29,  id: 2927903,  name: "Santa Inês"},
  {stateId: 29,  id: 2928059,  name: "Santa Luzia"},
  {stateId: 29,  id: 2928109,  name: "Santa Maria da Vitória"},
  {stateId: 29,  id: 2928406,  name: "Santa Rita de Cássia"},
  {stateId: 29,  id: 2928505,  name: "Santa Terezinha"},
  {stateId: 29,  id: 2928000,  name: "Santaluz"},
  {stateId: 29,  id: 2928208,  name: "Santana"},
  {stateId: 29,  id: 2928307,  name: "Santanópolis"},
  {stateId: 29,  id: 2928604,  name: "Santo Amaro"},
  {stateId: 29,  id: 2928703,  name: "Santo Antônio de Jesus"},
  {stateId: 29,  id: 2928802,  name: "Santo Estêvão"},
  {stateId: 29,  id: 2928901,  name: "São Desidério"},
  {stateId: 29,  id: 2928950,  name: "São Domingos"},
  {stateId: 29,  id: 2929107,  name: "São Felipe"},
  {stateId: 29,  id: 2929008,  name: "São Félix"},
  {stateId: 29,  id: 2929057,  name: "São Félix do Coribe"},
  {stateId: 29,  id: 2929206,  name: "São Francisco do Conde"},
  {stateId: 29,  id: 2929255,  name: "São Gabriel"},
  {stateId: 29,  id: 2929305,  name: "São Gonçalo dos Campos"},
  {stateId: 29,  id: 2929354,  name: "São José da Vitória"},
  {stateId: 29,  id: 2929370,  name: "São José do Jacuípe"},
  {stateId: 29,  id: 2929404,  name: "São Miguel das Matas"},
  {stateId: 29,  id: 2929503,  name: "São Sebastião do Passé"},
  {stateId: 29,  id: 2929602,  name: "Sapeaçu"},
  {stateId: 29,  id: 2929701,  name: "Sátiro Dias"},
  {stateId: 29,  id: 2929750,  name: "Saubara"},
  {stateId: 29,  id: 2929800,  name: "Saúde"},
  {stateId: 29,  id: 2929909,  name: "Seabra"},
  {stateId: 29,  id: 2930006,  name: "Sebastião Laranjeiras"},
  {stateId: 29,  id: 2930105,  name: "Senhor do Bonfim"},
  {stateId: 29,  id: 2930204,  name: "Sento Sé"},
  {stateId: 29,  id: 2930154,  name: "Serra do Ramalho"},
  {stateId: 29,  id: 2930303,  name: "Serra Dourada"},
  {stateId: 29,  id: 2930402,  name: "Serra Preta"},
  {stateId: 29,  id: 2930501,  name: "Serrinha"},
  {stateId: 29,  id: 2930600,  name: "Serrolândia"},
  {stateId: 29,  id: 2930709,  name: "Simões Filho"},
  {stateId: 29,  id: 2930758,  name: "Sítio do Mato"},
  {stateId: 29,  id: 2930766,  name: "Sítio do Quinto"},
  {stateId: 29,  id: 2930774,  name: "Sobradinho"},
  {stateId: 29,  id: 2930808,  name: "Souto Soares"},
  {stateId: 29,  id: 2930907,  name: "Tabocas do Brejo Velho"},
  {stateId: 29,  id: 2931004,  name: "Tanhaçu"},
  {stateId: 29,  id: 2931053,  name: "Tanque Novo"},
  {stateId: 29,  id: 2931103,  name: "Tanquinho"},
  {stateId: 29,  id: 2931202,  name: "Taperoá"},
  {stateId: 29,  id: 2931301,  name: "Tapiramutá"},
  {stateId: 29,  id: 2931350,  name: "Teixeira de Freitas"},
  {stateId: 29,  id: 2931400,  name: "Teodoro Sampaio"},
  {stateId: 29,  id: 2931509,  name: "Teofilândia"},
  {stateId: 29,  id: 2931608,  name: "Teolândia"},
  {stateId: 29,  id: 2931707,  name: "Terra Nova"},
  {stateId: 29,  id: 2931806,  name: "Tremedal"},
  {stateId: 29,  id: 2931905,  name: "Tucano"},
  {stateId: 29,  id: 2932002,  name: "Uauá"},
  {stateId: 29,  id: 2932101,  name: "Ubaíra"},
  {stateId: 29,  id: 2932200,  name: "Ubaitaba"},
  {stateId: 29,  id: 2932309,  name: "Ubatã"},
  {stateId: 29,  id: 2932408,  name: "Uibaí"},
  {stateId: 29,  id: 2932457,  name: "Umburanas"},
  {stateId: 29,  id: 2932507,  name: "Una"},
  {stateId: 29,  id: 2932606,  name: "Urandi"},
  {stateId: 29,  id: 2932705,  name: "Uruçuca"},
  {stateId: 29,  id: 2932804,  name: "Utinga"},
  {stateId: 29,  id: 2932903,  name: "Valença"},
  {stateId: 29,  id: 2933000,  name: "Valente"},
  {stateId: 29,  id: 2933059,  name: "Várzea da Roça"},
  {stateId: 29,  id: 2933109,  name: "Várzea do Poço"},
  {stateId: 29,  id: 2933158,  name: "Várzea Nova"},
  {stateId: 29,  id: 2933174,  name: "Varzedo"},
  {stateId: 29,  id: 2933208,  name: "Vera Cruz"},
  {stateId: 29,  id: 2933257,  name: "Vereda"},
  {stateId: 29,  id: 2933307,  name: "Vitória da Conquista"},
  {stateId: 29,  id: 2933406,  name: "Wagner"},
  {stateId: 29,  id: 2933455,  name: "Wanderley"},
  {stateId: 29,  id: 2933505,  name: "Wenceslau Guimarães"},
  {stateId: 29,  id: 2933604,  name: "Xique-Xique"},
  {stateId: 31,  id: 3100104,  name: "Abadia dos Dourados"},
  {stateId: 31,  id: 3100203,  name: "Abaeté"},
  {stateId: 31,  id: 3100302,  name: "Abre Campo"},
  {stateId: 31,  id: 3100401,  name: "Acaiaca"},
  {stateId: 31,  id: 3100500,  name: "Açucena"},
  {stateId: 31,  id: 3100609,  name: "Água Boa"},
  {stateId: 31,  id: 3100708,  name: "Água Comprida"},
  {stateId: 31,  id: 3100807,  name: "Aguanil"},
  {stateId: 31,  id: 3100906,  name: "Águas Formosas"},
  {stateId: 31,  id: 3101003,  name: "Águas Vermelhas"},
  {stateId: 31,  id: 3101102,  name: "Aimorés"},
  {stateId: 31,  id: 3101201,  name: "Aiuruoca"},
  {stateId: 31,  id: 3101300,  name: "Alagoa"},
  {stateId: 31,  id: 3101409,  name: "Albertina"},
  {stateId: 31,  id: 3101508,  name: "Além Paraíba"},
  {stateId: 31,  id: 3101607,  name: "Alfenas"},
  {stateId: 31,  id: 3101631,  name: "Alfredo Vasconcelos"},
  {stateId: 31,  id: 3101706,  name: "Almenara"},
  {stateId: 31,  id: 3101805,  name: "Alpercata"},
  {stateId: 31,  id: 3101904,  name: "Alpinópolis"},
  {stateId: 31,  id: 3102001,  name: "Alterosa"},
  {stateId: 31,  id: 3102050,  name: "Alto Caparaó"},
  {stateId: 31,  id: 3153509,  name: "Alto Jequitibá"},
  {stateId: 31,  id: 3102100,  name: "Alto Rio Doce"},
  {stateId: 31,  id: 3102209,  name: "Alvarenga"},
  {stateId: 31,  id: 3102308,  name: "Alvinópolis"},
  {stateId: 31,  id: 3102407,  name: "Alvorada de Minas"},
  {stateId: 31,  id: 3102506,  name: "Amparo do Serra"},
  {stateId: 31,  id: 3102605,  name: "Andradas"},
  {stateId: 31,  id: 3102803,  name: "Andrelândia"},
  {stateId: 31,  id: 3102852,  name: "Angelândia"},
  {stateId: 31,  id: 3102902,  name: "Antônio Carlos"},
  {stateId: 31,  id: 3103009,  name: "Antônio Dias"},
  {stateId: 31,  id: 3103108,  name: "Antônio Prado de Minas"},
  {stateId: 31,  id: 3103207,  name: "Araçaí"},
  {stateId: 31,  id: 3103306,  name: "Aracitaba"},
  {stateId: 31,  id: 3103405,  name: "Araçuaí"},
  {stateId: 31,  id: 3103504,  name: "Araguari"},
  {stateId: 31,  id: 3103603,  name: "Arantina"},
  {stateId: 31,  id: 3103702,  name: "Araponga"},
  {stateId: 31,  id: 3103751,  name: "Araporã"},
  {stateId: 31,  id: 3103801,  name: "Arapuá"},
  {stateId: 31,  id: 3103900,  name: "Araújos"},
  {stateId: 31,  id: 3104007,  name: "Araxá"},
  {stateId: 31,  id: 3104106,  name: "Arceburgo"},
  {stateId: 31,  id: 3104205,  name: "Arcos"},
  {stateId: 31,  id: 3104304,  name: "Areado"},
  {stateId: 31,  id: 3104403,  name: "Argirita"},
  {stateId: 31,  id: 3104452,  name: "Aricanduva"},
  {stateId: 31,  id: 3104502,  name: "Arinos"},
  {stateId: 31,  id: 3104601,  name: "Astolfo Dutra"},
  {stateId: 31,  id: 3104700,  name: "Ataléia"},
  {stateId: 31,  id: 3104809,  name: "Augusto de Lima"},
  {stateId: 31,  id: 3104908,  name: "Baependi"},
  {stateId: 31,  id: 3105004,  name: "Baldim"},
  {stateId: 31,  id: 3105103,  name: "Bambuí"},
  {stateId: 31,  id: 3105202,  name: "Bandeira"},
  {stateId: 31,  id: 3105301,  name: "Bandeira do Sul"},
  {stateId: 31,  id: 3105400,  name: "Barão de Cocais"},
  {stateId: 31,  id: 3105509,  name: "Barão de Monte Alto"},
  {stateId: 31,  id: 3105608,  name: "Barbacena"},
  {stateId: 31,  id: 3105707,  name: "Barra Longa"},
  {stateId: 31,  id: 3105905,  name: "Barroso"},
  {stateId: 31,  id: 3106002,  name: "Bela Vista de Minas"},
  {stateId: 31,  id: 3106101,  name: "Belmiro Braga"},
  {stateId: 31,  id: 3106200,  name: "Belo Horizonte"},
  {stateId: 31,  id: 3106309,  name: "Belo Oriente"},
  {stateId: 31,  id: 3106408,  name: "Belo Vale"},
  {stateId: 31,  id: 3106507,  name: "Berilo"},
  {stateId: 31,  id: 3106655,  name: "Berizal"},
  {stateId: 31,  id: 3106606,  name: "Bertópolis"},
  {stateId: 31,  id: 3106705,  name: "Betim"},
  {stateId: 31,  id: 3106804,  name: "Bias Fortes"},
  {stateId: 31,  id: 3106903,  name: "Bicas"},
  {stateId: 31,  id: 3107000,  name: "Biquinhas"},
  {stateId: 31,  id: 3107109,  name: "Boa Esperança"},
  {stateId: 31,  id: 3107208,  name: "Bocaina de Minas"},
  {stateId: 31,  id: 3107307,  name: "Bocaiúva"},
  {stateId: 31,  id: 3107406,  name: "Bom Despacho"},
  {stateId: 31,  id: 3107505,  name: "Bom Jardim de Minas"},
  {stateId: 31,  id: 3107604,  name: "Bom Jesus da Penha"},
  {stateId: 31,  id: 3107703,  name: "Bom Jesus do Amparo"},
  {stateId: 31,  id: 3107802,  name: "Bom Jesus do Galho"},
  {stateId: 31,  id: 3107901,  name: "Bom Repouso"},
  {stateId: 31,  id: 3108008,  name: "Bom Sucesso"},
  {stateId: 31,  id: 3108107,  name: "Bonfim"},
  {stateId: 31,  id: 3108206,  name: "Bonfinópolis de Minas"},
  {stateId: 31,  id: 3108255,  name: "Bonito de Minas"},
  {stateId: 31,  id: 3108305,  name: "Borda da Mata"},
  {stateId: 31,  id: 3108404,  name: "Botelhos"},
  {stateId: 31,  id: 3108503,  name: "Botumirim"},
  {stateId: 31,  id: 3108701,  name: "Brás Pires"},
  {stateId: 31,  id: 3108552,  name: "Brasilândia de Minas"},
  {stateId: 31,  id: 3108602,  name: "Brasília de Minas"},
  {stateId: 31,  id: 3108800,  name: "Braúnas"},
  {stateId: 31,  id: 3108909,  name: "Brazópolis"},
  {stateId: 31,  id: 3109006,  name: "Brumadinho"},
  {stateId: 31,  id: 3109105,  name: "Bueno Brandão"},
  {stateId: 31,  id: 3109204,  name: "Buenópolis"},
  {stateId: 31,  id: 3109253,  name: "Bugre"},
  {stateId: 31,  id: 3109303,  name: "Buritis"},
  {stateId: 31,  id: 3109402,  name: "Buritizeiro"},
  {stateId: 31,  id: 3109451,  name: "Cabeceira Grande"},
  {stateId: 31,  id: 3109501,  name: "Cabo Verde"},
  {stateId: 31,  id: 3109600,  name: "Cachoeira da Prata"},
  {stateId: 31,  id: 3109709,  name: "Cachoeira de Minas"},
  {stateId: 31,  id: 3102704,  name: "Cachoeira de Pajeú"},
  {stateId: 31,  id: 3109808,  name: "Cachoeira Dourada"},
  {stateId: 31,  id: 3109907,  name: "Caetanópolis"},
  {stateId: 31,  id: 3110004,  name: "Caeté"},
  {stateId: 31,  id: 3110103,  name: "Caiana"},
  {stateId: 31,  id: 3110202,  name: "Cajuri"},
  {stateId: 31,  id: 3110301,  name: "Caldas"},
  {stateId: 31,  id: 3110400,  name: "Camacho"},
  {stateId: 31,  id: 3110509,  name: "Camanducaia"},
  {stateId: 31,  id: 3110608,  name: "Cambuí"},
  {stateId: 31,  id: 3110707,  name: "Cambuquira"},
  {stateId: 31,  id: 3110806,  name: "Campanário"},
  {stateId: 31,  id: 3110905,  name: "Campanha"},
  {stateId: 31,  id: 3111002,  name: "Campestre"},
  {stateId: 31,  id: 3111101,  name: "Campina Verde"},
  {stateId: 31,  id: 3111150,  name: "Campo Azul"},
  {stateId: 31,  id: 3111200,  name: "Campo Belo"},
  {stateId: 31,  id: 3111309,  name: "Campo do Meio"},
  {stateId: 31,  id: 3111408,  name: "Campo Florido"},
  {stateId: 31,  id: 3111507,  name: "Campos Altos"},
  {stateId: 31,  id: 3111606,  name: "Campos Gerais"},
  {stateId: 31,  id: 3111903,  name: "Cana Verde"},
  {stateId: 31,  id: 3111705,  name: "Canaã"},
  {stateId: 31,  id: 3111804,  name: "Canápolis"},
  {stateId: 31,  id: 3112000,  name: "Candeias"},
  {stateId: 31,  id: 3112059,  name: "Cantagalo"},
  {stateId: 31,  id: 3112109,  name: "Caparaó"},
  {stateId: 31,  id: 3112208,  name: "Capela Nova"},
  {stateId: 31,  id: 3112307,  name: "Capelinha"},
  {stateId: 31,  id: 3112406,  name: "Capetinga"},
  {stateId: 31,  id: 3112505,  name: "Capim Branco"},
  {stateId: 31,  id: 3112604,  name: "Capinópolis"},
  {stateId: 31,  id: 3112653,  name: "Capitão Andrade"},
  {stateId: 31,  id: 3112703,  name: "Capitão Enéas"},
  {stateId: 31,  id: 3112802,  name: "Capitólio"},
  {stateId: 31,  id: 3112901,  name: "Caputira"},
  {stateId: 31,  id: 3113008,  name: "Caraí"},
  {stateId: 31,  id: 3113107,  name: "Caranaíba"},
  {stateId: 31,  id: 3113206,  name: "Carandaí"},
  {stateId: 31,  id: 3113305,  name: "Carangola"},
  {stateId: 31,  id: 3113404,  name: "Caratinga"},
  {stateId: 31,  id: 3113503,  name: "Carbonita"},
  {stateId: 31,  id: 3113602,  name: "Careaçu"},
  {stateId: 31,  id: 3113701,  name: "Carlos Chagas"},
  {stateId: 31,  id: 3113800,  name: "Carmésia"},
  {stateId: 31,  id: 3113909,  name: "Carmo da Cachoeira"},
  {stateId: 31,  id: 3114006,  name: "Carmo da Mata"},
  {stateId: 31,  id: 3114105,  name: "Carmo de Minas"},
  {stateId: 31,  id: 3114204,  name: "Carmo do Cajuru"},
  {stateId: 31,  id: 3114303,  name: "Carmo do Paranaíba"},
  {stateId: 31,  id: 3114402,  name: "Carmo do Rio Claro"},
  {stateId: 31,  id: 3114501,  name: "Carmópolis de Minas"},
  {stateId: 31,  id: 3114550,  name: "Carneirinho"},
  {stateId: 31,  id: 3114600,  name: "Carrancas"},
  {stateId: 31,  id: 3114709,  name: "Carvalhópolis"},
  {stateId: 31,  id: 3114808,  name: "Carvalhos"},
  {stateId: 31,  id: 3114907,  name: "Casa Grande"},
  {stateId: 31,  id: 3115003,  name: "Cascalho Rico"},
  {stateId: 31,  id: 3115102,  name: "Cássia"},
  {stateId: 31,  id: 3115300,  name: "Cataguases"},
  {stateId: 31,  id: 3115359,  name: "Catas Altas"},
  {stateId: 31,  id: 3115409,  name: "Catas Altas da Noruega"},
  {stateId: 31,  id: 3115458,  name: "Catuji"},
  {stateId: 31,  id: 3115474,  name: "Catuti"},
  {stateId: 31,  id: 3115508,  name: "Caxambu"},
  {stateId: 31,  id: 3115607,  name: "Cedro do Abaeté"},
  {stateId: 31,  id: 3115706,  name: "Central de Minas"},
  {stateId: 31,  id: 3115805,  name: "Centralina"},
  {stateId: 31,  id: 3115904,  name: "Chácara"},
  {stateId: 31,  id: 3116001,  name: "Chalé"},
  {stateId: 31,  id: 3116100,  name: "Chapada do Norte"},
  {stateId: 31,  id: 3116159,  name: "Chapada Gaúcha"},
  {stateId: 31,  id: 3116209,  name: "Chiador"},
  {stateId: 31,  id: 3116308,  name: "Cipotânea"},
  {stateId: 31,  id: 3116407,  name: "Claraval"},
  {stateId: 31,  id: 3116506,  name: "Claro dos Poções"},
  {stateId: 31,  id: 3116605,  name: "Cláudio"},
  {stateId: 31,  id: 3116704,  name: "Coimbra"},
  {stateId: 31,  id: 3116803,  name: "Coluna"},
  {stateId: 31,  id: 3116902,  name: "Comendador Gomes"},
  {stateId: 31,  id: 3117009,  name: "Comercinho"},
  {stateId: 31,  id: 3117108,  name: "Conceição da Aparecida"},
  {stateId: 31,  id: 3115201,  name: "Conceição da Barra de Minas"},
  {stateId: 31,  id: 3117306,  name: "Conceição das Alagoas"},
  {stateId: 31,  id: 3117207,  name: "Conceição das Pedras"},
  {stateId: 31,  id: 3117405,  name: "Conceição de Ipanema"},
  {stateId: 31,  id: 3117504,  name: "Conceição do Mato Dentro"},
  {stateId: 31,  id: 3117603,  name: "Conceição do Pará"},
  {stateId: 31,  id: 3117702,  name: "Conceição do Rio Verde"},
  {stateId: 31,  id: 3117801,  name: "Conceição dos Ouros"},
  {stateId: 31,  id: 3117836,  name: "Cônego Marinho"},
  {stateId: 31,  id: 3117876,  name: "Confins"},
  {stateId: 31,  id: 3117900,  name: "Congonhal"},
  {stateId: 31,  id: 3118007,  name: "Congonhas"},
  {stateId: 31,  id: 3118106,  name: "Congonhas do Norte"},
  {stateId: 31,  id: 3118205,  name: "Conquista"},
  {stateId: 31,  id: 3118304,  name: "Conselheiro Lafaiete"},
  {stateId: 31,  id: 3118403,  name: "Conselheiro Pena"},
  {stateId: 31,  id: 3118502,  name: "Consolação"},
  {stateId: 31,  id: 3118601,  name: "Contagem"},
  {stateId: 31,  id: 3118700,  name: "Coqueiral"},
  {stateId: 31,  id: 3118809,  name: "Coração de Jesus"},
  {stateId: 31,  id: 3118908,  name: "Cordisburgo"},
  {stateId: 31,  id: 3119005,  name: "Cordislândia"},
  {stateId: 31,  id: 3119104,  name: "Corinto"},
  {stateId: 31,  id: 3119203,  name: "Coroaci"},
  {stateId: 31,  id: 3119302,  name: "Coromandel"},
  {stateId: 31,  id: 3119401,  name: "Coronel Fabriciano"},
  {stateId: 31,  id: 3119500,  name: "Coronel Murta"},
  {stateId: 31,  id: 3119609,  name: "Coronel Pacheco"},
  {stateId: 31,  id: 3119708,  name: "Coronel Xavier Chaves"},
  {stateId: 31,  id: 3119807,  name: "Córrego Danta"},
  {stateId: 31,  id: 3119906,  name: "Córrego do Bom Jesus"},
  {stateId: 31,  id: 3119955,  name: "Córrego Fundo"},
  {stateId: 31,  id: 3120003,  name: "Córrego Novo"},
  {stateId: 31,  id: 3120102,  name: "Couto de Magalhães de Minas"},
  {stateId: 31,  id: 3120151,  name: "Crisólita"},
  {stateId: 31,  id: 3120201,  name: "Cristais"},
  {stateId: 31,  id: 3120300,  name: "Cristália"},
  {stateId: 31,  id: 3120409,  name: "Cristiano Otoni"},
  {stateId: 31,  id: 3120508,  name: "Cristina"},
  {stateId: 31,  id: 3120607,  name: "Crucilândia"},
  {stateId: 31,  id: 3120706,  name: "Cruzeiro da Fortaleza"},
  {stateId: 31,  id: 3120805,  name: "Cruzília"},
  {stateId: 31,  id: 3120839,  name: "Cuparaque"},
  {stateId: 31,  id: 3120870,  name: "Curral de Dentro"},
  {stateId: 31,  id: 3120904,  name: "Curvelo"},
  {stateId: 31,  id: 3121001,  name: "Datas"},
  {stateId: 31,  id: 3121100,  name: "Delfim Moreira"},
  {stateId: 31,  id: 3121209,  name: "Delfinópolis"},
  {stateId: 31,  id: 3121258,  name: "Delta"},
  {stateId: 31,  id: 3121308,  name: "Descoberto"},
  {stateId: 31,  id: 3121407,  name: "Desterro de Entre Rios"},
  {stateId: 31,  id: 3121506,  name: "Desterro do Melo"},
  {stateId: 31,  id: 3121605,  name: "Diamantina"},
  {stateId: 31,  id: 3121704,  name: "Diogo de Vasconcelos"},
  {stateId: 31,  id: 3121803,  name: "Dionísio"},
  {stateId: 31,  id: 3121902,  name: "Divinésia"},
  {stateId: 31,  id: 3122009,  name: "Divino"},
  {stateId: 31,  id: 3122108,  name: "Divino das Laranjeiras"},
  {stateId: 31,  id: 3122207,  name: "Divinolândia de Minas"},
  {stateId: 31,  id: 3122306,  name: "Divinópolis"},
  {stateId: 31,  id: 3122355,  name: "Divisa Alegre"},
  {stateId: 31,  id: 3122405,  name: "Divisa Nova"},
  {stateId: 31,  id: 3122454,  name: "Divisópolis"},
  {stateId: 31,  id: 3122470,  name: "Dom Bosco"},
  {stateId: 31,  id: 3122504,  name: "Dom Cavati"},
  {stateId: 31,  id: 3122603,  name: "Dom Joaquim"},
  {stateId: 31,  id: 3122702,  name: "Dom Silvério"},
  {stateId: 31,  id: 3122801,  name: "Dom Viçoso"},
  {stateId: 31,  id: 3122900,  name: "Dona Eusébia"},
  {stateId: 31,  id: 3123007,  name: "Dores de Campos"},
  {stateId: 31,  id: 3123106,  name: "Dores de Guanhães"},
  {stateId: 31,  id: 3123205,  name: "Dores do Indaiá"},
  {stateId: 31,  id: 3123304,  name: "Dores do Turvo"},
  {stateId: 31,  id: 3123403,  name: "Doresópolis"},
  {stateId: 31,  id: 3123502,  name: "Douradoquara"},
  {stateId: 31,  id: 3123528,  name: "Durandé"},
  {stateId: 31,  id: 3123601,  name: "Elói Mendes"},
  {stateId: 31,  id: 3123700,  name: "Engenheiro Caldas"},
  {stateId: 31,  id: 3123809,  name: "Engenheiro Navarro"},
  {stateId: 31,  id: 3123858,  name: "Entre Folhas"},
  {stateId: 31,  id: 3123908,  name: "Entre Rios de Minas"},
  {stateId: 31,  id: 3124005,  name: "Ervália"},
  {stateId: 31,  id: 3124104,  name: "Esmeraldas"},
  {stateId: 31,  id: 3124203,  name: "Espera Feliz"},
  {stateId: 31,  id: 3124302,  name: "Espinosa"},
  {stateId: 31,  id: 3124401,  name: "Espírito Santo do Dourado"},
  {stateId: 31,  id: 3124500,  name: "Estiva"},
  {stateId: 31,  id: 3124609,  name: "Estrela Dalva"},
  {stateId: 31,  id: 3124708,  name: "Estrela do Indaiá"},
  {stateId: 31,  id: 3124807,  name: "Estrela do Sul"},
  {stateId: 31,  id: 3124906,  name: "Eugenópolis"},
  {stateId: 31,  id: 3125002,  name: "Ewbank da Câmara"},
  {stateId: 31,  id: 3125101,  name: "Extrema"},
  {stateId: 31,  id: 3125200,  name: "Fama"},
  {stateId: 31,  id: 3125309,  name: "Faria Lemos"},
  {stateId: 31,  id: 3125408,  name: "Felício dos Santos"},
  {stateId: 31,  id: 3125606,  name: "Felisburgo"},
  {stateId: 31,  id: 3125705,  name: "Felixlândia"},
  {stateId: 31,  id: 3125804,  name: "Fernandes Tourinho"},
  {stateId: 31,  id: 3125903,  name: "Ferros"},
  {stateId: 31,  id: 3125952,  name: "Fervedouro"},
  {stateId: 31,  id: 3126000,  name: "Florestal"},
  {stateId: 31,  id: 3126109,  name: "Formiga"},
  {stateId: 31,  id: 3126208,  name: "Formoso"},
  {stateId: 31,  id: 3126307,  name: "Fortaleza de Minas"},
  {stateId: 31,  id: 3126406,  name: "Fortuna de Minas"},
  {stateId: 31,  id: 3126505,  name: "Francisco Badaró"},
  {stateId: 31,  id: 3126604,  name: "Francisco Dumont"},
  {stateId: 31,  id: 3126703,  name: "Francisco Sá"},
  {stateId: 31,  id: 3126752,  name: "Franciscópolis"},
  {stateId: 31,  id: 3126802,  name: "Frei Gaspar"},
  {stateId: 31,  id: 3126901,  name: "Frei Inocêncio"},
  {stateId: 31,  id: 3126950,  name: "Frei Lagonegro"},
  {stateId: 31,  id: 3127008,  name: "Fronteira"},
  {stateId: 31,  id: 3127057,  name: "Fronteira dos Vales"},
  {stateId: 31,  id: 3127073,  name: "Fruta de Leite"},
  {stateId: 31,  id: 3127107,  name: "Frutal"},
  {stateId: 31,  id: 3127206,  name: "Funilândia"},
  {stateId: 31,  id: 3127305,  name: "Galiléia"},
  {stateId: 31,  id: 3127339,  name: "Gameleiras"},
  {stateId: 31,  id: 3127354,  name: "Glaucilândia"},
  {stateId: 31,  id: 3127370,  name: "Goiabeira"},
  {stateId: 31,  id: 3127388,  name: "Goianá"},
  {stateId: 31,  id: 3127404,  name: "Gonçalves"},
  {stateId: 31,  id: 3127503,  name: "Gonzaga"},
  {stateId: 31,  id: 3127602,  name: "Gouveia"},
  {stateId: 31,  id: 3127701,  name: "Governador Valadares"},
  {stateId: 31,  id: 3127800,  name: "Grão Mogol"},
  {stateId: 31,  id: 3127909,  name: "Grupiara"},
  {stateId: 31,  id: 3128006,  name: "Guanhães"},
  {stateId: 31,  id: 3128105,  name: "Guapé"},
  {stateId: 31,  id: 3128204,  name: "Guaraciaba"},
  {stateId: 31,  id: 3128253,  name: "Guaraciama"},
  {stateId: 31,  id: 3128303,  name: "Guaranésia"},
  {stateId: 31,  id: 3128402,  name: "Guarani"},
  {stateId: 31,  id: 3128501,  name: "Guarará"},
  {stateId: 31,  id: 3128600,  name: "Guarda-Mor"},
  {stateId: 31,  id: 3128709,  name: "Guaxupé"},
  {stateId: 31,  id: 3128808,  name: "Guidoval"},
  {stateId: 31,  id: 3128907,  name: "Guimarânia"},
  {stateId: 31,  id: 3129004,  name: "Guiricema"},
  {stateId: 31,  id: 3129103,  name: "Gurinhatã"},
  {stateId: 31,  id: 3129202,  name: "Heliodora"},
  {stateId: 31,  id: 3129301,  name: "Iapu"},
  {stateId: 31,  id: 3129400,  name: "Ibertioga"},
  {stateId: 31,  id: 3129509,  name: "Ibiá"},
  {stateId: 31,  id: 3129608,  name: "Ibiaí"},
  {stateId: 31,  id: 3129657,  name: "Ibiracatu"},
  {stateId: 31,  id: 3129707,  name: "Ibiraci"},
  {stateId: 31,  id: 3129806,  name: "Ibirité"},
  {stateId: 31,  id: 3129905,  name: "Ibitiúra de Minas"},
  {stateId: 31,  id: 3130002,  name: "Ibituruna"},
  {stateId: 31,  id: 3130051,  name: "Icaraí de Minas"},
  {stateId: 31,  id: 3130101,  name: "Igarapé"},
  {stateId: 31,  id: 3130200,  name: "Igaratinga"},
  {stateId: 31,  id: 3130309,  name: "Iguatama"},
  {stateId: 31,  id: 3130408,  name: "Ijaci"},
  {stateId: 31,  id: 3130507,  name: "Ilicínea"},
  {stateId: 31,  id: 3130556,  name: "Imbé de Minas"},
  {stateId: 31,  id: 3130606,  name: "Inconfidentes"},
  {stateId: 31,  id: 3130655,  name: "Indaiabira"},
  {stateId: 31,  id: 3130705,  name: "Indianópolis"},
  {stateId: 31,  id: 3130804,  name: "Ingaí"},
  {stateId: 31,  id: 3130903,  name: "Inhapim"},
  {stateId: 31,  id: 3131000,  name: "Inhaúma"},
  {stateId: 31,  id: 3131109,  name: "Inimutaba"},
  {stateId: 31,  id: 3131158,  name: "Ipaba"},
  {stateId: 31,  id: 3131208,  name: "Ipanema"},
  {stateId: 31,  id: 3131307,  name: "Ipatinga"},
  {stateId: 31,  id: 3131406,  name: "Ipiaçu"},
  {stateId: 31,  id: 3131505,  name: "Ipuiúna"},
  {stateId: 31,  id: 3131604,  name: "Iraí de Minas"},
  {stateId: 31,  id: 3131703,  name: "Itabira"},
  {stateId: 31,  id: 3131802,  name: "Itabirinha"},
  {stateId: 31,  id: 3131901,  name: "Itabirito"},
  {stateId: 31,  id: 3132008,  name: "Itacambira"},
  {stateId: 31,  id: 3132107,  name: "Itacarambi"},
  {stateId: 31,  id: 3132206,  name: "Itaguara"},
  {stateId: 31,  id: 3132305,  name: "Itaipé"},
  {stateId: 31,  id: 3132404,  name: "Itajubá"},
  {stateId: 31,  id: 3132503,  name: "Itamarandiba"},
  {stateId: 31,  id: 3132602,  name: "Itamarati de Minas"},
  {stateId: 31,  id: 3132701,  name: "Itambacuri"},
  {stateId: 31,  id: 3132800,  name: "Itambé do Mato Dentro"},
  {stateId: 31,  id: 3132909,  name: "Itamogi"},
  {stateId: 31,  id: 3133006,  name: "Itamonte"},
  {stateId: 31,  id: 3133105,  name: "Itanhandu"},
  {stateId: 31,  id: 3133204,  name: "Itanhomi"},
  {stateId: 31,  id: 3133303,  name: "Itaobim"},
  {stateId: 31,  id: 3133402,  name: "Itapagipe"},
  {stateId: 31,  id: 3133501,  name: "Itapecerica"},
  {stateId: 31,  id: 3133600,  name: "Itapeva"},
  {stateId: 31,  id: 3133709,  name: "Itatiaiuçu"},
  {stateId: 31,  id: 3133758,  name: "Itaú de Minas"},
  {stateId: 31,  id: 3133808,  name: "Itaúna"},
  {stateId: 31,  id: 3133907,  name: "Itaverava"},
  {stateId: 31,  id: 3134004,  name: "Itinga"},
  {stateId: 31,  id: 3134103,  name: "Itueta"},
  {stateId: 31,  id: 3134202,  name: "Ituiutaba"},
  {stateId: 31,  id: 3134301,  name: "Itumirim"},
  {stateId: 31,  id: 3134400,  name: "Iturama"},
  {stateId: 31,  id: 3134509,  name: "Itutinga"},
  {stateId: 31,  id: 3134608,  name: "Jaboticatubas"},
  {stateId: 31,  id: 3134707,  name: "Jacinto"},
  {stateId: 31,  id: 3134806,  name: "Jacuí"},
  {stateId: 31,  id: 3134905,  name: "Jacutinga"},
  {stateId: 31,  id: 3135001,  name: "Jaguaraçu"},
  {stateId: 31,  id: 3135050,  name: "Jaíba"},
  {stateId: 31,  id: 3135076,  name: "Jampruca"},
  {stateId: 31,  id: 3135100,  name: "Janaúba"},
  {stateId: 31,  id: 3135209,  name: "Januária"},
  {stateId: 31,  id: 3135308,  name: "Japaraíba"},
  {stateId: 31,  id: 3135357,  name: "Japonvar"},
  {stateId: 31,  id: 3135407,  name: "Jeceaba"},
  {stateId: 31,  id: 3135456,  name: "Jenipapo de Minas"},
  {stateId: 31,  id: 3135506,  name: "Jequeri"},
  {stateId: 31,  id: 3135605,  name: "Jequitaí"},
  {stateId: 31,  id: 3135704,  name: "Jequitibá"},
  {stateId: 31,  id: 3135803,  name: "Jequitinhonha"},
  {stateId: 31,  id: 3135902,  name: "Jesuânia"},
  {stateId: 31,  id: 3136009,  name: "Joaíma"},
  {stateId: 31,  id: 3136108,  name: "Joanésia"},
  {stateId: 31,  id: 3136207,  name: "João Monlevade"},
  {stateId: 31,  id: 3136306,  name: "João Pinheiro"},
  {stateId: 31,  id: 3136405,  name: "Joaquim Felício"},
  {stateId: 31,  id: 3136504,  name: "Jordânia"},
  {stateId: 31,  id: 3136520,  name: "José Gonçalves de Minas"},
  {stateId: 31,  id: 3136553,  name: "José Raydan"},
  {stateId: 31,  id: 3136579,  name: "Josenópolis"},
  {stateId: 31,  id: 3136652,  name: "Juatuba"},
  {stateId: 31,  id: 3136702,  name: "Juiz de Fora"},
  {stateId: 31,  id: 3136801,  name: "Juramento"},
  {stateId: 31,  id: 3136900,  name: "Juruaia"},
  {stateId: 31,  id: 3136959,  name: "Juvenília"},
  {stateId: 31,  id: 3137007,  name: "Ladainha"},
  {stateId: 31,  id: 3137106,  name: "Lagamar"},
  {stateId: 31,  id: 3137205,  name: "Lagoa da Prata"},
  {stateId: 31,  id: 3137304,  name: "Lagoa dos Patos"},
  {stateId: 31,  id: 3137403,  name: "Lagoa Dourada"},
  {stateId: 31,  id: 3137502,  name: "Lagoa Formosa"},
  {stateId: 31,  id: 3137536,  name: "Lagoa Grande"},
  {stateId: 31,  id: 3137601,  name: "Lagoa Santa"},
  {stateId: 31,  id: 3137700,  name: "Lajinha"},
  {stateId: 31,  id: 3137809,  name: "Lambari"},
  {stateId: 31,  id: 3137908,  name: "Lamim"},
  {stateId: 31,  id: 3138005,  name: "Laranjal"},
  {stateId: 31,  id: 3138104,  name: "Lassance"},
  {stateId: 31,  id: 3138203,  name: "Lavras"},
  {stateId: 31,  id: 3138302,  name: "Leandro Ferreira"},
  {stateId: 31,  id: 3138351,  name: "Leme do Prado"},
  {stateId: 31,  id: 3138401,  name: "Leopoldina"},
  {stateId: 31,  id: 3138500,  name: "Liberdade"},
  {stateId: 31,  id: 3138609,  name: "Lima Duarte"},
  {stateId: 31,  id: 3138625,  name: "Limeira do Oeste"},
  {stateId: 31,  id: 3138658,  name: "Lontra"},
  {stateId: 31,  id: 3138674,  name: "Luisburgo"},
  {stateId: 31,  id: 3138682,  name: "Luislândia"},
  {stateId: 31,  id: 3138708,  name: "Luminárias"},
  {stateId: 31,  id: 3138807,  name: "Luz"},
  {stateId: 31,  id: 3138906,  name: "Machacalis"},
  {stateId: 31,  id: 3139003,  name: "Machado"},
  {stateId: 31,  id: 3139102,  name: "Madre de Deus de Minas"},
  {stateId: 31,  id: 3139201,  name: "Malacacheta"},
  {stateId: 31,  id: 3139250,  name: "Mamonas"},
  {stateId: 31,  id: 3139300,  name: "Manga"},
  {stateId: 31,  id: 3139409,  name: "Manhuaçu"},
  {stateId: 31,  id: 3139508,  name: "Manhumirim"},
  {stateId: 31,  id: 3139607,  name: "Mantena"},
  {stateId: 31,  id: 3139805,  name: "Mar de Espanha"},
  {stateId: 31,  id: 3139706,  name: "Maravilhas"},
  {stateId: 31,  id: 3139904,  name: "Maria da Fé"},
  {stateId: 31,  id: 3140001,  name: "Mariana"},
  {stateId: 31,  id: 3140100,  name: "Marilac"},
  {stateId: 31,  id: 3140159,  name: "Mário Campos"},
  {stateId: 31,  id: 3140209,  name: "Maripá de Minas"},
  {stateId: 31,  id: 3140308,  name: "Marliéria"},
  {stateId: 31,  id: 3140407,  name: "Marmelópolis"},
  {stateId: 31,  id: 3140506,  name: "Martinho Campos"},
  {stateId: 31,  id: 3140530,  name: "Martins Soares"},
  {stateId: 31,  id: 3140555,  name: "Mata Verde"},
  {stateId: 31,  id: 3140605,  name: "Materlândia"},
  {stateId: 31,  id: 3140704,  name: "Mateus Leme"},
  {stateId: 31,  id: 3171501,  name: "Mathias Lobato"},
  {stateId: 31,  id: 3140803,  name: "Matias Barbosa"},
  {stateId: 31,  id: 3140852,  name: "Matias Cardoso"},
  {stateId: 31,  id: 3140902,  name: "Matipó"},
  {stateId: 31,  id: 3141009,  name: "Mato Verde"},
  {stateId: 31,  id: 3141108,  name: "Matozinhos"},
  {stateId: 31,  id: 3141207,  name: "Matutina"},
  {stateId: 31,  id: 3141306,  name: "Medeiros"},
  {stateId: 31,  id: 3141405,  name: "Medina"},
  {stateId: 31,  id: 3141504,  name: "Mendes Pimentel"},
  {stateId: 31,  id: 3141603,  name: "Mercês"},
  {stateId: 31,  id: 3141702,  name: "Mesquita"},
  {stateId: 31,  id: 3141801,  name: "Minas Novas"},
  {stateId: 31,  id: 3141900,  name: "Minduri"},
  {stateId: 31,  id: 3142007,  name: "Mirabela"},
  {stateId: 31,  id: 3142106,  name: "Miradouro"},
  {stateId: 31,  id: 3142205,  name: "Miraí"},
  {stateId: 31,  id: 3142254,  name: "Miravânia"},
  {stateId: 31,  id: 3142304,  name: "Moeda"},
  {stateId: 31,  id: 3142403,  name: "Moema"},
  {stateId: 31,  id: 3142502,  name: "Monjolos"},
  {stateId: 31,  id: 3142601,  name: "Monsenhor Paulo"},
  {stateId: 31,  id: 3142700,  name: "Montalvânia"},
  {stateId: 31,  id: 3142809,  name: "Monte Alegre de Minas"},
  {stateId: 31,  id: 3142908,  name: "Monte Azul"},
  {stateId: 31,  id: 3143005,  name: "Monte Belo"},
  {stateId: 31,  id: 3143104,  name: "Monte Carmelo"},
  {stateId: 31,  id: 3143153,  name: "Monte Formoso"},
  {stateId: 31,  id: 3143203,  name: "Monte Santo de Minas"},
  {stateId: 31,  id: 3143401,  name: "Monte Sião"},
  {stateId: 31,  id: 3143302,  name: "Montes Claros"},
  {stateId: 31,  id: 3143450,  name: "Montezuma"},
  {stateId: 31,  id: 3143500,  name: "Morada Nova de Minas"},
  {stateId: 31,  id: 3143609,  name: "Morro da Garça"},
  {stateId: 31,  id: 3143708,  name: "Morro do Pilar"},
  {stateId: 31,  id: 3143807,  name: "Munhoz"},
  {stateId: 31,  id: 3143906,  name: "Muriaé"},
  {stateId: 31,  id: 3144003,  name: "Mutum"},
  {stateId: 31,  id: 3144102,  name: "Muzambinho"},
  {stateId: 31,  id: 3144201,  name: "Nacip Raydan"},
  {stateId: 31,  id: 3144300,  name: "Nanuque"},
  {stateId: 31,  id: 3144359,  name: "Naque"},
  {stateId: 31,  id: 3144375,  name: "Natalândia"},
  {stateId: 31,  id: 3144409,  name: "Natércia"},
  {stateId: 31,  id: 3144508,  name: "Nazareno"},
  {stateId: 31,  id: 3144607,  name: "Nepomuceno"},
  {stateId: 31,  id: 3144656,  name: "Ninheira"},
  {stateId: 31,  id: 3144672,  name: "Nova Belém"},
  {stateId: 31,  id: 3144706,  name: "Nova Era"},
  {stateId: 31,  id: 3144805,  name: "Nova Lima"},
  {stateId: 31,  id: 3144904,  name: "Nova Módica"},
  {stateId: 31,  id: 3145000,  name: "Nova Ponte"},
  {stateId: 31,  id: 3145059,  name: "Nova Porteirinha"},
  {stateId: 31,  id: 3145109,  name: "Nova Resende"},
  {stateId: 31,  id: 3145208,  name: "Nova Serrana"},
  {stateId: 31,  id: 3136603,  name: "Nova União"},
  {stateId: 31,  id: 3145307,  name: "Novo Cruzeiro"},
  {stateId: 31,  id: 3145356,  name: "Novo Oriente de Minas"},
  {stateId: 31,  id: 3145372,  name: "Novorizonte"},
  {stateId: 31,  id: 3145406,  name: "Olaria"},
  {stateId: 31,  id: 3145455,  name: "Olhos-d'Água"},
  {stateId: 31,  id: 3145505,  name: "Olímpio Noronha"},
  {stateId: 31,  id: 3145604,  name: "Oliveira"},
  {stateId: 31,  id: 3145703,  name: "Oliveira Fortes"},
  {stateId: 31,  id: 3145802,  name: "Onça de Pitangui"},
  {stateId: 31,  id: 3145851,  name: "Oratórios"},
  {stateId: 31,  id: 3145877,  name: "Orizânia"},
  {stateId: 31,  id: 3145901,  name: "Ouro Branco"},
  {stateId: 31,  id: 3146008,  name: "Ouro Fino"},
  {stateId: 31,  id: 3146107,  name: "Ouro Preto"},
  {stateId: 31,  id: 3146206,  name: "Ouro Verde de Minas"},
  {stateId: 31,  id: 3146255,  name: "Padre Carvalho"},
  {stateId: 31,  id: 3146305,  name: "Padre Paraíso"},
  {stateId: 31,  id: 3146552,  name: "Pai Pedro"},
  {stateId: 31,  id: 3146404,  name: "Paineiras"},
  {stateId: 31,  id: 3146503,  name: "Pains"},
  {stateId: 31,  id: 3146602,  name: "Paiva"},
  {stateId: 31,  id: 3146701,  name: "Palma"},
  {stateId: 31,  id: 3146750,  name: "Palmópolis"},
  {stateId: 31,  id: 3146909,  name: "Papagaios"},
  {stateId: 31,  id: 3147105,  name: "Pará de Minas"},
  {stateId: 31,  id: 3147006,  name: "Paracatu"},
  {stateId: 31,  id: 3147204,  name: "Paraguaçu"},
  {stateId: 31,  id: 3147303,  name: "Paraisópolis"},
  {stateId: 31,  id: 3147402,  name: "Paraopeba"},
  {stateId: 31,  id: 3147600,  name: "Passa Quatro"},
  {stateId: 31,  id: 3147709,  name: "Passa Tempo"},
  {stateId: 31,  id: 3147808,  name: "Passa Vinte"},
  {stateId: 31,  id: 3147501,  name: "Passabém"},
  {stateId: 31,  id: 3147907,  name: "Passos"},
  {stateId: 31,  id: 3147956,  name: "Patis"},
  {stateId: 31,  id: 3148004,  name: "Patos de Minas"},
  {stateId: 31,  id: 3148103,  name: "Patrocínio"},
  {stateId: 31,  id: 3148202,  name: "Patrocínio do Muriaé"},
  {stateId: 31,  id: 3148301,  name: "Paula Cândido"},
  {stateId: 31,  id: 3148400,  name: "Paulistas"},
  {stateId: 31,  id: 3148509,  name: "Pavão"},
  {stateId: 31,  id: 3148608,  name: "Peçanha"},
  {stateId: 31,  id: 3148707,  name: "Pedra Azul"},
  {stateId: 31,  id: 3148756,  name: "Pedra Bonita"},
  {stateId: 31,  id: 3148806,  name: "Pedra do Anta"},
  {stateId: 31,  id: 3148905,  name: "Pedra do Indaiá"},
  {stateId: 31,  id: 3149002,  name: "Pedra Dourada"},
  {stateId: 31,  id: 3149101,  name: "Pedralva"},
  {stateId: 31,  id: 3149150,  name: "Pedras de Maria da Cruz"},
  {stateId: 31,  id: 3149200,  name: "Pedrinópolis"},
  {stateId: 31,  id: 3149309,  name: "Pedro Leopoldo"},
  {stateId: 31,  id: 3149408,  name: "Pedro Teixeira"},
  {stateId: 31,  id: 3149507,  name: "Pequeri"},
  {stateId: 31,  id: 3149606,  name: "Pequi"},
  {stateId: 31,  id: 3149705,  name: "Perdigão"},
  {stateId: 31,  id: 3149804,  name: "Perdizes"},
  {stateId: 31,  id: 3149903,  name: "Perdões"},
  {stateId: 31,  id: 3149952,  name: "Periquito"},
  {stateId: 31,  id: 3150000,  name: "Pescador"},
  {stateId: 31,  id: 3150109,  name: "Piau"},
  {stateId: 31,  id: 3150158,  name: "Piedade de Caratinga"},
  {stateId: 31,  id: 3150208,  name: "Piedade de Ponte Nova"},
  {stateId: 31,  id: 3150307,  name: "Piedade do Rio Grande"},
  {stateId: 31,  id: 3150406,  name: "Piedade dos Gerais"},
  {stateId: 31,  id: 3150505,  name: "Pimenta"},
  {stateId: 31,  id: 3150539,  name: "Pingo d'Água"},
  {stateId: 31,  id: 3150570,  name: "Pintópolis"},
  {stateId: 31,  id: 3150604,  name: "Piracema"},
  {stateId: 31,  id: 3150703,  name: "Pirajuba"},
  {stateId: 31,  id: 3150802,  name: "Piranga"},
  {stateId: 31,  id: 3150901,  name: "Piranguçu"},
  {stateId: 31,  id: 3151008,  name: "Piranguinho"},
  {stateId: 31,  id: 3151107,  name: "Pirapetinga"},
  {stateId: 31,  id: 3151206,  name: "Pirapora"},
  {stateId: 31,  id: 3151305,  name: "Piraúba"},
  {stateId: 31,  id: 3151404,  name: "Pitangui"},
  {stateId: 31,  id: 3151503,  name: "Piumhi"},
  {stateId: 31,  id: 3151602,  name: "Planura"},
  {stateId: 31,  id: 3151701,  name: "Poço Fundo"},
  {stateId: 31,  id: 3151800,  name: "Poços de Caldas"},
  {stateId: 31,  id: 3151909,  name: "Pocrane"},
  {stateId: 31,  id: 3152006,  name: "Pompéu"},
  {stateId: 31,  id: 3152105,  name: "Ponte Nova"},
  {stateId: 31,  id: 3152131,  name: "Ponto Chique"},
  {stateId: 31,  id: 3152170,  name: "Ponto dos Volantes"},
  {stateId: 31,  id: 3152204,  name: "Porteirinha"},
  {stateId: 31,  id: 3152303,  name: "Porto Firme"},
  {stateId: 31,  id: 3152402,  name: "Poté"},
  {stateId: 31,  id: 3152501,  name: "Pouso Alegre"},
  {stateId: 31,  id: 3152600,  name: "Pouso Alto"},
  {stateId: 31,  id: 3152709,  name: "Prados"},
  {stateId: 31,  id: 3152808,  name: "Prata"},
  {stateId: 31,  id: 3152907,  name: "Pratápolis"},
  {stateId: 31,  id: 3153004,  name: "Pratinha"},
  {stateId: 31,  id: 3153103,  name: "Presidente Bernardes"},
  {stateId: 31,  id: 3153202,  name: "Presidente Juscelino"},
  {stateId: 31,  id: 3153301,  name: "Presidente Kubitschek"},
  {stateId: 31,  id: 3153400,  name: "Presidente Olegário"},
  {stateId: 31,  id: 3153608,  name: "Prudente de Morais"},
  {stateId: 31,  id: 3153707,  name: "Quartel Geral"},
  {stateId: 31,  id: 3153806,  name: "Queluzito"},
  {stateId: 31,  id: 3153905,  name: "Raposos"},
  {stateId: 31,  id: 3154002,  name: "Raul Soares"},
  {stateId: 31,  id: 3154101,  name: "Recreio"},
  {stateId: 31,  id: 3154150,  name: "Reduto"},
  {stateId: 31,  id: 3154200,  name: "Resende Costa"},
  {stateId: 31,  id: 3154309,  name: "Resplendor"},
  {stateId: 31,  id: 3154408,  name: "Ressaquinha"},
  {stateId: 31,  id: 3154457,  name: "Riachinho"},
  {stateId: 31,  id: 3154507,  name: "Riacho dos Machados"},
  {stateId: 31,  id: 3154606,  name: "Ribeirão das Neves"},
  {stateId: 31,  id: 3154705,  name: "Ribeirão Vermelho"},
  {stateId: 31,  id: 3154804,  name: "Rio Acima"},
  {stateId: 31,  id: 3154903,  name: "Rio Casca"},
  {stateId: 31,  id: 3155108,  name: "Rio do Prado"},
  {stateId: 31,  id: 3155009,  name: "Rio Doce"},
  {stateId: 31,  id: 3155207,  name: "Rio Espera"},
  {stateId: 31,  id: 3155306,  name: "Rio Manso"},
  {stateId: 31,  id: 3155405,  name: "Rio Novo"},
  {stateId: 31,  id: 3155504,  name: "Rio Paranaíba"},
  {stateId: 31,  id: 3155603,  name: "Rio Pardo de Minas"},
  {stateId: 31,  id: 3155702,  name: "Rio Piracicaba"},
  {stateId: 31,  id: 3155801,  name: "Rio Pomba"},
  {stateId: 31,  id: 3155900,  name: "Rio Preto"},
  {stateId: 31,  id: 3156007,  name: "Rio Vermelho"},
  {stateId: 31,  id: 3156106,  name: "Ritápolis"},
  {stateId: 31,  id: 3156205,  name: "Rochedo de Minas"},
  {stateId: 31,  id: 3156304,  name: "Rodeiro"},
  {stateId: 31,  id: 3156403,  name: "Romaria"},
  {stateId: 31,  id: 3156452,  name: "Rosário da Limeira"},
  {stateId: 31,  id: 3156502,  name: "Rubelita"},
  {stateId: 31,  id: 3156601,  name: "Rubim"},
  {stateId: 31,  id: 3156700,  name: "Sabará"},
  {stateId: 31,  id: 3156809,  name: "Sabinópolis"},
  {stateId: 31,  id: 3156908,  name: "Sacramento"},
  {stateId: 31,  id: 3157005,  name: "Salinas"},
  {stateId: 31,  id: 3157104,  name: "Salto da Divisa"},
  {stateId: 31,  id: 3157203,  name: "Santa Bárbara"},
  {stateId: 31,  id: 3157252,  name: "Santa Bárbara do Leste"},
  {stateId: 31,  id: 3157278,  name: "Santa Bárbara do Monte Verde"},
  {stateId: 31,  id: 3157302,  name: "Santa Bárbara do Tugúrio"},
  {stateId: 31,  id: 3157336,  name: "Santa Cruz de Minas"},
  {stateId: 31,  id: 3157377,  name: "Santa Cruz de Salinas"},
  {stateId: 31,  id: 3157401,  name: "Santa Cruz do Escalvado"},
  {stateId: 31,  id: 3157500,  name: "Santa Efigênia de Minas"},
  {stateId: 31,  id: 3157609,  name: "Santa Fé de Minas"},
  {stateId: 31,  id: 3157658,  name: "Santa Helena de Minas"},
  {stateId: 31,  id: 3157708,  name: "Santa Juliana"},
  {stateId: 31,  id: 3157807,  name: "Santa Luzia"},
  {stateId: 31,  id: 3157906,  name: "Santa Margarida"},
  {stateId: 31,  id: 3158003,  name: "Santa Maria de Itabira"},
  {stateId: 31,  id: 3158102,  name: "Santa Maria do Salto"},
  {stateId: 31,  id: 3158201,  name: "Santa Maria do Suaçuí"},
  {stateId: 31,  id: 3159209,  name: "Santa Rita de Caldas"},
  {stateId: 31,  id: 3159407,  name: "Santa Rita de Ibitipoca"},
  {stateId: 31,  id: 3159308,  name: "Santa Rita de Jacutinga"},
  {stateId: 31,  id: 3159357,  name: "Santa Rita de Minas"},
  {stateId: 31,  id: 3159506,  name: "Santa Rita do Itueto"},
  {stateId: 31,  id: 3159605,  name: "Santa Rita do Sapucaí"},
  {stateId: 31,  id: 3159704,  name: "Santa Rosa da Serra"},
  {stateId: 31,  id: 3159803,  name: "Santa Vitória"},
  {stateId: 31,  id: 3158300,  name: "Santana da Vargem"},
  {stateId: 31,  id: 3158409,  name: "Santana de Cataguases"},
  {stateId: 31,  id: 3158508,  name: "Santana de Pirapama"},
  {stateId: 31,  id: 3158607,  name: "Santana do Deserto"},
  {stateId: 31,  id: 3158706,  name: "Santana do Garambéu"},
  {stateId: 31,  id: 3158805,  name: "Santana do Jacaré"},
  {stateId: 31,  id: 3158904,  name: "Santana do Manhuaçu"},
  {stateId: 31,  id: 3158953,  name: "Santana do Paraíso"},
  {stateId: 31,  id: 3159001,  name: "Santana do Riacho"},
  {stateId: 31,  id: 3159100,  name: "Santana dos Montes"},
  {stateId: 31,  id: 3159902,  name: "Santo Antônio do Amparo"},
  {stateId: 31,  id: 3160009,  name: "Santo Antônio do Aventureiro"},
  {stateId: 31,  id: 3160108,  name: "Santo Antônio do Grama"},
  {stateId: 31,  id: 3160207,  name: "Santo Antônio do Itambé"},
  {stateId: 31,  id: 3160306,  name: "Santo Antônio do Jacinto"},
  {stateId: 31,  id: 3160405,  name: "Santo Antônio do Monte"},
  {stateId: 31,  id: 3160454,  name: "Santo Antônio do Retiro"},
  {stateId: 31,  id: 3160504,  name: "Santo Antônio do Rio Abaixo"},
  {stateId: 31,  id: 3160603,  name: "Santo Hipólito"},
  {stateId: 31,  id: 3160702,  name: "Santos Dumont"},
  {stateId: 31,  id: 3160801,  name: "São Bento Abade"},
  {stateId: 31,  id: 3160900,  name: "São Brás do Suaçuí"},
  {stateId: 31,  id: 3160959,  name: "São Domingos das Dores"},
  {stateId: 31,  id: 3161007,  name: "São Domingos do Prata"},
  {stateId: 31,  id: 3161056,  name: "São Félix de Minas"},
  {stateId: 31,  id: 3161106,  name: "São Francisco"},
  {stateId: 31,  id: 3161205,  name: "São Francisco de Paula"},
  {stateId: 31,  id: 3161304,  name: "São Francisco de Sales"},
  {stateId: 31,  id: 3161403,  name: "São Francisco do Glória"},
  {stateId: 31,  id: 3161502,  name: "São Geraldo"},
  {stateId: 31,  id: 3161601,  name: "São Geraldo da Piedade"},
  {stateId: 31,  id: 3161650,  name: "São Geraldo do Baixio"},
  {stateId: 31,  id: 3161700,  name: "São Gonçalo do Abaeté"},
  {stateId: 31,  id: 3161809,  name: "São Gonçalo do Pará"},
  {stateId: 31,  id: 3161908,  name: "São Gonçalo do Rio Abaixo"},
  {stateId: 31,  id: 3125507,  name: "São Gonçalo do Rio Preto"},
  {stateId: 31,  id: 3162005,  name: "São Gonçalo do Sapucaí"},
  {stateId: 31,  id: 3162104,  name: "São Gotardo"},
  {stateId: 31,  id: 3162203,  name: "São João Batista do Glória"},
  {stateId: 31,  id: 3162252,  name: "São João da Lagoa"},
  {stateId: 31,  id: 3162302,  name: "São João da Mata"},
  {stateId: 31,  id: 3162401,  name: "São João da Ponte"},
  {stateId: 31,  id: 3162450,  name: "São João das Missões"},
  {stateId: 31,  id: 3162500,  name: "São João del Rei"},
  {stateId: 31,  id: 3162559,  name: "São João do Manhuaçu"},
  {stateId: 31,  id: 3162575,  name: "São João do Manteninha"},
  {stateId: 31,  id: 3162609,  name: "São João do Oriente"},
  {stateId: 31,  id: 3162658,  name: "São João do Pacuí"},
  {stateId: 31,  id: 3162708,  name: "São João do Paraíso"},
  {stateId: 31,  id: 3162807,  name: "São João Evangelista"},
  {stateId: 31,  id: 3162906,  name: "São João Nepomuceno"},
  {stateId: 31,  id: 3162922,  name: "São Joaquim de Bicas"},
  {stateId: 31,  id: 3162948,  name: "São José da Barra"},
  {stateId: 31,  id: 3162955,  name: "São José da Lapa"},
  {stateId: 31,  id: 3163003,  name: "São José da Safira"},
  {stateId: 31,  id: 3163102,  name: "São José da Varginha"},
  {stateId: 31,  id: 3163201,  name: "São José do Alegre"},
  {stateId: 31,  id: 3163300,  name: "São José do Divino"},
  {stateId: 31,  id: 3163409,  name: "São José do Goiabal"},
  {stateId: 31,  id: 3163508,  name: "São José do Jacuri"},
  {stateId: 31,  id: 3163607,  name: "São José do Mantimento"},
  {stateId: 31,  id: 3163706,  name: "São Lourenço"},
  {stateId: 31,  id: 3163805,  name: "São Miguel do Anta"},
  {stateId: 31,  id: 3163904,  name: "São Pedro da União"},
  {stateId: 31,  id: 3164100,  name: "São Pedro do Suaçuí"},
  {stateId: 31,  id: 3164001,  name: "São Pedro dos Ferros"},
  {stateId: 31,  id: 3164209,  name: "São Romão"},
  {stateId: 31,  id: 3164308,  name: "São Roque de Minas"},
  {stateId: 31,  id: 3164407,  name: "São Sebastião da Bela Vista"},
  {stateId: 31,  id: 3164431,  name: "São Sebastião da Vargem Alegre"},
  {stateId: 31,  id: 3164472,  name: "São Sebastião do Anta"},
  {stateId: 31,  id: 3164506,  name: "São Sebastião do Maranhão"},
  {stateId: 31,  id: 3164605,  name: "São Sebastião do Oeste"},
  {stateId: 31,  id: 3164704,  name: "São Sebastião do Paraíso"},
  {stateId: 31,  id: 3164803,  name: "São Sebastião do Rio Preto"},
  {stateId: 31,  id: 3164902,  name: "São Sebastião do Rio Verde"},
  {stateId: 31,  id: 3165206,  name: "São Thomé das Letras"},
  {stateId: 31,  id: 3165008,  name: "São Tiago"},
  {stateId: 31,  id: 3165107,  name: "São Tomás de Aquino"},
  {stateId: 31,  id: 3165305,  name: "São Vicente de Minas"},
  {stateId: 31,  id: 3165404,  name: "Sapucaí-Mirim"},
  {stateId: 31,  id: 3165503,  name: "Sardoá"},
  {stateId: 31,  id: 3165537,  name: "Sarzedo"},
  {stateId: 31,  id: 3165560,  name: "Sem-Peixe"},
  {stateId: 31,  id: 3165578,  name: "Senador Amaral"},
  {stateId: 31,  id: 3165602,  name: "Senador Cortes"},
  {stateId: 31,  id: 3165701,  name: "Senador Firmino"},
  {stateId: 31,  id: 3165800,  name: "Senador José Bento"},
  {stateId: 31,  id: 3165909,  name: "Senador Modestino Gonçalves"},
  {stateId: 31,  id: 3166006,  name: "Senhora de Oliveira"},
  {stateId: 31,  id: 3166105,  name: "Senhora do Porto"},
  {stateId: 31,  id: 3166204,  name: "Senhora dos Remédios"},
  {stateId: 31,  id: 3166303,  name: "Sericita"},
  {stateId: 31,  id: 3166402,  name: "Seritinga"},
  {stateId: 31,  id: 3166501,  name: "Serra Azul de Minas"},
  {stateId: 31,  id: 3166600,  name: "Serra da Saudade"},
  {stateId: 31,  id: 3166808,  name: "Serra do Salitre"},
  {stateId: 31,  id: 3166709,  name: "Serra dos Aimorés"},
  {stateId: 31,  id: 3166907,  name: "Serrania"},
  {stateId: 31,  id: 3166956,  name: "Serranópolis de Minas"},
  {stateId: 31,  id: 3167004,  name: "Serranos"},
  {stateId: 31,  id: 3167103,  name: "Serro"},
  {stateId: 31,  id: 3167202,  name: "Sete Lagoas"},
  {stateId: 31,  id: 3165552,  name: "Setubinha"},
  {stateId: 31,  id: 3167301,  name: "Silveirânia"},
  {stateId: 31,  id: 3167400,  name: "Silvianópolis"},
  {stateId: 31,  id: 3167509,  name: "Simão Pereira"},
  {stateId: 31,  id: 3167608,  name: "Simonésia"},
  {stateId: 31,  id: 3167707,  name: "Sobrália"},
  {stateId: 31,  id: 3167806,  name: "Soledade de Minas"},
  {stateId: 31,  id: 3167905,  name: "Tabuleiro"},
  {stateId: 31,  id: 3168002,  name: "Taiobeiras"},
  {stateId: 31,  id: 3168051,  name: "Taparuba"},
  {stateId: 31,  id: 3168101,  name: "Tapira"},
  {stateId: 31,  id: 3168200,  name: "Tapiraí"},
  {stateId: 31,  id: 3168309,  name: "Taquaraçu de Minas"},
  {stateId: 31,  id: 3168408,  name: "Tarumirim"},
  {stateId: 31,  id: 3168507,  name: "Teixeiras"},
  {stateId: 31,  id: 3168606,  name: "Teófilo Otoni"},
  {stateId: 31,  id: 3168705,  name: "Timóteo"},
  {stateId: 31,  id: 3168804,  name: "Tiradentes"},
  {stateId: 31,  id: 3168903,  name: "Tiros"},
  {stateId: 31,  id: 3169000,  name: "Tocantins"},
  {stateId: 31,  id: 3169059,  name: "Tocos do Moji"},
  {stateId: 31,  id: 3169109,  name: "Toledo"},
  {stateId: 31,  id: 3169208,  name: "Tombos"},
  {stateId: 31,  id: 3169307,  name: "Três Corações"},
  {stateId: 31,  id: 3169356,  name: "Três Marias"},
  {stateId: 31,  id: 3169406,  name: "Três Pontas"},
  {stateId: 31,  id: 3169505,  name: "Tumiritinga"},
  {stateId: 31,  id: 3169604,  name: "Tupaciguara"},
  {stateId: 31,  id: 3169703,  name: "Turmalina"},
  {stateId: 31,  id: 3169802,  name: "Turvolândia"},
  {stateId: 31,  id: 3169901,  name: "Ubá"},
  {stateId: 31,  id: 3170008,  name: "Ubaí"},
  {stateId: 31,  id: 3170057,  name: "Ubaporanga"},
  {stateId: 31,  id: 3170107,  name: "Uberaba"},
  {stateId: 31,  id: 3170206,  name: "Uberlândia"},
  {stateId: 31,  id: 3170305,  name: "Umburatiba"},
  {stateId: 31,  id: 3170404,  name: "Unaí"},
  {stateId: 31,  id: 3170438,  name: "União de Minas"},
  {stateId: 31,  id: 3170479,  name: "Uruana de Minas"},
  {stateId: 31,  id: 3170503,  name: "Urucânia"},
  {stateId: 31,  id: 3170529,  name: "Urucuia"},
  {stateId: 31,  id: 3170578,  name: "Vargem Alegre"},
  {stateId: 31,  id: 3170602,  name: "Vargem Bonita"},
  {stateId: 31,  id: 3170651,  name: "Vargem Grande do Rio Pardo"},
  {stateId: 31,  id: 3170701,  name: "Varginha"},
  {stateId: 31,  id: 3170750,  name: "Varjão de Minas"},
  {stateId: 31,  id: 3170800,  name: "Várzea da Palma"},
  {stateId: 31,  id: 3170909,  name: "Varzelândia"},
  {stateId: 31,  id: 3171006,  name: "Vazante"},
  {stateId: 31,  id: 3171030,  name: "Verdelândia"},
  {stateId: 31,  id: 3171071,  name: "Veredinha"},
  {stateId: 31,  id: 3171105,  name: "Veríssimo"},
  {stateId: 31,  id: 3171154,  name: "Vermelho Novo"},
  {stateId: 31,  id: 3171204,  name: "Vespasiano"},
  {stateId: 31,  id: 3171303,  name: "Viçosa"},
  {stateId: 31,  id: 3171402,  name: "Vieiras"},
  {stateId: 31,  id: 3171600,  name: "Virgem da Lapa"},
  {stateId: 31,  id: 3171709,  name: "Virgínia"},
  {stateId: 31,  id: 3171808,  name: "Virginópolis"},
  {stateId: 31,  id: 3171907,  name: "Virgolândia"},
  {stateId: 31,  id: 3172004,  name: "Visconde do Rio Branco"},
  {stateId: 31,  id: 3172103,  name: "Volta Grande"},
  {stateId: 31,  id: 3172202,  name: "Wenceslau Braz"},
  {stateId: 32,  id: 3200102,  name: "Afonso Cláudio"},
  {stateId: 32,  id: 3200169,  name: "Água Doce do Norte"},
  {stateId: 32,  id: 3200136,  name: "Águia Branca"},
  {stateId: 32,  id: 3200201,  name: "Alegre"},
  {stateId: 32,  id: 3200300,  name: "Alfredo Chaves"},
  {stateId: 32,  id: 3200359,  name: "Alto Rio Novo"},
  {stateId: 32,  id: 3200409,  name: "Anchieta"},
  {stateId: 32,  id: 3200508,  name: "Apiacá"},
  {stateId: 32,  id: 3200607,  name: "Aracruz"},
  {stateId: 32,  id: 3200706,  name: "Atílio Vivacqua"},
  {stateId: 32,  id: 3200805,  name: "Baixo Guandu"},
  {stateId: 32,  id: 3200904,  name: "Barra de São Francisco"},
  {stateId: 32,  id: 3201001,  name: "Boa Esperança"},
  {stateId: 32,  id: 3201100,  name: "Bom Jesus do Norte"},
  {stateId: 32,  id: 3201159,  name: "Brejetuba"},
  {stateId: 32,  id: 3201209,  name: "Cachoeiro de Itapemirim"},
  {stateId: 32,  id: 3201308,  name: "Cariacica"},
  {stateId: 32,  id: 3201407,  name: "Castelo"},
  {stateId: 32,  id: 3201506,  name: "Colatina"},
  {stateId: 32,  id: 3201605,  name: "Conceição da Barra"},
  {stateId: 32,  id: 3201704,  name: "Conceição do Castelo"},
  {stateId: 32,  id: 3201803,  name: "Divino de São Lourenço"},
  {stateId: 32,  id: 3201902,  name: "Domingos Martins"},
  {stateId: 32,  id: 3202009,  name: "Dores do Rio Preto"},
  {stateId: 32,  id: 3202108,  name: "Ecoporanga"},
  {stateId: 32,  id: 3202207,  name: "Fundão"},
  {stateId: 32,  id: 3202256,  name: "Governador Lindenberg"},
  {stateId: 32,  id: 3202306,  name: "Guaçuí"},
  {stateId: 32,  id: 3202405,  name: "Guarapari"},
  {stateId: 32,  id: 3202454,  name: "Ibatiba"},
  {stateId: 32,  id: 3202504,  name: "Ibiraçu"},
  {stateId: 32,  id: 3202553,  name: "Ibitirama"},
  {stateId: 32,  id: 3202603,  name: "Iconha"},
  {stateId: 32,  id: 3202652,  name: "Irupi"},
  {stateId: 32,  id: 3202702,  name: "Itaguaçu"},
  {stateId: 32,  id: 3202801,  name: "Itapemirim"},
  {stateId: 32,  id: 3202900,  name: "Itarana"},
  {stateId: 32,  id: 3203007,  name: "Iúna"},
  {stateId: 32,  id: 3203056,  name: "Jaguaré"},
  {stateId: 32,  id: 3203106,  name: "Jerônimo Monteiro"},
  {stateId: 32,  id: 3203130,  name: "João Neiva"},
  {stateId: 32,  id: 3203163,  name: "Laranja da Terra"},
  {stateId: 32,  id: 3203205,  name: "Linhares"},
  {stateId: 32,  id: 3203304,  name: "Mantenópolis"},
  {stateId: 32,  id: 3203320,  name: "Marataízes"},
  {stateId: 32,  id: 3203346,  name: "Marechal Floriano"},
  {stateId: 32,  id: 3203353,  name: "Marilândia"},
  {stateId: 32,  id: 3203403,  name: "Mimoso do Sul"},
  {stateId: 32,  id: 3203502,  name: "Montanha"},
  {stateId: 32,  id: 3203601,  name: "Mucurici"},
  {stateId: 32,  id: 3203700,  name: "Muniz Freire"},
  {stateId: 32,  id: 3203809,  name: "Muqui"},
  {stateId: 32,  id: 3203908,  name: "Nova Venécia"},
  {stateId: 32,  id: 3204005,  name: "Pancas"},
  {stateId: 32,  id: 3204054,  name: "Pedro Canário"},
  {stateId: 32,  id: 3204104,  name: "Pinheiros"},
  {stateId: 32,  id: 3204203,  name: "Piúma"},
  {stateId: 32,  id: 3204252,  name: "Ponto Belo"},
  {stateId: 32,  id: 3204302,  name: "Presidente Kennedy"},
  {stateId: 32,  id: 3204351,  name: "Rio Bananal"},
  {stateId: 32,  id: 3204401,  name: "Rio Novo do Sul"},
  {stateId: 32,  id: 3204500,  name: "Santa Leopoldina"},
  {stateId: 32,  id: 3204559,  name: "Santa Maria de Jetibá"},
  {stateId: 32,  id: 3204609,  name: "Santa Teresa"},
  {stateId: 32,  id: 3204658,  name: "São Domingos do Norte"},
  {stateId: 32,  id: 3204708,  name: "São Gabriel da Palha"},
  {stateId: 32,  id: 3204807,  name: "São José do Calçado"},
  {stateId: 32,  id: 3204906,  name: "São Mateus"},
  {stateId: 32,  id: 3204955,  name: "São Roque do Canaã"},
  {stateId: 32,  id: 3205002,  name: "Serra"},
  {stateId: 32,  id: 3205010,  name: "Sooretama"},
  {stateId: 32,  id: 3205036,  name: "Vargem Alta"},
  {stateId: 32,  id: 3205069,  name: "Venda Nova do Imigrante"},
  {stateId: 32,  id: 3205101,  name: "Viana"},
  {stateId: 32,  id: 3205150,  name: "Vila Pavão"},
  {stateId: 32,  id: 3205176,  name: "Vila Valério"},
  {stateId: 32,  id: 3205200,  name: "Vila Velha"},
  {stateId: 32,  id: 3205309,  name: "Vitória"},
  {stateId: 33,  id: 3300100,  name: "Angra dos Reis"},
  {stateId: 33,  id: 3300159,  name: "Aperibé"},
  {stateId: 33,  id: 3300209,  name: "Araruama"},
  {stateId: 33,  id: 3300225,  name: "Areal"},
  {stateId: 33,  id: 3300233,  name: "Armação dos Búzios"},
  {stateId: 33,  id: 3300258,  name: "Arraial do Cabo"},
  {stateId: 33,  id: 3300308,  name: "Barra do Piraí"},
  {stateId: 33,  id: 3300407,  name: "Barra Mansa"},
  {stateId: 33,  id: 3300456,  name: "Belford Roxo"},
  {stateId: 33,  id: 3300506,  name: "Bom Jardim"},
  {stateId: 33,  id: 3300605,  name: "Bom Jesus do Itabapoana"},
  {stateId: 33,  id: 3300704,  name: "Cabo Frio"},
  {stateId: 33,  id: 3300803,  name: "Cachoeiras de Macacu"},
  {stateId: 33,  id: 3300902,  name: "Cambuci"},
  {stateId: 33,  id: 3301009,  name: "Campos dos Goytacazes"},
  {stateId: 33,  id: 3301108,  name: "Cantagalo"},
  {stateId: 33,  id: 3300936,  name: "Carapebus"},
  {stateId: 33,  id: 3301157,  name: "Cardoso Moreira"},
  {stateId: 33,  id: 3301207,  name: "Carmo"},
  {stateId: 33,  id: 3301306,  name: "Casimiro de Abreu"},
  {stateId: 33,  id: 3300951,  name: "Comendador Levy Gasparian"},
  {stateId: 33,  id: 3301405,  name: "Conceição de Macabu"},
  {stateId: 33,  id: 3301504,  name: "Cordeiro"},
  {stateId: 33,  id: 3301603,  name: "Duas Barras"},
  {stateId: 33,  id: 3301702,  name: "Duque de Caxias"},
  {stateId: 33,  id: 3301801,  name: "Engenheiro Paulo de Frontin"},
  {stateId: 33,  id: 3301850,  name: "Guapimirim"},
  {stateId: 33,  id: 3301876,  name: "Iguaba Grande"},
  {stateId: 33,  id: 3301900,  name: "Itaboraí"},
  {stateId: 33,  id: 3302007,  name: "Itaguaí"},
  {stateId: 33,  id: 3302056,  name: "Italva"},
  {stateId: 33,  id: 3302106,  name: "Itaocara"},
  {stateId: 33,  id: 3302205,  name: "Itaperuna"},
  {stateId: 33,  id: 3302254,  name: "Itatiaia"},
  {stateId: 33,  id: 3302270,  name: "Japeri"},
  {stateId: 33,  id: 3302304,  name: "Laje do Muriaé"},
  {stateId: 33,  id: 3302403,  name: "Macaé"},
  {stateId: 33,  id: 3302452,  name: "Macuco"},
  {stateId: 33,  id: 3302502,  name: "Magé"},
  {stateId: 33,  id: 3302601,  name: "Mangaratiba"},
  {stateId: 33,  id: 3302700,  name: "Maricá"},
  {stateId: 33,  id: 3302809,  name: "Mendes"},
  {stateId: 33,  id: 3302858,  name: "Mesquita"},
  {stateId: 33,  id: 3302908,  name: "Miguel Pereira"},
  {stateId: 33,  id: 3303005,  name: "Miracema"},
  {stateId: 33,  id: 3303104,  name: "Natividade"},
  {stateId: 33,  id: 3303203,  name: "Nilópolis"},
  {stateId: 33,  id: 3303302,  name: "Niterói"},
  {stateId: 33,  id: 3303401,  name: "Nova Friburgo"},
  {stateId: 33,  id: 3303500,  name: "Nova Iguaçu"},
  {stateId: 33,  id: 3303609,  name: "Paracambi"},
  {stateId: 33,  id: 3303708,  name: "Paraíba do Sul"},
  {stateId: 33,  id: 3303807,  name: "Paraty"},
  {stateId: 33,  id: 3303856,  name: "Paty do Alferes"},
  {stateId: 33,  id: 3303906,  name: "Petrópolis"},
  {stateId: 33,  id: 3303955,  name: "Pinheiral"},
  {stateId: 33,  id: 3304003,  name: "Piraí"},
  {stateId: 33,  id: 3304102,  name: "Porciúncula"},
  {stateId: 33,  id: 3304110,  name: "Porto Real"},
  {stateId: 33,  id: 3304128,  name: "Quatis"},
  {stateId: 33,  id: 3304144,  name: "Queimados"},
  {stateId: 33,  id: 3304151,  name: "Quissamã"},
  {stateId: 33,  id: 3304201,  name: "Resende"},
  {stateId: 33,  id: 3304300,  name: "Rio Bonito"},
  {stateId: 33,  id: 3304409,  name: "Rio Claro"},
  {stateId: 33,  id: 3304508,  name: "Rio das Flores"},
  {stateId: 33,  id: 3304524,  name: "Rio das Ostras"},
  {stateId: 33,  id: 3304557,  name: "Rio de Janeiro"},
  {stateId: 33,  id: 3304607,  name: "Santa Maria Madalena"},
  {stateId: 33,  id: 3304706,  name: "Santo Antônio de Pádua"},
  {stateId: 33,  id: 3304805,  name: "São Fidélis"},
  {stateId: 33,  id: 3304755,  name: "São Francisco de Itabapoana"},
  {stateId: 33,  id: 3304904,  name: "São Gonçalo"},
  {stateId: 33,  id: 3305000,  name: "São João da Barra"},
  {stateId: 33,  id: 3305109,  name: "São João de Meriti"},
  {stateId: 33,  id: 3305133,  name: "São José de Ubá"},
  {stateId: 33,  id: 3305158,  name: "São José do Vale do Rio Preto"},
  {stateId: 33,  id: 3305208,  name: "São Pedro da Aldeia"},
  {stateId: 33,  id: 3305307,  name: "São Sebastião do Alto"},
  {stateId: 33,  id: 3305406,  name: "Sapucaia"},
  {stateId: 33,  id: 3305505,  name: "Saquarema"},
  {stateId: 33,  id: 3305554,  name: "Seropédica"},
  {stateId: 33,  id: 3305604,  name: "Silva Jardim"},
  {stateId: 33,  id: 3305703,  name: "Sumidouro"},
  {stateId: 33,  id: 3305752,  name: "Tanguá"},
  {stateId: 33,  id: 3305802,  name: "Teresópolis"},
  {stateId: 33,  id: 3305901,  name: "Trajano de Moraes"},
  {stateId: 33,  id: 3306008,  name: "Três Rios"},
  {stateId: 33,  id: 3306107,  name: "Valença"},
  {stateId: 33,  id: 3306156,  name: "Varre-Sai"},
  {stateId: 33,  id: 3306206,  name: "Vassouras"},
  {stateId: 33,  id: 3306305,  name: "Volta Redonda"},
  {stateId: 35,  id: 3500105,  name: "Adamantina"},
  {stateId: 35,  id: 3500204,  name: "Adolfo"},
  {stateId: 35,  id: 3500303,  name: "Aguaí"},
  {stateId: 35,  id: 3500402,  name: "Águas da Prata"},
  {stateId: 35,  id: 3500501,  name: "Águas de Lindóia"},
  {stateId: 35,  id: 3500550,  name: "Águas de Santa Bárbara"},
  {stateId: 35,  id: 3500600,  name: "Águas de São Pedro"},
  {stateId: 35,  id: 3500709,  name: "Agudos"},
  {stateId: 35,  id: 3500758,  name: "Alambari"},
  {stateId: 35,  id: 3500808,  name: "Alfredo Marcondes"},
  {stateId: 35,  id: 3500907,  name: "Altair"},
  {stateId: 35,  id: 3501004,  name: "Altinópolis"},
  {stateId: 35,  id: 3501103,  name: "Alto Alegre"},
  {stateId: 35,  id: 3501152,  name: "Alumínio"},
  {stateId: 35,  id: 3501202,  name: "Álvares Florence"},
  {stateId: 35,  id: 3501301,  name: "Álvares Machado"},
  {stateId: 35,  id: 3501400,  name: "Álvaro de Carvalho"},
  {stateId: 35,  id: 3501509,  name: "Alvinlândia"},
  {stateId: 35,  id: 3501608,  name: "Americana"},
  {stateId: 35,  id: 3501707,  name: "Américo Brasiliense"},
  {stateId: 35,  id: 3501806,  name: "Américo de Campos"},
  {stateId: 35,  id: 3501905,  name: "Amparo"},
  {stateId: 35,  id: 3502002,  name: "Analândia"},
  {stateId: 35,  id: 3502101,  name: "Andradina"},
  {stateId: 35,  id: 3502200,  name: "Angatuba"},
  {stateId: 35,  id: 3502309,  name: "Anhembi"},
  {stateId: 35,  id: 3502408,  name: "Anhumas"},
  {stateId: 35,  id: 3502507,  name: "Aparecida"},
  {stateId: 35,  id: 3502606,  name: "Aparecida d'Oeste"},
  {stateId: 35,  id: 3502705,  name: "Apiaí"},
  {stateId: 35,  id: 3502754,  name: "Araçariguama"},
  {stateId: 35,  id: 3502804,  name: "Araçatuba"},
  {stateId: 35,  id: 3502903,  name: "Araçoiaba da Serra"},
  {stateId: 35,  id: 3503000,  name: "Aramina"},
  {stateId: 35,  id: 3503109,  name: "Arandu"},
  {stateId: 35,  id: 3503158,  name: "Arapeí"},
  {stateId: 35,  id: 3503208,  name: "Araraquara"},
  {stateId: 35,  id: 3503307,  name: "Araras"},
  {stateId: 35,  id: 3503356,  name: "Arco-Íris"},
  {stateId: 35,  id: 3503406,  name: "Arealva"},
  {stateId: 35,  id: 3503505,  name: "Areias"},
  {stateId: 35,  id: 3503604,  name: "Areiópolis"},
  {stateId: 35,  id: 3503703,  name: "Ariranha"},
  {stateId: 35,  id: 3503802,  name: "Artur Nogueira"},
  {stateId: 35,  id: 3503901,  name: "Arujá"},
  {stateId: 35,  id: 3503950,  name: "Aspásia"},
  {stateId: 35,  id: 3504008,  name: "Assis"},
  {stateId: 35,  id: 3504107,  name: "Atibaia"},
  {stateId: 35,  id: 3504206,  name: "Auriflama"},
  {stateId: 35,  id: 3504305,  name: "Avaí"},
  {stateId: 35,  id: 3504404,  name: "Avanhandava"},
  {stateId: 35,  id: 3504503,  name: "Avaré"},
  {stateId: 35,  id: 3504602,  name: "Bady Bassitt"},
  {stateId: 35,  id: 3504701,  name: "Balbinos"},
  {stateId: 35,  id: 3504800,  name: "Bálsamo"},
  {stateId: 35,  id: 3504909,  name: "Bananal"},
  {stateId: 35,  id: 3505005,  name: "Barão de Antonina"},
  {stateId: 35,  id: 3505104,  name: "Barbosa"},
  {stateId: 35,  id: 3505203,  name: "Bariri"},
  {stateId: 35,  id: 3505302,  name: "Barra Bonita"},
  {stateId: 35,  id: 3505351,  name: "Barra do Chapéu"},
  {stateId: 35,  id: 3505401,  name: "Barra do Turvo"},
  {stateId: 35,  id: 3505500,  name: "Barretos"},
  {stateId: 35,  id: 3505609,  name: "Barrinha"},
  {stateId: 35,  id: 3505708,  name: "Barueri"},
  {stateId: 35,  id: 3505807,  name: "Bastos"},
  {stateId: 35,  id: 3505906,  name: "Batatais"},
  {stateId: 35,  id: 3506003,  name: "Bauru"},
  {stateId: 35,  id: 3506102,  name: "Bebedouro"},
  {stateId: 35,  id: 3506201,  name: "Bento de Abreu"},
  {stateId: 35,  id: 3506300,  name: "Bernardino de Campos"},
  {stateId: 35,  id: 3506359,  name: "Bertioga"},
  {stateId: 35,  id: 3506409,  name: "Bilac"},
  {stateId: 35,  id: 3506508,  name: "Birigui"},
  {stateId: 35,  id: 3506607,  name: "Biritiba Mirim"},
  {stateId: 35,  id: 3506706,  name: "Boa Esperança do Sul"},
  {stateId: 35,  id: 3506805,  name: "Bocaina"},
  {stateId: 35,  id: 3506904,  name: "Bofete"},
  {stateId: 35,  id: 3507001,  name: "Boituva"},
  {stateId: 35,  id: 3507100,  name: "Bom Jesus dos Perdões"},
  {stateId: 35,  id: 3507159,  name: "Bom Sucesso de Itararé"},
  {stateId: 35,  id: 3507209,  name: "Borá"},
  {stateId: 35,  id: 3507308,  name: "Boracéia"},
  {stateId: 35,  id: 3507407,  name: "Borborema"},
  {stateId: 35,  id: 3507456,  name: "Borebi"},
  {stateId: 35,  id: 3507506,  name: "Botucatu"},
  {stateId: 35,  id: 3507605,  name: "Bragança Paulista"},
  {stateId: 35,  id: 3507704,  name: "Braúna"},
  {stateId: 35,  id: 3507753,  name: "Brejo Alegre"},
  {stateId: 35,  id: 3507803,  name: "Brodowski"},
  {stateId: 35,  id: 3507902,  name: "Brotas"},
  {stateId: 35,  id: 3508009,  name: "Buri"},
  {stateId: 35,  id: 3508108,  name: "Buritama"},
  {stateId: 35,  id: 3508207,  name: "Buritizal"},
  {stateId: 35,  id: 3508306,  name: "Cabrália Paulista"},
  {stateId: 35,  id: 3508405,  name: "Cabreúva"},
  {stateId: 35,  id: 3508504,  name: "Caçapava"},
  {stateId: 35,  id: 3508603,  name: "Cachoeira Paulista"},
  {stateId: 35,  id: 3508702,  name: "Caconde"},
  {stateId: 35,  id: 3508801,  name: "Cafelândia"},
  {stateId: 35,  id: 3508900,  name: "Caiabu"},
  {stateId: 35,  id: 3509007,  name: "Caieiras"},
  {stateId: 35,  id: 3509106,  name: "Caiuá"},
  {stateId: 35,  id: 3509205,  name: "Cajamar"},
  {stateId: 35,  id: 3509254,  name: "Cajati"},
  {stateId: 35,  id: 3509304,  name: "Cajobi"},
  {stateId: 35,  id: 3509403,  name: "Cajuru"},
  {stateId: 35,  id: 3509452,  name: "Campina do Monte Alegre"},
  {stateId: 35,  id: 3509502,  name: "Campinas"},
  {stateId: 35,  id: 3509601,  name: "Campo Limpo Paulista"},
  {stateId: 35,  id: 3509700,  name: "Campos do Jordão"},
  {stateId: 35,  id: 3509809,  name: "Campos Novos Paulista"},
  {stateId: 35,  id: 3509908,  name: "Cananéia"},
  {stateId: 35,  id: 3509957,  name: "Canas"},
  {stateId: 35,  id: 3510005,  name: "Cândido Mota"},
  {stateId: 35,  id: 3510104,  name: "Cândido Rodrigues"},
  {stateId: 35,  id: 3510153,  name: "Canitar"},
  {stateId: 35,  id: 3510203,  name: "Capão Bonito"},
  {stateId: 35,  id: 3510302,  name: "Capela do Alto"},
  {stateId: 35,  id: 3510401,  name: "Capivari"},
  {stateId: 35,  id: 3510500,  name: "Caraguatatuba"},
  {stateId: 35,  id: 3510609,  name: "Carapicuíba"},
  {stateId: 35,  id: 3510708,  name: "Cardoso"},
  {stateId: 35,  id: 3510807,  name: "Casa Branca"},
  {stateId: 35,  id: 3510906,  name: "Cássia dos Coqueiros"},
  {stateId: 35,  id: 3511003,  name: "Castilho"},
  {stateId: 35,  id: 3511102,  name: "Catanduva"},
  {stateId: 35,  id: 3511201,  name: "Catiguá"},
  {stateId: 35,  id: 3511300,  name: "Cedral"},
  {stateId: 35,  id: 3511409,  name: "Cerqueira César"},
  {stateId: 35,  id: 3511508,  name: "Cerquilho"},
  {stateId: 35,  id: 3511607,  name: "Cesário Lange"},
  {stateId: 35,  id: 3511706,  name: "Charqueada"},
  {stateId: 35,  id: 3557204,  name: "Chavantes"},
  {stateId: 35,  id: 3511904,  name: "Clementina"},
  {stateId: 35,  id: 3512001,  name: "Colina"},
  {stateId: 35,  id: 3512100,  name: "Colômbia"},
  {stateId: 35,  id: 3512209,  name: "Conchal"},
  {stateId: 35,  id: 3512308,  name: "Conchas"},
  {stateId: 35,  id: 3512407,  name: "Cordeirópolis"},
  {stateId: 35,  id: 3512506,  name: "Coroados"},
  {stateId: 35,  id: 3512605,  name: "Coronel Macedo"},
  {stateId: 35,  id: 3512704,  name: "Corumbataí"},
  {stateId: 35,  id: 3512803,  name: "Cosmópolis"},
  {stateId: 35,  id: 3512902,  name: "Cosmorama"},
  {stateId: 35,  id: 3513009,  name: "Cotia"},
  {stateId: 35,  id: 3513108,  name: "Cravinhos"},
  {stateId: 35,  id: 3513207,  name: "Cristais Paulista"},
  {stateId: 35,  id: 3513306,  name: "Cruzália"},
  {stateId: 35,  id: 3513405,  name: "Cruzeiro"},
  {stateId: 35,  id: 3513504,  name: "Cubatão"},
  {stateId: 35,  id: 3513603,  name: "Cunha"},
  {stateId: 35,  id: 3513702,  name: "Descalvado"},
  {stateId: 35,  id: 3513801,  name: "Diadema"},
  {stateId: 35,  id: 3513850,  name: "Dirce Reis"},
  {stateId: 35,  id: 3513900,  name: "Divinolândia"},
  {stateId: 35,  id: 3514007,  name: "Dobrada"},
  {stateId: 35,  id: 3514106,  name: "Dois Córregos"},
  {stateId: 35,  id: 3514205,  name: "Dolcinópolis"},
  {stateId: 35,  id: 3514304,  name: "Dourado"},
  {stateId: 35,  id: 3514403,  name: "Dracena"},
  {stateId: 35,  id: 3514502,  name: "Duartina"},
  {stateId: 35,  id: 3514601,  name: "Dumont"},
  {stateId: 35,  id: 3514700,  name: "Echaporã"},
  {stateId: 35,  id: 3514809,  name: "Eldorado"},
  {stateId: 35,  id: 3514908,  name: "Elias Fausto"},
  {stateId: 35,  id: 3514924,  name: "Elisiário"},
  {stateId: 35,  id: 3514957,  name: "Embaúba"},
  {stateId: 35,  id: 3515004,  name: "Embu das Artes"},
  {stateId: 35,  id: 3515103,  name: "Embu-Guaçu"},
  {stateId: 35,  id: 3515129,  name: "Emilianópolis"},
  {stateId: 35,  id: 3515152,  name: "Engenheiro Coelho"},
  {stateId: 35,  id: 3515186,  name: "Espírito Santo do Pinhal"},
  {stateId: 35,  id: 3515194,  name: "Espírito Santo do Turvo"},
  {stateId: 35,  id: 3557303,  name: "Estiva Gerbi"},
  {stateId: 35,  id: 3515301,  name: "Estrela do Norte"},
  {stateId: 35,  id: 3515202,  name: "Estrela d'Oeste"},
  {stateId: 35,  id: 3515350,  name: "Euclides da Cunha Paulista"},
  {stateId: 35,  id: 3515400,  name: "Fartura"},
  {stateId: 35,  id: 3515608,  name: "Fernando Prestes"},
  {stateId: 35,  id: 3515509,  name: "Fernandópolis"},
  {stateId: 35,  id: 3515657,  name: "Fernão"},
  {stateId: 35,  id: 3515707,  name: "Ferraz de Vasconcelos"},
  {stateId: 35,  id: 3515806,  name: "Flora Rica"},
  {stateId: 35,  id: 3515905,  name: "Floreal"},
  {stateId: 35,  id: 3516002,  name: "Flórida Paulista"},
  {stateId: 35,  id: 3516101,  name: "Florínea"},
  {stateId: 35,  id: 3516200,  name: "Franca"},
  {stateId: 35,  id: 3516309,  name: "Francisco Morato"},
  {stateId: 35,  id: 3516408,  name: "Franco da Rocha"},
  {stateId: 35,  id: 3516507,  name: "Gabriel Monteiro"},
  {stateId: 35,  id: 3516606,  name: "Gália"},
  {stateId: 35,  id: 3516705,  name: "Garça"},
  {stateId: 35,  id: 3516804,  name: "Gastão Vidigal"},
  {stateId: 35,  id: 3516853,  name: "Gavião Peixoto"},
  {stateId: 35,  id: 3516903,  name: "General Salgado"},
  {stateId: 35,  id: 3517000,  name: "Getulina"},
  {stateId: 35,  id: 3517109,  name: "Glicério"},
  {stateId: 35,  id: 3517208,  name: "Guaiçara"},
  {stateId: 35,  id: 3517307,  name: "Guaimbê"},
  {stateId: 35,  id: 3517406,  name: "Guaíra"},
  {stateId: 35,  id: 3517505,  name: "Guapiaçu"},
  {stateId: 35,  id: 3517604,  name: "Guapiara"},
  {stateId: 35,  id: 3517703,  name: "Guará"},
  {stateId: 35,  id: 3517802,  name: "Guaraçaí"},
  {stateId: 35,  id: 3517901,  name: "Guaraci"},
  {stateId: 35,  id: 3518008,  name: "Guarani d'Oeste"},
  {stateId: 35,  id: 3518107,  name: "Guarantã"},
  {stateId: 35,  id: 3518206,  name: "Guararapes"},
  {stateId: 35,  id: 3518305,  name: "Guararema"},
  {stateId: 35,  id: 3518404,  name: "Guaratinguetá"},
  {stateId: 35,  id: 3518503,  name: "Guareí"},
  {stateId: 35,  id: 3518602,  name: "Guariba"},
  {stateId: 35,  id: 3518701,  name: "Guarujá"},
  {stateId: 35,  id: 3518800,  name: "Guarulhos"},
  {stateId: 35,  id: 3518859,  name: "Guatapará"},
  {stateId: 35,  id: 3518909,  name: "Guzolândia"},
  {stateId: 35,  id: 3519006,  name: "Herculândia"},
  {stateId: 35,  id: 3519055,  name: "Holambra"},
  {stateId: 35,  id: 3519071,  name: "Hortolândia"},
  {stateId: 35,  id: 3519105,  name: "Iacanga"},
  {stateId: 35,  id: 3519204,  name: "Iacri"},
  {stateId: 35,  id: 3519253,  name: "Iaras"},
  {stateId: 35,  id: 3519303,  name: "Ibaté"},
  {stateId: 35,  id: 3519402,  name: "Ibirá"},
  {stateId: 35,  id: 3519501,  name: "Ibirarema"},
  {stateId: 35,  id: 3519600,  name: "Ibitinga"},
  {stateId: 35,  id: 3519709,  name: "Ibiúna"},
  {stateId: 35,  id: 3519808,  name: "Icém"},
  {stateId: 35,  id: 3519907,  name: "Iepê"},
  {stateId: 35,  id: 3520004,  name: "Igaraçu do Tietê"},
  {stateId: 35,  id: 3520103,  name: "Igarapava"},
  {stateId: 35,  id: 3520202,  name: "Igaratá"},
  {stateId: 35,  id: 3520301,  name: "Iguape"},
  {stateId: 35,  id: 3520426,  name: "Ilha Comprida"},
  {stateId: 35,  id: 3520442,  name: "Ilha Solteira"},
  {stateId: 35,  id: 3520400,  name: "Ilhabela"},
  {stateId: 35,  id: 3520509,  name: "Indaiatuba"},
  {stateId: 35,  id: 3520608,  name: "Indiana"},
  {stateId: 35,  id: 3520707,  name: "Indiaporã"},
  {stateId: 35,  id: 3520806,  name: "Inúbia Paulista"},
  {stateId: 35,  id: 3520905,  name: "Ipaussu"},
  {stateId: 35,  id: 3521002,  name: "Iperó"},
  {stateId: 35,  id: 3521101,  name: "Ipeúna"},
  {stateId: 35,  id: 3521150,  name: "Ipiguá"},
  {stateId: 35,  id: 3521200,  name: "Iporanga"},
  {stateId: 35,  id: 3521309,  name: "Ipuã"},
  {stateId: 35,  id: 3521408,  name: "Iracemápolis"},
  {stateId: 35,  id: 3521507,  name: "Irapuã"},
  {stateId: 35,  id: 3521606,  name: "Irapuru"},
  {stateId: 35,  id: 3521705,  name: "Itaberá"},
  {stateId: 35,  id: 3521804,  name: "Itaí"},
  {stateId: 35,  id: 3521903,  name: "Itajobi"},
  {stateId: 35,  id: 3522000,  name: "Itaju"},
  {stateId: 35,  id: 3522109,  name: "Itanhaém"},
  {stateId: 35,  id: 3522158,  name: "Itaoca"},
  {stateId: 35,  id: 3522208,  name: "Itapecerica da Serra"},
  {stateId: 35,  id: 3522307,  name: "Itapetininga"},
  {stateId: 35,  id: 3522406,  name: "Itapeva"},
  {stateId: 35,  id: 3522505,  name: "Itapevi"},
  {stateId: 35,  id: 3522604,  name: "Itapira"},
  {stateId: 35,  id: 3522653,  name: "Itapirapuã Paulista"},
  {stateId: 35,  id: 3522703,  name: "Itápolis"},
  {stateId: 35,  id: 3522802,  name: "Itaporanga"},
  {stateId: 35,  id: 3522901,  name: "Itapuí"},
  {stateId: 35,  id: 3523008,  name: "Itapura"},
  {stateId: 35,  id: 3523107,  name: "Itaquaquecetuba"},
  {stateId: 35,  id: 3523206,  name: "Itararé"},
  {stateId: 35,  id: 3523305,  name: "Itariri"},
  {stateId: 35,  id: 3523404,  name: "Itatiba"},
  {stateId: 35,  id: 3523503,  name: "Itatinga"},
  {stateId: 35,  id: 3523602,  name: "Itirapina"},
  {stateId: 35,  id: 3523701,  name: "Itirapuã"},
  {stateId: 35,  id: 3523800,  name: "Itobi"},
  {stateId: 35,  id: 3523909,  name: "Itu"},
  {stateId: 35,  id: 3524006,  name: "Itupeva"},
  {stateId: 35,  id: 3524105,  name: "Ituverava"},
  {stateId: 35,  id: 3524204,  name: "Jaborandi"},
  {stateId: 35,  id: 3524303,  name: "Jaboticabal"},
  {stateId: 35,  id: 3524402,  name: "Jacareí"},
  {stateId: 35,  id: 3524501,  name: "Jaci"},
  {stateId: 35,  id: 3524600,  name: "Jacupiranga"},
  {stateId: 35,  id: 3524709,  name: "Jaguariúna"},
  {stateId: 35,  id: 3524808,  name: "Jales"},
  {stateId: 35,  id: 3524907,  name: "Jambeiro"},
  {stateId: 35,  id: 3525003,  name: "Jandira"},
  {stateId: 35,  id: 3525102,  name: "Jardinópolis"},
  {stateId: 35,  id: 3525201,  name: "Jarinu"},
  {stateId: 35,  id: 3525300,  name: "Jaú"},
  {stateId: 35,  id: 3525409,  name: "Jeriquara"},
  {stateId: 35,  id: 3525508,  name: "Joanópolis"},
  {stateId: 35,  id: 3525607,  name: "João Ramalho"},
  {stateId: 35,  id: 3525706,  name: "José Bonifácio"},
  {stateId: 35,  id: 3525805,  name: "Júlio Mesquita"},
  {stateId: 35,  id: 3525854,  name: "Jumirim"},
  {stateId: 35,  id: 3525904,  name: "Jundiaí"},
  {stateId: 35,  id: 3526001,  name: "Junqueirópolis"},
  {stateId: 35,  id: 3526100,  name: "Juquiá"},
  {stateId: 35,  id: 3526209,  name: "Juquitiba"},
  {stateId: 35,  id: 3526308,  name: "Lagoinha"},
  {stateId: 35,  id: 3526407,  name: "Laranjal Paulista"},
  {stateId: 35,  id: 3526506,  name: "Lavínia"},
  {stateId: 35,  id: 3526605,  name: "Lavrinhas"},
  {stateId: 35,  id: 3526704,  name: "Leme"},
  {stateId: 35,  id: 3526803,  name: "Lençóis Paulista"},
  {stateId: 35,  id: 3526902,  name: "Limeira"},
  {stateId: 35,  id: 3527009,  name: "Lindóia"},
  {stateId: 35,  id: 3527108,  name: "Lins"},
  {stateId: 35,  id: 3527207,  name: "Lorena"},
  {stateId: 35,  id: 3527256,  name: "Lourdes"},
  {stateId: 35,  id: 3527306,  name: "Louveira"},
  {stateId: 35,  id: 3527405,  name: "Lucélia"},
  {stateId: 35,  id: 3527504,  name: "Lucianópolis"},
  {stateId: 35,  id: 3527603,  name: "Luís Antônio"},
  {stateId: 35,  id: 3527702,  name: "Luiziânia"},
  {stateId: 35,  id: 3527801,  name: "Lupércio"},
  {stateId: 35,  id: 3527900,  name: "Lutécia"},
  {stateId: 35,  id: 3528007,  name: "Macatuba"},
  {stateId: 35,  id: 3528106,  name: "Macaubal"},
  {stateId: 35,  id: 3528205,  name: "Macedônia"},
  {stateId: 35,  id: 3528304,  name: "Magda"},
  {stateId: 35,  id: 3528403,  name: "Mairinque"},
  {stateId: 35,  id: 3528502,  name: "Mairiporã"},
  {stateId: 35,  id: 3528601,  name: "Manduri"},
  {stateId: 35,  id: 3528700,  name: "Marabá Paulista"},
  {stateId: 35,  id: 3528809,  name: "Maracaí"},
  {stateId: 35,  id: 3528858,  name: "Marapoama"},
  {stateId: 35,  id: 3528908,  name: "Mariápolis"},
  {stateId: 35,  id: 3529005,  name: "Marília"},
  {stateId: 35,  id: 3529104,  name: "Marinópolis"},
  {stateId: 35,  id: 3529203,  name: "Martinópolis"},
  {stateId: 35,  id: 3529302,  name: "Matão"},
  {stateId: 35,  id: 3529401,  name: "Mauá"},
  {stateId: 35,  id: 3529500,  name: "Mendonça"},
  {stateId: 35,  id: 3529609,  name: "Meridiano"},
  {stateId: 35,  id: 3529658,  name: "Mesópolis"},
  {stateId: 35,  id: 3529708,  name: "Miguelópolis"},
  {stateId: 35,  id: 3529807,  name: "Mineiros do Tietê"},
  {stateId: 35,  id: 3530003,  name: "Mira Estrela"},
  {stateId: 35,  id: 3529906,  name: "Miracatu"},
  {stateId: 35,  id: 3530102,  name: "Mirandópolis"},
  {stateId: 35,  id: 3530201,  name: "Mirante do Paranapanema"},
  {stateId: 35,  id: 3530300,  name: "Mirassol"},
  {stateId: 35,  id: 3530409,  name: "Mirassolândia"},
  {stateId: 35,  id: 3530508,  name: "Mococa"},
  {stateId: 35,  id: 3530607,  name: "Mogi das Cruzes"},
  {stateId: 35,  id: 3530706,  name: "Mogi Guaçu"},
  {stateId: 35,  id: 3530805,  name: "Mogi Mirim"},
  {stateId: 35,  id: 3530904,  name: "Mombuca"},
  {stateId: 35,  id: 3531001,  name: "Monções"},
  {stateId: 35,  id: 3531100,  name: "Mongaguá"},
  {stateId: 35,  id: 3531209,  name: "Monte Alegre do Sul"},
  {stateId: 35,  id: 3531308,  name: "Monte Alto"},
  {stateId: 35,  id: 3531407,  name: "Monte Aprazível"},
  {stateId: 35,  id: 3531506,  name: "Monte Azul Paulista"},
  {stateId: 35,  id: 3531605,  name: "Monte Castelo"},
  {stateId: 35,  id: 3531803,  name: "Monte Mor"},
  {stateId: 35,  id: 3531704,  name: "Monteiro Lobato"},
  {stateId: 35,  id: 3531902,  name: "Morro Agudo"},
  {stateId: 35,  id: 3532009,  name: "Morungaba"},
  {stateId: 35,  id: 3532058,  name: "Motuca"},
  {stateId: 35,  id: 3532108,  name: "Murutinga do Sul"},
  {stateId: 35,  id: 3532157,  name: "Nantes"},
  {stateId: 35,  id: 3532207,  name: "Narandiba"},
  {stateId: 35,  id: 3532306,  name: "Natividade da Serra"},
  {stateId: 35,  id: 3532405,  name: "Nazaré Paulista"},
  {stateId: 35,  id: 3532504,  name: "Neves Paulista"},
  {stateId: 35,  id: 3532603,  name: "Nhandeara"},
  {stateId: 35,  id: 3532702,  name: "Nipoã"},
  {stateId: 35,  id: 3532801,  name: "Nova Aliança"},
  {stateId: 35,  id: 3532827,  name: "Nova Campina"},
  {stateId: 35,  id: 3532843,  name: "Nova Canaã Paulista"},
  {stateId: 35,  id: 3532868,  name: "Nova Castilho"},
  {stateId: 35,  id: 3532900,  name: "Nova Europa"},
  {stateId: 35,  id: 3533007,  name: "Nova Granada"},
  {stateId: 35,  id: 3533106,  name: "Nova Guataporanga"},
  {stateId: 35,  id: 3533205,  name: "Nova Independência"},
  {stateId: 35,  id: 3533304,  name: "Nova Luzitânia"},
  {stateId: 35,  id: 3533403,  name: "Nova Odessa"},
  {stateId: 35,  id: 3533254,  name: "Novais"},
  {stateId: 35,  id: 3533502,  name: "Novo Horizonte"},
  {stateId: 35,  id: 3533601,  name: "Nuporanga"},
  {stateId: 35,  id: 3533700,  name: "Ocauçu"},
  {stateId: 35,  id: 3533809,  name: "Óleo"},
  {stateId: 35,  id: 3533908,  name: "Olímpia"},
  {stateId: 35,  id: 3534005,  name: "Onda Verde"},
  {stateId: 35,  id: 3534104,  name: "Oriente"},
  {stateId: 35,  id: 3534203,  name: "Orindiúva"},
  {stateId: 35,  id: 3534302,  name: "Orlândia"},
  {stateId: 35,  id: 3534401,  name: "Osasco"},
  {stateId: 35,  id: 3534500,  name: "Oscar Bressane"},
  {stateId: 35,  id: 3534609,  name: "Osvaldo Cruz"},
  {stateId: 35,  id: 3534708,  name: "Ourinhos"},
  {stateId: 35,  id: 3534807,  name: "Ouro Verde"},
  {stateId: 35,  id: 3534757,  name: "Ouroeste"},
  {stateId: 35,  id: 3534906,  name: "Pacaembu"},
  {stateId: 35,  id: 3535002,  name: "Palestina"},
  {stateId: 35,  id: 3535101,  name: "Palmares Paulista"},
  {stateId: 35,  id: 3535200,  name: "Palmeira d'Oeste"},
  {stateId: 35,  id: 3535309,  name: "Palmital"},
  {stateId: 35,  id: 3535408,  name: "Panorama"},
  {stateId: 35,  id: 3535507,  name: "Paraguaçu Paulista"},
  {stateId: 35,  id: 3535606,  name: "Paraibuna"},
  {stateId: 35,  id: 3535705,  name: "Paraíso"},
  {stateId: 35,  id: 3535804,  name: "Paranapanema"},
  {stateId: 35,  id: 3535903,  name: "Paranapuã"},
  {stateId: 35,  id: 3536000,  name: "Parapuã"},
  {stateId: 35,  id: 3536109,  name: "Pardinho"},
  {stateId: 35,  id: 3536208,  name: "Pariquera-Açu"},
  {stateId: 35,  id: 3536257,  name: "Parisi"},
  {stateId: 35,  id: 3536307,  name: "Patrocínio Paulista"},
  {stateId: 35,  id: 3536406,  name: "Paulicéia"},
  {stateId: 35,  id: 3536505,  name: "Paulínia"},
  {stateId: 35,  id: 3536570,  name: "Paulistânia"},
  {stateId: 35,  id: 3536604,  name: "Paulo de Faria"},
  {stateId: 35,  id: 3536703,  name: "Pederneiras"},
  {stateId: 35,  id: 3536802,  name: "Pedra Bela"},
  {stateId: 35,  id: 3536901,  name: "Pedranópolis"},
  {stateId: 35,  id: 3537008,  name: "Pedregulho"},
  {stateId: 35,  id: 3537107,  name: "Pedreira"},
  {stateId: 35,  id: 3537156,  name: "Pedrinhas Paulista"},
  {stateId: 35,  id: 3537206,  name: "Pedro de Toledo"},
  {stateId: 35,  id: 3537305,  name: "Penápolis"},
  {stateId: 35,  id: 3537404,  name: "Pereira Barreto"},
  {stateId: 35,  id: 3537503,  name: "Pereiras"},
  {stateId: 35,  id: 3537602,  name: "Peruíbe"},
  {stateId: 35,  id: 3537701,  name: "Piacatu"},
  {stateId: 35,  id: 3537800,  name: "Piedade"},
  {stateId: 35,  id: 3537909,  name: "Pilar do Sul"},
  {stateId: 35,  id: 3538006,  name: "Pindamonhangaba"},
  {stateId: 35,  id: 3538105,  name: "Pindorama"},
  {stateId: 35,  id: 3538204,  name: "Pinhalzinho"},
  {stateId: 35,  id: 3538303,  name: "Piquerobi"},
  {stateId: 35,  id: 3538501,  name: "Piquete"},
  {stateId: 35,  id: 3538600,  name: "Piracaia"},
  {stateId: 35,  id: 3538709,  name: "Piracicaba"},
  {stateId: 35,  id: 3538808,  name: "Piraju"},
  {stateId: 35,  id: 3538907,  name: "Pirajuí"},
  {stateId: 35,  id: 3539004,  name: "Pirangi"},
  {stateId: 35,  id: 3539103,  name: "Pirapora do Bom Jesus"},
  {stateId: 35,  id: 3539202,  name: "Pirapozinho"},
  {stateId: 35,  id: 3539301,  name: "Pirassununga"},
  {stateId: 35,  id: 3539400,  name: "Piratininga"},
  {stateId: 35,  id: 3539509,  name: "Pitangueiras"},
  {stateId: 35,  id: 3539608,  name: "Planalto"},
  {stateId: 35,  id: 3539707,  name: "Platina"},
  {stateId: 35,  id: 3539806,  name: "Poá"},
  {stateId: 35,  id: 3539905,  name: "Poloni"},
  {stateId: 35,  id: 3540002,  name: "Pompéia"},
  {stateId: 35,  id: 3540101,  name: "Pongaí"},
  {stateId: 35,  id: 3540200,  name: "Pontal"},
  {stateId: 35,  id: 3540259,  name: "Pontalinda"},
  {stateId: 35,  id: 3540309,  name: "Pontes Gestal"},
  {stateId: 35,  id: 3540408,  name: "Populina"},
  {stateId: 35,  id: 3540507,  name: "Porangaba"},
  {stateId: 35,  id: 3540606,  name: "Porto Feliz"},
  {stateId: 35,  id: 3540705,  name: "Porto Ferreira"},
  {stateId: 35,  id: 3540754,  name: "Potim"},
  {stateId: 35,  id: 3540804,  name: "Potirendaba"},
  {stateId: 35,  id: 3540853,  name: "Pracinha"},
  {stateId: 35,  id: 3540903,  name: "Pradópolis"},
  {stateId: 35,  id: 3541000,  name: "Praia Grande"},
  {stateId: 35,  id: 3541059,  name: "Pratânia"},
  {stateId: 35,  id: 3541109,  name: "Presidente Alves"},
  {stateId: 35,  id: 3541208,  name: "Presidente Bernardes"},
  {stateId: 35,  id: 3541307,  name: "Presidente Epitácio"},
  {stateId: 35,  id: 3541406,  name: "Presidente Prudente"},
  {stateId: 35,  id: 3541505,  name: "Presidente Venceslau"},
  {stateId: 35,  id: 3541604,  name: "Promissão"},
  {stateId: 35,  id: 3541653,  name: "Quadra"},
  {stateId: 35,  id: 3541703,  name: "Quatá"},
  {stateId: 35,  id: 3541802,  name: "Queiroz"},
  {stateId: 35,  id: 3541901,  name: "Queluz"},
  {stateId: 35,  id: 3542008,  name: "Quintana"},
  {stateId: 35,  id: 3542107,  name: "Rafard"},
  {stateId: 35,  id: 3542206,  name: "Rancharia"},
  {stateId: 35,  id: 3542305,  name: "Redenção da Serra"},
  {stateId: 35,  id: 3542404,  name: "Regente Feijó"},
  {stateId: 35,  id: 3542503,  name: "Reginópolis"},
  {stateId: 35,  id: 3542602,  name: "Registro"},
  {stateId: 35,  id: 3542701,  name: "Restinga"},
  {stateId: 35,  id: 3542800,  name: "Ribeira"},
  {stateId: 35,  id: 3542909,  name: "Ribeirão Bonito"},
  {stateId: 35,  id: 3543006,  name: "Ribeirão Branco"},
  {stateId: 35,  id: 3543105,  name: "Ribeirão Corrente"},
  {stateId: 35,  id: 3543204,  name: "Ribeirão do Sul"},
  {stateId: 35,  id: 3543238,  name: "Ribeirão dos Índios"},
  {stateId: 35,  id: 3543253,  name: "Ribeirão Grande"},
  {stateId: 35,  id: 3543303,  name: "Ribeirão Pires"},
  {stateId: 35,  id: 3543402,  name: "Ribeirão Preto"},
  {stateId: 35,  id: 3543600,  name: "Rifaina"},
  {stateId: 35,  id: 3543709,  name: "Rincão"},
  {stateId: 35,  id: 3543808,  name: "Rinópolis"},
  {stateId: 35,  id: 3543907,  name: "Rio Claro"},
  {stateId: 35,  id: 3544004,  name: "Rio das Pedras"},
  {stateId: 35,  id: 3544103,  name: "Rio Grande da Serra"},
  {stateId: 35,  id: 3544202,  name: "Riolândia"},
  {stateId: 35,  id: 3543501,  name: "Riversul"},
  {stateId: 35,  id: 3544251,  name: "Rosana"},
  {stateId: 35,  id: 3544301,  name: "Roseira"},
  {stateId: 35,  id: 3544400,  name: "Rubiácea"},
  {stateId: 35,  id: 3544509,  name: "Rubinéia"},
  {stateId: 35,  id: 3544608,  name: "Sabino"},
  {stateId: 35,  id: 3544707,  name: "Sagres"},
  {stateId: 35,  id: 3544806,  name: "Sales"},
  {stateId: 35,  id: 3544905,  name: "Sales Oliveira"},
  {stateId: 35,  id: 3545001,  name: "Salesópolis"},
  {stateId: 35,  id: 3545100,  name: "Salmourão"},
  {stateId: 35,  id: 3545159,  name: "Saltinho"},
  {stateId: 35,  id: 3545209,  name: "Salto"},
  {stateId: 35,  id: 3545308,  name: "Salto de Pirapora"},
  {stateId: 35,  id: 3545407,  name: "Salto Grande"},
  {stateId: 35,  id: 3545506,  name: "Sandovalina"},
  {stateId: 35,  id: 3545605,  name: "Santa Adélia"},
  {stateId: 35,  id: 3545704,  name: "Santa Albertina"},
  {stateId: 35,  id: 3545803,  name: "Santa Bárbara d'Oeste"},
  {stateId: 35,  id: 3546009,  name: "Santa Branca"},
  {stateId: 35,  id: 3546108,  name: "Santa Clara d'Oeste"},
  {stateId: 35,  id: 3546207,  name: "Santa Cruz da Conceição"},
  {stateId: 35,  id: 3546256,  name: "Santa Cruz da Esperança"},
  {stateId: 35,  id: 3546306,  name: "Santa Cruz das Palmeiras"},
  {stateId: 35,  id: 3546405,  name: "Santa Cruz do Rio Pardo"},
  {stateId: 35,  id: 3546504,  name: "Santa Ernestina"},
  {stateId: 35,  id: 3546603,  name: "Santa Fé do Sul"},
  {stateId: 35,  id: 3546702,  name: "Santa Gertrudes"},
  {stateId: 35,  id: 3546801,  name: "Santa Isabel"},
  {stateId: 35,  id: 3546900,  name: "Santa Lúcia"},
  {stateId: 35,  id: 3547007,  name: "Santa Maria da Serra"},
  {stateId: 35,  id: 3547106,  name: "Santa Mercedes"},
  {stateId: 35,  id: 3547502,  name: "Santa Rita do Passa Quatro"},
  {stateId: 35,  id: 3547403,  name: "Santa Rita d'Oeste"},
  {stateId: 35,  id: 3547601,  name: "Santa Rosa de Viterbo"},
  {stateId: 35,  id: 3547650,  name: "Santa Salete"},
  {stateId: 35,  id: 3547205,  name: "Santana da Ponte Pensa"},
  {stateId: 35,  id: 3547304,  name: "Santana de Parnaíba"},
  {stateId: 35,  id: 3547700,  name: "Santo Anastácio"},
  {stateId: 35,  id: 3547809,  name: "Santo André"},
  {stateId: 35,  id: 3547908,  name: "Santo Antônio da Alegria"},
  {stateId: 35,  id: 3548005,  name: "Santo Antônio de Posse"},
  {stateId: 35,  id: 3548054,  name: "Santo Antônio do Aracanguá"},
  {stateId: 35,  id: 3548104,  name: "Santo Antônio do Jardim"},
  {stateId: 35,  id: 3548203,  name: "Santo Antônio do Pinhal"},
  {stateId: 35,  id: 3548302,  name: "Santo Expedito"},
  {stateId: 35,  id: 3548401,  name: "Santópolis do Aguapeí"},
  {stateId: 35,  id: 3548500,  name: "Santos"},
  {stateId: 35,  id: 3548609,  name: "São Bento do Sapucaí"},
  {stateId: 35,  id: 3548708,  name: "São Bernardo do Campo"},
  {stateId: 35,  id: 3548807,  name: "São Caetano do Sul"},
  {stateId: 35,  id: 3548906,  name: "São Carlos"},
  {stateId: 35,  id: 3549003,  name: "São Francisco"},
  {stateId: 35,  id: 3549102,  name: "São João da Boa Vista"},
  {stateId: 35,  id: 3549201,  name: "São João das Duas Pontes"},
  {stateId: 35,  id: 3549250,  name: "São João de Iracema"},
  {stateId: 35,  id: 3549300,  name: "São João do Pau d'Alho"},
  {stateId: 35,  id: 3549409,  name: "São Joaquim da Barra"},
  {stateId: 35,  id: 3549508,  name: "São José da Bela Vista"},
  {stateId: 35,  id: 3549607,  name: "São José do Barreiro"},
  {stateId: 35,  id: 3549706,  name: "São José do Rio Pardo"},
  {stateId: 35,  id: 3549805,  name: "São José do Rio Preto"},
  {stateId: 35,  id: 3549904,  name: "São José dos Campos"},
  {stateId: 35,  id: 3549953,  name: "São Lourenço da Serra"},
  {stateId: 35,  id: 3550001,  name: "São Luiz do Paraitinga"},
  {stateId: 35,  id: 3550100,  name: "São Manuel"},
  {stateId: 35,  id: 3550209,  name: "São Miguel Arcanjo"},
  {stateId: 35,  id: 3550308,  name: "São Paulo"},
  {stateId: 35,  id: 3550407,  name: "São Pedro"},
  {stateId: 35,  id: 3550506,  name: "São Pedro do Turvo"},
  {stateId: 35,  id: 3550605,  name: "São Roque"},
  {stateId: 35,  id: 3550704,  name: "São Sebastião"},
  {stateId: 35,  id: 3550803,  name: "São Sebastião da Grama"},
  {stateId: 35,  id: 3550902,  name: "São Simão"},
  {stateId: 35,  id: 3551009,  name: "São Vicente"},
  {stateId: 35,  id: 3551108,  name: "Sarapuí"},
  {stateId: 35,  id: 3551207,  name: "Sarutaiá"},
  {stateId: 35,  id: 3551306,  name: "Sebastianópolis do Sul"},
  {stateId: 35,  id: 3551405,  name: "Serra Azul"},
  {stateId: 35,  id: 3551603,  name: "Serra Negra"},
  {stateId: 35,  id: 3551504,  name: "Serrana"},
  {stateId: 35,  id: 3551702,  name: "Sertãozinho"},
  {stateId: 35,  id: 3551801,  name: "Sete Barras"},
  {stateId: 35,  id: 3551900,  name: "Severínia"},
  {stateId: 35,  id: 3552007,  name: "Silveiras"},
  {stateId: 35,  id: 3552106,  name: "Socorro"},
  {stateId: 35,  id: 3552205,  name: "Sorocaba"},
  {stateId: 35,  id: 3552304,  name: "Sud Mennucci"},
  {stateId: 35,  id: 3552403,  name: "Sumaré"},
  {stateId: 35,  id: 3552551,  name: "Suzanápolis"},
  {stateId: 35,  id: 3552502,  name: "Suzano"},
  {stateId: 35,  id: 3552601,  name: "Tabapuã"},
  {stateId: 35,  id: 3552700,  name: "Tabatinga"},
  {stateId: 35,  id: 3552809,  name: "Taboão da Serra"},
  {stateId: 35,  id: 3552908,  name: "Taciba"},
  {stateId: 35,  id: 3553005,  name: "Taguaí"},
  {stateId: 35,  id: 3553104,  name: "Taiaçu"},
  {stateId: 35,  id: 3553203,  name: "Taiúva"},
  {stateId: 35,  id: 3553302,  name: "Tambaú"},
  {stateId: 35,  id: 3553401,  name: "Tanabi"},
  {stateId: 35,  id: 3553500,  name: "Tapiraí"},
  {stateId: 35,  id: 3553609,  name: "Tapiratiba"},
  {stateId: 35,  id: 3553658,  name: "Taquaral"},
  {stateId: 35,  id: 3553708,  name: "Taquaritinga"},
  {stateId: 35,  id: 3553807,  name: "Taquarituba"},
  {stateId: 35,  id: 3553856,  name: "Taquarivaí"},
  {stateId: 35,  id: 3553906,  name: "Tarabai"},
  {stateId: 35,  id: 3553955,  name: "Tarumã"},
  {stateId: 35,  id: 3554003,  name: "Tatuí"},
  {stateId: 35,  id: 3554102,  name: "Taubaté"},
  {stateId: 35,  id: 3554201,  name: "Tejupá"},
  {stateId: 35,  id: 3554300,  name: "Teodoro Sampaio"},
  {stateId: 35,  id: 3554409,  name: "Terra Roxa"},
  {stateId: 35,  id: 3554508,  name: "Tietê"},
  {stateId: 35,  id: 3554607,  name: "Timburi"},
  {stateId: 35,  id: 3554656,  name: "Torre de Pedra"},
  {stateId: 35,  id: 3554706,  name: "Torrinha"},
  {stateId: 35,  id: 3554755,  name: "Trabiju"},
  {stateId: 35,  id: 3554805,  name: "Tremembé"},
  {stateId: 35,  id: 3554904,  name: "Três Fronteiras"},
  {stateId: 35,  id: 3554953,  name: "Tuiuti"},
  {stateId: 35,  id: 3555000,  name: "Tupã"},
  {stateId: 35,  id: 3555109,  name: "Tupi Paulista"},
  {stateId: 35,  id: 3555208,  name: "Turiúba"},
  {stateId: 35,  id: 3555307,  name: "Turmalina"},
  {stateId: 35,  id: 3555356,  name: "Ubarana"},
  {stateId: 35,  id: 3555406,  name: "Ubatuba"},
  {stateId: 35,  id: 3555505,  name: "Ubirajara"},
  {stateId: 35,  id: 3555604,  name: "Uchoa"},
  {stateId: 35,  id: 3555703,  name: "União Paulista"},
  {stateId: 35,  id: 3555802,  name: "Urânia"},
  {stateId: 35,  id: 3555901,  name: "Uru"},
  {stateId: 35,  id: 3556008,  name: "Urupês"},
  {stateId: 35,  id: 3556107,  name: "Valentim Gentil"},
  {stateId: 35,  id: 3556206,  name: "Valinhos"},
  {stateId: 35,  id: 3556305,  name: "Valparaíso"},
  {stateId: 35,  id: 3556354,  name: "Vargem"},
  {stateId: 35,  id: 3556404,  name: "Vargem Grande do Sul"},
  {stateId: 35,  id: 3556453,  name: "Vargem Grande Paulista"},
  {stateId: 35,  id: 3556503,  name: "Várzea Paulista"},
  {stateId: 35,  id: 3556602,  name: "Vera Cruz"},
  {stateId: 35,  id: 3556701,  name: "Vinhedo"},
  {stateId: 35,  id: 3556800,  name: "Viradouro"},
  {stateId: 35,  id: 3556909,  name: "Vista Alegre do Alto"},
  {stateId: 35,  id: 3556958,  name: "Vitória Brasil"},
  {stateId: 35,  id: 3557006,  name: "Votorantim"},
  {stateId: 35,  id: 3557105,  name: "Votuporanga"},
  {stateId: 35,  id: 3557154,  name: "Zacarias"},
  {stateId: 41,  id: 4100103,  name: "Abatiá"},
  {stateId: 41,  id: 4100202,  name: "Adrianópolis"},
  {stateId: 41,  id: 4100301,  name: "Agudos do Sul"},
  {stateId: 41,  id: 4100400,  name: "Almirante Tamandaré"},
  {stateId: 41,  id: 4100459,  name: "Altamira do Paraná"},
  {stateId: 41,  id: 4128625,  name: "Alto Paraíso"},
  {stateId: 41,  id: 4100608,  name: "Alto Paraná"},
  {stateId: 41,  id: 4100707,  name: "Alto Piquiri"},
  {stateId: 41,  id: 4100509,  name: "Altônia"},
  {stateId: 41,  id: 4100806,  name: "Alvorada do Sul"},
  {stateId: 41,  id: 4100905,  name: "Amaporã"},
  {stateId: 41,  id: 4101002,  name: "Ampére"},
  {stateId: 41,  id: 4101051,  name: "Anahy"},
  {stateId: 41,  id: 4101101,  name: "Andirá"},
  {stateId: 41,  id: 4101150,  name: "Ângulo"},
  {stateId: 41,  id: 4101200,  name: "Antonina"},
  {stateId: 41,  id: 4101309,  name: "Antônio Olinto"},
  {stateId: 41,  id: 4101408,  name: "Apucarana"},
  {stateId: 41,  id: 4101507,  name: "Arapongas"},
  {stateId: 41,  id: 4101606,  name: "Arapoti"},
  {stateId: 41,  id: 4101655,  name: "Arapuã"},
  {stateId: 41,  id: 4101705,  name: "Araruna"},
  {stateId: 41,  id: 4101804,  name: "Araucária"},
  {stateId: 41,  id: 4101853,  name: "Ariranha do Ivaí"},
  {stateId: 41,  id: 4101903,  name: "Assaí"},
  {stateId: 41,  id: 4102000,  name: "Assis Chateaubriand"},
  {stateId: 41,  id: 4102109,  name: "Astorga"},
  {stateId: 41,  id: 4102208,  name: "Atalaia"},
  {stateId: 41,  id: 4102307,  name: "Balsa Nova"},
  {stateId: 41,  id: 4102406,  name: "Bandeirantes"},
  {stateId: 41,  id: 4102505,  name: "Barbosa Ferraz"},
  {stateId: 41,  id: 4102703,  name: "Barra do Jacaré"},
  {stateId: 41,  id: 4102604,  name: "Barracão"},
  {stateId: 41,  id: 4102752,  name: "Bela Vista da Caroba"},
  {stateId: 41,  id: 4102802,  name: "Bela Vista do Paraíso"},
  {stateId: 41,  id: 4102901,  name: "Bituruna"},
  {stateId: 41,  id: 4103008,  name: "Boa Esperança"},
  {stateId: 41,  id: 4103024,  name: "Boa Esperança do Iguaçu"},
  {stateId: 41,  id: 4103040,  name: "Boa Ventura de São Roque"},
  {stateId: 41,  id: 4103057,  name: "Boa Vista da Aparecida"},
  {stateId: 41,  id: 4103107,  name: "Bocaiúva do Sul"},
  {stateId: 41,  id: 4103156,  name: "Bom Jesus do Sul"},
  {stateId: 41,  id: 4103206,  name: "Bom Sucesso"},
  {stateId: 41,  id: 4103222,  name: "Bom Sucesso do Sul"},
  {stateId: 41,  id: 4103305,  name: "Borrazópolis"},
  {stateId: 41,  id: 4103354,  name: "Braganey"},
  {stateId: 41,  id: 4103370,  name: "Brasilândia do Sul"},
  {stateId: 41,  id: 4103404,  name: "Cafeara"},
  {stateId: 41,  id: 4103453,  name: "Cafelândia"},
  {stateId: 41,  id: 4103479,  name: "Cafezal do Sul"},
  {stateId: 41,  id: 4103503,  name: "Califórnia"},
  {stateId: 41,  id: 4103602,  name: "Cambará"},
  {stateId: 41,  id: 4103701,  name: "Cambé"},
  {stateId: 41,  id: 4103800,  name: "Cambira"},
  {stateId: 41,  id: 4103909,  name: "Campina da Lagoa"},
  {stateId: 41,  id: 4103958,  name: "Campina do Simão"},
  {stateId: 41,  id: 4104006,  name: "Campina Grande do Sul"},
  {stateId: 41,  id: 4104055,  name: "Campo Bonito"},
  {stateId: 41,  id: 4104105,  name: "Campo do Tenente"},
  {stateId: 41,  id: 4104204,  name: "Campo Largo"},
  {stateId: 41,  id: 4104253,  name: "Campo Magro"},
  {stateId: 41,  id: 4104303,  name: "Campo Mourão"},
  {stateId: 41,  id: 4104402,  name: "Cândido de Abreu"},
  {stateId: 41,  id: 4104428,  name: "Candói"},
  {stateId: 41,  id: 4104451,  name: "Cantagalo"},
  {stateId: 41,  id: 4104501,  name: "Capanema"},
  {stateId: 41,  id: 4104600,  name: "Capitão Leônidas Marques"},
  {stateId: 41,  id: 4104659,  name: "Carambeí"},
  {stateId: 41,  id: 4104709,  name: "Carlópolis"},
  {stateId: 41,  id: 4104808,  name: "Cascavel"},
  {stateId: 41,  id: 4104907,  name: "Castro"},
  {stateId: 41,  id: 4105003,  name: "Catanduvas"},
  {stateId: 41,  id: 4105102,  name: "Centenário do Sul"},
  {stateId: 41,  id: 4105201,  name: "Cerro Azul"},
  {stateId: 41,  id: 4105300,  name: "Céu Azul"},
  {stateId: 41,  id: 4105409,  name: "Chopinzinho"},
  {stateId: 41,  id: 4105508,  name: "Cianorte"},
  {stateId: 41,  id: 4105607,  name: "Cidade Gaúcha"},
  {stateId: 41,  id: 4105706,  name: "Clevelândia"},
  {stateId: 41,  id: 4105805,  name: "Colombo"},
  {stateId: 41,  id: 4105904,  name: "Colorado"},
  {stateId: 41,  id: 4106001,  name: "Congonhinhas"},
  {stateId: 41,  id: 4106100,  name: "Conselheiro Mairinck"},
  {stateId: 41,  id: 4106209,  name: "Contenda"},
  {stateId: 41,  id: 4106308,  name: "Corbélia"},
  {stateId: 41,  id: 4106407,  name: "Cornélio Procópio"},
  {stateId: 41,  id: 4106456,  name: "Coronel Domingos Soares"},
  {stateId: 41,  id: 4106506,  name: "Coronel Vivida"},
  {stateId: 41,  id: 4106555,  name: "Corumbataí do Sul"},
  {stateId: 41,  id: 4106803,  name: "Cruz Machado"},
  {stateId: 41,  id: 4106571,  name: "Cruzeiro do Iguaçu"},
  {stateId: 41,  id: 4106605,  name: "Cruzeiro do Oeste"},
  {stateId: 41,  id: 4106704,  name: "Cruzeiro do Sul"},
  {stateId: 41,  id: 4106852,  name: "Cruzmaltina"},
  {stateId: 41,  id: 4106902,  name: "Curitiba"},
  {stateId: 41,  id: 4107009,  name: "Curiúva"},
  {stateId: 41,  id: 4107108,  name: "Diamante do Norte"},
  {stateId: 41,  id: 4107124,  name: "Diamante do Sul"},
  {stateId: 41,  id: 4107157,  name: "Diamante D'Oeste"},
  {stateId: 41,  id: 4107207,  name: "Dois Vizinhos"},
  {stateId: 41,  id: 4107256,  name: "Douradina"},
  {stateId: 41,  id: 4107306,  name: "Doutor Camargo"},
  {stateId: 41,  id: 4128633,  name: "Doutor Ulysses"},
  {stateId: 41,  id: 4107405,  name: "Enéas Marques"},
  {stateId: 41,  id: 4107504,  name: "Engenheiro Beltrão"},
  {stateId: 41,  id: 4107538,  name: "Entre Rios do Oeste"},
  {stateId: 41,  id: 4107520,  name: "Esperança Nova"},
  {stateId: 41,  id: 4107546,  name: "Espigão Alto do Iguaçu"},
  {stateId: 41,  id: 4107553,  name: "Farol"},
  {stateId: 41,  id: 4107603,  name: "Faxinal"},
  {stateId: 41,  id: 4107652,  name: "Fazenda Rio Grande"},
  {stateId: 41,  id: 4107702,  name: "Fênix"},
  {stateId: 41,  id: 4107736,  name: "Fernandes Pinheiro"},
  {stateId: 41,  id: 4107751,  name: "Figueira"},
  {stateId: 41,  id: 4107850,  name: "Flor da Serra do Sul"},
  {stateId: 41,  id: 4107801,  name: "Floraí"},
  {stateId: 41,  id: 4107900,  name: "Floresta"},
  {stateId: 41,  id: 4108007,  name: "Florestópolis"},
  {stateId: 41,  id: 4108106,  name: "Flórida"},
  {stateId: 41,  id: 4108205,  name: "Formosa do Oeste"},
  {stateId: 41,  id: 4108304,  name: "Foz do Iguaçu"},
  {stateId: 41,  id: 4108452,  name: "Foz do Jordão"},
  {stateId: 41,  id: 4108320,  name: "Francisco Alves"},
  {stateId: 41,  id: 4108403,  name: "Francisco Beltrão"},
  {stateId: 41,  id: 4108502,  name: "General Carneiro"},
  {stateId: 41,  id: 4108551,  name: "Godoy Moreira"},
  {stateId: 41,  id: 4108601,  name: "Goioerê"},
  {stateId: 41,  id: 4108650,  name: "Goioxim"},
  {stateId: 41,  id: 4108700,  name: "Grandes Rios"},
  {stateId: 41,  id: 4108809,  name: "Guaíra"},
  {stateId: 41,  id: 4108908,  name: "Guairaçá"},
  {stateId: 41,  id: 4108957,  name: "Guamiranga"},
  {stateId: 41,  id: 4109005,  name: "Guapirama"},
  {stateId: 41,  id: 4109104,  name: "Guaporema"},
  {stateId: 41,  id: 4109203,  name: "Guaraci"},
  {stateId: 41,  id: 4109302,  name: "Guaraniaçu"},
  {stateId: 41,  id: 4109401,  name: "Guarapuava"},
  {stateId: 41,  id: 4109500,  name: "Guaraqueçaba"},
  {stateId: 41,  id: 4109609,  name: "Guaratuba"},
  {stateId: 41,  id: 4109658,  name: "Honório Serpa"},
  {stateId: 41,  id: 4109708,  name: "Ibaiti"},
  {stateId: 41,  id: 4109757,  name: "Ibema"},
  {stateId: 41,  id: 4109807,  name: "Ibiporã"},
  {stateId: 41,  id: 4109906,  name: "Icaraíma"},
  {stateId: 41,  id: 4110003,  name: "Iguaraçu"},
  {stateId: 41,  id: 4110052,  name: "Iguatu"},
  {stateId: 41,  id: 4110078,  name: "Imbaú"},
  {stateId: 41,  id: 4110102,  name: "Imbituva"},
  {stateId: 41,  id: 4110201,  name: "Inácio Martins"},
  {stateId: 41,  id: 4110300,  name: "Inajá"},
  {stateId: 41,  id: 4110409,  name: "Indianópolis"},
  {stateId: 41,  id: 4110508,  name: "Ipiranga"},
  {stateId: 41,  id: 4110607,  name: "Iporã"},
  {stateId: 41,  id: 4110656,  name: "Iracema do Oeste"},
  {stateId: 41,  id: 4110706,  name: "Irati"},
  {stateId: 41,  id: 4110805,  name: "Iretama"},
  {stateId: 41,  id: 4110904,  name: "Itaguajé"},
  {stateId: 41,  id: 4110953,  name: "Itaipulândia"},
  {stateId: 41,  id: 4111001,  name: "Itambaracá"},
  {stateId: 41,  id: 4111100,  name: "Itambé"},
  {stateId: 41,  id: 4111209,  name: "Itapejara d'Oeste"},
  {stateId: 41,  id: 4111258,  name: "Itaperuçu"},
  {stateId: 41,  id: 4111308,  name: "Itaúna do Sul"},
  {stateId: 41,  id: 4111407,  name: "Ivaí"},
  {stateId: 41,  id: 4111506,  name: "Ivaiporã"},
  {stateId: 41,  id: 4111555,  name: "Ivaté"},
  {stateId: 41,  id: 4111605,  name: "Ivatuba"},
  {stateId: 41,  id: 4111704,  name: "Jaboti"},
  {stateId: 41,  id: 4111803,  name: "Jacarezinho"},
  {stateId: 41,  id: 4111902,  name: "Jaguapitã"},
  {stateId: 41,  id: 4112009,  name: "Jaguariaíva"},
  {stateId: 41,  id: 4112108,  name: "Jandaia do Sul"},
  {stateId: 41,  id: 4112207,  name: "Janiópolis"},
  {stateId: 41,  id: 4112306,  name: "Japira"},
  {stateId: 41,  id: 4112405,  name: "Japurá"},
  {stateId: 41,  id: 4112504,  name: "Jardim Alegre"},
  {stateId: 41,  id: 4112603,  name: "Jardim Olinda"},
  {stateId: 41,  id: 4112702,  name: "Jataizinho"},
  {stateId: 41,  id: 4112751,  name: "Jesuítas"},
  {stateId: 41,  id: 4112801,  name: "Joaquim Távora"},
  {stateId: 41,  id: 4112900,  name: "Jundiaí do Sul"},
  {stateId: 41,  id: 4112959,  name: "Juranda"},
  {stateId: 41,  id: 4113007,  name: "Jussara"},
  {stateId: 41,  id: 4113106,  name: "Kaloré"},
  {stateId: 41,  id: 4113205,  name: "Lapa"},
  {stateId: 41,  id: 4113254,  name: "Laranjal"},
  {stateId: 41,  id: 4113304,  name: "Laranjeiras do Sul"},
  {stateId: 41,  id: 4113403,  name: "Leópolis"},
  {stateId: 41,  id: 4113429,  name: "Lidianópolis"},
  {stateId: 41,  id: 4113452,  name: "Lindoeste"},
  {stateId: 41,  id: 4113502,  name: "Loanda"},
  {stateId: 41,  id: 4113601,  name: "Lobato"},
  {stateId: 41,  id: 4113700,  name: "Londrina"},
  {stateId: 41,  id: 4113734,  name: "Luiziana"},
  {stateId: 41,  id: 4113759,  name: "Lunardelli"},
  {stateId: 41,  id: 4113809,  name: "Lupionópolis"},
  {stateId: 41,  id: 4113908,  name: "Mallet"},
  {stateId: 41,  id: 4114005,  name: "Mamborê"},
  {stateId: 41,  id: 4114104,  name: "Mandaguaçu"},
  {stateId: 41,  id: 4114203,  name: "Mandaguari"},
  {stateId: 41,  id: 4114302,  name: "Mandirituba"},
  {stateId: 41,  id: 4114351,  name: "Manfrinópolis"},
  {stateId: 41,  id: 4114401,  name: "Mangueirinha"},
  {stateId: 41,  id: 4114500,  name: "Manoel Ribas"},
  {stateId: 41,  id: 4114609,  name: "Marechal Cândido Rondon"},
  {stateId: 41,  id: 4114708,  name: "Maria Helena"},
  {stateId: 41,  id: 4114807,  name: "Marialva"},
  {stateId: 41,  id: 4114906,  name: "Marilândia do Sul"},
  {stateId: 41,  id: 4115002,  name: "Marilena"},
  {stateId: 41,  id: 4115101,  name: "Mariluz"},
  {stateId: 41,  id: 4115200,  name: "Maringá"},
  {stateId: 41,  id: 4115309,  name: "Mariópolis"},
  {stateId: 41,  id: 4115358,  name: "Maripá"},
  {stateId: 41,  id: 4115408,  name: "Marmeleiro"},
  {stateId: 41,  id: 4115457,  name: "Marquinho"},
  {stateId: 41,  id: 4115507,  name: "Marumbi"},
  {stateId: 41,  id: 4115606,  name: "Matelândia"},
  {stateId: 41,  id: 4115705,  name: "Matinhos"},
  {stateId: 41,  id: 4115739,  name: "Mato Rico"},
  {stateId: 41,  id: 4115754,  name: "Mauá da Serra"},
  {stateId: 41,  id: 4115804,  name: "Medianeira"},
  {stateId: 41,  id: 4115853,  name: "Mercedes"},
  {stateId: 41,  id: 4115903,  name: "Mirador"},
  {stateId: 41,  id: 4116000,  name: "Miraselva"},
  {stateId: 41,  id: 4116059,  name: "Missal"},
  {stateId: 41,  id: 4116109,  name: "Moreira Sales"},
  {stateId: 41,  id: 4116208,  name: "Morretes"},
  {stateId: 41,  id: 4116307,  name: "Munhoz de Melo"},
  {stateId: 41,  id: 4116406,  name: "Nossa Senhora das Graças"},
  {stateId: 41,  id: 4116505,  name: "Nova Aliança do Ivaí"},
  {stateId: 41,  id: 4116604,  name: "Nova América da Colina"},
  {stateId: 41,  id: 4116703,  name: "Nova Aurora"},
  {stateId: 41,  id: 4116802,  name: "Nova Cantu"},
  {stateId: 41,  id: 4116901,  name: "Nova Esperança"},
  {stateId: 41,  id: 4116950,  name: "Nova Esperança do Sudoeste"},
  {stateId: 41,  id: 4117008,  name: "Nova Fátima"},
  {stateId: 41,  id: 4117057,  name: "Nova Laranjeiras"},
  {stateId: 41,  id: 4117107,  name: "Nova Londrina"},
  {stateId: 41,  id: 4117206,  name: "Nova Olímpia"},
  {stateId: 41,  id: 4117255,  name: "Nova Prata do Iguaçu"},
  {stateId: 41,  id: 4117214,  name: "Nova Santa Bárbara"},
  {stateId: 41,  id: 4117222,  name: "Nova Santa Rosa"},
  {stateId: 41,  id: 4117271,  name: "Nova Tebas"},
  {stateId: 41,  id: 4117297,  name: "Novo Itacolomi"},
  {stateId: 41,  id: 4117305,  name: "Ortigueira"},
  {stateId: 41,  id: 4117404,  name: "Ourizona"},
  {stateId: 41,  id: 4117453,  name: "Ouro Verde do Oeste"},
  {stateId: 41,  id: 4117503,  name: "Paiçandu"},
  {stateId: 41,  id: 4117602,  name: "Palmas"},
  {stateId: 41,  id: 4117701,  name: "Palmeira"},
  {stateId: 41,  id: 4117800,  name: "Palmital"},
  {stateId: 41,  id: 4117909,  name: "Palotina"},
  {stateId: 41,  id: 4118006,  name: "Paraíso do Norte"},
  {stateId: 41,  id: 4118105,  name: "Paranacity"},
  {stateId: 41,  id: 4118204,  name: "Paranaguá"},
  {stateId: 41,  id: 4118303,  name: "Paranapoema"},
  {stateId: 41,  id: 4118402,  name: "Paranavaí"},
  {stateId: 41,  id: 4118451,  name: "Pato Bragado"},
  {stateId: 41,  id: 4118501,  name: "Pato Branco"},
  {stateId: 41,  id: 4118600,  name: "Paula Freitas"},
  {stateId: 41,  id: 4118709,  name: "Paulo Frontin"},
  {stateId: 41,  id: 4118808,  name: "Peabiru"},
  {stateId: 41,  id: 4118857,  name: "Perobal"},
  {stateId: 41,  id: 4118907,  name: "Pérola"},
  {stateId: 41,  id: 4119004,  name: "Pérola d'Oeste"},
  {stateId: 41,  id: 4119103,  name: "Piên"},
  {stateId: 41,  id: 4119152,  name: "Pinhais"},
  {stateId: 41,  id: 4119251,  name: "Pinhal de São Bento"},
  {stateId: 41,  id: 4119202,  name: "Pinhalão"},
  {stateId: 41,  id: 4119301,  name: "Pinhão"},
  {stateId: 41,  id: 4119400,  name: "Piraí do Sul"},
  {stateId: 41,  id: 4119509,  name: "Piraquara"},
  {stateId: 41,  id: 4119608,  name: "Pitanga"},
  {stateId: 41,  id: 4119657,  name: "Pitangueiras"},
  {stateId: 41,  id: 4119707,  name: "Planaltina do Paraná"},
  {stateId: 41,  id: 4119806,  name: "Planalto"},
  {stateId: 41,  id: 4119905,  name: "Ponta Grossa"},
  {stateId: 41,  id: 4119954,  name: "Pontal do Paraná"},
  {stateId: 41,  id: 4120002,  name: "Porecatu"},
  {stateId: 41,  id: 4120101,  name: "Porto Amazonas"},
  {stateId: 41,  id: 4120150,  name: "Porto Barreiro"},
  {stateId: 41,  id: 4120200,  name: "Porto Rico"},
  {stateId: 41,  id: 4120309,  name: "Porto Vitória"},
  {stateId: 41,  id: 4120333,  name: "Prado Ferreira"},
  {stateId: 41,  id: 4120358,  name: "Pranchita"},
  {stateId: 41,  id: 4120408,  name: "Presidente Castelo Branco"},
  {stateId: 41,  id: 4120507,  name: "Primeiro de Maio"},
  {stateId: 41,  id: 4120606,  name: "Prudentópolis"},
  {stateId: 41,  id: 4120655,  name: "Quarto Centenário"},
  {stateId: 41,  id: 4120705,  name: "Quatiguá"},
  {stateId: 41,  id: 4120804,  name: "Quatro Barras"},
  {stateId: 41,  id: 4120853,  name: "Quatro Pontes"},
  {stateId: 41,  id: 4120903,  name: "Quedas do Iguaçu"},
  {stateId: 41,  id: 4121000,  name: "Querência do Norte"},
  {stateId: 41,  id: 4121109,  name: "Quinta do Sol"},
  {stateId: 41,  id: 4121208,  name: "Quitandinha"},
  {stateId: 41,  id: 4121257,  name: "Ramilândia"},
  {stateId: 41,  id: 4121307,  name: "Rancho Alegre"},
  {stateId: 41,  id: 4121356,  name: "Rancho Alegre D'Oeste"},
  {stateId: 41,  id: 4121406,  name: "Realeza"},
  {stateId: 41,  id: 4121505,  name: "Rebouças"},
  {stateId: 41,  id: 4121604,  name: "Renascença"},
  {stateId: 41,  id: 4121703,  name: "Reserva"},
  {stateId: 41,  id: 4121752,  name: "Reserva do Iguaçu"},
  {stateId: 41,  id: 4121802,  name: "Ribeirão Claro"},
  {stateId: 41,  id: 4121901,  name: "Ribeirão do Pinhal"},
  {stateId: 41,  id: 4122008,  name: "Rio Azul"},
  {stateId: 41,  id: 4122107,  name: "Rio Bom"},
  {stateId: 41,  id: 4122156,  name: "Rio Bonito do Iguaçu"},
  {stateId: 41,  id: 4122172,  name: "Rio Branco do Ivaí"},
  {stateId: 41,  id: 4122206,  name: "Rio Branco do Sul"},
  {stateId: 41,  id: 4122305,  name: "Rio Negro"},
  {stateId: 41,  id: 4122404,  name: "Rolândia"},
  {stateId: 41,  id: 4122503,  name: "Roncador"},
  {stateId: 41,  id: 4122602,  name: "Rondon"},
  {stateId: 41,  id: 4122651,  name: "Rosário do Ivaí"},
  {stateId: 41,  id: 4122701,  name: "Sabáudia"},
  {stateId: 41,  id: 4122800,  name: "Salgado Filho"},
  {stateId: 41,  id: 4122909,  name: "Salto do Itararé"},
  {stateId: 41,  id: 4123006,  name: "Salto do Lontra"},
  {stateId: 41,  id: 4123105,  name: "Santa Amélia"},
  {stateId: 41,  id: 4123204,  name: "Santa Cecília do Pavão"},
  {stateId: 41,  id: 4123303,  name: "Santa Cruz de Monte Castelo"},
  {stateId: 41,  id: 4123402,  name: "Santa Fé"},
  {stateId: 41,  id: 4123501,  name: "Santa Helena"},
  {stateId: 41,  id: 4123600,  name: "Santa Inês"},
  {stateId: 41,  id: 4123709,  name: "Santa Isabel do Ivaí"},
  {stateId: 41,  id: 4123808,  name: "Santa Izabel do Oeste"},
  {stateId: 41,  id: 4123824,  name: "Santa Lúcia"},
  {stateId: 41,  id: 4123857,  name: "Santa Maria do Oeste"},
  {stateId: 41,  id: 4123907,  name: "Santa Mariana"},
  {stateId: 41,  id: 4123956,  name: "Santa Mônica"},
  {stateId: 41,  id: 4124020,  name: "Santa Tereza do Oeste"},
  {stateId: 41,  id: 4124053,  name: "Santa Terezinha de Itaipu"},
  {stateId: 41,  id: 4124004,  name: "Santana do Itararé"},
  {stateId: 41,  id: 4124103,  name: "Santo Antônio da Platina"},
  {stateId: 41,  id: 4124202,  name: "Santo Antônio do Caiuá"},
  {stateId: 41,  id: 4124301,  name: "Santo Antônio do Paraíso"},
  {stateId: 41,  id: 4124400,  name: "Santo Antônio do Sudoeste"},
  {stateId: 41,  id: 4124509,  name: "Santo Inácio"},
  {stateId: 41,  id: 4124608,  name: "São Carlos do Ivaí"},
  {stateId: 41,  id: 4124707,  name: "São Jerônimo da Serra"},
  {stateId: 41,  id: 4124806,  name: "São João"},
  {stateId: 41,  id: 4124905,  name: "São João do Caiuá"},
  {stateId: 41,  id: 4125001,  name: "São João do Ivaí"},
  {stateId: 41,  id: 4125100,  name: "São João do Triunfo"},
  {stateId: 41,  id: 4125308,  name: "São Jorge do Ivaí"},
  {stateId: 41,  id: 4125357,  name: "São Jorge do Patrocínio"},
  {stateId: 41,  id: 4125209,  name: "São Jorge d'Oeste"},
  {stateId: 41,  id: 4125407,  name: "São José da Boa Vista"},
  {stateId: 41,  id: 4125456,  name: "São José das Palmeiras"},
  {stateId: 41,  id: 4125506,  name: "São José dos Pinhais"},
  {stateId: 41,  id: 4125555,  name: "São Manoel do Paraná"},
  {stateId: 41,  id: 4125605,  name: "São Mateus do Sul"},
  {stateId: 41,  id: 4125704,  name: "São Miguel do Iguaçu"},
  {stateId: 41,  id: 4125753,  name: "São Pedro do Iguaçu"},
  {stateId: 41,  id: 4125803,  name: "São Pedro do Ivaí"},
  {stateId: 41,  id: 4125902,  name: "São Pedro do Paraná"},
  {stateId: 41,  id: 4126009,  name: "São Sebastião da Amoreira"},
  {stateId: 41,  id: 4126108,  name: "São Tomé"},
  {stateId: 41,  id: 4126207,  name: "Sapopema"},
  {stateId: 41,  id: 4126256,  name: "Sarandi"},
  {stateId: 41,  id: 4126272,  name: "Saudade do Iguaçu"},
  {stateId: 41,  id: 4126306,  name: "Sengés"},
  {stateId: 41,  id: 4126355,  name: "Serranópolis do Iguaçu"},
  {stateId: 41,  id: 4126405,  name: "Sertaneja"},
  {stateId: 41,  id: 4126504,  name: "Sertanópolis"},
  {stateId: 41,  id: 4126603,  name: "Siqueira Campos"},
  {stateId: 41,  id: 4126652,  name: "Sulina"},
  {stateId: 41,  id: 4126678,  name: "Tamarana"},
  {stateId: 41,  id: 4126702,  name: "Tamboara"},
  {stateId: 41,  id: 4126801,  name: "Tapejara"},
  {stateId: 41,  id: 4126900,  name: "Tapira"},
  {stateId: 41,  id: 4127007,  name: "Teixeira Soares"},
  {stateId: 41,  id: 4127106,  name: "Telêmaco Borba"},
  {stateId: 41,  id: 4127205,  name: "Terra Boa"},
  {stateId: 41,  id: 4127304,  name: "Terra Rica"},
  {stateId: 41,  id: 4127403,  name: "Terra Roxa"},
  {stateId: 41,  id: 4127502,  name: "Tibagi"},
  {stateId: 41,  id: 4127601,  name: "Tijucas do Sul"},
  {stateId: 41,  id: 4127700,  name: "Toledo"},
  {stateId: 41,  id: 4127809,  name: "Tomazina"},
  {stateId: 41,  id: 4127858,  name: "Três Barras do Paraná"},
  {stateId: 41,  id: 4127882,  name: "Tunas do Paraná"},
  {stateId: 41,  id: 4127908,  name: "Tuneiras do Oeste"},
  {stateId: 41,  id: 4127957,  name: "Tupãssi"},
  {stateId: 41,  id: 4127965,  name: "Turvo"},
  {stateId: 41,  id: 4128005,  name: "Ubiratã"},
  {stateId: 41,  id: 4128104,  name: "Umuarama"},
  {stateId: 41,  id: 4128203,  name: "União da Vitória"},
  {stateId: 41,  id: 4128302,  name: "Uniflor"},
  {stateId: 41,  id: 4128401,  name: "Uraí"},
  {stateId: 41,  id: 4128534,  name: "Ventania"},
  {stateId: 41,  id: 4128559,  name: "Vera Cruz do Oeste"},
  {stateId: 41,  id: 4128609,  name: "Verê"},
  {stateId: 41,  id: 4128658,  name: "Virmond"},
  {stateId: 41,  id: 4128708,  name: "Vitorino"},
  {stateId: 41,  id: 4128500,  name: "Wenceslau Braz"},
  {stateId: 41,  id: 4128807,  name: "Xambrê"},
  {stateId: 42,  id: 4200051,  name: "Abdon Batista"},
  {stateId: 42,  id: 4200101,  name: "Abelardo Luz"},
  {stateId: 42,  id: 4200200,  name: "Agrolândia"},
  {stateId: 42,  id: 4200309,  name: "Agronômica"},
  {stateId: 42,  id: 4200408,  name: "Água Doce"},
  {stateId: 42,  id: 4200507,  name: "Águas de Chapecó"},
  {stateId: 42,  id: 4200556,  name: "Águas Frias"},
  {stateId: 42,  id: 4200606,  name: "Águas Mornas"},
  {stateId: 42,  id: 4200705,  name: "Alfredo Wagner"},
  {stateId: 42,  id: 4200754,  name: "Alto Bela Vista"},
  {stateId: 42,  id: 4200804,  name: "Anchieta"},
  {stateId: 42,  id: 4200903,  name: "Angelina"},
  {stateId: 42,  id: 4201000,  name: "Anita Garibaldi"},
  {stateId: 42,  id: 4201109,  name: "Anitápolis"},
  {stateId: 42,  id: 4201208,  name: "Antônio Carlos"},
  {stateId: 42,  id: 4201257,  name: "Apiúna"},
  {stateId: 42,  id: 4201273,  name: "Arabutã"},
  {stateId: 42,  id: 4201307,  name: "Araquari"},
  {stateId: 42,  id: 4201406,  name: "Araranguá"},
  {stateId: 42,  id: 4201505,  name: "Armazém"},
  {stateId: 42,  id: 4201604,  name: "Arroio Trinta"},
  {stateId: 42,  id: 4201653,  name: "Arvoredo"},
  {stateId: 42,  id: 4201703,  name: "Ascurra"},
  {stateId: 42,  id: 4201802,  name: "Atalanta"},
  {stateId: 42,  id: 4201901,  name: "Aurora"},
  {stateId: 42,  id: 4201950,  name: "Balneário Arroio do Silva"},
  {stateId: 42,  id: 4202057,  name: "Balneário Barra do Sul"},
  {stateId: 42,  id: 4202008,  name: "Balneário Camboriú"},
  {stateId: 42,  id: 4202073,  name: "Balneário Gaivota"},
  {stateId: 42,  id: 4212809,  name: "Balneário Piçarras"},
  {stateId: 42,  id: 4220000,  name: "Balneário Rincão"},
  {stateId: 42,  id: 4202081,  name: "Bandeirante"},
  {stateId: 42,  id: 4202099,  name: "Barra Bonita"},
  {stateId: 42,  id: 4202107,  name: "Barra Velha"},
  {stateId: 42,  id: 4202131,  name: "Bela Vista do Toldo"},
  {stateId: 42,  id: 4202156,  name: "Belmonte"},
  {stateId: 42,  id: 4202206,  name: "Benedito Novo"},
  {stateId: 42,  id: 4202305,  name: "Biguaçu"},
  {stateId: 42,  id: 4202404,  name: "Blumenau"},
  {stateId: 42,  id: 4202438,  name: "Bocaina do Sul"},
  {stateId: 42,  id: 4202503,  name: "Bom Jardim da Serra"},
  {stateId: 42,  id: 4202537,  name: "Bom Jesus"},
  {stateId: 42,  id: 4202578,  name: "Bom Jesus do Oeste"},
  {stateId: 42,  id: 4202602,  name: "Bom Retiro"},
  {stateId: 42,  id: 4202453,  name: "Bombinhas"},
  {stateId: 42,  id: 4202701,  name: "Botuverá"},
  {stateId: 42,  id: 4202800,  name: "Braço do Norte"},
  {stateId: 42,  id: 4202859,  name: "Braço do Trombudo"},
  {stateId: 42,  id: 4202875,  name: "Brunópolis"},
  {stateId: 42,  id: 4202909,  name: "Brusque"},
  {stateId: 42,  id: 4203006,  name: "Caçador"},
  {stateId: 42,  id: 4203105,  name: "Caibi"},
  {stateId: 42,  id: 4203154,  name: "Calmon"},
  {stateId: 42,  id: 4203204,  name: "Camboriú"},
  {stateId: 42,  id: 4203303,  name: "Campo Alegre"},
  {stateId: 42,  id: 4203402,  name: "Campo Belo do Sul"},
  {stateId: 42,  id: 4203501,  name: "Campo Erê"},
  {stateId: 42,  id: 4203600,  name: "Campos Novos"},
  {stateId: 42,  id: 4203709,  name: "Canelinha"},
  {stateId: 42,  id: 4203808,  name: "Canoinhas"},
  {stateId: 42,  id: 4203253,  name: "Capão Alto"},
  {stateId: 42,  id: 4203907,  name: "Capinzal"},
  {stateId: 42,  id: 4203956,  name: "Capivari de Baixo"},
  {stateId: 42,  id: 4204004,  name: "Catanduvas"},
  {stateId: 42,  id: 4204103,  name: "Caxambu do Sul"},
  {stateId: 42,  id: 4204152,  name: "Celso Ramos"},
  {stateId: 42,  id: 4204178,  name: "Cerro Negro"},
  {stateId: 42,  id: 4204194,  name: "Chapadão do Lageado"},
  {stateId: 42,  id: 4204202,  name: "Chapecó"},
  {stateId: 42,  id: 4204251,  name: "Cocal do Sul"},
  {stateId: 42,  id: 4204301,  name: "Concórdia"},
  {stateId: 42,  id: 4204350,  name: "Cordilheira Alta"},
  {stateId: 42,  id: 4204400,  name: "Coronel Freitas"},
  {stateId: 42,  id: 4204459,  name: "Coronel Martins"},
  {stateId: 42,  id: 4204558,  name: "Correia Pinto"},
  {stateId: 42,  id: 4204509,  name: "Corupá"},
  {stateId: 42,  id: 4204608,  name: "Criciúma"},
  {stateId: 42,  id: 4204707,  name: "Cunha Porã"},
  {stateId: 42,  id: 4204756,  name: "Cunhataí"},
  {stateId: 42,  id: 4204806,  name: "Curitibanos"},
  {stateId: 42,  id: 4204905,  name: "Descanso"},
  {stateId: 42,  id: 4205001,  name: "Dionísio Cerqueira"},
  {stateId: 42,  id: 4205100,  name: "Dona Emma"},
  {stateId: 42,  id: 4205159,  name: "Doutor Pedrinho"},
  {stateId: 42,  id: 4205175,  name: "Entre Rios"},
  {stateId: 42,  id: 4205191,  name: "Ermo"},
  {stateId: 42,  id: 4205209,  name: "Erval Velho"},
  {stateId: 42,  id: 4205308,  name: "Faxinal dos Guedes"},
  {stateId: 42,  id: 4205357,  name: "Flor do Sertão"},
  {stateId: 42,  id: 4205407,  name: "Florianópolis"},
  {stateId: 42,  id: 4205431,  name: "Formosa do Sul"},
  {stateId: 42,  id: 4205456,  name: "Forquilhinha"},
  {stateId: 42,  id: 4205506,  name: "Fraiburgo"},
  {stateId: 42,  id: 4205555,  name: "Frei Rogério"},
  {stateId: 42,  id: 4205605,  name: "Galvão"},
  {stateId: 42,  id: 4205704,  name: "Garopaba"},
  {stateId: 42,  id: 4205803,  name: "Garuva"},
  {stateId: 42,  id: 4205902,  name: "Gaspar"},
  {stateId: 42,  id: 4206009,  name: "Governador Celso Ramos"},
  {stateId: 42,  id: 4206108,  name: "Grão Pará"},
  {stateId: 42,  id: 4206207,  name: "Gravatal"},
  {stateId: 42,  id: 4206306,  name: "Guabiruba"},
  {stateId: 42,  id: 4206405,  name: "Guaraciaba"},
  {stateId: 42,  id: 4206504,  name: "Guaramirim"},
  {stateId: 42,  id: 4206603,  name: "Guarujá do Sul"},
  {stateId: 42,  id: 4206652,  name: "Guatambú"},
  {stateId: 42,  id: 4206702,  name: "Herval d'Oeste"},
  {stateId: 42,  id: 4206751,  name: "Ibiam"},
  {stateId: 42,  id: 4206801,  name: "Ibicaré"},
  {stateId: 42,  id: 4206900,  name: "Ibirama"},
  {stateId: 42,  id: 4207007,  name: "Içara"},
  {stateId: 42,  id: 4207106,  name: "Ilhota"},
  {stateId: 42,  id: 4207205,  name: "Imaruí"},
  {stateId: 42,  id: 4207304,  name: "Imbituba"},
  {stateId: 42,  id: 4207403,  name: "Imbuia"},
  {stateId: 42,  id: 4207502,  name: "Indaial"},
  {stateId: 42,  id: 4207577,  name: "Iomerê"},
  {stateId: 42,  id: 4207601,  name: "Ipira"},
  {stateId: 42,  id: 4207650,  name: "Iporã do Oeste"},
  {stateId: 42,  id: 4207684,  name: "Ipuaçu"},
  {stateId: 42,  id: 4207700,  name: "Ipumirim"},
  {stateId: 42,  id: 4207759,  name: "Iraceminha"},
  {stateId: 42,  id: 4207809,  name: "Irani"},
  {stateId: 42,  id: 4207858,  name: "Irati"},
  {stateId: 42,  id: 4207908,  name: "Irineópolis"},
  {stateId: 42,  id: 4208005,  name: "Itá"},
  {stateId: 42,  id: 4208104,  name: "Itaiópolis"},
  {stateId: 42,  id: 4208203,  name: "Itajaí"},
  {stateId: 42,  id: 4208302,  name: "Itapema"},
  {stateId: 42,  id: 4208401,  name: "Itapiranga"},
  {stateId: 42,  id: 4208450,  name: "Itapoá"},
  {stateId: 42,  id: 4208500,  name: "Ituporanga"},
  {stateId: 42,  id: 4208609,  name: "Jaborá"},
  {stateId: 42,  id: 4208708,  name: "Jacinto Machado"},
  {stateId: 42,  id: 4208807,  name: "Jaguaruna"},
  {stateId: 42,  id: 4208906,  name: "Jaraguá do Sul"},
  {stateId: 42,  id: 4208955,  name: "Jardinópolis"},
  {stateId: 42,  id: 4209003,  name: "Joaçaba"},
  {stateId: 42,  id: 4209102,  name: "Joinville"},
  {stateId: 42,  id: 4209151,  name: "José Boiteux"},
  {stateId: 42,  id: 4209177,  name: "Jupiá"},
  {stateId: 42,  id: 4209201,  name: "Lacerdópolis"},
  {stateId: 42,  id: 4209300,  name: "Lages"},
  {stateId: 42,  id: 4209409,  name: "Laguna"},
  {stateId: 42,  id: 4209458,  name: "Lajeado Grande"},
  {stateId: 42,  id: 4209508,  name: "Laurentino"},
  {stateId: 42,  id: 4209607,  name: "Lauro Müller"},
  {stateId: 42,  id: 4209706,  name: "Lebon Régis"},
  {stateId: 42,  id: 4209805,  name: "Leoberto Leal"},
  {stateId: 42,  id: 4209854,  name: "Lindóia do Sul"},
  {stateId: 42,  id: 4209904,  name: "Lontras"},
  {stateId: 42,  id: 4210001,  name: "Luiz Alves"},
  {stateId: 42,  id: 4210035,  name: "Luzerna"},
  {stateId: 42,  id: 4210050,  name: "Macieira"},
  {stateId: 42,  id: 4210100,  name: "Mafra"},
  {stateId: 42,  id: 4210209,  name: "Major Gercino"},
  {stateId: 42,  id: 4210308,  name: "Major Vieira"},
  {stateId: 42,  id: 4210407,  name: "Maracajá"},
  {stateId: 42,  id: 4210506,  name: "Maravilha"},
  {stateId: 42,  id: 4210555,  name: "Marema"},
  {stateId: 42,  id: 4210605,  name: "Massaranduba"},
  {stateId: 42,  id: 4210704,  name: "Matos Costa"},
  {stateId: 42,  id: 4210803,  name: "Meleiro"},
  {stateId: 42,  id: 4210852,  name: "Mirim Doce"},
  {stateId: 42,  id: 4210902,  name: "Modelo"},
  {stateId: 42,  id: 4211009,  name: "Mondaí"},
  {stateId: 42,  id: 4211058,  name: "Monte Carlo"},
  {stateId: 42,  id: 4211108,  name: "Monte Castelo"},
  {stateId: 42,  id: 4211207,  name: "Morro da Fumaça"},
  {stateId: 42,  id: 4211256,  name: "Morro Grande"},
  {stateId: 42,  id: 4211306,  name: "Navegantes"},
  {stateId: 42,  id: 4211405,  name: "Nova Erechim"},
  {stateId: 42,  id: 4211454,  name: "Nova Itaberaba"},
  {stateId: 42,  id: 4211504,  name: "Nova Trento"},
  {stateId: 42,  id: 4211603,  name: "Nova Veneza"},
  {stateId: 42,  id: 4211652,  name: "Novo Horizonte"},
  {stateId: 42,  id: 4211702,  name: "Orleans"},
  {stateId: 42,  id: 4211751,  name: "Otacílio Costa"},
  {stateId: 42,  id: 4211801,  name: "Ouro"},
  {stateId: 42,  id: 4211850,  name: "Ouro Verde"},
  {stateId: 42,  id: 4211876,  name: "Paial"},
  {stateId: 42,  id: 4211892,  name: "Painel"},
  {stateId: 42,  id: 4211900,  name: "Palhoça"},
  {stateId: 42,  id: 4212007,  name: "Palma Sola"},
  {stateId: 42,  id: 4212056,  name: "Palmeira"},
  {stateId: 42,  id: 4212106,  name: "Palmitos"},
  {stateId: 42,  id: 4212205,  name: "Papanduva"},
  {stateId: 42,  id: 4212239,  name: "Paraíso"},
  {stateId: 42,  id: 4212254,  name: "Passo de Torres"},
  {stateId: 42,  id: 4212270,  name: "Passos Maia"},
  {stateId: 42,  id: 4212304,  name: "Paulo Lopes"},
  {stateId: 42,  id: 4212403,  name: "Pedras Grandes"},
  {stateId: 42,  id: 4212502,  name: "Penha"},
  {stateId: 42,  id: 4212601,  name: "Peritiba"},
  {stateId: 42,  id: 4212650,  name: "Pescaria Brava"},
  {stateId: 42,  id: 4212700,  name: "Petrolândia"},
  {stateId: 42,  id: 4212908,  name: "Pinhalzinho"},
  {stateId: 42,  id: 4213005,  name: "Pinheiro Preto"},
  {stateId: 42,  id: 4213104,  name: "Piratuba"},
  {stateId: 42,  id: 4213153,  name: "Planalto Alegre"},
  {stateId: 42,  id: 4213203,  name: "Pomerode"},
  {stateId: 42,  id: 4213302,  name: "Ponte Alta"},
  {stateId: 42,  id: 4213351,  name: "Ponte Alta do Norte"},
  {stateId: 42,  id: 4213401,  name: "Ponte Serrada"},
  {stateId: 42,  id: 4213500,  name: "Porto Belo"},
  {stateId: 42,  id: 4213609,  name: "Porto União"},
  {stateId: 42,  id: 4213708,  name: "Pouso Redondo"},
  {stateId: 42,  id: 4213807,  name: "Praia Grande"},
  {stateId: 42,  id: 4213906,  name: "Presidente Castello Branco"},
  {stateId: 42,  id: 4214003,  name: "Presidente Getúlio"},
  {stateId: 42,  id: 4214102,  name: "Presidente Nereu"},
  {stateId: 42,  id: 4214151,  name: "Princesa"},
  {stateId: 42,  id: 4214201,  name: "Quilombo"},
  {stateId: 42,  id: 4214300,  name: "Rancho Queimado"},
  {stateId: 42,  id: 4214409,  name: "Rio das Antas"},
  {stateId: 42,  id: 4214508,  name: "Rio do Campo"},
  {stateId: 42,  id: 4214607,  name: "Rio do Oeste"},
  {stateId: 42,  id: 4214805,  name: "Rio do Sul"},
  {stateId: 42,  id: 4214706,  name: "Rio dos Cedros"},
  {stateId: 42,  id: 4214904,  name: "Rio Fortuna"},
  {stateId: 42,  id: 4215000,  name: "Rio Negrinho"},
  {stateId: 42,  id: 4215059,  name: "Rio Rufino"},
  {stateId: 42,  id: 4215075,  name: "Riqueza"},
  {stateId: 42,  id: 4215109,  name: "Rodeio"},
  {stateId: 42,  id: 4215208,  name: "Romelândia"},
  {stateId: 42,  id: 4215307,  name: "Salete"},
  {stateId: 42,  id: 4215356,  name: "Saltinho"},
  {stateId: 42,  id: 4215406,  name: "Salto Veloso"},
  {stateId: 42,  id: 4215455,  name: "Sangão"},
  {stateId: 42,  id: 4215505,  name: "Santa Cecília"},
  {stateId: 42,  id: 4215554,  name: "Santa Helena"},
  {stateId: 42,  id: 4215604,  name: "Santa Rosa de Lima"},
  {stateId: 42,  id: 4215653,  name: "Santa Rosa do Sul"},
  {stateId: 42,  id: 4215679,  name: "Santa Terezinha"},
  {stateId: 42,  id: 4215687,  name: "Santa Terezinha do Progresso"},
  {stateId: 42,  id: 4215695,  name: "Santiago do Sul"},
  {stateId: 42,  id: 4215703,  name: "Santo Amaro da Imperatriz"},
  {stateId: 42,  id: 4215802,  name: "São Bento do Sul"},
  {stateId: 42,  id: 4215752,  name: "São Bernardino"},
  {stateId: 42,  id: 4215901,  name: "São Bonifácio"},
  {stateId: 42,  id: 4216008,  name: "São Carlos"},
  {stateId: 42,  id: 4216057,  name: "São Cristóvão do Sul"},
  {stateId: 42,  id: 4216107,  name: "São Domingos"},
  {stateId: 42,  id: 4216206,  name: "São Francisco do Sul"},
  {stateId: 42,  id: 4216305,  name: "São João Batista"},
  {stateId: 42,  id: 4216354,  name: "São João do Itaperiú"},
  {stateId: 42,  id: 4216255,  name: "São João do Oeste"},
  {stateId: 42,  id: 4216404,  name: "São João do Sul"},
  {stateId: 42,  id: 4216503,  name: "São Joaquim"},
  {stateId: 42,  id: 4216602,  name: "São José"},
  {stateId: 42,  id: 4216701,  name: "São José do Cedro"},
  {stateId: 42,  id: 4216800,  name: "São José do Cerrito"},
  {stateId: 42,  id: 4216909,  name: "São Lourenço do Oeste"},
  {stateId: 42,  id: 4217006,  name: "São Ludgero"},
  {stateId: 42,  id: 4217105,  name: "São Martinho"},
  {stateId: 42,  id: 4217154,  name: "São Miguel da Boa Vista"},
  {stateId: 42,  id: 4217204,  name: "São Miguel do Oeste"},
  {stateId: 42,  id: 4217253,  name: "São Pedro de Alcântara"},
  {stateId: 42,  id: 4217303,  name: "Saudades"},
  {stateId: 42,  id: 4217402,  name: "Schroeder"},
  {stateId: 42,  id: 4217501,  name: "Seara"},
  {stateId: 42,  id: 4217550,  name: "Serra Alta"},
  {stateId: 42,  id: 4217600,  name: "Siderópolis"},
  {stateId: 42,  id: 4217709,  name: "Sombrio"},
  {stateId: 42,  id: 4217758,  name: "Sul Brasil"},
  {stateId: 42,  id: 4217808,  name: "Taió"},
  {stateId: 42,  id: 4217907,  name: "Tangará"},
  {stateId: 42,  id: 4217956,  name: "Tigrinhos"},
  {stateId: 42,  id: 4218004,  name: "Tijucas"},
  {stateId: 42,  id: 4218103,  name: "Timbé do Sul"},
  {stateId: 42,  id: 4218202,  name: "Timbó"},
  {stateId: 42,  id: 4218251,  name: "Timbó Grande"},
  {stateId: 42,  id: 4218301,  name: "Três Barras"},
  {stateId: 42,  id: 4218350,  name: "Treviso"},
  {stateId: 42,  id: 4218400,  name: "Treze de Maio"},
  {stateId: 42,  id: 4218509,  name: "Treze Tílias"},
  {stateId: 42,  id: 4218608,  name: "Trombudo Central"},
  {stateId: 42,  id: 4218707,  name: "Tubarão"},
  {stateId: 42,  id: 4218756,  name: "Tunápolis"},
  {stateId: 42,  id: 4218806,  name: "Turvo"},
  {stateId: 42,  id: 4218855,  name: "União do Oeste"},
  {stateId: 42,  id: 4218905,  name: "Urubici"},
  {stateId: 42,  id: 4218954,  name: "Urupema"},
  {stateId: 42,  id: 4219002,  name: "Urussanga"},
  {stateId: 42,  id: 4219101,  name: "Vargeão"},
  {stateId: 42,  id: 4219150,  name: "Vargem"},
  {stateId: 42,  id: 4219176,  name: "Vargem Bonita"},
  {stateId: 42,  id: 4219200,  name: "Vidal Ramos"},
  {stateId: 42,  id: 4219309,  name: "Videira"},
  {stateId: 42,  id: 4219358,  name: "Vitor Meireles"},
  {stateId: 42,  id: 4219408,  name: "Witmarsum"},
  {stateId: 42,  id: 4219507,  name: "Xanxerê"},
  {stateId: 42,  id: 4219606,  name: "Xavantina"},
  {stateId: 42,  id: 4219705,  name: "Xaxim"},
  {stateId: 42,  id: 4219853,  name: "Zortéa"},
  {stateId: 43,  id: 4300034,  name: "Aceguá"},
  {stateId: 43,  id: 4300059,  name: "Água Santa"},
  {stateId: 43,  id: 4300109,  name: "Agudo"},
  {stateId: 43,  id: 4300208,  name: "Ajuricaba"},
  {stateId: 43,  id: 4300307,  name: "Alecrim"},
  {stateId: 43,  id: 4300406,  name: "Alegrete"},
  {stateId: 43,  id: 4300455,  name: "Alegria"},
  {stateId: 43,  id: 4300471,  name: "Almirante Tamandaré do Sul"},
  {stateId: 43,  id: 4300505,  name: "Alpestre"},
  {stateId: 43,  id: 4300554,  name: "Alto Alegre"},
  {stateId: 43,  id: 4300570,  name: "Alto Feliz"},
  {stateId: 43,  id: 4300604,  name: "Alvorada"},
  {stateId: 43,  id: 4300638,  name: "Amaral Ferrador"},
  {stateId: 43,  id: 4300646,  name: "Ametista do Sul"},
  {stateId: 43,  id: 4300661,  name: "André da Rocha"},
  {stateId: 43,  id: 4300703,  name: "Anta Gorda"},
  {stateId: 43,  id: 4300802,  name: "Antônio Prado"},
  {stateId: 43,  id: 4300851,  name: "Arambaré"},
  {stateId: 43,  id: 4300877,  name: "Araricá"},
  {stateId: 43,  id: 4300901,  name: "Aratiba"},
  {stateId: 43,  id: 4301008,  name: "Arroio do Meio"},
  {stateId: 43,  id: 4301073,  name: "Arroio do Padre"},
  {stateId: 43,  id: 4301057,  name: "Arroio do Sal"},
  {stateId: 43,  id: 4301206,  name: "Arroio do Tigre"},
  {stateId: 43,  id: 4301107,  name: "Arroio dos Ratos"},
  {stateId: 43,  id: 4301305,  name: "Arroio Grande"},
  {stateId: 43,  id: 4301404,  name: "Arvorezinha"},
  {stateId: 43,  id: 4301503,  name: "Augusto Pestana"},
  {stateId: 43,  id: 4301552,  name: "Áurea"},
  {stateId: 43,  id: 4301602,  name: "Bagé"},
  {stateId: 43,  id: 4301636,  name: "Balneário Pinhal"},
  {stateId: 43,  id: 4301651,  name: "Barão"},
  {stateId: 43,  id: 4301701,  name: "Barão de Cotegipe"},
  {stateId: 43,  id: 4301750,  name: "Barão do Triunfo"},
  {stateId: 43,  id: 4301859,  name: "Barra do Guarita"},
  {stateId: 43,  id: 4301875,  name: "Barra do Quaraí"},
  {stateId: 43,  id: 4301909,  name: "Barra do Ribeiro"},
  {stateId: 43,  id: 4301925,  name: "Barra do Rio Azul"},
  {stateId: 43,  id: 4301958,  name: "Barra Funda"},
  {stateId: 43,  id: 4301800,  name: "Barracão"},
  {stateId: 43,  id: 4302006,  name: "Barros Cassal"},
  {stateId: 43,  id: 4302055,  name: "Benjamin Constant do Sul"},
  {stateId: 43,  id: 4302105,  name: "Bento Gonçalves"},
  {stateId: 43,  id: 4302154,  name: "Boa Vista das Missões"},
  {stateId: 43,  id: 4302204,  name: "Boa Vista do Buricá"},
  {stateId: 43,  id: 4302220,  name: "Boa Vista do Cadeado"},
  {stateId: 43,  id: 4302238,  name: "Boa Vista do Incra"},
  {stateId: 43,  id: 4302253,  name: "Boa Vista do Sul"},
  {stateId: 43,  id: 4302303,  name: "Bom Jesus"},
  {stateId: 43,  id: 4302352,  name: "Bom Princípio"},
  {stateId: 43,  id: 4302378,  name: "Bom Progresso"},
  {stateId: 43,  id: 4302402,  name: "Bom Retiro do Sul"},
  {stateId: 43,  id: 4302451,  name: "Boqueirão do Leão"},
  {stateId: 43,  id: 4302501,  name: "Bossoroca"},
  {stateId: 43,  id: 4302584,  name: "Bozano"},
  {stateId: 43,  id: 4302600,  name: "Braga"},
  {stateId: 43,  id: 4302659,  name: "Brochier"},
  {stateId: 43,  id: 4302709,  name: "Butiá"},
  {stateId: 43,  id: 4302808,  name: "Caçapava do Sul"},
  {stateId: 43,  id: 4302907,  name: "Cacequi"},
  {stateId: 43,  id: 4303004,  name: "Cachoeira do Sul"},
  {stateId: 43,  id: 4303103,  name: "Cachoeirinha"},
  {stateId: 43,  id: 4303202,  name: "Cacique Doble"},
  {stateId: 43,  id: 4303301,  name: "Caibaté"},
  {stateId: 43,  id: 4303400,  name: "Caiçara"},
  {stateId: 43,  id: 4303509,  name: "Camaquã"},
  {stateId: 43,  id: 4303558,  name: "Camargo"},
  {stateId: 43,  id: 4303608,  name: "Cambará do Sul"},
  {stateId: 43,  id: 4303673,  name: "Campestre da Serra"},
  {stateId: 43,  id: 4303707,  name: "Campina das Missões"},
  {stateId: 43,  id: 4303806,  name: "Campinas do Sul"},
  {stateId: 43,  id: 4303905,  name: "Campo Bom"},
  {stateId: 43,  id: 4304002,  name: "Campo Novo"},
  {stateId: 43,  id: 4304101,  name: "Campos Borges"},
  {stateId: 43,  id: 4304200,  name: "Candelária"},
  {stateId: 43,  id: 4304309,  name: "Cândido Godói"},
  {stateId: 43,  id: 4304358,  name: "Candiota"},
  {stateId: 43,  id: 4304408,  name: "Canela"},
  {stateId: 43,  id: 4304507,  name: "Canguçu"},
  {stateId: 43,  id: 4304606,  name: "Canoas"},
  {stateId: 43,  id: 4304614,  name: "Canudos do Vale"},
  {stateId: 43,  id: 4304622,  name: "Capão Bonito do Sul"},
  {stateId: 43,  id: 4304630,  name: "Capão da Canoa"},
  {stateId: 43,  id: 4304655,  name: "Capão do Cipó"},
  {stateId: 43,  id: 4304663,  name: "Capão do Leão"},
  {stateId: 43,  id: 4304689,  name: "Capela de Santana"},
  {stateId: 43,  id: 4304697,  name: "Capitão"},
  {stateId: 43,  id: 4304671,  name: "Capivari do Sul"},
  {stateId: 43,  id: 4304713,  name: "Caraá"},
  {stateId: 43,  id: 4304705,  name: "Carazinho"},
  {stateId: 43,  id: 4304804,  name: "Carlos Barbosa"},
  {stateId: 43,  id: 4304853,  name: "Carlos Gomes"},
  {stateId: 43,  id: 4304903,  name: "Casca"},
  {stateId: 43,  id: 4304952,  name: "Caseiros"},
  {stateId: 43,  id: 4305009,  name: "Catuípe"},
  {stateId: 43,  id: 4305108,  name: "Caxias do Sul"},
  {stateId: 43,  id: 4305116,  name: "Centenário"},
  {stateId: 43,  id: 4305124,  name: "Cerrito"},
  {stateId: 43,  id: 4305132,  name: "Cerro Branco"},
  {stateId: 43,  id: 4305157,  name: "Cerro Grande"},
  {stateId: 43,  id: 4305173,  name: "Cerro Grande do Sul"},
  {stateId: 43,  id: 4305207,  name: "Cerro Largo"},
  {stateId: 43,  id: 4305306,  name: "Chapada"},
  {stateId: 43,  id: 4305355,  name: "Charqueadas"},
  {stateId: 43,  id: 4305371,  name: "Charrua"},
  {stateId: 43,  id: 4305405,  name: "Chiapetta"},
  {stateId: 43,  id: 4305439,  name: "Chuí"},
  {stateId: 43,  id: 4305447,  name: "Chuvisca"},
  {stateId: 43,  id: 4305454,  name: "Cidreira"},
  {stateId: 43,  id: 4305504,  name: "Ciríaco"},
  {stateId: 43,  id: 4305587,  name: "Colinas"},
  {stateId: 43,  id: 4305603,  name: "Colorado"},
  {stateId: 43,  id: 4305702,  name: "Condor"},
  {stateId: 43,  id: 4305801,  name: "Constantina"},
  {stateId: 43,  id: 4305835,  name: "Coqueiro Baixo"},
  {stateId: 43,  id: 4305850,  name: "Coqueiros do Sul"},
  {stateId: 43,  id: 4305871,  name: "Coronel Barros"},
  {stateId: 43,  id: 4305900,  name: "Coronel Bicaco"},
  {stateId: 43,  id: 4305934,  name: "Coronel Pilar"},
  {stateId: 43,  id: 4305959,  name: "Cotiporã"},
  {stateId: 43,  id: 4305975,  name: "Coxilha"},
  {stateId: 43,  id: 4306007,  name: "Crissiumal"},
  {stateId: 43,  id: 4306056,  name: "Cristal"},
  {stateId: 43,  id: 4306072,  name: "Cristal do Sul"},
  {stateId: 43,  id: 4306106,  name: "Cruz Alta"},
  {stateId: 43,  id: 4306130,  name: "Cruzaltense"},
  {stateId: 43,  id: 4306205,  name: "Cruzeiro do Sul"},
  {stateId: 43,  id: 4306304,  name: "David Canabarro"},
  {stateId: 43,  id: 4306320,  name: "Derrubadas"},
  {stateId: 43,  id: 4306353,  name: "Dezesseis de Novembro"},
  {stateId: 43,  id: 4306379,  name: "Dilermando de Aguiar"},
  {stateId: 43,  id: 4306403,  name: "Dois Irmãos"},
  {stateId: 43,  id: 4306429,  name: "Dois Irmãos das Missões"},
  {stateId: 43,  id: 4306452,  name: "Dois Lajeados"},
  {stateId: 43,  id: 4306502,  name: "Dom Feliciano"},
  {stateId: 43,  id: 4306601,  name: "Dom Pedrito"},
  {stateId: 43,  id: 4306551,  name: "Dom Pedro de Alcântara"},
  {stateId: 43,  id: 4306700,  name: "Dona Francisca"},
  {stateId: 43,  id: 4306734,  name: "Doutor Maurício Cardoso"},
  {stateId: 43,  id: 4306759,  name: "Doutor Ricardo"},
  {stateId: 43,  id: 4306767,  name: "Eldorado do Sul"},
  {stateId: 43,  id: 4306809,  name: "Encantado"},
  {stateId: 43,  id: 4306908,  name: "Encruzilhada do Sul"},
  {stateId: 43,  id: 4306924,  name: "Engenho Velho"},
  {stateId: 43,  id: 4306957,  name: "Entre Rios do Sul"},
  {stateId: 43,  id: 4306932,  name: "Entre-Ijuís"},
  {stateId: 43,  id: 4306973,  name: "Erebango"},
  {stateId: 43,  id: 4307005,  name: "Erechim"},
  {stateId: 43,  id: 4307054,  name: "Ernestina"},
  {stateId: 43,  id: 4307203,  name: "Erval Grande"},
  {stateId: 43,  id: 4307302,  name: "Erval Seco"},
  {stateId: 43,  id: 4307401,  name: "Esmeralda"},
  {stateId: 43,  id: 4307450,  name: "Esperança do Sul"},
  {stateId: 43,  id: 4307500,  name: "Espumoso"},
  {stateId: 43,  id: 4307559,  name: "Estação"},
  {stateId: 43,  id: 4307609,  name: "Estância Velha"},
  {stateId: 43,  id: 4307708,  name: "Esteio"},
  {stateId: 43,  id: 4307807,  name: "Estrela"},
  {stateId: 43,  id: 4307815,  name: "Estrela Velha"},
  {stateId: 43,  id: 4307831,  name: "Eugênio de Castro"},
  {stateId: 43,  id: 4307864,  name: "Fagundes Varela"},
  {stateId: 43,  id: 4307906,  name: "Farroupilha"},
  {stateId: 43,  id: 4308003,  name: "Faxinal do Soturno"},
  {stateId: 43,  id: 4308052,  name: "Faxinalzinho"},
  {stateId: 43,  id: 4308078,  name: "Fazenda Vilanova"},
  {stateId: 43,  id: 4308102,  name: "Feliz"},
  {stateId: 43,  id: 4308201,  name: "Flores da Cunha"},
  {stateId: 43,  id: 4308250,  name: "Floriano Peixoto"},
  {stateId: 43,  id: 4308300,  name: "Fontoura Xavier"},
  {stateId: 43,  id: 4308409,  name: "Formigueiro"},
  {stateId: 43,  id: 4308433,  name: "Forquetinha"},
  {stateId: 43,  id: 4308458,  name: "Fortaleza dos Valos"},
  {stateId: 43,  id: 4308508,  name: "Frederico Westphalen"},
  {stateId: 43,  id: 4308607,  name: "Garibaldi"},
  {stateId: 43,  id: 4308656,  name: "Garruchos"},
  {stateId: 43,  id: 4308706,  name: "Gaurama"},
  {stateId: 43,  id: 4308805,  name: "General Câmara"},
  {stateId: 43,  id: 4308854,  name: "Gentil"},
  {stateId: 43,  id: 4308904,  name: "Getúlio Vargas"},
  {stateId: 43,  id: 4309001,  name: "Giruá"},
  {stateId: 43,  id: 4309050,  name: "Glorinha"},
  {stateId: 43,  id: 4309100,  name: "Gramado"},
  {stateId: 43,  id: 4309126,  name: "Gramado dos Loureiros"},
  {stateId: 43,  id: 4309159,  name: "Gramado Xavier"},
  {stateId: 43,  id: 4309209,  name: "Gravataí"},
  {stateId: 43,  id: 4309258,  name: "Guabiju"},
  {stateId: 43,  id: 4309308,  name: "Guaíba"},
  {stateId: 43,  id: 4309407,  name: "Guaporé"},
  {stateId: 43,  id: 4309506,  name: "Guarani das Missões"},
  {stateId: 43,  id: 4309555,  name: "Harmonia"},
  {stateId: 43,  id: 4307104,  name: "Herval"},
  {stateId: 43,  id: 4309571,  name: "Herveiras"},
  {stateId: 43,  id: 4309605,  name: "Horizontina"},
  {stateId: 43,  id: 4309654,  name: "Hulha Negra"},
  {stateId: 43,  id: 4309704,  name: "Humaitá"},
  {stateId: 43,  id: 4309753,  name: "Ibarama"},
  {stateId: 43,  id: 4309803,  name: "Ibiaçá"},
  {stateId: 43,  id: 4309902,  name: "Ibiraiaras"},
  {stateId: 43,  id: 4309951,  name: "Ibirapuitã"},
  {stateId: 43,  id: 4310009,  name: "Ibirubá"},
  {stateId: 43,  id: 4310108,  name: "Igrejinha"},
  {stateId: 43,  id: 4310207,  name: "Ijuí"},
  {stateId: 43,  id: 4310306,  name: "Ilópolis"},
  {stateId: 43,  id: 4310330,  name: "Imbé"},
  {stateId: 43,  id: 4310363,  name: "Imigrante"},
  {stateId: 43,  id: 4310405,  name: "Independência"},
  {stateId: 43,  id: 4310413,  name: "Inhacorá"},
  {stateId: 43,  id: 4310439,  name: "Ipê"},
  {stateId: 43,  id: 4310462,  name: "Ipiranga do Sul"},
  {stateId: 43,  id: 4310504,  name: "Iraí"},
  {stateId: 43,  id: 4310538,  name: "Itaara"},
  {stateId: 43,  id: 4310553,  name: "Itacurubi"},
  {stateId: 43,  id: 4310579,  name: "Itapuca"},
  {stateId: 43,  id: 4310603,  name: "Itaqui"},
  {stateId: 43,  id: 4310652,  name: "Itati"},
  {stateId: 43,  id: 4310702,  name: "Itatiba do Sul"},
  {stateId: 43,  id: 4310751,  name: "Ivorá"},
  {stateId: 43,  id: 4310801,  name: "Ivoti"},
  {stateId: 43,  id: 4310850,  name: "Jaboticaba"},
  {stateId: 43,  id: 4310876,  name: "Jacuizinho"},
  {stateId: 43,  id: 4310900,  name: "Jacutinga"},
  {stateId: 43,  id: 4311007,  name: "Jaguarão"},
  {stateId: 43,  id: 4311106,  name: "Jaguari"},
  {stateId: 43,  id: 4311122,  name: "Jaquirana"},
  {stateId: 43,  id: 4311130,  name: "Jari"},
  {stateId: 43,  id: 4311155,  name: "Jóia"},
  {stateId: 43,  id: 4311205,  name: "Júlio de Castilhos"},
  {stateId: 43,  id: 4311239,  name: "Lagoa Bonita do Sul"},
  {stateId: 43,  id: 4311270,  name: "Lagoa dos Três Cantos"},
  {stateId: 43,  id: 4311304,  name: "Lagoa Vermelha"},
  {stateId: 43,  id: 4311254,  name: "Lagoão"},
  {stateId: 43,  id: 4311403,  name: "Lajeado"},
  {stateId: 43,  id: 4311429,  name: "Lajeado do Bugre"},
  {stateId: 43,  id: 4311502,  name: "Lavras do Sul"},
  {stateId: 43,  id: 4311601,  name: "Liberato Salzano"},
  {stateId: 43,  id: 4311627,  name: "Lindolfo Collor"},
  {stateId: 43,  id: 4311643,  name: "Linha Nova"},
  {stateId: 43,  id: 4311718,  name: "Maçambará"},
  {stateId: 43,  id: 4311700,  name: "Machadinho"},
  {stateId: 43,  id: 4311734,  name: "Mampituba"},
  {stateId: 43,  id: 4311759,  name: "Manoel Viana"},
  {stateId: 43,  id: 4311775,  name: "Maquiné"},
  {stateId: 43,  id: 4311791,  name: "Maratá"},
  {stateId: 43,  id: 4311809,  name: "Marau"},
  {stateId: 43,  id: 4311908,  name: "Marcelino Ramos"},
  {stateId: 43,  id: 4311981,  name: "Mariana Pimentel"},
  {stateId: 43,  id: 4312005,  name: "Mariano Moro"},
  {stateId: 43,  id: 4312054,  name: "Marques de Souza"},
  {stateId: 43,  id: 4312104,  name: "Mata"},
  {stateId: 43,  id: 4312138,  name: "Mato Castelhano"},
  {stateId: 43,  id: 4312153,  name: "Mato Leitão"},
  {stateId: 43,  id: 4312179,  name: "Mato Queimado"},
  {stateId: 43,  id: 4312203,  name: "Maximiliano de Almeida"},
  {stateId: 43,  id: 4312252,  name: "Minas do Leão"},
  {stateId: 43,  id: 4312302,  name: "Miraguaí"},
  {stateId: 43,  id: 4312351,  name: "Montauri"},
  {stateId: 43,  id: 4312377,  name: "Monte Alegre dos Campos"},
  {stateId: 43,  id: 4312385,  name: "Monte Belo do Sul"},
  {stateId: 43,  id: 4312401,  name: "Montenegro"},
  {stateId: 43,  id: 4312427,  name: "Mormaço"},
  {stateId: 43,  id: 4312443,  name: "Morrinhos do Sul"},
  {stateId: 43,  id: 4312450,  name: "Morro Redondo"},
  {stateId: 43,  id: 4312476,  name: "Morro Reuter"},
  {stateId: 43,  id: 4312500,  name: "Mostardas"},
  {stateId: 43,  id: 4312609,  name: "Muçum"},
  {stateId: 43,  id: 4312617,  name: "Muitos Capões"},
  {stateId: 43,  id: 4312625,  name: "Muliterno"},
  {stateId: 43,  id: 4312658,  name: "Não-Me-Toque"},
  {stateId: 43,  id: 4312674,  name: "Nicolau Vergueiro"},
  {stateId: 43,  id: 4312708,  name: "Nonoai"},
  {stateId: 43,  id: 4312757,  name: "Nova Alvorada"},
  {stateId: 43,  id: 4312807,  name: "Nova Araçá"},
  {stateId: 43,  id: 4312906,  name: "Nova Bassano"},
  {stateId: 43,  id: 4312955,  name: "Nova Boa Vista"},
  {stateId: 43,  id: 4313003,  name: "Nova Bréscia"},
  {stateId: 43,  id: 4313011,  name: "Nova Candelária"},
  {stateId: 43,  id: 4313037,  name: "Nova Esperança do Sul"},
  {stateId: 43,  id: 4313060,  name: "Nova Hartz"},
  {stateId: 43,  id: 4313086,  name: "Nova Pádua"},
  {stateId: 43,  id: 4313102,  name: "Nova Palma"},
  {stateId: 43,  id: 4313201,  name: "Nova Petrópolis"},
  {stateId: 43,  id: 4313300,  name: "Nova Prata"},
  {stateId: 43,  id: 4313334,  name: "Nova Ramada"},
  {stateId: 43,  id: 4313359,  name: "Nova Roma do Sul"},
  {stateId: 43,  id: 4313375,  name: "Nova Santa Rita"},
  {stateId: 43,  id: 4313490,  name: "Novo Barreiro"},
  {stateId: 43,  id: 4313391,  name: "Novo Cabrais"},
  {stateId: 43,  id: 4313409,  name: "Novo Hamburgo"},
  {stateId: 43,  id: 4313425,  name: "Novo Machado"},
  {stateId: 43,  id: 4313441,  name: "Novo Tiradentes"},
  {stateId: 43,  id: 4313466,  name: "Novo Xingu"},
  {stateId: 43,  id: 4313508,  name: "Osório"},
  {stateId: 43,  id: 4313607,  name: "Paim Filho"},
  {stateId: 43,  id: 4313656,  name: "Palmares do Sul"},
  {stateId: 43,  id: 4313706,  name: "Palmeira das Missões"},
  {stateId: 43,  id: 4313805,  name: "Palmitinho"},
  {stateId: 43,  id: 4313904,  name: "Panambi"},
  {stateId: 43,  id: 4313953,  name: "Pantano Grande"},
  {stateId: 43,  id: 4314001,  name: "Paraí"},
  {stateId: 43,  id: 4314027,  name: "Paraíso do Sul"},
  {stateId: 43,  id: 4314035,  name: "Pareci Novo"},
  {stateId: 43,  id: 4314050,  name: "Parobé"},
  {stateId: 43,  id: 4314068,  name: "Passa Sete"},
  {stateId: 43,  id: 4314076,  name: "Passo do Sobrado"},
  {stateId: 43,  id: 4314100,  name: "Passo Fundo"},
  {stateId: 43,  id: 4314134,  name: "Paulo Bento"},
  {stateId: 43,  id: 4314159,  name: "Paverama"},
  {stateId: 43,  id: 4314175,  name: "Pedras Altas"},
  {stateId: 43,  id: 4314209,  name: "Pedro Osório"},
  {stateId: 43,  id: 4314308,  name: "Pejuçara"},
  {stateId: 43,  id: 4314407,  name: "Pelotas"},
  {stateId: 43,  id: 4314423,  name: "Picada Café"},
  {stateId: 43,  id: 4314456,  name: "Pinhal"},
  {stateId: 43,  id: 4314464,  name: "Pinhal da Serra"},
  {stateId: 43,  id: 4314472,  name: "Pinhal Grande"},
  {stateId: 43,  id: 4314498,  name: "Pinheirinho do Vale"},
  {stateId: 43,  id: 4314506,  name: "Pinheiro Machado"},
  {stateId: 43,  id: 4314548,  name: "Pinto Bandeira"},
  {stateId: 43,  id: 4314555,  name: "Pirapó"},
  {stateId: 43,  id: 4314605,  name: "Piratini"},
  {stateId: 43,  id: 4314704,  name: "Planalto"},
  {stateId: 43,  id: 4314753,  name: "Poço das Antas"},
  {stateId: 43,  id: 4314779,  name: "Pontão"},
  {stateId: 43,  id: 4314787,  name: "Ponte Preta"},
  {stateId: 43,  id: 4314803,  name: "Portão"},
  {stateId: 43,  id: 4314902,  name: "Porto Alegre"},
  {stateId: 43,  id: 4315008,  name: "Porto Lucena"},
  {stateId: 43,  id: 4315057,  name: "Porto Mauá"},
  {stateId: 43,  id: 4315073,  name: "Porto Vera Cruz"},
  {stateId: 43,  id: 4315107,  name: "Porto Xavier"},
  {stateId: 43,  id: 4315131,  name: "Pouso Novo"},
  {stateId: 43,  id: 4315149,  name: "Presidente Lucena"},
  {stateId: 43,  id: 4315156,  name: "Progresso"},
  {stateId: 43,  id: 4315172,  name: "Protásio Alves"},
  {stateId: 43,  id: 4315206,  name: "Putinga"},
  {stateId: 43,  id: 4315305,  name: "Quaraí"},
  {stateId: 43,  id: 4315313,  name: "Quatro Irmãos"},
  {stateId: 43,  id: 4315321,  name: "Quevedos"},
  {stateId: 43,  id: 4315354,  name: "Quinze de Novembro"},
  {stateId: 43,  id: 4315404,  name: "Redentora"},
  {stateId: 43,  id: 4315453,  name: "Relvado"},
  {stateId: 43,  id: 4315503,  name: "Restinga Sêca"},
  {stateId: 43,  id: 4315552,  name: "Rio dos Índios"},
  {stateId: 43,  id: 4315602,  name: "Rio Grande"},
  {stateId: 43,  id: 4315701,  name: "Rio Pardo"},
  {stateId: 43,  id: 4315750,  name: "Riozinho"},
  {stateId: 43,  id: 4315800,  name: "Roca Sales"},
  {stateId: 43,  id: 4315909,  name: "Rodeio Bonito"},
  {stateId: 43,  id: 4315958,  name: "Rolador"},
  {stateId: 43,  id: 4316006,  name: "Rolante"},
  {stateId: 43,  id: 4316105,  name: "Ronda Alta"},
  {stateId: 43,  id: 4316204,  name: "Rondinha"},
  {stateId: 43,  id: 4316303,  name: "Roque Gonzales"},
  {stateId: 43,  id: 4316402,  name: "Rosário do Sul"},
  {stateId: 43,  id: 4316428,  name: "Sagrada Família"},
  {stateId: 43,  id: 4316436,  name: "Saldanha Marinho"},
  {stateId: 43,  id: 4316451,  name: "Salto do Jacuí"},
  {stateId: 43,  id: 4316477,  name: "Salvador das Missões"},
  {stateId: 43,  id: 4316501,  name: "Salvador do Sul"},
  {stateId: 43,  id: 4316600,  name: "Sananduva"},
  {stateId: 43,  id: 4316709,  name: "Santa Bárbara do Sul"},
  {stateId: 43,  id: 4316733,  name: "Santa Cecília do Sul"},
  {stateId: 43,  id: 4316758,  name: "Santa Clara do Sul"},
  {stateId: 43,  id: 4316808,  name: "Santa Cruz do Sul"},
  {stateId: 43,  id: 4316972,  name: "Santa Margarida do Sul"},
  {stateId: 43,  id: 4316907,  name: "Santa Maria"},
  {stateId: 43,  id: 4316956,  name: "Santa Maria do Herval"},
  {stateId: 43,  id: 4317202,  name: "Santa Rosa"},
  {stateId: 43,  id: 4317251,  name: "Santa Tereza"},
  {stateId: 43,  id: 4317301,  name: "Santa Vitória do Palmar"},
  {stateId: 43,  id: 4317004,  name: "Santana da Boa Vista"},
  {stateId: 43,  id: 4317103,  name: "Sant'Ana do Livramento"},
  {stateId: 43,  id: 4317400,  name: "Santiago"},
  {stateId: 43,  id: 4317509,  name: "Santo Ângelo"},
  {stateId: 43,  id: 4317608,  name: "Santo Antônio da Patrulha"},
  {stateId: 43,  id: 4317707,  name: "Santo Antônio das Missões"},
  {stateId: 43,  id: 4317558,  name: "Santo Antônio do Palma"},
  {stateId: 43,  id: 4317756,  name: "Santo Antônio do Planalto"},
  {stateId: 43,  id: 4317806,  name: "Santo Augusto"},
  {stateId: 43,  id: 4317905,  name: "Santo Cristo"},
  {stateId: 43,  id: 4317954,  name: "Santo Expedito do Sul"},
  {stateId: 43,  id: 4318002,  name: "São Borja"},
  {stateId: 43,  id: 4318051,  name: "São Domingos do Sul"},
  {stateId: 43,  id: 4318101,  name: "São Francisco de Assis"},
  {stateId: 43,  id: 4318200,  name: "São Francisco de Paula"},
  {stateId: 43,  id: 4318309,  name: "São Gabriel"},
  {stateId: 43,  id: 4318408,  name: "São Jerônimo"},
  {stateId: 43,  id: 4318424,  name: "São João da Urtiga"},
  {stateId: 43,  id: 4318432,  name: "São João do Polêsine"},
  {stateId: 43,  id: 4318440,  name: "São Jorge"},
  {stateId: 43,  id: 4318457,  name: "São José das Missões"},
  {stateId: 43,  id: 4318465,  name: "São José do Herval"},
  {stateId: 43,  id: 4318481,  name: "São José do Hortêncio"},
  {stateId: 43,  id: 4318499,  name: "São José do Inhacorá"},
  {stateId: 43,  id: 4318507,  name: "São José do Norte"},
  {stateId: 43,  id: 4318606,  name: "São José do Ouro"},
  {stateId: 43,  id: 4318614,  name: "São José do Sul"},
  {stateId: 43,  id: 4318622,  name: "São José dos Ausentes"},
  {stateId: 43,  id: 4318705,  name: "São Leopoldo"},
  {stateId: 43,  id: 4318804,  name: "São Lourenço do Sul"},
  {stateId: 43,  id: 4318903,  name: "São Luiz Gonzaga"},
  {stateId: 43,  id: 4319000,  name: "São Marcos"},
  {stateId: 43,  id: 4319109,  name: "São Martinho"},
  {stateId: 43,  id: 4319125,  name: "São Martinho da Serra"},
  {stateId: 43,  id: 4319158,  name: "São Miguel das Missões"},
  {stateId: 43,  id: 4319208,  name: "São Nicolau"},
  {stateId: 43,  id: 4319307,  name: "São Paulo das Missões"},
  {stateId: 43,  id: 4319356,  name: "São Pedro da Serra"},
  {stateId: 43,  id: 4319364,  name: "São Pedro das Missões"},
  {stateId: 43,  id: 4319372,  name: "São Pedro do Butiá"},
  {stateId: 43,  id: 4319406,  name: "São Pedro do Sul"},
  {stateId: 43,  id: 4319505,  name: "São Sebastião do Caí"},
  {stateId: 43,  id: 4319604,  name: "São Sepé"},
  {stateId: 43,  id: 4319703,  name: "São Valentim"},
  {stateId: 43,  id: 4319711,  name: "São Valentim do Sul"},
  {stateId: 43,  id: 4319737,  name: "São Valério do Sul"},
  {stateId: 43,  id: 4319752,  name: "São Vendelino"},
  {stateId: 43,  id: 4319802,  name: "São Vicente do Sul"},
  {stateId: 43,  id: 4319901,  name: "Sapiranga"},
  {stateId: 43,  id: 4320008,  name: "Sapucaia do Sul"},
  {stateId: 43,  id: 4320107,  name: "Sarandi"},
  {stateId: 43,  id: 4320206,  name: "Seberi"},
  {stateId: 43,  id: 4320230,  name: "Sede Nova"},
  {stateId: 43,  id: 4320263,  name: "Segredo"},
  {stateId: 43,  id: 4320305,  name: "Selbach"},
  {stateId: 43,  id: 4320321,  name: "Senador Salgado Filho"},
  {stateId: 43,  id: 4320354,  name: "Sentinela do Sul"},
  {stateId: 43,  id: 4320404,  name: "Serafina Corrêa"},
  {stateId: 43,  id: 4320453,  name: "Sério"},
  {stateId: 43,  id: 4320503,  name: "Sertão"},
  {stateId: 43,  id: 4320552,  name: "Sertão Santana"},
  {stateId: 43,  id: 4320578,  name: "Sete de Setembro"},
  {stateId: 43,  id: 4320602,  name: "Severiano de Almeida"},
  {stateId: 43,  id: 4320651,  name: "Silveira Martins"},
  {stateId: 43,  id: 4320677,  name: "Sinimbu"},
  {stateId: 43,  id: 4320701,  name: "Sobradinho"},
  {stateId: 43,  id: 4320800,  name: "Soledade"},
  {stateId: 43,  id: 4320859,  name: "Tabaí"},
  {stateId: 43,  id: 4320909,  name: "Tapejara"},
  {stateId: 43,  id: 4321006,  name: "Tapera"},
  {stateId: 43,  id: 4321105,  name: "Tapes"},
  {stateId: 43,  id: 4321204,  name: "Taquara"},
  {stateId: 43,  id: 4321303,  name: "Taquari"},
  {stateId: 43,  id: 4321329,  name: "Taquaruçu do Sul"},
  {stateId: 43,  id: 4321352,  name: "Tavares"},
  {stateId: 43,  id: 4321402,  name: "Tenente Portela"},
  {stateId: 43,  id: 4321436,  name: "Terra de Areia"},
  {stateId: 43,  id: 4321451,  name: "Teutônia"},
  {stateId: 43,  id: 4321469,  name: "Tio Hugo"},
  {stateId: 43,  id: 4321477,  name: "Tiradentes do Sul"},
  {stateId: 43,  id: 4321493,  name: "Toropi"},
  {stateId: 43,  id: 4321501,  name: "Torres"},
  {stateId: 43,  id: 4321600,  name: "Tramandaí"},
  {stateId: 43,  id: 4321626,  name: "Travesseiro"},
  {stateId: 43,  id: 4321634,  name: "Três Arroios"},
  {stateId: 43,  id: 4321667,  name: "Três Cachoeiras"},
  {stateId: 43,  id: 4321709,  name: "Três Coroas"},
  {stateId: 43,  id: 4321808,  name: "Três de Maio"},
  {stateId: 43,  id: 4321832,  name: "Três Forquilhas"},
  {stateId: 43,  id: 4321857,  name: "Três Palmeiras"},
  {stateId: 43,  id: 4321907,  name: "Três Passos"},
  {stateId: 43,  id: 4321956,  name: "Trindade do Sul"},
  {stateId: 43,  id: 4322004,  name: "Triunfo"},
  {stateId: 43,  id: 4322103,  name: "Tucunduva"},
  {stateId: 43,  id: 4322152,  name: "Tunas"},
  {stateId: 43,  id: 4322186,  name: "Tupanci do Sul"},
  {stateId: 43,  id: 4322202,  name: "Tupanciretã"},
  {stateId: 43,  id: 4322251,  name: "Tupandi"},
  {stateId: 43,  id: 4322301,  name: "Tuparendi"},
  {stateId: 43,  id: 4322327,  name: "Turuçu"},
  {stateId: 43,  id: 4322343,  name: "Ubiretama"},
  {stateId: 43,  id: 4322350,  name: "União da Serra"},
  {stateId: 43,  id: 4322376,  name: "Unistalda"},
  {stateId: 43,  id: 4322400,  name: "Uruguaiana"},
  {stateId: 43,  id: 4322509,  name: "Vacaria"},
  {stateId: 43,  id: 4322533,  name: "Vale do Sol"},
  {stateId: 43,  id: 4322541,  name: "Vale Real"},
  {stateId: 43,  id: 4322525,  name: "Vale Verde"},
  {stateId: 43,  id: 4322558,  name: "Vanini"},
  {stateId: 43,  id: 4322608,  name: "Venâncio Aires"},
  {stateId: 43,  id: 4322707,  name: "Vera Cruz"},
  {stateId: 43,  id: 4322806,  name: "Veranópolis"},
  {stateId: 43,  id: 4322855,  name: "Vespasiano Corrêa"},
  {stateId: 43,  id: 4322905,  name: "Viadutos"},
  {stateId: 43,  id: 4323002,  name: "Viamão"},
  {stateId: 43,  id: 4323101,  name: "Vicente Dutra"},
  {stateId: 43,  id: 4323200,  name: "Victor Graeff"},
  {stateId: 43,  id: 4323309,  name: "Vila Flores"},
  {stateId: 43,  id: 4323358,  name: "Vila Lângaro"},
  {stateId: 43,  id: 4323408,  name: "Vila Maria"},
  {stateId: 43,  id: 4323457,  name: "Vila Nova do Sul"},
  {stateId: 43,  id: 4323507,  name: "Vista Alegre"},
  {stateId: 43,  id: 4323606,  name: "Vista Alegre do Prata"},
  {stateId: 43,  id: 4323705,  name: "Vista Gaúcha"},
  {stateId: 43,  id: 4323754,  name: "Vitória das Missões"},
  {stateId: 43,  id: 4323770,  name: "Westfália"},
  {stateId: 43,  id: 4323804,  name: "Xangri-lá"},
  {stateId: 50,  id: 5000203,  name: "Água Clara"},
  {stateId: 50,  id: 5000252,  name: "Alcinópolis"},
  {stateId: 50,  id: 5000609,  name: "Amambai"},
  {stateId: 50,  id: 5000708,  name: "Anastácio"},
  {stateId: 50,  id: 5000807,  name: "Anaurilândia"},
  {stateId: 50,  id: 5000856,  name: "Angélica"},
  {stateId: 50,  id: 5000906,  name: "Antônio João"},
  {stateId: 50,  id: 5001003,  name: "Aparecida do Taboado"},
  {stateId: 50,  id: 5001102,  name: "Aquidauana"},
  {stateId: 50,  id: 5001243,  name: "Aral Moreira"},
  {stateId: 50,  id: 5001508,  name: "Bandeirantes"},
  {stateId: 50,  id: 5001904,  name: "Bataguassu"},
  {stateId: 50,  id: 5002001,  name: "Batayporã"},
  {stateId: 50,  id: 5002100,  name: "Bela Vista"},
  {stateId: 50,  id: 5002159,  name: "Bodoquena"},
  {stateId: 50,  id: 5002209,  name: "Bonito"},
  {stateId: 50,  id: 5002308,  name: "Brasilândia"},
  {stateId: 50,  id: 5002407,  name: "Caarapó"},
  {stateId: 50,  id: 5002605,  name: "Camapuã"},
  {stateId: 50,  id: 5002704,  name: "Campo Grande"},
  {stateId: 50,  id: 5002803,  name: "Caracol"},
  {stateId: 50,  id: 5002902,  name: "Cassilândia"},
  {stateId: 50,  id: 5002951,  name: "Chapadão do Sul"},
  {stateId: 50,  id: 5003108,  name: "Corguinho"},
  {stateId: 50,  id: 5003157,  name: "Coronel Sapucaia"},
  {stateId: 50,  id: 5003207,  name: "Corumbá"},
  {stateId: 50,  id: 5003256,  name: "Costa Rica"},
  {stateId: 50,  id: 5003306,  name: "Coxim"},
  {stateId: 50,  id: 5003454,  name: "Deodápolis"},
  {stateId: 50,  id: 5003488,  name: "Dois Irmãos do Buriti"},
  {stateId: 50,  id: 5003504,  name: "Douradina"},
  {stateId: 50,  id: 5003702,  name: "Dourados"},
  {stateId: 50,  id: 5003751,  name: "Eldorado"},
  {stateId: 50,  id: 5003801,  name: "Fátima do Sul"},
  {stateId: 50,  id: 5003900,  name: "Figueirão"},
  {stateId: 50,  id: 5004007,  name: "Glória de Dourados"},
  {stateId: 50,  id: 5004106,  name: "Guia Lopes da Laguna"},
  {stateId: 50,  id: 5004304,  name: "Iguatemi"},
  {stateId: 50,  id: 5004403,  name: "Inocência"},
  {stateId: 50,  id: 5004502,  name: "Itaporã"},
  {stateId: 50,  id: 5004601,  name: "Itaquiraí"},
  {stateId: 50,  id: 5004700,  name: "Ivinhema"},
  {stateId: 50,  id: 5004809,  name: "Japorã"},
  {stateId: 50,  id: 5004908,  name: "Jaraguari"},
  {stateId: 50,  id: 5005004,  name: "Jardim"},
  {stateId: 50,  id: 5005103,  name: "Jateí"},
  {stateId: 50,  id: 5005152,  name: "Juti"},
  {stateId: 50,  id: 5005202,  name: "Ladário"},
  {stateId: 50,  id: 5005251,  name: "Laguna Carapã"},
  {stateId: 50,  id: 5005400,  name: "Maracaju"},
  {stateId: 50,  id: 5005608,  name: "Miranda"},
  {stateId: 50,  id: 5005681,  name: "Mundo Novo"},
  {stateId: 50,  id: 5005707,  name: "Naviraí"},
  {stateId: 50,  id: 5005806,  name: "Nioaque"},
  {stateId: 50,  id: 5006002,  name: "Nova Alvorada do Sul"},
  {stateId: 50,  id: 5006200,  name: "Nova Andradina"},
  {stateId: 50,  id: 5006259,  name: "Novo Horizonte do Sul"},
  {stateId: 50,  id: 5006275,  name: "Paraíso das Águas"},
  {stateId: 50,  id: 5006309,  name: "Paranaíba"},
  {stateId: 50,  id: 5006358,  name: "Paranhos"},
  {stateId: 50,  id: 5006408,  name: "Pedro Gomes"},
  {stateId: 50,  id: 5006606,  name: "Ponta Porã"},
  {stateId: 50,  id: 5006903,  name: "Porto Murtinho"},
  {stateId: 50,  id: 5007109,  name: "Ribas do Rio Pardo"},
  {stateId: 50,  id: 5007208,  name: "Rio Brilhante"},
  {stateId: 50,  id: 5007307,  name: "Rio Negro"},
  {stateId: 50,  id: 5007406,  name: "Rio Verde de Mato Grosso"},
  {stateId: 50,  id: 5007505,  name: "Rochedo"},
  {stateId: 50,  id: 5007554,  name: "Santa Rita do Pardo"},
  {stateId: 50,  id: 5007695,  name: "São Gabriel do Oeste"},
  {stateId: 50,  id: 5007802,  name: "Selvíria"},
  {stateId: 50,  id: 5007703,  name: "Sete Quedas"},
  {stateId: 50,  id: 5007901,  name: "Sidrolândia"},
  {stateId: 50,  id: 5007935,  name: "Sonora"},
  {stateId: 50,  id: 5007950,  name: "Tacuru"},
  {stateId: 50,  id: 5007976,  name: "Taquarussu"},
  {stateId: 50,  id: 5008008,  name: "Terenos"},
  {stateId: 50,  id: 5008305,  name: "Três Lagoas"},
  {stateId: 50,  id: 5008404,  name: "Vicentina"},
  {stateId: 51,  id: 5100102,  name: "Acorizal"},
  {stateId: 51,  id: 5100201,  name: "Água Boa"},
  {stateId: 51,  id: 5100250,  name: "Alta Floresta"},
  {stateId: 51,  id: 5100300,  name: "Alto Araguaia"},
  {stateId: 51,  id: 5100359,  name: "Alto Boa Vista"},
  {stateId: 51,  id: 5100409,  name: "Alto Garças"},
  {stateId: 51,  id: 5100508,  name: "Alto Paraguai"},
  {stateId: 51,  id: 5100607,  name: "Alto Taquari"},
  {stateId: 51,  id: 5100805,  name: "Apiacás"},
  {stateId: 51,  id: 5101001,  name: "Araguaiana"},
  {stateId: 51,  id: 5101209,  name: "Araguainha"},
  {stateId: 51,  id: 5101258,  name: "Araputanga"},
  {stateId: 51,  id: 5101308,  name: "Arenápolis"},
  {stateId: 51,  id: 5101407,  name: "Aripuanã"},
  {stateId: 51,  id: 5101605,  name: "Barão de Melgaço"},
  {stateId: 51,  id: 5101704,  name: "Barra do Bugres"},
  {stateId: 51,  id: 5101803,  name: "Barra do Garças"},
  {stateId: 51,  id: 5101852,  name: "Bom Jesus do Araguaia"},
  {stateId: 51,  id: 5101902,  name: "Brasnorte"},
  {stateId: 51,  id: 5102504,  name: "Cáceres"},
  {stateId: 51,  id: 5102603,  name: "Campinápolis"},
  {stateId: 51,  id: 5102637,  name: "Campo Novo do Parecis"},
  {stateId: 51,  id: 5102678,  name: "Campo Verde"},
  {stateId: 51,  id: 5102686,  name: "Campos de Júlio"},
  {stateId: 51,  id: 5102694,  name: "Canabrava do Norte"},
  {stateId: 51,  id: 5102702,  name: "Canarana"},
  {stateId: 51,  id: 5102793,  name: "Carlinda"},
  {stateId: 51,  id: 5102850,  name: "Castanheira"},
  {stateId: 51,  id: 5103007,  name: "Chapada dos Guimarães"},
  {stateId: 51,  id: 5103056,  name: "Cláudia"},
  {stateId: 51,  id: 5103106,  name: "Cocalinho"},
  {stateId: 51,  id: 5103205,  name: "Colíder"},
  {stateId: 51,  id: 5103254,  name: "Colniza"},
  {stateId: 51,  id: 5103304,  name: "Comodoro"},
  {stateId: 51,  id: 5103353,  name: "Confresa"},
  {stateId: 51,  id: 5103361,  name: "Conquista D'Oeste"},
  {stateId: 51,  id: 5103379,  name: "Cotriguaçu"},
  {stateId: 51,  id: 5103403,  name: "Cuiabá"},
  {stateId: 51,  id: 5103437,  name: "Curvelândia"},
  {stateId: 51,  id: 5103452,  name: "Denise"},
  {stateId: 51,  id: 5103502,  name: "Diamantino"},
  {stateId: 51,  id: 5103601,  name: "Dom Aquino"},
  {stateId: 51,  id: 5103700,  name: "Feliz Natal"},
  {stateId: 51,  id: 5103809,  name: "Figueirópolis D'Oeste"},
  {stateId: 51,  id: 5103858,  name: "Gaúcha do Norte"},
  {stateId: 51,  id: 5103908,  name: "General Carneiro"},
  {stateId: 51,  id: 5103957,  name: "Glória D'Oeste"},
  {stateId: 51,  id: 5104104,  name: "Guarantã do Norte"},
  {stateId: 51,  id: 5104203,  name: "Guiratinga"},
  {stateId: 51,  id: 5104500,  name: "Indiavaí"},
  {stateId: 51,  id: 5104526,  name: "Ipiranga do Norte"},
  {stateId: 51,  id: 5104542,  name: "Itanhangá"},
  {stateId: 51,  id: 5104559,  name: "Itaúba"},
  {stateId: 51,  id: 5104609,  name: "Itiquira"},
  {stateId: 51,  id: 5104807,  name: "Jaciara"},
  {stateId: 51,  id: 5104906,  name: "Jangada"},
  {stateId: 51,  id: 5105002,  name: "Jauru"},
  {stateId: 51,  id: 5105101,  name: "Juara"},
  {stateId: 51,  id: 5105150,  name: "Juína"},
  {stateId: 51,  id: 5105176,  name: "Juruena"},
  {stateId: 51,  id: 5105200,  name: "Juscimeira"},
  {stateId: 51,  id: 5105234,  name: "Lambari D'Oeste"},
  {stateId: 51,  id: 5105259,  name: "Lucas do Rio Verde"},
  {stateId: 51,  id: 5105309,  name: "Luciara"},
  {stateId: 51,  id: 5105580,  name: "Marcelândia"},
  {stateId: 51,  id: 5105606,  name: "Matupá"},
  {stateId: 51,  id: 5105622,  name: "Mirassol d'Oeste"},
  {stateId: 51,  id: 5105903,  name: "Nobres"},
  {stateId: 51,  id: 5106000,  name: "Nortelândia"},
  {stateId: 51,  id: 5106109,  name: "Nossa Senhora do Livramento"},
  {stateId: 51,  id: 5106158,  name: "Nova Bandeirantes"},
  {stateId: 51,  id: 5106208,  name: "Nova Brasilândia"},
  {stateId: 51,  id: 5106216,  name: "Nova Canaã do Norte"},
  {stateId: 51,  id: 5108808,  name: "Nova Guarita"},
  {stateId: 51,  id: 5106182,  name: "Nova Lacerda"},
  {stateId: 51,  id: 5108857,  name: "Nova Marilândia"},
  {stateId: 51,  id: 5108907,  name: "Nova Maringá"},
  {stateId: 51,  id: 5108956,  name: "Nova Monte Verde"},
  {stateId: 51,  id: 5106224,  name: "Nova Mutum"},
  {stateId: 51,  id: 5106174,  name: "Nova Nazaré"},
  {stateId: 51,  id: 5106232,  name: "Nova Olímpia"},
  {stateId: 51,  id: 5106190,  name: "Nova Santa Helena"},
  {stateId: 51,  id: 5106240,  name: "Nova Ubiratã"},
  {stateId: 51,  id: 5106257,  name: "Nova Xavantina"},
  {stateId: 51,  id: 5106273,  name: "Novo Horizonte do Norte"},
  {stateId: 51,  id: 5106265,  name: "Novo Mundo"},
  {stateId: 51,  id: 5106315,  name: "Novo Santo Antônio"},
  {stateId: 51,  id: 5106281,  name: "Novo São Joaquim"},
  {stateId: 51,  id: 5106299,  name: "Paranaíta"},
  {stateId: 51,  id: 5106307,  name: "Paranatinga"},
  {stateId: 51,  id: 5106372,  name: "Pedra Preta"},
  {stateId: 51,  id: 5106422,  name: "Peixoto de Azevedo"},
  {stateId: 51,  id: 5106455,  name: "Planalto da Serra"},
  {stateId: 51,  id: 5106505,  name: "Poconé"},
  {stateId: 51,  id: 5106653,  name: "Pontal do Araguaia"},
  {stateId: 51,  id: 5106703,  name: "Ponte Branca"},
  {stateId: 51,  id: 5106752,  name: "Pontes e Lacerda"},
  {stateId: 51,  id: 5106778,  name: "Porto Alegre do Norte"},
  {stateId: 51,  id: 5106802,  name: "Porto dos Gaúchos"},
  {stateId: 51,  id: 5106828,  name: "Porto Esperidião"},
  {stateId: 51,  id: 5106851,  name: "Porto Estrela"},
  {stateId: 51,  id: 5107008,  name: "Poxoréu"},
  {stateId: 51,  id: 5107040,  name: "Primavera do Leste"},
  {stateId: 51,  id: 5107065,  name: "Querência"},
  {stateId: 51,  id: 5107156,  name: "Reserva do Cabaçal"},
  {stateId: 51,  id: 5107180,  name: "Ribeirão Cascalheira"},
  {stateId: 51,  id: 5107198,  name: "Ribeirãozinho"},
  {stateId: 51,  id: 5107206,  name: "Rio Branco"},
  {stateId: 51,  id: 5107578,  name: "Rondolândia"},
  {stateId: 51,  id: 5107602,  name: "Rondonópolis"},
  {stateId: 51,  id: 5107701,  name: "Rosário Oeste"},
  {stateId: 51,  id: 5107750,  name: "Salto do Céu"},
  {stateId: 51,  id: 5107248,  name: "Santa Carmem"},
  {stateId: 51,  id: 5107743,  name: "Santa Cruz do Xingu"},
  {stateId: 51,  id: 5107768,  name: "Santa Rita do Trivelato"},
  {stateId: 51,  id: 5107776,  name: "Santa Terezinha"},
  {stateId: 51,  id: 5107263,  name: "Santo Afonso"},
  {stateId: 51,  id: 5107792,  name: "Santo Antônio do Leste"},
  {stateId: 51,  id: 5107800,  name: "Santo Antônio do Leverger"},
  {stateId: 51,  id: 5107859,  name: "São Félix do Araguaia"},
  {stateId: 51,  id: 5107297,  name: "São José do Povo"},
  {stateId: 51,  id: 5107305,  name: "São José do Rio Claro"},
  {stateId: 51,  id: 5107354,  name: "São José do Xingu"},
  {stateId: 51,  id: 5107107,  name: "São José dos Quatro Marcos"},
  {stateId: 51,  id: 5107404,  name: "São Pedro da Cipa"},
  {stateId: 51,  id: 5107875,  name: "Sapezal"},
  {stateId: 51,  id: 5107883,  name: "Serra Nova Dourada"},
  {stateId: 51,  id: 5107909,  name: "Sinop"},
  {stateId: 51,  id: 5107925,  name: "Sorriso"},
  {stateId: 51,  id: 5107941,  name: "Tabaporã"},
  {stateId: 51,  id: 5107958,  name: "Tangará da Serra"},
  {stateId: 51,  id: 5108006,  name: "Tapurah"},
  {stateId: 51,  id: 5108055,  name: "Terra Nova do Norte"},
  {stateId: 51,  id: 5108105,  name: "Tesouro"},
  {stateId: 51,  id: 5108204,  name: "Torixoréu"},
  {stateId: 51,  id: 5108303,  name: "União do Sul"},
  {stateId: 51,  id: 5108352,  name: "Vale de São Domingos"},
  {stateId: 51,  id: 5108402,  name: "Várzea Grande"},
  {stateId: 51,  id: 5108501,  name: "Vera"},
  {stateId: 51,  id: 5105507,  name: "Vila Bela da Santíssima Trindade"},
  {stateId: 51,  id: 5108600,  name: "Vila Rica"},
  {stateId: 52,  id: 5200050,  name: "Abadia de Goiás"},
  {stateId: 52,  id: 5200100,  name: "Abadiânia"},
  {stateId: 52,  id: 5200134,  name: "Acreúna"},
  {stateId: 52,  id: 5200159,  name: "Adelândia"},
  {stateId: 52,  id: 5200175,  name: "Água Fria de Goiás"},
  {stateId: 52,  id: 5200209,  name: "Água Limpa"},
  {stateId: 52,  id: 5200258,  name: "Águas Lindas de Goiás"},
  {stateId: 52,  id: 5200308,  name: "Alexânia"},
  {stateId: 52,  id: 5200506,  name: "Aloândia"},
  {stateId: 52,  id: 5200555,  name: "Alto Horizonte"},
  {stateId: 52,  id: 5200605,  name: "Alto Paraíso de Goiás"},
  {stateId: 52,  id: 5200803,  name: "Alvorada do Norte"},
  {stateId: 52,  id: 5200829,  name: "Amaralina"},
  {stateId: 52,  id: 5200852,  name: "Americano do Brasil"},
  {stateId: 52,  id: 5200902,  name: "Amorinópolis"},
  {stateId: 52,  id: 5201108,  name: "Anápolis"},
  {stateId: 52,  id: 5201207,  name: "Anhanguera"},
  {stateId: 52,  id: 5201306,  name: "Anicuns"},
  {stateId: 52,  id: 5201405,  name: "Aparecida de Goiânia"},
  {stateId: 52,  id: 5201454,  name: "Aparecida do Rio Doce"},
  {stateId: 52,  id: 5201504,  name: "Aporé"},
  {stateId: 52,  id: 5201603,  name: "Araçu"},
  {stateId: 52,  id: 5201702,  name: "Aragarças"},
  {stateId: 52,  id: 5201801,  name: "Aragoiânia"},
  {stateId: 52,  id: 5202155,  name: "Araguapaz"},
  {stateId: 52,  id: 5202353,  name: "Arenópolis"},
  {stateId: 52,  id: 5202502,  name: "Aruanã"},
  {stateId: 52,  id: 5202601,  name: "Aurilândia"},
  {stateId: 52,  id: 5202809,  name: "Avelinópolis"},
  {stateId: 52,  id: 5203104,  name: "Baliza"},
  {stateId: 52,  id: 5203203,  name: "Barro Alto"},
  {stateId: 52,  id: 5203302,  name: "Bela Vista de Goiás"},
  {stateId: 52,  id: 5203401,  name: "Bom Jardim de Goiás"},
  {stateId: 52,  id: 5203500,  name: "Bom Jesus de Goiás"},
  {stateId: 52,  id: 5203559,  name: "Bonfinópolis"},
  {stateId: 52,  id: 5203575,  name: "Bonópolis"},
  {stateId: 52,  id: 5203609,  name: "Brazabrantes"},
  {stateId: 52,  id: 5203807,  name: "Britânia"},
  {stateId: 52,  id: 5203906,  name: "Buriti Alegre"},
  {stateId: 52,  id: 5203939,  name: "Buriti de Goiás"},
  {stateId: 52,  id: 5203962,  name: "Buritinópolis"},
  {stateId: 52,  id: 5204003,  name: "Cabeceiras"},
  {stateId: 52,  id: 5204102,  name: "Cachoeira Alta"},
  {stateId: 52,  id: 5204201,  name: "Cachoeira de Goiás"},
  {stateId: 52,  id: 5204250,  name: "Cachoeira Dourada"},
  {stateId: 52,  id: 5204300,  name: "Caçu"},
  {stateId: 52,  id: 5204409,  name: "Caiapônia"},
  {stateId: 52,  id: 5204508,  name: "Caldas Novas"},
  {stateId: 52,  id: 5204557,  name: "Caldazinha"},
  {stateId: 52,  id: 5204607,  name: "Campestre de Goiás"},
  {stateId: 52,  id: 5204656,  name: "Campinaçu"},
  {stateId: 52,  id: 5204706,  name: "Campinorte"},
  {stateId: 52,  id: 5204805,  name: "Campo Alegre de Goiás"},
  {stateId: 52,  id: 5204854,  name: "Campo Limpo de Goiás"},
  {stateId: 52,  id: 5204904,  name: "Campos Belos"},
  {stateId: 52,  id: 5204953,  name: "Campos Verdes"},
  {stateId: 52,  id: 5205000,  name: "Carmo do Rio Verde"},
  {stateId: 52,  id: 5205059,  name: "Castelândia"},
  {stateId: 52,  id: 5205109,  name: "Catalão"},
  {stateId: 52,  id: 5205208,  name: "Caturaí"},
  {stateId: 52,  id: 5205307,  name: "Cavalcante"},
  {stateId: 52,  id: 5205406,  name: "Ceres"},
  {stateId: 52,  id: 5205455,  name: "Cezarina"},
  {stateId: 52,  id: 5205471,  name: "Chapadão do Céu"},
  {stateId: 52,  id: 5205497,  name: "Cidade Ocidental"},
  {stateId: 52,  id: 5205513,  name: "Cocalzinho de Goiás"},
  {stateId: 52,  id: 5205521,  name: "Colinas do Sul"},
  {stateId: 52,  id: 5205703,  name: "Córrego do Ouro"},
  {stateId: 52,  id: 5205802,  name: "Corumbá de Goiás"},
  {stateId: 52,  id: 5205901,  name: "Corumbaíba"},
  {stateId: 52,  id: 5206206,  name: "Cristalina"},
  {stateId: 52,  id: 5206305,  name: "Cristianópolis"},
  {stateId: 52,  id: 5206404,  name: "Crixás"},
  {stateId: 52,  id: 5206503,  name: "Cromínia"},
  {stateId: 52,  id: 5206602,  name: "Cumari"},
  {stateId: 52,  id: 5206701,  name: "Damianópolis"},
  {stateId: 52,  id: 5206800,  name: "Damolândia"},
  {stateId: 52,  id: 5206909,  name: "Davinópolis"},
  {stateId: 52,  id: 5207105,  name: "Diorama"},
  {stateId: 52,  id: 5208301,  name: "Divinópolis de Goiás"},
  {stateId: 52,  id: 5207253,  name: "Doverlândia"},
  {stateId: 52,  id: 5207352,  name: "Edealina"},
  {stateId: 52,  id: 5207402,  name: "Edéia"},
  {stateId: 52,  id: 5207501,  name: "Estrela do Norte"},
  {stateId: 52,  id: 5207535,  name: "Faina"},
  {stateId: 52,  id: 5207600,  name: "Fazenda Nova"},
  {stateId: 52,  id: 5207808,  name: "Firminópolis"},
  {stateId: 52,  id: 5207907,  name: "Flores de Goiás"},
  {stateId: 52,  id: 5208004,  name: "Formosa"},
  {stateId: 52,  id: 5208103,  name: "Formoso"},
  {stateId: 52,  id: 5208152,  name: "Gameleira de Goiás"},
  {stateId: 52,  id: 5208400,  name: "Goianápolis"},
  {stateId: 52,  id: 5208509,  name: "Goiandira"},
  {stateId: 52,  id: 5208608,  name: "Goianésia"},
  {stateId: 52,  id: 5208707,  name: "Goiânia"},
  {stateId: 52,  id: 5208806,  name: "Goianira"},
  {stateId: 52,  id: 5208905,  name: "Goiás"},
  {stateId: 52,  id: 5209101,  name: "Goiatuba"},
  {stateId: 52,  id: 5209150,  name: "Gouvelândia"},
  {stateId: 52,  id: 5209200,  name: "Guapó"},
  {stateId: 52,  id: 5209291,  name: "Guaraíta"},
  {stateId: 52,  id: 5209408,  name: "Guarani de Goiás"},
  {stateId: 52,  id: 5209457,  name: "Guarinos"},
  {stateId: 52,  id: 5209606,  name: "Heitoraí"},
  {stateId: 52,  id: 5209705,  name: "Hidrolândia"},
  {stateId: 52,  id: 5209804,  name: "Hidrolina"},
  {stateId: 52,  id: 5209903,  name: "Iaciara"},
  {stateId: 52,  id: 5209937,  name: "Inaciolândia"},
  {stateId: 52,  id: 5209952,  name: "Indiara"},
  {stateId: 52,  id: 5210000,  name: "Inhumas"},
  {stateId: 52,  id: 5210109,  name: "Ipameri"},
  {stateId: 52,  id: 5210158,  name: "Ipiranga de Goiás"},
  {stateId: 52,  id: 5210208,  name: "Iporá"},
  {stateId: 52,  id: 5210307,  name: "Israelândia"},
  {stateId: 52,  id: 5210406,  name: "Itaberaí"},
  {stateId: 52,  id: 5210562,  name: "Itaguari"},
  {stateId: 52,  id: 5210604,  name: "Itaguaru"},
  {stateId: 52,  id: 5210802,  name: "Itajá"},
  {stateId: 52,  id: 5210901,  name: "Itapaci"},
  {stateId: 52,  id: 5211008,  name: "Itapirapuã"},
  {stateId: 52,  id: 5211206,  name: "Itapuranga"},
  {stateId: 52,  id: 5211305,  name: "Itarumã"},
  {stateId: 52,  id: 5211404,  name: "Itauçu"},
  {stateId: 52,  id: 5211503,  name: "Itumbiara"},
  {stateId: 52,  id: 5211602,  name: "Ivolândia"},
  {stateId: 52,  id: 5211701,  name: "Jandaia"},
  {stateId: 52,  id: 5211800,  name: "Jaraguá"},
  {stateId: 52,  id: 5211909,  name: "Jataí"},
  {stateId: 52,  id: 5212006,  name: "Jaupaci"},
  {stateId: 52,  id: 5212055,  name: "Jesúpolis"},
  {stateId: 52,  id: 5212105,  name: "Joviânia"},
  {stateId: 52,  id: 5212204,  name: "Jussara"},
  {stateId: 52,  id: 5212253,  name: "Lagoa Santa"},
  {stateId: 52,  id: 5212303,  name: "Leopoldo de Bulhões"},
  {stateId: 52,  id: 5212501,  name: "Luziânia"},
  {stateId: 52,  id: 5212600,  name: "Mairipotaba"},
  {stateId: 52,  id: 5212709,  name: "Mambaí"},
  {stateId: 52,  id: 5212808,  name: "Mara Rosa"},
  {stateId: 52,  id: 5212907,  name: "Marzagão"},
  {stateId: 52,  id: 5212956,  name: "Matrinchã"},
  {stateId: 52,  id: 5213004,  name: "Maurilândia"},
  {stateId: 52,  id: 5213053,  name: "Mimoso de Goiás"},
  {stateId: 52,  id: 5213087,  name: "Minaçu"},
  {stateId: 52,  id: 5213103,  name: "Mineiros"},
  {stateId: 52,  id: 5213400,  name: "Moiporá"},
  {stateId: 52,  id: 5213509,  name: "Monte Alegre de Goiás"},
  {stateId: 52,  id: 5213707,  name: "Montes Claros de Goiás"},
  {stateId: 52,  id: 5213756,  name: "Montividiu"},
  {stateId: 52,  id: 5213772,  name: "Montividiu do Norte"},
  {stateId: 52,  id: 5213806,  name: "Morrinhos"},
  {stateId: 52,  id: 5213855,  name: "Morro Agudo de Goiás"},
  {stateId: 52,  id: 5213905,  name: "Mossâmedes"},
  {stateId: 52,  id: 5214002,  name: "Mozarlândia"},
  {stateId: 52,  id: 5214051,  name: "Mundo Novo"},
  {stateId: 52,  id: 5214101,  name: "Mutunópolis"},
  {stateId: 52,  id: 5214408,  name: "Nazário"},
  {stateId: 52,  id: 5214507,  name: "Nerópolis"},
  {stateId: 52,  id: 5214606,  name: "Niquelândia"},
  {stateId: 52,  id: 5214705,  name: "Nova América"},
  {stateId: 52,  id: 5214804,  name: "Nova Aurora"},
  {stateId: 52,  id: 5214838,  name: "Nova Crixás"},
  {stateId: 52,  id: 5214861,  name: "Nova Glória"},
  {stateId: 52,  id: 5214879,  name: "Nova Iguaçu de Goiás"},
  {stateId: 52,  id: 5214903,  name: "Nova Roma"},
  {stateId: 52,  id: 5215009,  name: "Nova Veneza"},
  {stateId: 52,  id: 5215207,  name: "Novo Brasil"},
  {stateId: 52,  id: 5215231,  name: "Novo Gama"},
  {stateId: 52,  id: 5215256,  name: "Novo Planalto"},
  {stateId: 52,  id: 5215306,  name: "Orizona"},
  {stateId: 52,  id: 5215405,  name: "Ouro Verde de Goiás"},
  {stateId: 52,  id: 5215504,  name: "Ouvidor"},
  {stateId: 52,  id: 5215603,  name: "Padre Bernardo"},
  {stateId: 52,  id: 5215652,  name: "Palestina de Goiás"},
  {stateId: 52,  id: 5215702,  name: "Palmeiras de Goiás"},
  {stateId: 52,  id: 5215801,  name: "Palmelo"},
  {stateId: 52,  id: 5215900,  name: "Palminópolis"},
  {stateId: 52,  id: 5216007,  name: "Panamá"},
  {stateId: 52,  id: 5216304,  name: "Paranaiguara"},
  {stateId: 52,  id: 5216403,  name: "Paraúna"},
  {stateId: 52,  id: 5216452,  name: "Perolândia"},
  {stateId: 52,  id: 5216809,  name: "Petrolina de Goiás"},
  {stateId: 52,  id: 5216908,  name: "Pilar de Goiás"},
  {stateId: 52,  id: 5217104,  name: "Piracanjuba"},
  {stateId: 52,  id: 5217203,  name: "Piranhas"},
  {stateId: 52,  id: 5217302,  name: "Pirenópolis"},
  {stateId: 52,  id: 5217401,  name: "Pires do Rio"},
  {stateId: 52,  id: 5217609,  name: "Planaltina"},
  {stateId: 52,  id: 5217708,  name: "Pontalina"},
  {stateId: 52,  id: 5218003,  name: "Porangatu"},
  {stateId: 52,  id: 5218052,  name: "Porteirão"},
  {stateId: 52,  id: 5218102,  name: "Portelândia"},
  {stateId: 52,  id: 5218300,  name: "Posse"},
  {stateId: 52,  id: 5218391,  name: "Professor Jamil"},
  {stateId: 52,  id: 5218508,  name: "Quirinópolis"},
  {stateId: 52,  id: 5218607,  name: "Rialma"},
  {stateId: 52,  id: 5218706,  name: "Rianápolis"},
  {stateId: 52,  id: 5218789,  name: "Rio Quente"},
  {stateId: 52,  id: 5218805,  name: "Rio Verde"},
  {stateId: 52,  id: 5218904,  name: "Rubiataba"},
  {stateId: 52,  id: 5219001,  name: "Sanclerlândia"},
  {stateId: 52,  id: 5219100,  name: "Santa Bárbara de Goiás"},
  {stateId: 52,  id: 5219209,  name: "Santa Cruz de Goiás"},
  {stateId: 52,  id: 5219258,  name: "Santa Fé de Goiás"},
  {stateId: 52,  id: 5219308,  name: "Santa Helena de Goiás"},
  {stateId: 52,  id: 5219357,  name: "Santa Isabel"},
  {stateId: 52,  id: 5219407,  name: "Santa Rita do Araguaia"},
  {stateId: 52,  id: 5219456,  name: "Santa Rita do Novo Destino"},
  {stateId: 52,  id: 5219506,  name: "Santa Rosa de Goiás"},
  {stateId: 52,  id: 5219605,  name: "Santa Tereza de Goiás"},
  {stateId: 52,  id: 5219704,  name: "Santa Terezinha de Goiás"},
  {stateId: 52,  id: 5219712,  name: "Santo Antônio da Barra"},
  {stateId: 52,  id: 5219738,  name: "Santo Antônio de Goiás"},
  {stateId: 52,  id: 5219753,  name: "Santo Antônio do Descoberto"},
  {stateId: 52,  id: 5219803,  name: "São Domingos"},
  {stateId: 52,  id: 5219902,  name: "São Francisco de Goiás"},
  {stateId: 52,  id: 5220058,  name: "São João da Paraúna"},
  {stateId: 52,  id: 5220009,  name: "São João d'Aliança"},
  {stateId: 52,  id: 5220108,  name: "São Luís de Montes Belos"},
  {stateId: 52,  id: 5220157,  name: "São Luiz do Norte"},
  {stateId: 52,  id: 5220207,  name: "São Miguel do Araguaia"},
  {stateId: 52,  id: 5220264,  name: "São Miguel do Passa Quatro"},
  {stateId: 52,  id: 5220280,  name: "São Patrício"},
  {stateId: 52,  id: 5220405,  name: "São Simão"},
  {stateId: 52,  id: 5220454,  name: "Senador Canedo"},
  {stateId: 52,  id: 5220504,  name: "Serranópolis"},
  {stateId: 52,  id: 5220603,  name: "Silvânia"},
  {stateId: 52,  id: 5220686,  name: "Simolândia"},
  {stateId: 52,  id: 5220702,  name: "Sítio d'Abadia"},
  {stateId: 52,  id: 5221007,  name: "Taquaral de Goiás"},
  {stateId: 52,  id: 5221080,  name: "Teresina de Goiás"},
  {stateId: 52,  id: 5221197,  name: "Terezópolis de Goiás"},
  {stateId: 52,  id: 5221304,  name: "Três Ranchos"},
  {stateId: 52,  id: 5221403,  name: "Trindade"},
  {stateId: 52,  id: 5221452,  name: "Trombas"},
  {stateId: 52,  id: 5221502,  name: "Turvânia"},
  {stateId: 52,  id: 5221551,  name: "Turvelândia"},
  {stateId: 52,  id: 5221577,  name: "Uirapuru"},
  {stateId: 52,  id: 5221601,  name: "Uruaçu"},
  {stateId: 52,  id: 5221700,  name: "Uruana"},
  {stateId: 52,  id: 5221809,  name: "Urutaí"},
  {stateId: 52,  id: 5221858,  name: "Valparaíso de Goiás"},
  {stateId: 52,  id: 5221908,  name: "Varjão"},
  {stateId: 52,  id: 5222005,  name: "Vianópolis"},
  {stateId: 52,  id: 5222054,  name: "Vicentinópolis"},
  {stateId: 52,  id: 5222203,  name: "Vila Boa"},
  {stateId: 52,  id: 5222302,  name: "Vila Propício"},
  {stateId: 53,  id: 5300108,  name: "Brasília"}
];

@Injectable({
  providedIn: 'root'
})
export class StateCityService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {}

  listState() {
    try {

      dataState = dataState.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

      const result = dataState;
      return result;
    } catch(e) {
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error && e.error.message ? e.error.message : e.message
      });
      return [];
    }
  }

  listCity() {
    try {
      const result = dataCity;
      return result;
    } catch(e) {
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error && e.error.message ? e.error.message : e.message
      });
      return [];
    }
  }

  getState(id) {
    try {
      const result = dataState.filter(function(state){ return state.id == id})[0];
      return result;
    } catch(e) {
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error && e.error.message ? e.error.message : e.message
      });
      return null;
    }
  }

  getCity(id) {
    try {
      const result = dataCity.filter(function(city){ return city.id == id})[0];
      return result;
    } catch(e) {
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error && e.error.message ? e.error.message : e.message
      });
      return null;
    }
  }

  getCitiesByState(stateId) {
    try {
      const result = dataCity.filter(function(city){ return city.stateId == stateId});
      return result;
    } catch(e) {
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error && e.error.message ? e.error.message : e.message
      });
      return null;
    }
  }
}
