import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  static getToken(): string {
    return localStorage.getItem('token');
  }

  static getUserByToken() {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch {
        return null;
      }
    }
    return null;
  }

  static isUserAuthorized(allowedProfiles: Array<string>): boolean {
    const user = this.getUserByToken();
    if (user && user.profile && allowedProfiles.filter(ap => ap === user.profile).length > 0) {
      return true;
    }
    return false;
  }
}
