import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { CourseService } from '../services/course/course.service';
import { StateCityService } from '../services/state-city.service';

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.scss']
})
export class MyCoursesComponent implements OnInit {

  courses: any[] = [];
  states: any[] = [];
  teamLeaderCourses: any[] = [];

  constructor(
    private courseService: CourseService,
    private stateCityService: StateCityService,
    private router: Router
  ) { }

  async ngOnInit() {
    let courses = await this.courseService.listUserCourses();
    for (let course of courses) {
      if (this.courses.filter(c => c.courseId == course.courseId).length == 0) {
        this.courses.push(course);
      }
    }
    this.states = this.stateCityService.listState();
    this.teamLeaderCourses = await this.courseService.listTeamLeaderCourse();
    for (let i = 0; i < this.courses.length; i++) {
      let state = this.states.find(s => s.id == this.courses[i].course.state);
      let city = this.stateCityService.getCity(this.courses[i].course.city);
      this.courses[i].course.cityName = city ? city.name : 'Online';
      this.courses[i].course.stateName = state ? state.name : '';
    }
  }

  seeCourse(id: any) {
    this.router.navigate(['/curso/' + id]);
  }

  getCity(cityId) {
    const city = this.stateCityService.getCity(cityId);
    return city && city.name ? city.name : '';
  }

  getState(stateId) {
    const state = this.stateCityService.getState(stateId);
    return state && state.name ? state.name : '';
  }

}
