// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: 'http://ec2-18-231-40-243.sa-east-1.compute.amazonaws.com:3010/',
  api: 'https://portalibfbackend.azurewebsites.net/',
  // api: 'http://localhost:3010/',
  appUrl: 'http://portalibf.com.br/',
  pagseguroCallBackUrl: 'http://portalibf.com.br/course/929bc521-11ce-11ec-b17d-01bc9fe6742d',
  // api: 'http://ec2-18-231-40-243.sa-east-1.compute.amazonaws.com:3010/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
