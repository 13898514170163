import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../services/notification/notification.service';
import { StateCityService } from '../services/state-city.service';
import { UserService } from '../services/user/user.service';
import { cellPhoneValidate, cpfValidate, emailValidate } from '../utils/formValidator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  user = {
    createdAt: new Date(),
    name: null,
    email: null,
    gender: null,
    birthDate: null,
    city: null,
    state: null,
    address: null,
    district: null,
    complement: null,
    cellPhone: null,
    document: null,
    civilState: null,
    spouseName: null,
    spouseEmail: null,
    spouseCellPhone: null,
    children: null,
    optinNewsletter: null,
    profileId: null,
    hasChild: false,
    password: null,
    confirmPassword: null
  };

  civilState = [
    { id: 1, name: 'Solteiro(a)' },
    { id: 2, name: 'Casado(a)' },
  ];

  genders = [
    { id: 1, name: 'Masculino' },
    { id: 2, name: 'Feminino' },
  ];

  states = null;
  cities = null;

  leadCourseId = null;

  constructor(
    private stateCityService: StateCityService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.states = this.stateCityService.listState();
    if (this.route.snapshot.paramMap.get('courseId')) {
      this.leadCourseId = this.route.snapshot.paramMap.get('courseId');
    }
  }

  validateRegister() {
    let lastName = this.user.name.split(' ')
    if (!lastName) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome completo para prosseguir'
      });
      return false;
    }
    lastName = lastName.filter(l => l.length > 0);
    if (!this.user.name || lastName.length <= 1) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome completo para prosseguir'
      });
      return false;
    }
    if (!this.user.email || !emailValidate(this.user.email)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um e-mail válido para prosseguir'
      });
      return false;
    }
    if (!this.user.cellPhone || !cellPhoneValidate(this.user.cellPhone)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um celular válido para prosseguir'
      });
      return false;
    }
    if (!this.user.document || !cpfValidate(this.user.document)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha um CPF válido para prosseguir'
      });
      return false;
    }
    if (!this.user.password || this.user.password.length < 6) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha uma senha de ao menos 6 caracteres.'
      });
      return false;
    }

    if (this.user.password != this.user.confirmPassword) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'As senhas não batem!'
      });
      return false;
    }
    // if(!this.user.birthDate) {
    //   this.notificationService.notification$.next({
    //     type: 'warning',
    //     message: 'Preencha uma data de nascimento válida para prosseguir'
    //   });
    //   return false;
    // }
    // if(!this.user.state) {
    //   this.notificationService.notification$.next({
    //     type: 'warning',
    //     message: 'Selecione um estado para prosseguir'
    //   });
    //   return false;
    // }
    // if(!this.user.city) {
    //   this.notificationService.notification$.next({
    //     type: 'warning',
    //     message: 'Selecione uma cidade para prosseguir'
    //   });
    //   return false;
    // }
    // if(!this.user.address) {
    //   this.notificationService.notification$.next({
    //     type: 'warning',
    //     message: 'Preencha um endereço para prosseguir'
    //   });
    //   return false;
    // }
    // if(!this.user.district) {
    //   this.notificationService.notification$.next({
    //     type: 'warning',
    //     message: 'Preencha um bairro para prosseguir'
    //   });
    //   return false;
    // }
    // if(!this.user.civilState) {
    //   this.notificationService.notification$.next({
    //     type: 'warning',
    //     message: 'Selecione um estado civil para prosseguir'
    //   });
    //   return false;
    // }
    // if(this.user.civilState != 1) {
    //   if(this.user.hasChild == null || this.user.children == null) {
    //     this.notificationService.notification$.next({
    //       type: 'warning',
    //       message: 'Preencha a quantidade de filhos para prosseguir'
    //     });
    //     return false;
    //   }
    //   if(this.user.civilState == 2 && !this.user.spouseName) {
    //     this.notificationService.notification$.next({
    //       type: 'warning',
    //       message: 'Preencha o nome de seu cônjuge para prosseguir'
    //     });
    //     return false;
    //   }
    //   if(this.user.civilState == 2 && !this.user.spouseEmail) {
    //     this.notificationService.notification$.next({
    //       type: 'warning',
    //       message: 'Preencha o e-mail de seu cônjuge para prosseguir'
    //     });
    //     return false;
    //   }
    //   if(this.user.civilState == 2 && !this.user.spouseCellPhone) {
    //     this.notificationService.notification$.next({
    //       type: 'warning',
    //       message: 'Preencha o telefone de seu cônjuge para prosseguir'
    //     });
    //     return false;
    //   }
    // }
    return true;
  }

  async register() {
    if (this.validateRegister()) {

      this.user.email = this.user.email.trim().toLowerCase();
      
      const result = await this.userService.createUser(this.user);
      if (result) {
        this.notificationService.notification$.next({
          type: 'success',
          message: 'Usuário criado com sucesso'
        });
        const result = await this.userService.login(this.user.email, this.user.password);

        let subscriptionLink = localStorage.getItem('redirectCourseSubscription');

        if(subscriptionLink) {
          this.router.navigate(['/curso/inscricao/' + subscriptionLink]);
          localStorage.removeItem('redirectCourseSubscription');
          return;
        }


        if (this.leadCourseId) {
          return this.router.navigate(['/curso/inscricao/' + this.leadCourseId]);
        }
        return this.router.navigate(['/dashboard']);
      }
    }
  }

  changeState(stateId) {
    this.user.city = null;
    this.cities = this.stateCityService.getCitiesByState(stateId);
  }

  login() {
    if (this.leadCourseId) {
      return this.router.navigate(['/login/' + this.leadCourseId]);
    }
    this.router.navigate(['/login']);
  }

}
